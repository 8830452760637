import React, { useEffect, useState } from "react";
import classes from "./createneworganisationmain.module.scss";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionCreateOrganizationApiCall,
  actionGetAutoSuggestCityApiCall,
} from "../../../../Redux/Actions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  validateEmail,
  validateImageSize,
  validateName,
  validatePhone,
  validateText,
  validateWebsite,
} from "../../../../utils/commonFunctions";

const CreateNewOrganisationMain = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let close = props.handleClose;
  const loading = useSelector((state) => state.CommonReducer.loader);
  const [previewImg, setPreviewImg] = useState();
  const [description, setDescription] = useState("");
  const [focus, setFocus] = useState(false);
  const [errors, setError] = useState({});
  const [values, setValues] = useState({});
  const [previewBannerImg, setPreviewBannerImg] = useState();
  const autoSuggestedCity = useSelector(
    (state) => state.CommonReducer.autoSuggestedCity
  );
  useEffect(() => {
    if (autoSuggestedCity) {
      setValues({
        ...values,
        city: autoSuggestedCity[0]?.city?.name,
        zipcode_id: autoSuggestedCity[0]?.id,
        street: autoSuggestedCity[0]?.street,
      });
    }
  }, [autoSuggestedCity]);
  useEffect(() => {
    if (values?.house_number && values?.postal_code?.length == 6) {
      dispatch(
        actionGetAutoSuggestCityApiCall({
          house_no: values.house_number,
          zipcode: values.postal_code,
        })
      );
    }
  }, [values?.postal_code, values?.house_number]);

  let checkValidation = (value, name) => {
    let dataText;
    if (value !== "") {
      if (name.includes("name")) {
        dataText = validateName(value, name);
        setErrorFunc(dataText, name);
      } else if (name.includes("email")) {
        dataText = validateEmail(value, name);
        setErrorFunc(dataText, name);
      } else if (name.includes("phone")) {
        dataText = validatePhone(value, name);
        setErrorFunc(dataText, name);
      } else if (name.includes("web")) {
        dataText = validateWebsite(value, name);
        setErrorFunc(dataText, name);
      } else if (name === "logo" || name === "banner") {
        dataText = validateImageSize(value, name);
        setErrorFunc(dataText, name);
      }
    } else {
      dataText = validateText(value, name);
      setErrorFunc(dataText, name);
    }
  };
  const setErrorFunc = (dataText, name) => {
    setError((prev) => ({
      ...prev,
      [name]: dataText?.isValid ? "" : dataText.message,
    }));
  };
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type == "file") {
      setValues({
        ...values,
        [name]: e.target.files[0],
      });
      checkValidation(e.target.files[0], name);
    } else {
      setValues({
        ...values,
        [name]: value.trim(),
      });
      if (name != "shortDescription") checkValidation(value, name);
    }
  };
  const onFocus = (event) => {
    let { name, value } = event.target;
    if (name != "shortDescription") {
      checkValidation(name, value);
    }
  };

  const onBlur = (event) => {
    let { name, value } = event.target;
    if (name != "shortDescription") {
      checkValidation(name, value);
    }
  };

  const createOrg = () => {
    checkValidation(values.org_name ? values.org_name : "", "org_name");
    checkValidation(values.banner ? values.banner : "", "banner");
    checkValidation(values.email ? values.email : "", "email");
    checkValidation(values.logo ? values.logo : "", "logo");
    checkValidation(values.website ? values.website : "", "website");
    checkValidation(values.phone ? values.phone : "", "phone");
    checkValidation(
      values.house_number ? values.house_number : "",
      "house_number"
    );
    checkValidation(
      values.postal_code ? values.postal_code : "",
      "postal_code"
    );
    if (
      Object.values(errors).every((value) => value == "" && values.org_name) &&
      values.logo &&
      values.banner
    ) {
      apiCall();
    }
  };
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const apiCall = () => {
    const data = new FormData();
    data.append("org_name", values.org_name);
    data.append("zipcode_id", values.zipcode_id);
    data.append("street", values.street);
    data.append("email", values.email);
    data.append("city", values.city);
    data.append("postcode", values.postal_code);
    data.append("file", values.logo);
    data.append("number", values.house_number);
    data.append("banner", values.banner);
    data.append("website", values.website);
    data.append("phone", values.phone);
    data.append("shortDescription", values.shortDescription);
    data.append("description", description);
    values?.import_type && data.append("import_type", values.import_type);
    values?.import_id && data.append("import_id", values.import_id);
    const body = { data, navigate, close };
    dispatch(actionCreateOrganizationApiCall(body));
  };
  const onChangePicture = (e) => {
    handleChange(e);
    setPreviewImg(URL.createObjectURL(e.target.files[0]));
  };
  const handleDrop = (e) => {
    e.preventDefault();
    handleChange(e);
    const droppedFile = e.dataTransfer.files[0];
    setPreviewImg(URL.createObjectURL(droppedFile));
  };
  const onChangeBannerPicture = (e) => {
    handleChange(e);
    setPreviewBannerImg(URL.createObjectURL(e.target.files[0]));
  };
  const handleBannerDrop = (e) => {
    e.preventDefault();
    handleChange(e);
    const droppedFile = e.dataTransfer.files[0];
    setPreviewBannerImg(URL.createObjectURL(droppedFile));
  };
  const dataProviderArray = [
    { label: "Realworks", value: "TIARA" },
    { label: "Kolibri", value: "KOLIBRI" },
    { label: "Skarabee", value: "SKARABEE" },
    { label: "Other", value: "OTHER" },
    { label: "None", value: "" },
  ];
  return (
    <div className={classes.CreateOrgMain}>
      <h3>{t("create_new_organisation")}</h3>
      <div className={classes.CarteOrgSection}>
        <div className={classes.CarteOrgWarp}>
          <h6>{t("upload_logo")}</h6>
          <div
            className={classes.uploadMain}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            name="logo"
          >
            <input
              type="file"
              id="upload"
              accept=".jpg, .jpeg, .png,.webp,.svg"
              hidden
              name="logo"
              onChange={onChangePicture}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <label htmlFor="upload">
              {previewImg ? (
                <div className={classes.PhotoUpload}>
                  <img className={classes.img1} src={previewImg} />
                </div>
              ) : (
                <div className={classes.PhotoUpload}>
                  <img src={FileUploadIcon} />
                </div>
              )}
            </label>
          </div>
          {errors.logo && (
            <p className={`${classes.errorText}`}>{errors.logo}</p>
          )}
          <div className={classes.linkInputWrap}>
            <p>{t("organisation_name")}</p>
            <input
              type="text"
              name="org_name"
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {errors.org_name && (
              <p className={`${classes.errorText}`}>{errors.org_name}</p>
            )}
          </div>
          <div className={classes.InputWrap}>
            <div className={classes.FildBox}>
              <p>{t("house_number")}</p>
              <input
                type="number"
                name="house_number"
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              {errors.house_number && (
                <p className={`${classes.errorText}`}>{errors.house_number}</p>
              )}
            </div>
            <div className={classes.FildBox}>
              <p>{t("postal_code")}</p>
              <input
                type="text"
                name="postal_code"
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              {errors.postal_code && (
                <p className={`${classes.errorText}`}>{errors.postal_code}</p>
              )}
            </div>
          </div>
          <div className={classes.InputWrap}>
            <div className={classes.FildBox}>
              <p>{t("city")}</p>
              <input disabled value={values.city} />
            </div>
            <div className={classes.FildBox}>
              <p>{t("street_name")}</p>
              <input disabled value={values.street} />
            </div>
          </div>
          <div className={classes.linkInputWrap}>
            <p>{t("organisation_e_mail")}</p>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          {errors.email && (
            <p className={`${classes.errorText}`}>{errors.email}</p>
          )}
          <div className={classes.linkInputWrap}>
            <p>{t("website")}</p>
            <input
              type="text"
              name="website"
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          {errors.website && (
            <p className={`${classes.errorText}`}>{errors.website}</p>
          )}
          <div className={classes.InputWrap}>
            <div className={classes.FildBox}>
              <p>{t("classification")}</p>
              <input
                type="text"
                name="classification"
                disabled
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </div>
            <div className={classes.FildBox}>
              <p>{t("phonenumber")}</p>
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                maxLength="10"
                minLength="8"
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleKeyPressForNumber}
              />
              {errors.phone && (
                <p className={`${classes.errorText}`}>{errors.phone}</p>
              )}
            </div>
          </div>
          <div className={classes.InputWrap}>
            <div className={classes.FildBox}>
              <p>{t("data_provider")}</p>
              <select name="import_type" onChange={handleChange}>
                <option value="" disabled selected>
                  {`${t("data_provider")}..`}
                </option>
                {dataProviderArray.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className={classes.FildBox}>
              <p>{t("data_provider_id")}</p>
              <input
                type="text"
                name="import_id"
                onChange={handleChange}
                maxLength="10"
                minLength="8"
                onKeyDown={handleKeyPressForNumber}
              />
            </div>
          </div>
        </div>
        <div className={classes.CarteBannerWrap}>
          <h6>{t("upload_banner")}</h6>
          <div
            className={classes.uploadMain}
            onDrop={handleBannerDrop}
            onDragOver={(e) => e.preventDefault()}
            name="banner"
          >
            <input
              type="file"
              id="upload1"
              accept=".jpg, .jpeg, .png,.webp,.svg"
              hidden
              name="banner"
              onChange={onChangeBannerPicture}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <label htmlFor="upload1">
              {previewBannerImg ? (
                <div className={classes.PhotoUpload}>
                  <img className={classes.img1} src={previewBannerImg} />
                </div>
              ) : (
                <div className={classes.PhotoUpload}>
                  <img src={FileUploadIcon} />
                </div>
              )}
            </label>
          </div>
          {errors.banner && (
            <p className={`${classes.errorText}`}>{errors.banner}</p>
          )}
          <div className={classes.DescriptionWrap}>
            <p>{t("short_description")}</p>
            <textarea
              name="shortDescription"
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          <div className={classes.DescriptionWrap}>
            <p className={classes.TitleMain}>{t("full_description")}</p>
            <CKEditor
              editor={ClassicEditor}
              onChange={(event, editor) => {
                const data = editor.getData();
                let temp = { data };
                if (focus) setDescription(temp.data);
              }}
              onFocus={() => setFocus(true)}
            />
          </div>
        </div>
      </div>
      <div className={classes.btnWrap}>
        <button onClick={createOrg}>
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {t("create")}
        </button>
      </div>
    </div>
  );
};

export default CreateNewOrganisationMain;
