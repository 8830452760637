import React from "react";
import classes from "./emailmanagementpage.module.scss";
import EmailManagementMain from "../../components/EmailManagementMain";

const EmailManagementPage = () => {
	return (
		<React.Fragment>
			<EmailManagementMain />
		</React.Fragment>
	);
};

export default EmailManagementPage;
