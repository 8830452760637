// import React, { useEffect, useRef, useState } from "react";
// import classes from "./emailcampaign.module.scss";
// import { useTranslation } from "react-i18next";
// import SearchIcon from "../../../assets/images/SearchIcon.svg";
// import moment from "moment";
// import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import {
//   actionCreateNewCLick,
//   getCampaignApiCall,
// } from "../../../Redux/Actions";
// import CreateCampaign from "../../CreateCampaignForm/Index";

// const EmailCampaign = () => {
//   const [open, setOpen] = useState(false);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { t } = useTranslation();
//   const CampaignsData = useSelector(
//     (state) => state.CommonReducer?.savedNewCampaigns
//   );
//   useEffect(() => {
//     dispatch(getCampaignApiCall());
//   }, []);
//   const [campaignSearch, setAddCampaignSearch] = useState();
//   const [editformData,setEditformData] = useState(null);
  
//   let pathname = useLocation().pathname;
//   pathname = pathname.substring(pathname.lastIndexOf("/") + 1);

//   const [windowSize, setWindowSize] = useState(getWindowSize());

//   function getWindowSize() {
//     return window.innerWidth;
//   }
//   const handleClick = () => {
//     dispatch(actionCreateNewCLick("true"));  
//     setOpen(!open);
//   };
//   const handleEdit =(data,e)=>{
//     if(e == "edit"){
//       dispatch(actionCreateNewCLick("false"));  
//       setEditformData(data)
//       setOpen(!open);
//     }
//   }
//   return (
//     <React.Fragment>
//       <div className={classes.detailPage}>
//         <div className={classes.container}>
//           <div className={classes.address}>
//             <div className={classes.headerwrap}>
//               <p>{open ? t("Create a New Campaign") : t("Campaigns")}</p>
//               <button onClick={handleClick}>
//                 {open ? "Back" : "Create New"}
//               </button>
//             </div>
//             {open ? (
//               <CreateCampaign editformData={editformData} />
//             ) : (
//               <div className={classes.addressContainer}>
//                 <div className={classes.tableContainer}>
//                   <div className={classes.SearchBar}>
//                     <img src={SearchIcon} alt="" />
//                     <input
//                       type="search"
//                       placeholder="Search Campaigns"
//                       value={campaignSearch}
//                       onChange={(e) => setAddCampaignSearch(e.target.value)}
//                     />
//                   </div>
//                   <div className={classes.TableListngLayOut}>
//                     <table>
//                       <tr>
//                         <th></th>
//                         <th>{t("Campaigns")}</th>
//                         <th>{t("Status")}</th>
//                         <th>{t("Actions")}</th>
//                         <th></th>
//                       </tr>
//                       {CampaignsData && CampaignsData.length > 0 ? (
//                         CampaignsData?.map((item) => (
//                           <>
//                             <tr key={item?.id}>
//                               <td>
//                                 <p></p>
//                               </td>
//                               <td>
//                                 <span className={classes.title}>
//                                   <h4>{item.campaign_title}</h4>
//                                 </span>
//                                 <br />
//                                 <span>Branche: {item?.branch} </span>
//                                 <br />
//                                 <span>
//                                   Classifications: {item?.classification}
//                                 </span>
//                                 <br />
//                                 <span>
//                                   {" "}
//                                   Tags: {(item?.campaign_tag_list?.map((item)=>item.tag_name).join(", "))}
//                                 </span>
//                                 <br />
//                                 <br />
//                                 {item?.status == false ?
//                                  <span>
//                                  {" "}
//                                  Edited:{" "}
//                                  {moment(item?.updated_at).format("LLL")}
//                                </span> 
//                                : 
//                                <>
//                                <span>
//                                {" "}
//                                Sent:{" "}
//                                {moment(item?.send_date).format("MMM Do YY")} | {item?.send_time}
//                                {console.log(item?.send_time, "time")}
//                              </span>
//                              <br/>
//                                <span>
//                                {" "}
//                                Recipients:{" "}
//                                {item?.recipients}
//                              </span>
//                                </>
//                                  }  
//                                 <br/>
//                               </td>
//                               <td>
//                                 <div
//                                   style={{
//                                     display: "flex",
//                                     justifyContent: "space-between",
//                                     alignItems: "center",
//                                     paddingRight: "1rem",
//                                   }}
//                                 >
//                                   <button
//                                     className={
//                                       item?.status == true
//                                         ? classes.sent
//                                         : classes.draft
//                                     }
//                                     >
//                                     {console.log(item?.status, "statuss")}
//                                     {item?.status == true ? "Sent" : "Draft"}
//                                   </button>
//                                   <div style={{ marginTop: "1rem" }}>
//                                     <p></p>
//                                     <p></p>
//                                   </div>
//                                   <div style={{ marginTop: "1rem" }}>
//                                     <p></p>
//                                     <p></p>
//                                   </div>
//                                 </div>
//                               </td>
//                               <td>
//                                 <select onChange={(e) =>
//                                     handleEdit(item, e.target.value)
//                                   } className={classes.select}>
//                                   <option defaultChecked>Options</option>
//                                   <option value="edit">Edit</option>
//                                   <option value = "delete">Deleteeeeee</option>
//                                 </select>
//                               </td>
//                             </tr>
//                           </>
//                         ))
//                       ) : (
//                         <>
//                           {" "}
//                           <tr>
//                             <td>
//                               <p></p>
//                             </td>
//                             <p>No data</p>
//                           </tr>
//                         </>
//                       )}
//                     </table>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default EmailCampaign;

import React, { useEffect, useState } from "react";
import classes from "./emailcampaign.module.scss";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  actionCreateNewCLick,
  deleteCampaignRequest,
  getCampaignApiCall,
} from "../../../Redux/Actions";
import CreateCampaign from "../../CreateCampaignForm/Index";

const EmailCampaign = () => {   
  const [open, setOpen] = useState(false);
  const [campaignSearch, setAddCampaignSearch] = useState("");
  const [editformData, setEditformData] = useState(null);
  const [edit, setEdit] = useState(false)
  const [selectedValue, setSelectedValue] = useState("options");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const CampaignsData = useSelector(
    (state) => state.CommonReducer?.savedNewCampaigns
  );
  useEffect(() => {
    dispatch(getCampaignApiCall());
  }, []);
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);

  const handleClick = () => {
    dispatch(actionCreateNewCLick("true"));
    dispatch(getCampaignApiCall());
    setEdit(false)
    setOpen(!open);
  };

  const handleEdit = (data, e) => {
    console.log(data,"data from dataa")
    console.log("eeeeeeeeeeeee", e)
    if (e === "edit") {
      dispatch(actionCreateNewCLick("false"));
      setEditformData(data);
      setEdit(true)
      setOpen(!open);
    }
     else if (e === "Delete") {
      console.log(data,"data id i gguesss")
      setEdit(false)
    dispatch(deleteCampaignRequest(data.id));
    setTimeout(() => {
      dispatch(getCampaignApiCall());
    }, 1000);
  }
  // setSelectedValue("options");
  };

  let filteredCampaigns = [];
  if (
    Array.isArray(CampaignsData) &&
    CampaignsData.length &&
    typeof CampaignsData.filter === "function"
  ) {
    filteredCampaigns = CampaignsData.filter((item) =>
      item.campaign_title.toLowerCase().includes(campaignSearch.toLowerCase())
    );
  }


  return (
    <React.Fragment>
      <div className={classes.detailPage}>
        <div className={classes.container}>
          <div className={classes.address}>
            <div className={classes.headerwrap}>
              <p>{open ? t("Create a New Campaign") : t("Campaigns")}</p>
              <button onClick={handleClick}>
                {open ? "Back" : "Create New"}
              </button>
            </div>
            {open ? (
              <CreateCampaign  editformData={edit && editformData} />
            ) : (
              <div className={classes.addressContainer}>
                <div className={classes.tableContainer}>
                  <div className={classes.SearchBar}>
                    <img src={SearchIcon} alt="" />
                    <input
                      type="search"
                      placeholder="Search Campaigns"
                      value={campaignSearch}
                      onChange={(e) => setAddCampaignSearch(e.target.value)}
                    />
                  </div>
                  <div className={classes.TableListngLayOut}>
                    <table>
                      <tr>
                        <th></th>
                        <th>{t("Campaigns")}</th>
                        <th>{t("Status")}</th>
                        <th>{""}</th>
                        <th>{t("Actions")}</th>
                        <th></th>
                      </tr>
                      {filteredCampaigns && filteredCampaigns.length > 0 ? (
                        filteredCampaigns.map((item) => (
                          <tr key={item?.id}>
                            <td>
                              <p></p>
                            </td>
                            <td>
                              <span className={classes.title}>
                                <h4>{item.campaign_title}</h4>
                              </span>
                              <br />
                              <span>Branche: {item?.branch} </span>
                              <br />
                              <span>
                                Classifications: {item?.classification}
                              </span>
                              <br />
                              <span>
                                Tags:{" "}
                                {item?.campaign_tag_list
                                  ?.map((tag) => tag.tag_name)
                                  .join(", ")}
                              </span>
                              <br />
                              <br />
                              {item?.status === false ? (
                                <span>
                                  {" "}
                                  Edited:{" "}
                                  {moment(item?.updated_at).format("LLL")}
                                </span>
                              ) : (
                                <>
                                  <span>
                                    {" "}
                                    Sent:{" "}
                                    {moment(item?.send_date).format(
                                      "MMM Do YY"
                                    )}{" "}
                                    | {item?.send_time}
                                  </span>
                                  <br />
                                  <span>
                                    {" "}
                                    Recipients: {item?.recipients}
                                  </span>
                                </>
                              )}
                              <br />
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingRight: "1rem",
                                }}
                              >
                                <button
                                  className={
                                    item?.status === true
                                      ? classes.sent
                                      : classes.draft
                                  }
                                >
                            
                                  {item?.status === true ? "Sent" : "Draft"}
                                </button>
                                <div style={{ marginTop: "1rem" }}>
                                  <p></p>
                                  <p></p>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <p></p>
                                  <p></p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style={{ display: "flex", gap: "20px" }}>
                                <div>
                              <span style={{color: "#595f72",fontSize: "14px",fontWeight: "bold"}}>{item?.opens}</span><br/>
                              <span>Opens</span>
                                </div>
                                <div>
                              <span style={{color: "#595f72",fontSize: "14px",fontWeight: "bold"}}>{item?.click}</span><br/>
                              <span>Clicks</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <select
                                onChange={(e) =>
                                  handleEdit(item, e.target.value)
                                }
                                className={classes.select}
                                // value={selectedValue}
                              >
                                <option defaultChecked>Options</option>
                                <option value="edit">Edit</option>
                                <option >Delete</option>
                                
                              </select>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <p></p>
                          </td>
                          <p>No data</p>
                        </tr>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailCampaign;

