import React from "react";
import SingleCampaignDetailed from "../../components/SingleCampaignDetailed/index";

const SingleInvoicePage = () => {

  return (
    <React.Fragment>
      <SingleCampaignDetailed />
    </React.Fragment>
  );
};

export default SingleInvoicePage;
