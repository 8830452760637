import React, { useEffect, useRef, useState } from "react";
import MainLayount from "../common/MainLayout";
import classes from "./discountAndextramain.module.scss";
import DiscountAndExtraHeader from "../common/DiscountAndExtraHeader";
import { useTranslation } from "react-i18next";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionDeleteDiscountAndExtrasApiCall,
  actionGetDiscountAndExtraApiCall,
} from "../../Redux/Actions";
import Loader from "../common/Loader";
import moment from "moment";
import ModalMain from "../common/Modal";
import CreateDiscount from "../ModalComponents/DiscountAndExtraModal/CreateDiscount";

const DiscountAndExtraMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const [addDiscount, setAddDiscount] = useState(false);

  const discountData = useSelector(
    (state) => state.CommonReducer.saveDiscountAndExtrasData
  );
  const loader = useSelector((state) => state.CommonReducer.loader);
  const listRef = useRef(null);
  const divref = useRef([]);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 400) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const Close = () => setAddDiscount(false);

  const onCLickDeleteDiscount = (id) => {
    const data = id;
    const body = { data, navigate };
    dispatch(actionDeleteDiscountAndExtrasApiCall(body));
  };
  const onClickEditDiscount = (id) => {
    const data = id;
    const body = { data, navigate, edit: true };
    dispatch(actionGetDiscountAndExtraApiCall(body));
    setAddDiscount(true);
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.DiscountAndExtraMain}>
          <DiscountAndExtraHeader />
          <div className={classes.SubscriptionSection}>
            <table>
              <tr>
                <th>
                  {t("name")}
                  <span className={classes.dot} />
                </th>
                <th>{t("type_c")}</th>
                <th>{t("start_on")}</th>
                <th>{t("runs_till")}</th>
                <th></th>
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {discountData.length <= 0 ? (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={6}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  ) : (
                    discountData.map((item, index) => {
                      return (
                        <tr ref={(e) => (divref.current[index] = e)}>
                          <td>{item.name}</td>
                          <td>
                            {item.type == "DISCOUNT"
                              ? t("discount")
                              : t("extra")}
                          </td>
                          <td>
                            {item.valid_from
                              ? moment(item.valid_from).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                          <td>
                            {item.valid_until
                              ? moment(item.valid_until).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                          <td className={classes.userEditor}>
                            <img
                              src={userEditorIcon}
                              onClick={() => {
                                if (discountData.length - 1 > index) {
                                  handleScroll(index);
                                }
                                if (ind !== index) {
                                  setShow(!show);
                                  setInd(index);
                                } else {
                                  setInd("");
                                }
                              }}
                            />
                            {show && ind == index && (
                              <div
                                ref={listRef}
                                style={
                                  discountData.length - 2 > index
                                    ? {}
                                    : { top: "-100px" }
                                }
                                className={classes.EditorWrap}
                                onClick={() => setShow(!show)}
                              >
                                <ul>
                                  <li
                                    onClick={() => onClickEditDiscount(item.id)}
                                  >
                                    {t("edit_discount")}
                                  </li>
                                  <li
                                    onClick={() =>
                                      onCLickDeleteDiscount(item.id)
                                    }
                                  >
                                    {t("delete_discount_c")}
                                  </li>
                                </ul>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
      <ModalMain showmodel={addDiscount} handleClose={Close} size={"md"}>
        <CreateDiscount edit={true} close={Close} />
      </ModalMain>
    </React.Fragment>
  );
};

export default DiscountAndExtraMain;
