import React, { useEffect } from "react";
import classes from "./districtheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import { useState } from "react";
import DistrictForm from "../DistrictForm";
import { actionGetDistrictTableSystemApiCall } from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../CustomSelect";
import SideBar from "../SideBar";

const DistrictHeader = () => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);
  const [headerSearch, setHeaderSearch] = useState({
    name: "",
    city_id: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.CommonReducer);

  const SelectItems = {
    name: "city_id",
    apiName: "city",
    placeholder: t("city_p"),
    options:
      state?.getCityData &&
      state?.getCityData?.map((p) => {
        return { label: p.name, value: p.id };
      }),
  };

  const [sidebar, setSideBar] = useState(false);

  const filterClick = () => {
    const body = {
      navigate,
      page: 1,
      search: headerSearch.name,
      city_id: headerSearch?.city_id ? headerSearch?.city_id?.value : "",
    };
    dispatch(actionGetDistrictTableSystemApiCall(body));
  };

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")}{" "}
              {t("district")}
            </h5>
            <form onSubmit={onClickOfButton} className={classes.managementFild}>
              <div className={classes.fildBox}>
                <input
                  type="text"
                  placeholder={`${t("name_p")}...`}
                  onChange={(e) =>
                    setHeaderSearch({
                      ...headerSearch,
                      name: e.target.value,
                    })
                  }
                />
              </div>

              <div className={classes.fildBox}>
                <CustomSelect
                  isClearable={true}
                  item={SelectItems}
                  apiName={SelectItems.apiName}
                  isInputChange={true}
                  headerSearch={headerSearch}
                  setHeaderSearch={setHeaderSearch}
                />
              </div>

              <div className={classes.fildBox} onClick={onClickOfButton}>
                <button>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <DistrictForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default DistrictHeader;
