import React, { useEffect, useState } from "react";
import classes from "./edituserslisting.module.scss";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionEditPropertyAPICall } from "../../../../Redux/Actions";

const EditUsersListing = () => {
  const singleListData = useSelector(
    (state) => state.CommonReducer.saveSinglePropertyData
  );
  const dispatch = useDispatch();
  const [previewImg, setPreviewImg] = useState();
  const [showError, setShowError] = useState(false);
  const [data, setData] = useState({
    property_id: "",
    address: "",
    postcode: "",
    city: "",
    no_bedroom: "",
    livable_area: "",
    logo_image: "",
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const priceType = [
    { name: "k.k." },
    { name: "v.o.n." },
    { name: "m2" },
    { name: "per month" },
    { name: "per year" },
  ];
  const priceInfo = [
    { name: "none (display price)" },
    { name: "higher price range" },
    { name: "public auction" },
    { name: "price in consultation" },
    { name: "price on request" },
    { name: "fixed price" },
  ];
  const [inputFields, setInputFields] = useState([
    {
      price: "",
      price_type: "k.k.",
      price_info: "none (display price)",
      property_type: "rent",
    },
  ]);

  useEffect(() => {
    if (singleListData?.PropertyPrice?.length > 0)
      setInputFields(singleListData?.PropertyPrice);
  }, [singleListData?.PropertyPrice]);

  useEffect(() => {
    setData({
      property_id: singleListData.id,
      address: singleListData.address,
      postcode: singleListData.postcode,
      city: singleListData.city,
      no_bedroom: singleListData.no_bedroom,
      livable_area: singleListData.livable_area,
      logo_image: singleListData?.image_list[0]?.url_original_file,
      status: singleListData.status,
    });
  }, [singleListData]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      price: "",
      price_type: "k.k.",
      price_info: "none (display price)",
      property_type: "rent",
    });
    setInputFields(values);
  };
  const handleremoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "price") {
      values[index].price = event.target.value;
    } else if (event.target.name === "price_type") {
      values[index].price_type = event.target.value;
    } else if (event.target.name === "price_info") {
      values[index].price_info = event.target.value;
    } else {
      values[index].property_type = event.target.value;
    }
    setInputFields(values);
  };

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value.trim() });
  };

  const handleEditButton = () => {
    setShowError(true);
    let check = true;
    for (let i in data) {
      if (i != "status") {
        if (!data[i] || data[i] == "null" || data[i] == "undefined") {
          check = false;
        }
      }
    }

    if (check == true) {
      let formdata = new FormData();
      formdata.append(
        "main_img",
        data.logo_image[0]?.name ? data.logo_image[0].name : data.logo_image
      );
      formdata.append("property_id", singleListData.id);
      formdata.append("address", data.address);
      formdata.append("postcode", data.postcode);
      formdata.append("city", data.city);
      formdata.append("no_bedroom", data.no_bedroom);
      formdata.append("livable_area", data.livable_area);
      formdata.append("property_price", JSON.stringify(inputFields));
      const body = { formdata, navigate };
      dispatch(actionEditPropertyAPICall(body));
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setData({ ...data, ["logo_image"]: droppedFile });
    setPreviewImg(URL.createObjectURL(droppedFile));
  };
  return (
    <div className={classes.EditUsersListing}>
      <h3>{t("edit_users_listing")}</h3>
      <div className={classes.EditUsersListingWrap}>
        <div className={classes.EditUsersDetailsSection}>
          <div className={classes.CarteOrgWarp}>
            <h6>{t("change_main_picture")}</h6>
            <div
              className={classes.uploadMain}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <input
                type="file"
                id="upload"
                name="logo_image"
                accept=".jpg, .jpeg, .png,.webp,.svg"
                hidden
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: [e.target.files[0]] });
                  setPreviewImg(URL.createObjectURL(e.target.files[0]));
                }}
              />
              <label htmlFor="upload">
                <div className={classes.PhotoUpload}>
                  {previewImg || data?.logo_image ? (
                    <img
                      className={classes.img1}
                      src={previewImg ? previewImg : data?.logo_image}
                    />
                  ) : (
                    <img src={FileUploadIcon} />
                  )}
                </div>
              </label>
              {showError &&
                (data?.logo_image == null || data?.logo_image == "") && (
                  <p className={classes.errorText}>{t("file_required")}</p>
                )}
            </div>
            <div className={classes.linkInputWrap}>
              <p>{t("address")}</p>
              <input
                type="text"
                name="address"
                defaultValue={data.address}
                onChange={onInputChange}
              />
              {showError && (data?.address == null || data?.address == "") && (
                <p className={classes.errorText}>{t("address_required")}</p>
              )}
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("postal_code")}</p>
                <input
                  type="text"
                  name="postcode"
                  defaultValue={data.postcode}
                  onChange={onInputChange}
                />
                {showError &&
                  (data?.postcode == null || data?.postcode == "") && (
                    <p className={classes.errorText}>
                      {t("postalcode_required")}
                    </p>
                  )}
              </div>
              <div className={classes.FildBox}>
                <p>{t("city")}</p>
                <input
                  type="text"
                  name="city"
                  defaultValue={data.city}
                  onChange={onInputChange}
                />
                {showError && (data?.city == null || data?.city == "") && (
                  <p className={classes.errorText}>{t("city_required")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.EditUsersListingSection}>
          {inputFields?.length > 0 &&
            inputFields.map((item, index) => (
              <div className={classes.ListingSection}>
                <div className={classes.ListingWrap}>
                  <div className={classes.ListingBox}>
                    <div className={classes.LsitBox}>
                      <p>{t("type_of_listing")}</p>
                      <select
                        name="property_type"
                        id="cars"
                        form="carform"
                        defaultValue={item.property_type}
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        <option
                          value="rent"
                          selected={item.property_type == "rent"}
                          data-image={"PinkTick"}
                        >
                          {t("to_rent")}
                        </option>
                        <option
                          value="buy"
                          selected={item.property_type == "buy"}
                        >
                          {t("to_buy")}
                        </option>
                        <option
                          value="sell"
                          selected={item.property_type == "sell"}
                        >
                          {t("to_sell")}
                        </option>
                      </select>
                    </div>
                    <div className={classes.LsitBox}>
                      <p>{t("price_of_listing")}</p>
                      <input
                        type="text"
                        name="price"
                        defaultValue={item.price}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                    <div className={classes.LsitBox}>
                      <p>{t("price_type")}</p>
                      <select
                        name="price_type"
                        id="cars"
                        form="carform"
                        defaultValue={item.price_type}
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        {priceType.map((item1) => {
                          return (
                            <option
                              value={item1.name}
                              selected={item.price_type == item1.name}
                            >
                              {item1.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className={classes.LsitBox}>
                      <p>{t("price_info")}</p>
                      <select
                        name="price_info"
                        id="cars"
                        form="carform"
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        {priceInfo.map((item1) => {
                          return (
                            <option
                              value={item1.name}
                              selected={item.price_info == item1.name}
                            >
                              {item1.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className={classes.AddOrRemoveListing}>
                    <span
                      className={classes.RemoveListing}
                      onClick={handleremoveFields}
                    >
                      -
                    </span>
                    {inputFields?.length - 1 == index && (
                      <span
                        className={classes.AddListing}
                        onClick={handleAddFields}
                      >
                        +
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          <div className={classes.ListingDetails}>
            <div className={classes.ListngInput}>
              <p>{t("square_meters_of_listing")}</p>
              <input
                type="text"
                name="livable_area"
                defaultValue={data.livable_area}
                onChange={onInputChange}
              />{" "}
              {showError &&
                (data?.livable_area == null || data?.livable_area == "") && (
                  <p className={classes.errorText}>
                    {t("square_meters_required")}
                  </p>
                )}
            </div>
            <div className={classes.ListngInput}>
              <p>{t("number_of_bedrooms")}</p>
              <input
                type="text"
                name="no_bedroom"
                defaultValue={data.no_bedroom}
                onChange={onInputChange}
              />
              {showError &&
                (data?.no_bedroom == null || data?.no_bedroom == "") && (
                  <p className={classes.errorText}>
                    {t("no_of_bedrooms_required")}
                  </p>
                )}
            </div>
          </div>
          <div className={classes.EditbtnWrap}>
            <button onClick={handleEditButton}>{t("edit")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUsersListing;
