import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./zipcodeheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import OffcanvasMain from "../Offcanvas";
import ZipcodeForm from "../ZipcodeForm";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../CustomSelect";
import { actionGetZipcodeApiCall } from "../../../Redux/Actions";
import SideBar from "../SideBar";
const ZipcodeHeader = () => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebar, setSideBar] = useState(false);
  const state = useSelector((state) => state.CommonReducer);
  const SelectItems = [
    {
      placeholder: t("municipality"),
      name: "municipality_id",
      options:
        state?.getMunicipalityTable &&
        state?.getMunicipalityTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "municipality",
    },
    {
      placeholder: t("country"),
      name: "country_id",
      options:
        state?.getCountryTable &&
        state?.getCountryTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "country",
    },
    {
      name: "province_id",
      placeholder: t("province"),
      options:
        state?.getProvinceData &&
        state?.getProvinceData?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "province",
    },
    {
      name: "city_id",
      apiName: "city",
      placeholder: t("city_p"),
      options:
        state?.getCityData &&
        state?.getCityData?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
    {
      name: "neighbourhood_id",
      apiName: "neighbourhood",
      placeholder: t("neighbourhood"),
      options:
        state?.getNeighbourhoodTable &&
        state?.getNeighbourhoodTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
    {
      name: "district_id",
      apiName: "district",
      placeholder: t("district"),
      options:
        state?.getDistrictTable &&
        state?.getDistrictTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
    {
      name: "country_part_id",
      apiName: "country_part",
      placeholder: t("country_part"),
      options:
        state?.getCountryPartTable &&
        state?.getCountryPartTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
  ];
  const [headerSearch, setHeaderSearch] = useState({
    number: "",
    country_id: "",
    municipality_id: "",
    province_id: "",
    city_id: "",
    neighbourhood_id: "",
    district_id: "",
    country_part_id: "",
  });
  const filterClick = () => {
    const body = {
      navigate,
      page: 1,
      edit: false,
      search: headerSearch.number,
      country_id: headerSearch.country_id ? headerSearch.country_id.value : "",
      municipality_id: headerSearch.municipality_id
        ? headerSearch.municipality_id.value
        : "",
      province_id: headerSearch.province_id
        ? headerSearch.province_id.value
        : "",
      city_id: headerSearch.city_id ? headerSearch.city_id.value : "",
      neighbourhood_id: headerSearch.neighbourhood_id
        ? headerSearch.neighbourhood_id.value
        : "",
      district_id: headerSearch.district_id
        ? headerSearch.district_id.value
        : "",
      country_part_id: headerSearch.country_part_id
        ? headerSearch.country_part_id.value
        : "",
    };
    dispatch(actionGetZipcodeApiCall(body));
  };
  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")}{" "}
              {t("zipcode")}
            </h5>
            <form className={classes.managementFild} onSubmit={filterClick}>
              <div className={classes.fildBox}>
                <input
                  placeholder={t("code")}
                  onChange={(e) => {
                    setHeaderSearch({
                      ...headerSearch,
                      number: e.target.value,
                    });
                  }}
                />
              </div>
              {SelectItems.map((item) => {
                return (
                  <div className={classes.fildBox}>
                    <CustomSelect
                      item={item}
                      isClearable={true}
                      apiName={item.apiName}
                      isInputChange={true}
                      headerSearch={headerSearch}
                      setHeaderSearch={setHeaderSearch}
                    />
                  </div>
                );
              })}
              <div
                className={classes.fildButtonBox}
                onClick={(e) => {
                  e.preventDefault();
                  filterClick();
                }}
              >
                <button>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        width="lg"
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <ZipcodeForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default ZipcodeHeader;
