import React from "react";
import LanguageManagementMain from "../../components/LanguageManagementMain";

const LanguageManagementPage = () => {
  return (
    <React.Fragment>
      <LanguageManagementMain />
    </React.Fragment>
  );
};

export default LanguageManagementPage;
