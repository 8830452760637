import React, { useState } from "react";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import deleteIcon from "../../../assets/icons/DeleteIcon.svg";
import pdfIcon from "../../../assets/icons/PdfIcon.svg";
import classes from "./logform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddContactLogApiCall,
  actionDeleteContactLogApiCall,
} from "../../../Redux/Actions";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";

const LogForm = ({ handleClose, editData }) => {
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = singleOrgData?.agency_contact;
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [formData, setFormData] = useState({
    subject : "",
    selectedOption: "",
    addedby: userdata?.full_name || userdata?.email,
    logText: "",
    pdfFile: null,
  });
  useEffect(() => {
    if (editData) {
      setFormData({
        subject:editData.subject,
        selectedOption: editData.contact.id,
        addedby: editData.added_by,
        logText: editData.log,
        pdfFile: editData.pdf_file
          ? { url: editData.pdf_file, name: editData.pdf_file_name }
          : null,
      });
    }
  }, [editData]);
  const [requiredError, setRequiredError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.includes("application/pdf")) {
      setFormData({
        ...formData,
        pdfFile: file,
      });
    } else {
      toast.error("Invalid file type. Please select a pdf file.");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.selectedOption) {
      setRequiredError(true);
      return;
    }
    const data = new FormData();
    data.append("agency_id", singleOrgData.id);
    data.append("subject", formData.subject);
    data.append("agency_member_id", formData.selectedOption);
    data.append(
      "added_by",
      editData ? editData.added_by : userdata?.full_name || userdata?.email
    );
    data.append("log", formData.logText);
    formData.pdfFile && editData
      ? data.append(
          typeof formData.pdfFile == "string" ? "url" : "file",
          formData.pdfFile
        )
      : formData.pdfFile && data.append("file", formData.pdfFile);
    dispatch(
      actionAddContactLogApiCall({
        navigate,
        data,
        closePopup: handleClose,
        getapiCall: { agency_id: singleOrgData.id, navigate },
        edit: editData ? editData.id : "",
      })
    );
  };
  const handleDeleteBtn = () => {
    dispatch(
      actionDeleteContactLogApiCall({
        navigate,
        id: editData.id,
        closePopup: handleClose,
        getapiCall: { agency_id: singleOrgData.id, navigate },
      })
    );
  };



  return (
    <React.Fragment>
      <div className={classes.logFormSection}>
        {editData ? <h3>Edit Log</h3> : <h3>Add Log</h3>}

        <div className={classes.uploadpdflabel}>Upload PDF</div>
        <div className={classes.uploadfilewrapper}>
          <label htmlFor="pdfUpload">
            {formData.pdfFile != null ? (
              <div className={classes.container}>
                <img className={classes.image} src={pdfIcon} alt="" />{" "}
                <span>{formData.pdfFile.name}</span>
              </div>
            ) : (
              <img src={upload} alt="upload" />
            )}
          </label>
          {formData.pdfFile != null && (
            <div
              className={classes.icon}
              onClick={(e) => {
                setFormData({ ...formData, pdfFile: null });
              }}
            >
              <MdDelete />
            </div>
          )}
        </div>

        <input
          type="file"
          accept=".pdf"
          id="pdfUpload"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        
        <div className={classes.inputsWrapper}>
        <label htmlFor="subject">Subject</label>
          <input
            type="text"
            name="subject"
            onChange={handleInputChange}
            // value={formData.subject}
            // disabled
            // className={classes.Disabled}
          />
          <label>Contact</label>
          <select
            name="selectedOption"
            value={formData.selectedOption}
            onChange={handleInputChange}
          >
            <option value="">Contact</option>
            {options.map((option, index) => (
              <option
                className={classes.setContent}
                key={index}
                value={option.id}
              >
                {option?.full_name || option?.user_agency?.full_name}
              </option>
            ))}
          </select>
          {requiredError && (
            <p className={classes.errorText}>Contact is required.</p>
          )}
          <label htmlFor="addedby">Added by</label>
          <input
            type="text"
            name="addedby"
            value={formData.addedby}
            disabled
            className={classes.Disabled}
          />
          <label htmlFor="logText">Log</label>
          <textarea
            name="logText"
            value={formData.logText}
            onChange={handleInputChange}
            cols="30"
            rows="5"
          ></textarea>
        </div>

        <div className={classes.btnwrapper}>
          {editData ? (
            <>
              <button onClick={handleDeleteBtn} className={classes.btncancel}>
                <img src={deleteIcon} alt="" />
                Delete
              </button>
              <button className={classes.btnadd} onClick={handleSubmit}>
                Save
              </button>
            </>
          ) : (
            <>
              <button onClick={handleClose} className={classes.btncancel}>
                Cancel
              </button>
              <button className={classes.btnadd} onClick={handleSubmit}>
                Add
              </button>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LogForm;
