import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./createtag.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddEmailApiCall,
  actionGetAllTagsApiCall,
  actionLangListAPICall,
  addTagApiCall, 
} from "../../../Redux/Actions";
import APIUrl from "../../../API/APIUrl";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { FaBlackTie } from "react-icons/fa";
import { toast } from "react-toastify";
import { validateText } from "../../../utils/commonFunctions";

const CreateTag = (props) => {
  const [values,setValues] = useState("")
  const [tagError, setTagError] = useState("");
  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let close = props.onClose;

  const handleTag = (e) => {
    setValues(e.target.value)
  }
 
const handlInputTag =(e)=>{
  e.preventDefault();
  if(values.length === 0 || values == ""){
    toast.error("Please fill the field to continue")
  }else{
    apiCall();
    setValues("")
  }}

const apiCall = () => {
  const data = JSON.stringify({
    "tag": values
  });
  const body = { data, navigate, close };
  dispatch(addTagApiCall(body));
  setTimeout(() => {
    dispatch(actionGetAllTagsApiCall());
  },1000);
};

  return (
    <div className={classes.EditEmailMain}>
      <h3> Add Tag </h3>
      <div className={classes.EditEmailWrap}>
        <div className={classes.InputWrap}>
          <p className={classes.to}>{t("Add tag")}</p>
          <p>{t("What should we name this tag?")}</p>
        </div>
        <form >
        <div className={classes.InputWrap}>
          <p>{t("Tag Name")}</p>
          <input
          type="text"
          name="tagDetails"
          value={values}
          onChange={handleTag}
          required
          />
        </div>
        <div className={classes.btnWrap}>
          <button type="button" onClick={handlInputTag}>
            {" "}
            {t("Send")}
          </button>
          <button onClick={() => props.onClose()} className={classes.cancelBtn}>
            {" "}
            {t("Cancel")}
          </button>
        </div>
        </form>
       
      </div>
    </div>
  );
};

export default CreateTag;
