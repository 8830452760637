import React, { useEffect, useState } from "react";
import classes from "./organisationmanagementmain.module.scss";
import { useTranslation } from "react-i18next";
import MainLayount from "../common/MainLayout";
import OrganisationManagementHeader from "../common/OrganisationManagementHeadre";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  actionApproveOrgApiCall,
  actionBlockOrganizationApiCall,
  actionDeleteOrganizationApiCall,
  actionGetPropertyListAPICall,
  actionSaveAllSubscriptionListByOrg,
  actionSaveSingleOrganizationdata,
  actionUnblockOrgApiCall,
  actionViewOrganizationApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModalMain from "../common/Modal";
import EditOrganisation from "../ModalComponents/OrganisationManagementModals/EditOrganisation";
import moment from "moment";
import Loader from "../common/Loader";
import { useRef } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const OrganisationManagementMain = () => {
  const { t } = useTranslation();
  const orglist = useSelector((state) => state.CommonReducer.orgListData);
  const loader = useSelector((state) => state.CommonReducer.loader);
  const orggloader = useSelector((state) => state.CommonReducer.orgloader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [creatUser, setCreateUser] = useState();
  const listRef = useRef(null);
  const divref = useRef([]);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 280) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const adduser = (id) => {
    let data = [...selectedUser];
    data.push(id);
    setSelectedUser(data);
  };
  const deleteUser = (id) => {
    if (selectedUser?.length > 0) {
      let data = selectedUser.filter((item) => item != id);
      setSelectedUser(data);
    }
  };

  const onCLickofViewOrg = (index) => {
    const data = index;
    const body = { data, navigate };
    dispatch(actionViewOrganizationApiCall(body));
    getPropertyListAPICall(index);
  };

  const onCLickBlockOrg = (id) => {
    const data = {
      id: [id],
    };
    const body = { data, navigate };
    dispatch(actionBlockOrganizationApiCall(body));
  };

  const onCLickDeleteOrg = (id) => {
    const data = {
      id: [id],
    };
    const body = { data, navigate };
    dispatch(actionDeleteOrganizationApiCall(body));
  };
  const onCLickApproveOrg = (id) => {
    const data = {
      organizationid: id,
      approve: 1,
    };
    const body = { data, navigate };
    dispatch(actionApproveOrgApiCall(body));
  };
  const onCLickUnBlockOrg = (id) => {
    const data = {
      id: [id],
    };
    const body = { data, navigate };
    dispatch(actionUnblockOrgApiCall(body));
  };

  const getPropertyListAPICall = (id) => {
    const data = { page: 1, organizationid: id };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  };

  const onClickOfSingleRow = (index) => {
    if (orglist.length - 2 > index) {
      handleScroll(index);
    }
    if (ind !== index) {
      setShow(!show);
      setInd(index);
    } else {
      setInd("");
    }
  };

  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.OrgMain}>
          <OrganisationManagementHeader selectedUser={selectedUser} />
          <div className={classes.OrgListingSection}>
            <table>
              <tr>
                <th className={classes.selectOrg}></th>
                <th></th>
                <th>{t("name")}</th>
                <th>{t("city_p").toUpperCase()}</th>
                <th>{t("classification_c")}</th>
                <th>{t("membership")}</th>
                <th>{t("created_on")}</th>
                <th>{t("id")}</th>
                <th>{t("users_c")}</th>
                <th>{t("listings")}</th>
                <th>{t("branches").toUpperCase()}</th>
              </tr>
              {orggloader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {orglist && orglist?.length > 0 ? (
                    orglist?.map((item, index) => (
                      <tr
                        className={
                          selectedUser.find((s) => s == item.id) &&
                          (item.status == "Active" || item.status == "online")
                            ? `${classes.active} ${classes.InvisibleCard}`
                            : item.status == "blocked"
                            ? `${classes.InvisibleCard}`
                            : selectedUser.find((s) => s == item.id)
                            ? `${classes.active}`
                            : null
                        }
                        ref={(e) => (divref.current[index] = e)}
                        onClick={() => onClickOfSingleRow(index)}
                      >
                        <td className={classes.selectOrg}>
                          <input
                            type="checkbox"
                            id={`org1` + index}
                            name="org"
                            className={classes.radioCustom}
                            onChange={(e) => {
                              if (e.target.checked) {
                                adduser(item.id);
                              } else {
                                deleteUser(item.id);
                              }
                            }}
                            checked={
                              selectedUser.find((s) => s == item.id)
                                ? true
                                : false
                            }
                          />
                          <label
                            className={classes.radioCustomLabel}
                            htmlFor={`org1` + index}
                          ></label>
                        </td>
                        <td onClick={(e) => e.stopPropagation()}>
                          {(item?.status == "online" ||
                            item.status == "Active") && (
                            <FaEye onClick={() => onCLickBlockOrg(item.id)} />
                          )}
                          {item?.status == "blocked" && (
                            <FaEyeSlash
                              onClick={() => onCLickUnBlockOrg(item.id)}
                            />
                          )}
                        </td>
                        <td className={classes.NameWrap}>
                          {item?.name ? item.name : item.email}
                        </td>
                        <td>{item?.city ? item.city : "NA"}</td>
                        <td>
                          {item?.agency_classification_list &&
                          item.agency_classification_list.length > 0
                            ? item.agency_classification_list.map((item) => {
                                return (
                                  <>
                                    <span>{item.name}</span>
                                    <br />
                                  </>
                                );
                              })
                            : "NA"}
                        </td>
                        <td>
                          {item?.member_status ? item.member_status : "NA"}
                        </td>
                        <td>
                          {item?.created_at
                            ? moment(item.created_at).format("DD/MM/YYYY HH:MM")
                            : "NA"}
                        </td>
                        <td>{item?.id ? item.id : "NA"}</td>
                        <td>{item?.user_count}</td>
                        <td>{item?.listing_count}</td>
                        <td>
                          {" "}
                          {item?.agency_branch_list &&
                          item.agency_branch_list.length > 0
                            ? item.agency_branch_list.map((item) => {
                                return (
                                  <>
                                    <span>{item.name}</span>
                                    <br />
                                  </>
                                );
                              })
                            : "NA"}
                        </td>
                        <td
                          className={classes.userEditor}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img
                            src={userEditorIcon}
                            onClick={() => onClickOfSingleRow(index)}
                          />
                          {show && ind == index && (
                            <div
                              ref={listRef}
                              className={classes.EditorWrap}
                              style={
                                orglist.length - 3 > index ||
                                orglist.length <= 3
                                  ? {}
                                  : { top: "-130px" }
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                setShow(!show);
                              }}
                            >
                              <ul>
                                <li
                                  onClick={() => {
                                    onCLickofViewOrg(item.id);
                                    dispatch(
                                      actionSaveSingleOrganizationdata("")
                                    );
                                    dispatch(
                                      actionSaveAllSubscriptionListByOrg("")
                                    );
                                  }}
                                >
                                  {t("view_edit_org")}
                                </li>

                                {item?.is_approved == "Not Approved" && (
                                  <li
                                    onClick={() => onCLickApproveOrg(item.id)}
                                  >
                                    {t("approve_org")}
                                  </li>
                                )}
                                {item?.status == "online" ||
                                  (item.status == "Active" && (
                                    <li
                                      onClick={() => onCLickBlockOrg(item.id)}
                                    >
                                      {t("block_org")}
                                    </li>
                                  ))}
                                {item?.status == "blocked" && (
                                  <li
                                    onClick={() => onCLickUnBlockOrg(item.id)}
                                  >
                                    {t("online_org")}
                                  </li>
                                )}
                                <li onClick={() => onCLickDeleteOrg(item.id)}>
                                  {t("delete_org")}
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={11}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
      <ModalMain
        showmodel={creatUser}
        handleClose={() => setCreateUser(false)}
        dialogClassName={classes.ModaleSize}
      >
        <EditOrganisation
          handleClose={() => setCreateUser(false)}
          edit={false}
        />
      </ModalMain>
    </React.Fragment>
  );
};

export default OrganisationManagementMain;
