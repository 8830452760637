import React, { useEffect } from "react";
import classes from "./supplierdetailsmain.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { actionAddSupplierApiCall } from "../../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddSupplier = (props) => {
  const { t } = useTranslation();

  const [supplierName, setSupplierName] = useState();
  const [supplierType, setSupplierType] = useState();
  const [supplierUrl, setSupplierUrl] = useState();

  const navigate = useNavigate();
  const [error, setError] = useState({});

  const dispatch = useDispatch();

  const supplierTypes = useSelector(
    (state) => state.CommonReducer.supplierTypesData
  );

  const handleChange = (event) => {
    const { name, value } = event?.target;
    if (name === "supplier_name") setSupplierName(value);
    if (name === "supplier_type") setSupplierType(value);
    if (name === "url") setSupplierUrl(value);
  };

  const onClickOfSaveBtn = () => {
    let closePopup = props.onClose;
    const data = {
      supplier_type_id: supplierType,
      supplier_name: supplierName,
      url: supplierUrl,
    };

    dispatch(
      actionAddSupplierApiCall({
        data,
        edit: false,
        close: closePopup,
        navigate,
        getapiCall: { data: {}, navigate },
      })
    );
  };

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.detailPage}>
          <div className={classes.gridWrapper}>
            <div className={classes.gridContainer}>
              <div key="supplier_name" className={classes.Col2LabelContainer}>
                <label>Name</label>
                <div className={classes.inputContainer}>
                  <input
                    type="text"
                    name="supplier_name"
                    onChange={(event) => handleChange(event)}
                  />
                </div>
                <p className={classes.errorText}>{error["supplier_name"]}</p>
              </div>
              <div className={classes.Col2LabelContainer}>
                <label>Type</label>
                <div className={classes.inputContainer}>
                  <select
                    name="supplier_type"
                    value={supplierType}
                    onChange={(event) => handleChange(event)}
                  >
                    <option value="">Select an option</option>
                    {supplierTypes &&
                      supplierTypes?.map((option, index) => (
                        <option key={option[0]} value={option[0]}>
                          {option[1]}
                        </option>
                      ))}
                  </select>
                </div>
                <p className={classes.errorText}>{error["supplier_type"]}</p>
              </div>
              <div className={classes.Col2LabelContainer}>
                <label>Url</label>
                <div className={classes.inputContainer}>
                  https://www.
                  <input
                    type="text"
                    name="url"
                    onChange={(event) => handleChange(event)}
                  />
                </div>
                <p className={classes.errorText}>{error["supplier_url"]}</p>
              </div>
            </div>
            <button onClick={onClickOfSaveBtn}>Save</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddSupplier;
