import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classes from "./citymain.module.scss";
import MainLayout from "../common/MainLayout";
import CityHeader from "../common/CityHeader";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { actionDeleteCityApiCall } from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import OffcanvasMain from "../common/Offcanvas";
import CityForm from "../common/CityForm";
import { useEffect } from "react";
import Loader from "../common/Loader";

const CityMain = () => {
  const getCityData = useSelector((state) => state?.CommonReducer?.getCityData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const divref = useRef([]);
  const [openCanvas, setOpenCanvas] = useState(false);
  const loader = useSelector((state) => state.CommonReducer.loader);
  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 303) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = (item) => {
    dispatch(actionDeleteCityApiCall({ id: item?.id, navigate }));
  };

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.CityMain}>
          <CityHeader />
          <div className={classes.CitySection}>
            <table>
              <thead>
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("code").toUpperCase()}</th>
                  <th>{t("municipality").toUpperCase()}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader == true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={6}>
                      <Loader />
                    </td>
                  </tr>
                ) : getCityData.length > 0 ? (
                  getCityData.map((item, index) => (
                    <tr ref={(e) => (divref.current[index] = e)} key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.code ? item?.code : "-"}</td>
                      <td>
                        {item?.municipality ? item?.municipality?.name : "-"}
                      </td>
                      <td
                        className={classes.userEditor}
                        onClick={() => {
                          handleScroll(index);
                          if (ind !== index) {
                            setShow(!show);
                            setInd(index);
                          } else {
                            setInd("");
                          }
                        }}
                      >
                        <img src={userEditorIcon} />
                        {show && ind === index && (
                          <div
                            ref={listRef}
                            className={classes.EditorWrap}
                            onClick={() => setShow(!show)}
                            style={
                              getCityData.length - 1 > index
                                ? {}
                                : getCityData.length > 4
                                ? { top: "-100px" }
                                : {}
                            }
                          >
                            <ul>
                              <li
                                onClick={() => {
                                  setOpenCanvas(true);
                                  setEditData(item);
                                }}
                              >
                                {t("edit")} {t("city_p")}
                              </li>
                              <li onClick={() => handleDelete(item)}>
                                {t("delete")} {t("city_p")}
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={5}>
                      <h2>{t("no_data_found")}</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <OffcanvasMain
            showcanvas={openCanvas}
            placement={"end"}
            backgroundColor="true"
            width="md"
            handleClose={() => {
              setOpenCanvas(false);
            }}
          >
            <CityForm
              editData={editData}
              handleClose={() => {
                setOpenCanvas(false);
              }}
            />
          </OffcanvasMain>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default CityMain;
