import React, { useEffect, useState } from "react";
import classes from "./translationform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddDistrictDataApiCall,
  actionAddEditTranslationApiCall,
} from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { json, useNavigate } from "react-router-dom";
import CustomSelect from "../CustomSelect";

const TranslationForm = ({ editData, handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const state = useSelector((state) => state.CommonReducer);
  const loader = useSelector((state) => state.CommonReducer.loader);
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );

  const [formData, setFormData] = useState({
    column: "",
    value: "",
    translationsArray: [],
  });
  const [errors, setErrors] = useState({
    column: "",
    value: "",
    translations: "",
  });
  const navigate = useNavigate();

  const { translationsArray } = formData;

  useEffect(() => {
    // Assuming state?.saveMasterTableColumn is an array
    if (
      state?.saveMasterTableColumn &&
      state?.saveMasterTableColumn.length > 0
    ) {
      // Set the default option to the first item in the array
      setFormData({
        ...formData,
        column: state.saveMasterTableColumn[0].column,
      });
    }
  }, [state?.saveMasterTableColumn]);
  useEffect(() => {
    if (editData) {
      setFormData({
        name: editData?.name || "",
        code: editData?.code || "",
        description: editData?.description || "",
        image: editData?.slider_image || "",
        city_id: editData.city_id || "",
      });
    }
  }, [editData]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { column: "", value: "", translations: "" };

    if (formData.column === "") {
      newErrors.column = t("column") + " " + t("required");
      valid = false;
    }
    if (formData.value === "") {
      newErrors.value = t("value") + " " + t("required");
      valid = false;
    }
    const translationErrors = translationsArray.some(
      (translation) => !translation.translation
    );
    if (translationErrors) {
      // Handle translation errors
      newErrors.translations = t("Translations are required");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const stringData = {
        column: formData.column,
        value: formData.value,
        translationsArray: formData.translationsArray,
      };
      let data = JSON.stringify(stringData);

      const closePopup = handleClose;
      dispatch(
        actionAddEditTranslationApiCall({
          data,
          closePopup,
          edit: editData ? editData.id : false,
          navigate,
        })
      );
    }
  };
  return (
    <React.Fragment>
      <div className={classes.branchesFormSection}>
        {editData ? (
          <h3>
            {t("edit")} {t("translation")}
          </h3>
        ) : (
          <h3>
            {t("add")} {t("translation")}
          </h3>
        )}
        <div className={classes.inputsWrapper}>
          <div className={classes.Text}>
            <label htmlFor="name">{t("column")}</label>
            <select id="column" name="column" onChange={handleInputChange}>
              {state?.saveMasterTableColumn?.length > 0 &&
                state?.saveMasterTableColumn?.map((item, index) => {
                  return (
                    <option
                      value={item.column}
                      selected={index == 0 ? true : false}
                    >
                      {item.column}
                    </option>
                  );
                })}
            </select>
            {errors.column && <p className={classes.error}>{errors.column}</p>}
          </div>
          <div className={classes.Text}>
            <label htmlFor="value">{t("value")}</label>
            <input
              type="text"
              name="value"
              onChange={(e) => {
                // const newValue = e.target.value.replace(/[^A-Z_]/g, "");
                setFormData({ ...formData, [e.target.name]: e.target.value });
              }}
              value={formData.value}
            />
            {errors.value && <p className={classes.error}>{errors.value}</p>}
          </div>
          <div className={classes.Text}>
            {langdata.map((language) => (
              <>
                <label htmlFor={`${language}-input1`}>
                  {`${t("translation")} ${language.language_label}`}
                </label>
                <input
                  type="text"
                  id={`${language}-input1`}
                  name="input1"
                  value={
                    formData.translationsArray.find(
                      (translation) => translation.language_id === language.id
                    )?.translation || ""
                  }
                  onChange={(e) => {
                    const updatedTranslation = {
                      language_id: language.id,
                      language: language.language,
                      translation: e.target.value,
                    };

                    setFormData((prevData) => ({
                      ...prevData,
                      translationsArray: [
                        ...prevData.translationsArray.filter(
                          (translation) =>
                            translation.language_id !== language.id
                        ),
                        updatedTranslation,
                      ],
                    }));
                  }}
                />
              </>
            ))}
            {errors.translations && (
              <p className={classes.error}>{errors.translations}</p>
            )}
          </div>
        </div>

        <div className={classes.btnwrapper}>
          <div className={classes.btnWrapperShift}>
            {editData ? (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? <Loader /> : `${t("edit")}`}
                </button>
              </>
            ) : (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? (
                    <Loader />
                  ) : (
                    `${t("add")} ${t("translation")}`
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TranslationForm;
