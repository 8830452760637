import React, { useState } from "react";
import classes from "./singlecampaignheader.module.scss";
import { useTranslation } from "react-i18next";
import carteUserIcon from "../../../assets/icons/carteUserIcon.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import BlockIcon from "../../../assets/images/BlockIcon.svg";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import {
  actionDeletePropertyBannerApiCall,
} from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import onlineIcon from "../../../assets/icons/onlineIcon.svg";

const SingleCampaignHeader = () => {
  const { t, i18n } = useTranslation();
  const campaignData = useSelector((state) => state.CommonReducer.singleUserData);
  const [sidebar, setSideBar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
  };
  const onCLickDeleteCampaign = () => {
    const data = {
      id: [campaignData.id],
    };
    const body = { data, navigate, singledata: true };
    dispatch(actionDeletePropertyBannerApiCall(body));
  };

  return (
    <React.Fragment>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <div className={classes.containerHeader}>
        <div className={classes.CanvasWarp}>
          <button onClick={sidebarShow}>
            <GiHamburgerMenu />
          </button>
        </div>
        <div className={classes.BreadCrumbLayout}>
          <p
            className={classes.semiBoldLightText}
            onClick={() => navigate("/campaign-management")}
          >
            {t("banner_campaign_management")}
          </p>
          <p className={classes.hyphen}>-</p>
          <p className={classes.semiBoldMedText}>{t("View Campaign")}</p>
        </div>
        <div className={classes.ActionOnUser}>
          <div className={classes.buttonLayout}>
            <button onClick={onCLickDeleteCampaign}>
              {t("delete_campaign")}
              <img src={DelIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleCampaignHeader;
