import React, { useState } from "react";
import classes from "./contact.module.scss";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import StarIcon from "../../../assets/images/starIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  actionAddContactApiCall,
  actionDeleteContactApiCall,
  actionGetAllTagsApiCall,
  actionGetEmployeeJobTitleApiCall,
  actionSendPasswordLinkAPICall,
} from "../../../Redux/Actions";
import deleteIcon from "../../../assets/icons/DeleteIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.error("Failed to parse JSON:", str);
    return null; // or return an appropriate fallback value such as []
  }
};
const ContactForm = ({ handleClose, editData }) => {
  // console.log(editData?.member_tags,"edit data from contact page")
  const editedTags =  editData?.member_tags?.map((item) => ({
    value: item?.tag_name,
    label: item?.tag_name,
    id:item?.tag_id
  })) || [];
  console.log(editedTags.map((item)=>item.value),"editedTagsss")
  // const defaultvalues = 
  // editData && editData.tags ? 
  // safeJSONParse(editData.tags).map(tag => 
  //   ({ value: tag, 
  //     label: tag 
  //   })) : [];
// const defaultvalues = editData && JSON.parse(editData?.tags).map(tag => ({value: tag, label: tag}))
// console.log(JSON.parse(editData?.tags),"tags from parse")
  const { t } = useTranslation();
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  const Tags = useSelector((state) => state.CommonReducer.saveAllTags);
  console.log(Tags,"tagss")
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // DATA RECEIVED FROM API
  const [formData, setFormData] = useState({
    isMainContact: false,
    fullName: editData?.full_name || editData?.user_agency?.first_name || "",
    lastName: editData?.last_name || editData?.user_agency?.last_name || "",
    email: editData?.email || editData?.user_agency?.email || "",
    phoneNumber: editData?.phone || editData?.user_agency?.phone,
    jobTitle: editData?.job_title || "",
    profileImage: null,
    marketingEmails: "",
    quotationReceived: false,
    invoiceReceived: false,
    orderConfirmation: false,
    receivePropertyUpdates: false,
    deliveryEmail: true,
    user_id: null,
    profile_picture: null,
  });
  const [selectedTags, setSelectedTags] = useState([]);

  const [error, setError] = useState();
  const jobOptions = useSelector(
    (state) => state.CommonReducer.saveEmployeeJobTitle
  );
  useEffect(() => {
    dispatch(actionGetEmployeeJobTitleApiCall({ navigate }));
    dispatch(actionGetAllTagsApiCall())
  }, []);


  //EDITED DATA
  useEffect(() => {
    if (editData) {
      setFormData({
        isMainContact: editData.set_as_main_contact || false,
        fullName: editData?.first_name,
        lastName: editData?.last_name,
        email: editData?.email,
        phoneNumber: editData?.phone,
        jobTitle: editData?.job_title,
        profileImage: editData?.profile_picture,
        marketingEmails: editData?.marketing_emails,
        quotationReceived: editData.quotation_received,
        invoiceReceived: editData.invoice_received || false,
        orderConfirmation: editData.order_confirmation,
        receivePropertyUpdates: editData.receive_property_updates,
        deliveryEmail: editData.delivery_email,
        user_id: editData.user_id,
        profile_picture: editData?.url_original_file,
      });
      setSelectedTags(editedTags?.map((item)=>({value: item.value, label: item.value,id:item.id})));
      console.log(selectedTags,"selected tagssssssss")
      setSelectedImage(editData?.user_agency?.url_original_file);
    }
  }, [editData]);


  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const validation = () => {
    console.log(selectedTags,"selected tags")
    let valid = true;
    if (formData.email) {
      if (
        !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(formData.email)
      ) {
        setError(t("please_enter_valid_email_id"));
        valid = false;
      } else {
        setError("");
      }
    } else {
      setError("email required");
      valid = false;
    }
    return valid;
  };
  const handleSave = () => {
    if (validation()) {
      const data = new FormData();
      let profileImage =
        editData?.profile_picture == formData.profileImage &&
        !formData.profile_picture
          ? editData?.profile_picture
          : formData.profile_picture;
      formData.user_id && data.append("user_id", formData.user_id);
      formData.fullName && data.append("full_name", formData.fullName);
      formData.lastName && data.append("last_name", formData.lastName);
      formData.phoneNumber && data.append("phone", formData.phoneNumber);
      formData.jobTitle && data.append("job_title", formData.jobTitle);
      formData.marketingEmails && data.append("marketing_emails", formData.marketingEmails);
      data.append("set_as_main_contact", formData.isMainContact && 1);
      data.append("email", formData.email);
      profileImage &&
      data.append(
        editData?.profile_picture == formData.profileImage &&
        !formData.profile_picture
        ? "file"
        : "profile_picture",
        profileImage
      );
      selectedTags.forEach((tag, index) => {
        data.append(`tags[${index}]`, tag.id);
      });
      console.log(selectedTags,"selected tags")
      // data.append("tags[]", selectedTags?.map((item) => item?.value === "" ? null : item?.value))
        data.append("quotation_received", formData.quotationReceived);
        data.append("invoice_received", formData.invoiceReceived);
        data.append("order_confirmation", formData.orderConfirmation);
        data.append("receive_property_updates", formData.receivePropertyUpdates);
        data.append("delivery_email", formData.deliveryEmail);
        dispatch(
          actionAddContactApiCall({
            navigate,
            data,
            orgid: singleOrgData.id,
            member_id: editData ? editData.id : "",
            CloseModal: handleClose,
            orgApicall: { data: pathname, navigate },
          })
      );
    }
  };
  // Function to handle the image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/svg+xml",
      ];
      if (allowedTypes.includes(file.type)) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          toast.error("Image size must not exceed 2 MB.");
        } else {
          setFormData({ ...formData, ["profile_picture"]: file });
          const reader = new FileReader();

          reader.onload = (e) => {
            setSelectedImage(e.target.result);
          };
          reader.readAsDataURL(file);
        }
      } else {
        toast.error("Invalid file type. Please select a valid image file.");
      }
    }
  };

  // Function to clear the selected image
  const clearImage = () => {
    setFormData({
      ...formData,
      profileImage: null,
      profile_picture: null,
    });
    setSelectedImage(null);
  };
  
  const handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if (name === "jobTitle") {
      value = target.options[target.selectedIndex].value;
    }
    // if (name === "tags") {
    //   value = target.options[target.selectedIndex].value;
    // }

    if (target.name == "phoneNumber") {
      value = value.replace(/\D/g, "");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeletebtn = () => {
    dispatch(
      actionDeleteContactApiCall({
        orgApicall: { data: pathname, navigate },
        navigate,
        CloseModal: handleClose,
        id: editData.id,
      })
    );
  };

  const onClickSendPasswordLink = () => {
    const data = { userid: editData.user_id };
    const body = { data, navigate, toast: true };
    dispatch(actionSendPasswordLinkAPICall(body));
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const tagOptions = Tags?.map((tag) => ({
    value: tag?.tag_name,
    label: tag?.tag_name,
    id:tag?.id
  }));
  console.log(tagOptions,"tagoot")
  const tagsArray = Array.isArray(editData?.tags) ? editData?.tags : [];
// const handleOnChange = ()=>{
//   if(selectedTags.length > 0){
//     setSelectedTags()
// }
  // console.log("EDIT DATA", editData);
  // console.log("FORMDATA", formData);
  return (
    <React.Fragment>
      <div className={classes.contactformsection}>
        {editData ? <h3>Edit Contact</h3> : <h3>Add Contact</h3>}
        <div className={classes.contactformwrapper}>
          <div className={classes.col1}>
            <div className={classes.addressinputwrapper}>
              <label htmlFor="">Set as main contact</label>
              <div
                className={classes.startImageWrapper}
                onClick={() =>
                  setFormData({
                    ...formData,
                    ["isMainContact"]: !formData.isMainContact,
                  })
                }
              >
                {formData.isMainContact && (
                  <img src={StarIcon} alt="star" htmlFor="MainContact" />
                )}
              </div>
              <label htmlFor="">First name</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
              <label htmlFor="">Last name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <label htmlFor="">E-mail</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validation}
              />
              {error && <span>{error}</span>}
              <label htmlFor="">Phonenumber (#)</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                maxLength="10"
                minLength="8"
              />
              <label for="job-title">Job Title</label>
              <select
                id="job-title"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  {`${t("Select a job title")}`}
                </option>
                {jobOptions &&
                  jobOptions?.map((option) => (
                    <option key={option.id} value={option?.value}>
                      {option?.translation}
                    </option>
                  ))}
              </select>

              <div className={classes.uploadPicWrapper}>
                <div className={classes.imageprview}>
                  <label htmlFor="file-upload">Profile Upload</label>
                  <div className={classes.preview}>
                    {selectedImage ? (
                      <div className={classes.innerpreview}>
                        <img
                          style={{
                            objectFit: "fill",
                            width: "200px",
                            height: "200px",
                          }}
                          src={selectedImage}
                          alt="Profile Preview"
                        />
                        <div className={classes.cross} onClick={clearImage}>
                          <IoClose />
                        </div>
                      </div>
                    ) : (
                      <div className={classes.innerpreview}>
                        <p>
                          Profile <br /> Picture
                        </p>
                        <div className={classes.cross} onClick={clearImage}>
                          <IoClose />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={classes.upload}>
                  <div className={classes.uploadImg}>
                    <label htmlFor="file-upload">
                      <img src={upload} alt="Upload" />
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      accept=".jpg, .jpeg, .png,.webp,.svg"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </div>

                  <div className={classes.labelupload}>
                    <label htmlFor="file-upload">Upload</label>
                  </div>
                </div>

              </div>

              <label htmlFor="">Tags</label>
              {/* {editData?.tags && <p>{editData?.tags}</p>} */}
              <Select
              isMulti
            styles={customStyles}
            name="tags"
            defaultValue={ selectedTags }
            value={selectedTags}
            onChange={setSelectedTags}
            options={tagOptions}
          /> 
            </div>
          </div>
          <div className={classes.col2}>
            <div className={classes.switchWrapperandbtn}>

            <label htmlFor="">Marketing e-mails</label>

            <label className={classes.Switch}>
              <input
                type="checkbox"
                name="marketingEmails"
                checked={formData.marketingEmails}
                onChange={handleInputChange}
              />
              <span className={classes.Slider} />
            </label>
              <label htmlFor="">Quotation recieved</label>

              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  name="quotationReceived"
                  checked={formData.quotationReceived}
                  onChange={handleInputChange}
                />
                <span className={classes.Slider} />
              </label>
              <label htmlFor="">Invoice recieved</label>

              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  name="invoiceReceived"
                  checked={formData.invoiceReceived}
                  onChange={handleInputChange}
                />
                <span className={classes.Slider} />
              </label>

              <label htmlFor="">Order confirmation</label>

              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  name="orderConfirmation"
                  checked={formData.orderConfirmation}
                  onChange={handleInputChange}
                />
                <span className={classes.Slider} />
              </label>

              <label htmlFor="">Delivery e-mail</label>

              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  name="deliveryEmail"
                  checked={formData.deliveryEmail}
                  onChange={handleInputChange}
                />
                <span className={classes.Slider} />
              </label>

              <label htmlFor="">Receive Property Updates</label>

              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  name="receivePropertyUpdates"
                  checked={formData.receivePropertyUpdates}
                  onChange={handleInputChange}
                />
                <span className={classes.Slider} />
              </label>

              {formData.user_id && (
                <button
                  className={classes.sendpasswordreset}
                  onClick={onClickSendPasswordLink}
                >
                  Send Password Reset
                </button>
              )}
            </div>
          </div>
        </div>

        <div className={classes.row2}>
          <div className={classes.cancelsavebtn}>
            {!editData ? (
              <>
                <button onClick={handleClose} className={classes.cancel}>
                  Cancel
                </button>
                <button className={classes.save} onClick={handleSave}>
                  Save
                </button>
              </>
            ) : (
              <>
                <button onClick={handleDeletebtn} className={classes.cancel}>
                  <img src={deleteIcon} alt="" />
                  Delete
                </button>
                <button className={classes.save} onClick={handleSave}>
                  Save
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactForm;
