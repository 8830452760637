import React, { useEffect } from "react";
import classes from "./translationheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useFetcher, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import { useState } from "react";
import DistrictForm from "../DistrictForm";
import {
  actionGetDistrictTableSystemApiCall,
  actionGetTranslationColumnApiCall,
  actionLangListAPICall,
  actionMasterTableGetTranslationColumnApiCall,
  actionSaveTranslationColumnFilter,
  actionSaveTranslationLanguageFilter,
} from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../CustomSelect";
import SideBar from "../SideBar";
import TranslationForm from "../TranslationForm";
import Select from "react-select";

const TranslationHeader = ({ openCanvasB, editData, setopenCanvasB }) => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);

  const [headerSearch, setHeaderSearch] = useState({
    name: "",
    language: "en",
    column_name: "",
  });
  const [selectedColumn, setSelectedColumn] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.CommonReducer);
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  // const isColumnAvailable = state?.saveTranslationColumnFilter ? state?.saveTranslationColumnFilter
  // getting data from branches form child

  const branchChild = (child) => {
    setopenCanvasB(false);
    setOpenCanvas(child);
  };
  const [sidebar, setSideBar] = useState(false);
  useEffect(() => {
    if (openCanvasB) {
      setOpenCanvas(openCanvasB);
    }
  }, [openCanvasB]);

  useEffect(() => {
    const body = { navigate };
    dispatch(actionLangListAPICall(body));
    dispatch(actionMasterTableGetTranslationColumnApiCall({ navigate }));
  }, []);

  useEffect(() => {
    // Assuming state?.saveMasterTableColumn is an array
    if (
      state?.saveMasterTableColumn &&
      state?.saveMasterTableColumn.length > 0
    ) {
      // Set the default option to the first item in the array
      setHeaderSearch({
        ...headerSearch,
        column_name: {
          label: state.saveMasterTableColumn[0].column,
          value: state.saveMasterTableColumn[0].column,
        },
      });
    }
  }, [state?.saveMasterTableColumn]);

  const filterClick = () => {
    const body = {
      navigate,
      search: headerSearch.name,
      language: headerSearch.language,
      column: headerSearch.column_name.value,
      page: 1,
    };
    dispatch(actionGetTranslationColumnApiCall(body));
  };

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };

  useEffect(() => {
    // state?.saveTranslationColumnFilter
    // ?
    //   state?.saveTranslationColumnFilter
    // : state?.saveMasterTableColumn && {
    //     label: state.saveMasterTableColumn[0].column,
    //     value: state.saveMasterTableColumn[0].column,
    //   }

    if (
      state?.saveTranslationColumnFilter &&
      Object.keys(state?.saveTranslationColumnFilter).length > 0
    ) {
      setHeaderSearch({
        ...headerSearch,
        column_name: state?.saveTranslationColumnFilter,
      });
    } else if (state?.saveMasterTableColumn) {
      // setSelectedColumn({
      //   label: state.saveMasterTableColumn[0].column,
      //   value: state.saveMasterTableColumn[0].column,
      // });
      setHeaderSearch({
        ...headerSearch,
        column_name: {
          label: state.saveMasterTableColumn[0].column,
          value: state.saveMasterTableColumn[0].column,
        },
      });
    }
  }, [state?.saveTranslationColumnFilter, state?.saveMasterTableColumn]);

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")}{" "}
              {t("translations")}
            </h5>
            <form onSubmit={onClickOfButton} className={classes.managementFild}>
              <div className={classes.fildBox}>
                <input
                  type="text"
                  placeholder={`${t("name_p")}...`}
                  onChange={(e) =>
                    setHeaderSearch({
                      ...headerSearch,
                      name: e.target.value,
                    })
                  }
                />
              </div>

              <div className={classes.fildBox}>
                <select
                  id="status"
                  name="language"
                  onChange={(e) => {
                    setHeaderSearch({
                      ...headerSearch,
                      language: e.target.value,
                    });
                    let langId = langdata.find(
                      (item) => item.language == e.target.value
                    ).id;
                    dispatch(
                      actionSaveTranslationLanguageFilter({
                        language: e.target.value,
                        language_id: langId,
                      })
                    );
                  }}
                >
                  {langdata?.map((item, index) => {
                    return (
                      <option
                        value={item.language}
                        selected={index == 0 ? true : false}
                      >
                        {item.language_label}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className={classes.fildBox}>
                <select
                  id="status"
                  name="column_name"
                  onChange={(e) =>
                    setHeaderSearch({
                      ...headerSearch,
                      column_name: e.target.value,
                    })
                  }
                >
                  {state?.saveMasterTableColumn?.length > 0 &&
                    state?.saveMasterTableColumn?.map((item, index) => {
                      return (
                        <option
                          value={item.column}
                          selected={index == 0 ? true : false}
                        >
                          {item.column}
                        </option>
                      );
                    })}
                </select>
              </div> */}

              <div className={classes.fildBox}>
                <Select
                  placeholder={<div>{t("column")}</div>}
                  className="basic-single"
                  classNamePrefix="select"
                  value={headerSearch.column_name}
                  isClearable={false}
                  isSearchable={true}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onInputChange={
                    (data) => {}
                    // setHeaderSearch({
                    //   ...headerSearch,
                    //   column_name: data,
                    // })
                  }
                  options={
                    state?.saveMasterTableColumn &&
                    state?.saveMasterTableColumn?.map((p) => {
                      return { label: p.column, value: p.column };
                    })
                  }
                  onChange={(option) => {
                    dispatch(actionSaveTranslationColumnFilter(option));
                    setHeaderSearch({
                      ...headerSearch,
                      column_name: option,
                    });
                  }}
                />
              </div>

              <div className={classes.fildBox} onClick={onClickOfButton}>
                <button>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <TranslationForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default TranslationHeader;
