import React, { useEffect, useState } from "react";
import LanguageManagementHeader from "../common/LanguageManagementHeader";
import MainLayount from "../common/MainLayout";
import classes from "./languagemanagementmain.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionUpdateLangLabelListAPICall } from "../../Redux/Actions";
import Loader from "../common/Loader";

const LanguageManagementMain = () => {
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangLabelListData
  );
  const loader = useSelector((state) => state.CommonReducer.loader);
  const lang = useSelector((state) => state.CommonReducer.language);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [name, setName] = useState();

  const onClickOfUpdate = (id) => {
    const data = {
      id: id,
      label_value: name,
    };
    const body = { data, navigate };
    dispatch(actionUpdateLangLabelListAPICall(body));
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.LanguageMain}>
          <LanguageManagementHeader />
          <div className={classes.LanguageListingSection}>
            <table>
              <tr>
                <th>{t("english")}</th>
                {lang == "en" && <th>{`${t("english")} ${t("value")}`}</th>}
                {lang == "nl" && <th>{`${t("dutch")} ${t("value")}`}</th>}
                <th>{t("action")}</th>
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={3}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {langdata && langdata?.length > 0 ? (
                    langdata?.map((itme) => (
                      <tr>
                        <td style={{ maxWidth: "250px" }}>
                          {itme.default_eng_label}
                        </td>
                        <td style={{ maxWidth: "250px" }}>
                          <div className={classes.fildBox}>
                            <textarea
                              placeholder={`${t("name_p")}...`}
                              value={id == itme.id ? name : itme.label_value}
                              onChange={(e) => {
                                setName(e.target.value);
                                setId(itme.id);
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <button onClick={() => onClickOfUpdate(itme.id)}>
                            {t("update")}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={3}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default LanguageManagementMain;
