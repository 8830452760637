import React, { useEffect, useState } from "react";
import MainLayount from "../common/MainLayout";
import UserManagmentHeadre from "../common/UserManagmentHeadre";
import classes from "./usermanagementmain.module.scss";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionBlockUserApiCall,
  actionDeleteUserApiCall,
  actionViewUserApiCall,
  actionOrganizationListApiCall,
  actionGetPropertyListAPICall,
  actionUnblockUserApiCall,
  actionSendPasswordLinkAPICall,
  actionSetLoader,
  actionLinkSend,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../common/ConfirmationModal";
import ModalMain from "../common/Modal";
import EditUser from "../ModalComponents/UserManagementModals/EditUser";
import moment from "moment";
import Loader from "../common/Loader";
import { useRef } from "react";

const UserManagementMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailflag = useSelector((state) => state.CommonReducer.usedEmailFlag);
  const userlist = useSelector((state) => state.CommonReducer.userListData);
  const loader = useSelector((state) => state.CommonReducer.loader);
  const [ind, setInd] = useState("");
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [creatUser, setCreateUser] = useState();
  const reduxState = useSelector((state) => state.CommonReducer);
  const listRef = useRef(null);
  const divref = useRef([]);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 304) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const adduser = (id) => {
    let data = [...selectedUser];
    data.push(id);
    setSelectedUser(data);
  };
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const deleteUser = (id) => {
    if (selectedUser?.length > 0) {
      let data = selectedUser.filter((item) => item != id);
      setSelectedUser(data);
    }
  };
  const onCLickBlockUser = (id) => {
    const data = {
      users_id: [id],
    };
    const body = { data, navigate };
    dispatch(actionBlockUserApiCall(body));
  };
  const onCLickUnBlockUser = (id) => {
    const data = {
      users_id: [id],
    };
    const body = { data, navigate };
    dispatch(actionUnblockUserApiCall(body));
  };
  const onCLickViewUser = (id) => {
    const data = id;
    const body = { data, navigate };
    dispatch(actionViewUserApiCall(body));
    listuserapicall(id);
    getPropertyListAPICall(id);
    dispatch(actionLinkSend(false));
  };
  const listuserapicall = (id) => {
    const data = { page: 1, userid: id };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  };
  const getPropertyListAPICall = (id) => {
    const data = { page: 1, userid: id };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  };

  const onCLickDeleteUser = (id) => {
    const data = {
      users_id: [id],
    };
    const body = { data, navigate };
    dispatch(actionDeleteUserApiCall(body));
  };
  const onClickSendPasswordLink = (id) => {
    const data = { userid: id };
    const body = { data, navigate };
    dispatch(actionSendPasswordLinkAPICall(body));
  };
  const onClickEditUser = (index) => {
    const data = index;
    const body = { data, navigate, edit: true };
    dispatch(actionViewUserApiCall(body));
    listuserapicall(index);
    getPropertyListAPICall(index);
    setCreateUser(true);
  };


  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.UserMain}>
          <UserManagmentHeadre selectedUser={selectedUser} />

          <div className={classes.UserListingSection}>
            <table>
              <tr>
                <th className={classes.selectUser}></th>
                <th>{t("name")}</th>
                <th>{t("created_on")}</th>
                <th>{t("id")}</th>
                <th className={classes.OrgSection}>{t("organisations")}</th>
                <th style={{ textAlign: "center" }}>{t("listings")}</th>
                <th>{t("status_c")}</th>
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {userlist && userlist?.length > 0 ? (
                    userlist.map((item, index) => (
                      <tr
                        className={
                          selectedUser.find((s) => s == item.id)
                            ? classes.active
                            : null
                        }
                        ref={(e) => (divref.current[index] = e)}
                      >
                        <td className={classes.selectUser}>
                          <input
                            type="checkbox"
                            id={`org1` + index}
                            name="org"
                            className={classes.radioCustom}
                            onChange={(e) => {
                              if (e.target.checked) {
                                adduser(item.id);
                              } else {
                                deleteUser(item.id);
                              }
                            }}
                            checked={
                              selectedUser.find((s) => s == item.id)
                                ? true
                                : false
                            }
                          />
                          <label
                            className={classes.radioCustomLabel}
                            htmlFor={`org1` + index}
                          ></label>
                        </td>
                        <td>{item.first_name ? item.first_name : item.email}</td>
                        <td>
                          {item.created_at
                            ? moment(item.created_at).format("DD/MM/YYYY HH:MM")
                            : "NA"}
                        </td>
                        <td>{item.id ? item.id : "NA"}</td>
                        <td className={classes.OrgSection}>
                          {item.organisation_count
                            ? item.organisation_count
                            : 0}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.listing_count ? item.listing_count : 0}
                        </td>
                        <td>
                          {item.status ? (
                            <div className={classes.status}>
                              <p
                                className={
                                  item.status == "online"
                                    ? classes.greenText
                                    : classes.redText
                                }
                              >
                                <span
                                  className={
                                    item.status == "online"
                                      ? classes.greenDot
                                      : classes.redDot
                                  }
                                ></span>
                                {item.status}
                              </p>
                            </div>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td className={classes.userEditor}>
                          <img
                            src={userEditorIcon}
                            onClick={() => {
                              if (userlist.length - 1 > index) {
                                handleScroll(index);
                              }
                              if (ind !== index) {
                                setShow(!show);
                                setInd(index);
                              } else {
                                setInd("");
                              }
                            }}
                          />
                          {show && ind == index && (
                            <div
                              className={classes.EditorWrap}
                              style={
                                userlist.length - 2 > index
                                  ? {}
                                  : { top: "-170px" }
                              }
                              onClick={() => setShow(!show)}
                              ref={listRef}
                            >
                              <ul>
                                <li onClick={() => onCLickViewUser(item.id)}>
                                  {t("view_user")}
                                </li>
                                <li onClick={() => onClickEditUser(item.id)}>
                                  {t("edit_user")}
                                </li>
                                {item.status == "online" && (
                                  <li onClick={() => onCLickBlockUser(item.id)}>
                                    {t("block_user")}
                                  </li>
                                )}
                                {item.status == "blocked" && (
                                  <li
                                    onClick={() => onCLickUnBlockUser(item.id)}
                                  >
                                    {t("online_user")}
                                  </li>
                                )}
                                <li onClick={() => onCLickDeleteUser(item.id)}>
                                  {t("delete_user")}
                                </li>
                                {reduxState.linkSend == item.id ? (
                                  <li className={classes.TextBlue}>
                                    {t("link_was_sent")}
                                  </li>
                                ) : (
                                  <li
                                    onClick={() =>
                                      onClickSendPasswordLink(item.id)
                                    }
                                  >
                                    {t("send_reset_password_link")}
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={6}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
      <ModalMain
        showmodel={creatUser}
        handleClose={() => setCreateUser(false)}
        size={"md"}
      >
        <EditUser handleClose={() => setCreateUser(false)} edit={false} />
      </ModalMain>
      <ConfirmationModal showmodel={emailflag} size={"sm"}></ConfirmationModal>
    </React.Fragment>
  );
};

export default UserManagementMain;
