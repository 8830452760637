import classes from "./mainlayout.module.scss";
import SideBar from "../SideBar";
import FooterMain from "../Footermain";

import React, { useState, useEffect, useCallback } from "react";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

const MainLayount = ({ children, closePopup }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleWindowResize = useCallback(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(handleWindowResize, 200);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [handleWindowResize]);

  return (
    <React.Fragment>
      <div className={classes.MainLayout}>
        {screenWidth > 991 && (
          <div className={classes.SideBarMain}>
            <SideBar closePopup={closePopup} />
          </div>
        )}
        <div className={classes.LayoutSection}>
          {children}
          <FooterMain closePopup={closePopup} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainLayount;
