import React from "react";
import classes from "./listingorgpage.module.scss";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetPropertyDetailApiCall,
  actionGetPropertyListAPICall,
  actionUpdatePropertyVisibleApiCall,
  actiongetPropertyViewsApiCall,
} from "../../../Redux/Actions";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import { useEffect } from "react";
import EditIcon from "../../../assets/images/EditLogo.svg";
import ModalMain from "../../common/Modal";
import EditOrganisation from "../../ModalComponents/OrganisationManagementModals/EditOrganisation";
import EditListing from "../../ModalComponents/ListingManagementModals/EditListing";

const ListingOrgPage = () => {
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  const SavePropertyListdata = useSelector(
    (state) => state.CommonReducer.SavePropertyListdata
  );
  const state = useSelector((state) => state.CommonReducer);
  const [page, setPage] = useState(1);
  const [listSearch, setListSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalpage, setTotalPage] = useState(1);
  const [editListing, setEditListing] = useState(false);

  useEffect(() => {
    let ans = Math.ceil(state?.totalListCount / 10);
    setTotalPage(ans != NaN ? ans : 1);
  }, [state]);

  useEffect(() => {
    if (singleOrgData.id) {
      const data = {
        organizationid: singleOrgData.id,
        address: listSearch,
        page: page,
      };
      const body = { data, navigate };
      dispatch(actionGetPropertyListAPICall(body));
    }
  }, [listSearch, page]);

  const updatePropertyVisible = (hide, item) => {
    const data = {
      property_id: item.id,
      hide_property: hide,
    };
    const body = { data, navigate, organizationid: singleOrgData.id };
    dispatch(actionUpdatePropertyVisibleApiCall(body));
  };
  const onClickofEditIcon = (id) => {
    setEditListing(true);
    const data = {
      property_id: id,
    };
    const body = { data, navigate, edit: true };
    dispatch(actionGetPropertyDetailApiCall(body));
    dispatch(actiongetPropertyViewsApiCall(body));
  };
  return (
    <div className={classes.listingMain}>
      <p>{t("Organisation Listings")}</p>
      <div className={classes.TableListngLayOut}>
        <div className={classes.SearchBar}>
          <img src={SearchIcon} alt="" />
          <input
            type="search"
            placeholder="Search in Listing"
            onChange={(e) => setListSearch(e.target.value)}
          />
        </div>
        <table>
          <tr>
            <th>{t("address_capital")}</th>
            <th>{t("CITY")}</th>
            <th>{t("postalcode")}</th>
            <th>{t("price")}</th>
            <th>{t("TYPE")}</th>
            <th>{t("STATUS")}</th>
            <th></th>
          </tr>
          {SavePropertyListdata.length > 0 ? (
            SavePropertyListdata?.map((item) => (
              <tr
                className={
                  item.hide_property == 1 ? classes.InvisibleCard : null
                }
              >
                <td>{item.address}</td>
                <td>{item.city}</td>
                <td>{item.postcode}</td>
                <td>{item.sell_price}</td>
                {item.is_rent == 0 && item.is_sell == 1 && <td>{t("sell")}</td>}
                {item.is_rent == 1 && item.is_sell == 0 && <td>{t("rent")}</td>}
                {item.is_rent == 1 && item.is_sell == 1 && <td>{t("both")}</td>}
                <td className={classes.pinkText}>
                  {item.status == "SOLD" && item.status}
                </td>
                <td>
                  <div
                    className={classes.ListingIcons}
                    onClick={() => onClickofEditIcon(item.id)}
                  >
                    <img src={EditIcon} alt="" />
                  </div>
                </td>
                {/* <td>
                  <div className={classes.ListingIcons}>
                    {item.hide_property == 0 ? (
                      <FaEye onClick={() => updatePropertyVisible(1, item)} />
                    ) : (
                      <FaEyeSlash
                        onClick={() => updatePropertyVisible(0, item)}
                      />
                    )}
                  </div>
                </td> */}
              </tr>
            ))
          ) : (
            <tr className={classes.NoData}>
              <td className={classes.LoaderWrap} colSpan={6}>
                <h2>{t("no_data_found")}</h2>
              </td>
            </tr>
          )}
        </table>
        <div className={classes.PaginationWrapper}>
          <p onClick={() => setPage(page - 1)}>{page > 1 && "< Prev"}</p>
          <p onClick={() => setPage(page + 1)}>
            {page >= 1 && page < totalpage && "Next >"}
          </p>
        </div>
      </div>
      <ModalMain
        showmodel={editListing}
        handleClose={() => setEditListing(false)}
        size={"xl"}
      >
        <EditListing handleClose={() => setEditListing(false)} />
      </ModalMain>
    </div>
  );
};

export default ListingOrgPage;
