import React, { useEffect } from "react";
import classes from "./branchesheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import { useState } from "react";
import BranchesForm from "../BranchesForm";
import SideBar from "../SideBar";

const BranchesHeader = ({ openCanvasB }) => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);
  const [sidebar, setSideBar] = useState(false);
  const navigate = useNavigate();

  const sidebarShow = () => {
    setSideBar(true);
  };
  useEffect(() => {
    if (openCanvasB) {
      setOpenCanvas(openCanvasB);
    }
  }, [openCanvasB]);

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("View Branches")}
            </h5>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <BranchesForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default BranchesHeader;
