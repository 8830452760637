import React, { useEffect, useState } from "react";
import classes from "./systemmanagementheader.module.scss";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";

const SystemManagementHeader = (props) => {
  const { t } = useTranslation();
  const [sidebar, setSideBar] = useState(false);

  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap} onClick={props.ClosePopup}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("system_management")}</h5>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default SystemManagementHeader;
