import React, { useRef, useState } from "react";
import classes from "./branchesmain.module.scss";
import MainLayout from "../common/MainLayout";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useTranslation } from "react-i18next";
import BranchesHeader from "../common/BranchesHeader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionBanchNestedGetApi,
  actionDeleteBranch,
  actionGetBranchTableSystemApiCall,
} from "../../Redux/Actions";
import BranchesForm from "../common/BranchesForm";
import OffcanvasMain from "../common/Offcanvas";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
const BranchesMain = () => {
  const tableData = useSelector(
    (state) => state?.CommonReducer?.getBranchTable
  );
  const loader = useSelector((state) => state.CommonReducer.loader);
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const divref = useRef([]);
  const [openCanvas, setOpenCanvas] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(actionBanchNestedGetApi({ navigate }));
  }, []);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 303) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = (item) => {
    dispatch(actionDeleteBranch({ id: item?.id, navigate }));
  };

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.BranchesMain}>
          <BranchesHeader editData={editData} />
          <div className={classes.BranchesSection}>
            <table>
              <thead>
                <tr>
                  <th>{t("NAME")}</th>
                  <th>{t("MAIN BRANCH")}</th>
                  <th>{t("SUB BRANCH")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader == true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={3}>
                      <Loader />
                    </td>
                  </tr>
                ) : tableData.length > 0 ? (
                  tableData.map((item, index) => (
                    <tr ref={(e) => (divref.current[index] = e)} key={index}>
                      <td>{item?.name}</td>
                      <td>
                        {item?.parentBranch
                          ? item?.parentBranch?.name
                          : item?.name}
                      </td>
                      <td>{item?.parentBranch ? item?.name : "-"}</td>
                      <td className={classes.userEditor}>
                        <img
                          src={userEditorIcon}
                          onClick={() => {
                            handleScroll(index);
                            if (ind !== index) {
                              setShow(!show);
                              setInd(index);
                            } else {
                              setInd("");
                            }
                          }}
                        />
                        {show && ind === index && (
                          <div
                            ref={listRef}
                            className={classes.EditorWrap}
                            onClick={() => setShow(!show)}
                            style={
                              tableData.length - 1 > index
                                ? {}
                                : tableData.length > 4
                                ? { top: "-100px" }
                                : {}
                            }
                          >
                            <ul>
                              <li
                                onClick={() => {
                                  setOpenCanvas(true);
                                  setEditData(item);
                                }}
                              >
                                {t("Edit Branche")}
                              </li>
                              <li onClick={() => handleDelete(item)}>
                                {t("Delete Branche")}
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={3}>
                      <h2>{t("no_data_found")}</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <OffcanvasMain
            showcanvas={openCanvas}
            placement={"end"}
            backgroundColor="true"
            handleClose={() => {
              setOpenCanvas(false);
            }}
          >
            <BranchesForm
              editData={editData}
              handleClose={() => {
                setOpenCanvas(false);
              }}
            />
          </OffcanvasMain>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default BranchesMain;
