import React, { useState } from "react";
import classes from "./vieworgsubscriptionplan.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { BsFillStarFill } from "react-icons/bs";
import PriceFormatter from "../../../utils/PriceFormatter";
import moment from "moment";
import ModalMain from "../Modal";
import EditOrganisationAddSubscription from "../EditOrganisationAddSubscription";
import { useNavigate } from "react-router-dom";
import {
  actionGetAllPaymentOptionApiCall,
  actionGetSubscriptionApiCall,
} from "../../../Redux/Actions";
import AddSubscriptionModal from "../../ModalComponents/SubscriptionModal/AddSubscriptionModal";
import ConfirmationModal from "../../common/ConfirmationModal";

const ViewOrgSubscriptionPlan = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userListData = useSelector(
    (state) => state.CommonReducer.saveSubscriptionListForOrg
  );
  let ActiveSubscription = userListData.find(
    (item) =>
      item.payment_status === "paid" && item.subscription_detail.active === 1
  );
  const [createuser, setCreateUser] = useState(false);
  const [show, setShow] = useState(false);
  const [activePlan, setActivePlan] = useState();
  const [stopConfirmModal, setStopConfirmModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");

  const ViewOrgSubscriptionPlanClose = () => {
    props.editPopUpClose();
    props.handleClose();
  };
  const getLabel = (status) => {
    if (status === "canceled" || status === "failed") {
      return "Not invoiced";
    } else if (status === "open" || status === "pending") {
      return "Open invoice";
    } else if (status === "paid") {
      return "Paid";
    } else if (status === "expired") {
      return "Overdue";
    }
  };
  const getStatusStyle = (paymentStatus) => {
    switch (paymentStatus) {
      case "Not invoiced":
        return { color: "#8F8F8F" };
      case "Open invoice":
        return { color: "#FFD651" };
      case "Paid":
        return { color: "#4DCD76" };
      case "Overdue":
        return { color: "#FF0000" };
    }
  };
  const onClickOfAddNew = () => {
    dispatch(actionGetAllPaymentOptionApiCall({ navigate }));
    setCreateUser(true);
  };
  const onClickEdit = (id, item) => {
    const data = id;
    const body = { data, navigate, edit: true };
    dispatch(actionGetSubscriptionApiCall(body));
    setActivePlan(item);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      <div className={classes.ViewOrgSubscriptionPlanMain}>
        <h3>
          {props.orgName ? `${props.orgName} ` : `{${t("organisations")}} `}
          {t("subscription_overview")}
        </h3>
        <div className={classes.ViewOrgSubscriptionPlanWrap}>
          <table>
            <tr>
              <th>{t("name")}</th>
              <th>{t("starrt")}</th>
              <th>{t("end")}</th>
              <th>{t("price")}</th>
              <th>{t("status_c")}</th>
              <th>{t("active")}</th>
              <th>{t("action")}</th>
            </tr>
            {userListData.length > 0 ? (
              userListData.map((item) => (
                <tr>
                  <td>
                    {item?.subscription_detail?.product_detail?.name
                      ? item?.subscription_detail?.product_detail?.name
                      : "NA"}
                  </td>
                  <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                  <td>
                    {PriceFormatter(
                      Number(item.total_price)
                      // + Number(item.total_vat)
                    )}
                  </td>
                  <td style={getStatusStyle(getLabel(item.payment_status))}>
                    {getLabel(item.payment_status)}
                  </td>{" "}
                  <td className={classes.activeIcon}>
                    {item?.id === ActiveSubscription?.id && <BsFillStarFill />}
                  </td>
                  <td>
                    <div className={classes.actionContainer}>
                      {/* {(getLabel(item.payment_status) === "Paid" ||
                        getLabel(item.payment_status) === "Open invoice") &&
                        item.subscription_detail.active == 1 && ( */}
                      {item.subscription_detail.active == 1 && (
                        <button
                          onClick={() => {
                            setStopConfirmModal(true);
                            setSubscriptionId(item.subscription_detail.id);
                          }}
                          className={classes.stopButton}
                        >
                          {t("stop")}
                        </button>
                      )}
                      {/* )} */}
                      {getLabel(item?.payment_status) === "Open invoice" && (
                        <button
                          className={classes.editButton}
                          onClick={() =>
                            onClickEdit(
                              item?.subscription_detail?.product_detail?.id,
                              item
                            )
                          }
                        >
                          {t("edit")}
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className={classes.NoData}>
                <td className={classes.LoaderWrap} colSpan={6}>
                  <h2>{t("no_data_found")}</h2>
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className={classes.AddUseAsSection}>
          <button onClick={onClickOfAddNew}>{t("add_new")}</button>
          {/* <button>{t("edit")}</button> */}
        </div>
      </div>
      <ModalMain
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        dialogClassName={classes.ModaleSize}
      >
        <EditOrganisationAddSubscription
          handleClose={() => setCreateUser(false)}
          ViewOrgSubscriptionPlanClose={ViewOrgSubscriptionPlanClose}
        />
      </ModalMain>
      <ModalMain showmodel={show} handleClose={handleClose} size={"xl"}>
        <AddSubscriptionModal
          edit={true}
          close={handleClose}
          data={activePlan}
          EditOrganisationAddSubscriptionClose={handleClose}
        />
      </ModalMain>
      <ConfirmationModal
        showmodel={stopConfirmModal}
        size={"sm"}
        input={true}
        subscriptionId={subscriptionId}
        orgId={props.orgId}
        handleClose={() => setStopConfirmModal(false)}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default ViewOrgSubscriptionPlan;
