const PriceFormatter = (price, decimal = 0, currency = "€ ", banner) => {
  // const formatter = new Intl.NumberFormat("de-DE", {
  //   style: "currency",
  //   currency: "EUR",
  //   maximumFractionDigits: 2,
  //   minimumFractionDigits: 0,
  // });

  // return formatter.format(price);

  // const formatter = new Intl.NumberFormat("de-DE", {
  //   style: "currency",
  //   currency: "EUR",
  // }).resolvedOptions().maximumFractionDigits;

  let formattedPrice = parseFloat(price).toLocaleString("nl-NL", {
    minimumFractionDigits: banner ? 2 : 0,
    maximumFractionDigits: banner ? 2 : decimal,
  });
  return `${currency}${formattedPrice}`;
};

export default PriceFormatter;
