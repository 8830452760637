import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import classes from "./cityform.module.scss";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { actionAddCityApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../CustomSelect";
import Loader from "../Loader";
import { toast } from "react-toastify";

const CityForm = ({ editData, handleClose }) => {
  const state = useSelector((state) => state?.CommonReducer);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const SelectItems = [
    {
      placeholder: t("municipality"),
      name: "municipality_id",
      options:
        state?.getMunicipalityTable &&
        state?.getMunicipalityTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "municipality",
    },
  ];
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    image: "",
    municipality_id: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    code: "",
    image: "",
    municipality_id: "",
  });
  useEffect(() => {
    if (editData) {
      setFormData({
        ...formData,
        name: editData?.name || "",
        code: editData?.code || "",
        description: editData?.description || "",
        image: editData?.slider_image || "",
        municipality_id: editData.municipality_id,
      });
    }
  }, [editData]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      code: "",
      image: "",
      municipality_id: "",
    };

    if (formData.name.trim() === "") {
      newErrors.name = t("name_required");
      valid = false;
    }
    if (formData.code === "") {
      newErrors.code = t("code") + " " + t("required");
      valid = false;
    }
    if (formData.image === "") {
      newErrors.image = t("image_required");
      valid = false;
    }
    if (formData.municipality_id === "") {
      newErrors.municipality_id = t("municipality") + " " + t("required");
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      let closePopup = handleClose;
      const data = new FormData();
      data.append("name", formData.name);
      data.append("code", formData.code);
      data.append("description", formData.description);
      data.append(
        "file",
        formData["image 1"] ? formData["image 1"] : formData["image"]
      );
      data.append(
        "municipality_id",
        formData.municipality_id && formData.municipality_id.value
          ? formData.municipality_id.value
          : formData.municipality_id
      );

      dispatch(
        actionAddCityApiCall({
          data,
          edit: editData ? editData.id : false,
          closePopup,
          navigate,
        })
      );
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/svg+xml",
      ];
      if (allowedTypes.includes(file.type)) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          toast.error("Image size must not exceed 2 MB.");
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            setFormData({
              ...formData,
              [`${event.target.name} 1`]: file,
              [event.target.name]: e.target.result,
            });
          };
          setErrors({ ...errors, [event.target.name]: "" });
          reader.readAsDataURL(file);
        }
      } else {
        toast.error("Invalid file type. Please select a valid image file.");
      }
    }
  };
  const clearImage = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };
  return (
    <React.Fragment>
      <div className={classes.branchesFormSection}>
        {editData ? (
          <h3>
            {t("edit")} {t("city_p")}
          </h3>
        ) : (
          <h3>
            {t("add")} {t("city_p")}
          </h3>
        )}
        <div className={classes.inputsWrapperContainer}>
          <div className={classes.inputsWrapper}>
            {SelectItems.map((item) => {
              return (
                <div className={`${classes.Text} ${classes.SelectText}`}>
                  <label>{item.placeholder}</label>
                  <CustomSelect
                    defaultInputValue={editData ? true : false}
                    value={
                      editData && {
                        label: editData[item.placeholder.toLowerCase()]?.name,
                        value: editData[item.placeholder.toLowerCase()]?.id,
                      }
                    }
                    item={item}
                    apiName={item.apiName}
                    isInputChange={true}
                    headerSearch={formData}
                    setHeaderSearch={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  {errors[item.name] && (
                    <p className={classes.error}>{errors[item.name]}</p>
                  )}
                </div>
              );
            })}
            <div className={classes.Text}>
              <label htmlFor="name">{t("countriename")}</label>
              <input
                type="text"
                name="name"
                onChange={handleInputChange}
                onBlur={validateForm}
                value={formData.name}
              />
              {errors.name && <p className={classes.error}>{errors.name}</p>}
            </div>
            <div className={classes.Text}>
              <label htmlFor="code">{t("code")}</label>
              <input
                type="text"
                name="code"
                onChange={handleInputChange}
                onBlur={validateForm}
                value={formData.code}
              />
              {errors.code && <p className={classes.error}>{errors.code}</p>}
            </div>
            <div className={classes.Text}>
              <label htmlFor="name">{t("description")}</label>
              <textarea
                name="description"
                onChange={handleInputChange}
                value={formData.description}
                onBlur={validateForm}
                rows="4"
              />
            </div>
          </div>
        </div>
        <div className={classes.uploadPicWrapper}>
          <div className={classes.uploadPicWrapper}>
            <div className={classes.imageprview}>
              <label htmlFor="file-upload1">{t("slider_image")}</label>
              <div className={`${classes.preview} `}>
                {formData.image ? (
                  <div className={`${classes.innerpreview} `}>
                    <img src={formData.image} alt="Profile Preview" />
                    <div
                      className={classes.cross}
                      onClick={() => clearImage("image")}
                    >
                      <IoClose />
                    </div>
                  </div>
                ) : (
                  <div className={`${classes.innerpreview}`}>
                    <p>
                      Slider <br />
                      image
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className={classes.upload}>
              <div className={classes.uploadImg}>
                <label htmlFor="file-upload1">
                  <img src={upload} alt="Upload" />
                </label>
                <input
                  type="file"
                  id="file-upload1"
                  accept=".jpg, .jpeg, .png,.webp,.svg"
                  style={{ display: "none" }}
                  name="image"
                  onChange={handleImageChange}
                />
              </div>

              <div className={classes.labelupload}>
                <label htmlFor="file-upload1">Upload New</label>
              </div>
            </div>
          </div>
        </div>
        {errors.image && <p className={classes.error}>{errors.image}</p>}

        <div className={classes.btnwrapper}>
          <div className={classes.btnWrapperShift}>
            {editData ? (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? <Loader /> : `${t("edit")}`}
                </button>
              </>
            ) : (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? (
                    <Loader />
                  ) : (
                    `${t("add")} ${t("city_p")}`
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CityForm;
