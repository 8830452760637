import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./creatediscount.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionAddDiscountAndExtrasApiCall } from "../../../../Redux/Actions";
import Validate from "../../../../utils/Validations";

const CreateDiscount = ({ edit, close }) => {
  const singleData = useSelector(
    (state) => state.CommonReducer.saveSingleDiscountAndExtrasData
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const [data, setData] = useState({
    name: "",
    id: "",
    type: "",
    price: "",
    valid_from: "",
    valid_until: "",
  });
  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (edit && singleData) {
      let temp = {
        ...singleData[0],
        ["price"]:
          singleData[0].type == "DISCOUNT"
            ? singleData[0].percentage
            : singleData[0].price,
      };
      delete temp.percentage;
      setData(temp);
    }
  }, [singleData]);
  const AddDiscount = () => {
    let error1 = Validate("", "", true, data);
    setError(error1);
    if (Object.keys(error1).length == 0) {
      const body = { data, close, edit, navigate };
      dispatch(actionAddDiscountAndExtrasApiCall(body));
    }
  };
  const onChangeOfInput = (e) => {
    let { name, value, type } = e.target;
    let error = Validate(name, value);
    setError(error);
    if (type == "date") {
      setData({ ...data, [name]: formatDate(value) });
    } else {
      setData({ ...data, [name]: value });
    }
  };
  const dateValidaterFunction = (e) => {
    const enteredValue = e.target.value;
    if (enteredValue) {
      const dateParts = e.target.value?.split("-");
      const year = dateParts[0];
      if (year?.length > 4) {
        e.preventDefault();
      } else {
        onChangeOfInput(e);
      }
    }
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <div className={classes.CreateDiscount}>
      <h3>
        {edit ? t("edit") : t("create")} {t("extra_discount")}
      </h3>
      <div className={classes.CreateDiscountWrap}>
        <div className={classes.AllSecionWrap}>
          <p>{t("wish_for_extra_or_discount")}</p>
          <div className={classes.SelectionWrap}>
            <input
              type="radio"
              id="EXTRA"
              name="type"
              checked={data.type == "EXTRA" ? true : false}
              className={classes.radioCustom}
              onChange={(e) => {
                let { name, value } = e.target;
                let error = Validate(name, value);
                setError(error);
                setData({ ...data, [e.target.name]: e.target.id });
              }}
            />
            <label className={classes.radioCustomLabel} htmlFor="EXTRA">
              {t("Extra")}
            </label>
          </div>
          <div className={classes.SelectionWrap}>
            <input
              type="radio"
              id="DISCOUNT"
              name="type"
              className={classes.radioCustom}
              checked={data.type == "DISCOUNT" ? true : false}
              onChange={(e) => {
                let { name, value } = e.target;
                let error = Validate(name, value);
                setError(error);
                setData({ ...data, [e.target.name]: e.target.id });
              }}
            />
            <label className={classes.radioCustomLabel} htmlFor="DISCOUNT">
              {t("Discount")}
            </label>
          </div>
          {error?.type && <p className={classes.errorText}>{error.type}</p>}
        </div>
        <div className={classes.AllSecionWrap}>
          <p>
            {t("what_amount_will_this")}{" "}
            {`'${data.type == "DISCOUNT" ? t("discount") : t("extra")}'`}{" "}
            {t("be")}
          </p>

          <div className={classes.InputWrap}>
            <p>{data.type == "DISCOUNT" ? "%" : "€"}</p>
            <input
              type="number"
              name="price"
              placeholder={
                data.type == "DISCOUNT"
                  ? t("discount_amount_placeholder")
                  : t("extra_amount_placeholder")
              }
              onChange={onChangeOfInput}
              value={data.price}
            />
          </div>
          {error?.price && <p className={classes.errorText}>{error.price}</p>}
        </div>
        <div className={classes.AllSecionWrap}>
          <p>
            {t("what_should_this")}{" "}
            {`'${data.type == "DISCOUNT" ? t("discount") : t("extra")}'`}
            {t("be_called")}
          </p>
          <div className={classes.ExtraInputWrap}>
            <input
              type="text"
              name="name"
              placeholder={
                data.type == "DISCOUNT"
                  ? t("discount_name_placeholder")
                  : t("extra_name_placeholder")
              }
              onChange={onChangeOfInput}
              value={data.name}
            />
          </div>
          {error?.name && <p className={classes.errorText}>{error.name}</p>}
        </div>

        <div className={classes.FlexWrap}>
          <div className={classes.InputBox}>
            <p>{t("start_at")}</p>
            <input
              type="date"
              name="valid_from"
              min={disablePastDate()}
              value={data.valid_from}
              onChange={(e) => {
                dateValidaterFunction(e);
              }}
            />
            {error?.valid_from && (
              <p className={classes.errorText}>{error.valid_from}</p>
            )}
          </div>
          <div className={classes.InputBox}>
            <p>{t("end_at")}</p>
            <input
              type="date"
              name="valid_until"
              min={data.valid_from}
              value={data.valid_until}
              onChange={(e) => {
                dateValidaterFunction(e);
              }}
            />
            {error?.valid_until && (
              <p className={classes.errorText}>{error.valid_until}</p>
            )}
          </div>
        </div>
      </div>
      <div className={classes.btnWrap}>
        <button onClick={AddDiscount}>{t("save")}</button>
      </div>
    </div>
  );
};

export default CreateDiscount;
