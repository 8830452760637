import React from "react";
import classes from "./invoiceorgpage.module.scss";
import ModalMain from "../../common/Modal";
import { useState } from "react";
import { t } from "i18next";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import EditIcon from "../../../assets/images/EditLogo.svg";
const InvoiceOrgPage = () => {
  const [active, setActive] = useState(false);
  const [createuser, setCreateUser] = useState(false);
  const tempInvoiceData = [
    {
      invoice_id: 234045,
      invoice_date: "02/10/2023",
      expiration_date: "02/12/2023",
      amount: 100000,
      tax: 2100,
      total: 121000,
      remaining: 2100,
      on_hold: "No",
      status: "Paid",
    },
    {
      invoice_id: 234045,
      invoice_date: "02/10/2023",
      expiration_date: "02/12/2023",
      amount: 100000,
      tax: 2100,
      total: 121000,
      remaining: 2100,
      on_hold: "No",
      status: "Paid",
    },
    {
      invoice_id: 234045,
      invoice_date: "02/10/2023",
      expiration_date: "02/12/2023",
      amount: 100000,
      tax: 2100,
      total: 121000,
      remaining: 2100,
      on_hold: "No",
      status: "Paid",
    },
  ];
  return (
    <div className={classes.InvoiceOrgPage}>
      <p>Organisation Invoices</p>
      <div className={classes.tableContainer}>
        <div className={classes.TableListngLayOut}>
          <div className={classes.SearchBar}>
            <div>
              <img src={SearchIcon} alt="" />
              <input
                type="search"
                placeholder="Search in invoices"
                // onChange={(e) => setSubscriptionSearch(e.target.value)}
              />
            </div>
            <div className={classes.activeOnly}>
              <span> Only Show unpaid</span>
              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  checked={active}
                  onChange={() => setActive(!active)}
                />
                <span className={classes.Slider} />
              </label>
            </div>
          </div>
          <table>
            <tr>
              <th>{t("INFO")}</th>
              <th>{t("INVOICE#")}</th>
              <th>{t("INVOICE DATE")}</th>
              <th>{t("EXPIRATION DATE")}</th>
              <th>{t("AMOUNT")}</th>
              <th>{t("TAX")}</th>
              <th>{t("TOTAL")}</th>
              <th>{t("REMAINING")}</th>
              <th>{t("ON HOLD")}</th>
              <th>{t("STATUS")}</th>
              <th></th>
            </tr>
            {tempInvoiceData.length > 0 ? (
              tempInvoiceData.map((item) => {
                return (
                  <tr>
                    <td>
                      <div className={classes.ListingIcons}>
                        <img src={SearchIcon} alt="" />
                      </div>
                    </td>
                    <td>{item.invoice_id}</td>
                    <td>{item.invoice_date}</td>
                    <td>{item.expiration_date}</td>
                    <td>{item.amount}</td>
                    <td>{item.tax}</td>
                    <td>{item.total}</td>
                    <td>{item.remaining}</td>
                    <td>{item.on_hold}</td>
                    <td>{item.status}</td>
                    <td>
                      <div
                        className={classes.ListingIcons}
                        // onClick={() => onClickofEditIcon(item.id)}
                      >
                        <img src={EditIcon} alt="" />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className={classes.NoData}>
                <td className={classes.LoaderWrap} colSpan={6}>
                  <h2>{t("no_data_found")}</h2>
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className={classes.AddUseAsSection}>
          <button onClick={() => {}}>{t("add")}</button>
        </div>
      </div>
      <ModalMain
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        dialogClassName={classes.ModaleSize}
        size={"xl"}
      >
        {/* <EditOrganisationAddSubscription
          handleClose={() => setCreateUser(false)}
          ViewOrgSubscriptionPlanClose={ViewOrgSubscriptionPlanClose}
        /> */}
      </ModalMain>
    </div>
  );
};

export default InvoiceOrgPage;
