export const UserListingData = [
	{
		id: 1,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 2,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 3,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 4,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 5,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 6,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 7,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 8,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
	{
		id: 9,
		name: "John Doe",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		organisation: "1",
		listing: "12",
		status: "online",
	},
];

export const UserLoggingHistoryData = [
	{
		id: 1,
		event: "Successful login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 2,
		event: "Successful login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 3,
		event: "Failed login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 4,
		event: "Successful login",
		system: "Android phone",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 5,
		event: "Failed login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 6,
		event: "Successful login",
		system: "ios",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 7,
		event: "Successful login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 8,
		event: "Successful login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 9,
		event: "Failed login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 10,
		event: "Successful login",
		system: "Android phone",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 11,
		event: "Failed login",
		system: "Desktop computer",
		time: "01/01/2023 - 14:23",
	},
	{
		id: 12,
		event: "Successful login",
		system: "ios",
		time: "01/01/2023 - 14:23",
	},
];

export const OrgListingData = [
	{
		id: 1,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 2,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 3,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 4,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 5,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 6,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 7,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 8,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 9,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 10,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 11,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
	{
		id: 12,
		name: "Scholten Makelaars",
		city: "Amsterdam",
		classification: "NVM",
		membership: "Paying",
		created: "01/01/2023 - 14:23",
		user_id: "3424",
		user: "33",
		listing: "12",
		branch: "online",
	},
];

export const ListingData = [
	{
		id: 1,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 2,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 3,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 4,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 5,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 6,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 7,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 8,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 9,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 10,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 11,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
	{
		id: 12,
		address: "Brink 41",
		city: "Amsterdam",
		postal_code: "9701 KV",
		price: "€ 334.000,-",
		type: "SELL",
		list_id: "3424",
		contact: "John Doe",
		organisation: "Lamberink",
		status: "Onder bod",
	},
];

export const EditUserData = [
	{
		id: 1,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 2,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 3,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 4,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 5,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 6,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 7,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 8,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
	{
		id: 9,
		name: "Lamberink",
		location: "Roden",
		role: "Realtor",
	},
];

export const BranchData = [
	{
		id: 1,
		branch: "Lamberink, Amsterdam",
		type: "Main",
	},
	{
		id: 2,
		branch: "Lamberink, Amsterdam",
		type: "Main",
	},
	{
		id: 3,
		branch: "Lamberink, Amsterdam",
		type: "Main",
	},
	{
		id: 4,
		branch: "Lamberink, Amsterdam",
		type: "Main",
	},
	{
		id: 5,
		branch: "Lamberink, Amsterdam",
		type: "Main",
	},
	{
		id: 6,
		branch: "Lamberink, Assen",
		type: "Sub",
	},
	{
		id: 7,
		branch: "Lamberink, Amsterdam",
		type: "Main",
	},
	{
		id: 8,
		branch: "Lamberink, Enschede",
		type: "Sub",
	},
	{
		id: 9,
		branch: "Lamberink, Amsterdam",
		type: "Main",
	},
];

export const PricingHistoryData = [
	{
		id: 1,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 2,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 3,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 4,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 5,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 6,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 7,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 8,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
	{
		id: 9,
		date: "03/11/2018",
		price: "€800,- p.m.",
	},
];

export const SingleUserListingData = [
	{
		id: 1,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 2,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 3,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 4,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 5,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 6,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 7,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 8,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
	{
		id: 9,
		address: "Brink 41",
		postal_code: "9701 HV",
		city: "Assen",
		price: "€800,- p.m.",
		type: "SELL / RENT",
	},
];

export const DropDownData = [
	{
		id: 1,
		name:"red"
	},
	{
		id: 2,
		name:"yello"
	},
	{
		id: 3,
		name:"black"
	},
	{
		id: 4,
		name:"green"
	},
	{
		id: 5,
		name:"orange"
	},	
];

export const LanguageData = [
	{
		id: 1,
		name:"Text"
	},
	{
		id: 2,
		name:"Text"
	},
	{
		id: 3,
		name:"Text"
	},
	{
		id: 4,
		name:"Text"
	},
	{
		id: 5,
		name:"Text"
	},
	{
		id: 6,
		name:"Text"
	},
	{
		id: 7,
		name:"Text"
	},
	{
		id: 8,
		name:"Text"
	},
	{
		id: 9,
		name:"Text"
	},
	{
		id: 10,
		name:"Text"
	},	
];

export const BannerManagementData = [
	{
		id: 1,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 2,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 3,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 4,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 5,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 6,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 7,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 8,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 9,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},
	{
		id: 10,
		position:"top",
		stardate:"11/07/2023",
		enddate:"26/12/2023",
		text:"yes/no"
	},	
];