import React, { useEffect, useState } from "react";
import classes from "./editorganisation.module.scss";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import RoleChangeIcon from "../../../../assets/icons/RoleChangeIcon.svg";
import DeleteIcon from "../../../../assets/icons/DeleteIcon.svg";
import StarIcon from "../../../../assets/icons/StarIcon.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { BsFillStarFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionChangeAdminOrgApiCall,
  actionChangeUserFromPropertyApiCall,
  actionGetAllSubscriptionByOrgApiCall,
  actionGetEmployeeJobTitleApiCall,
  actionRemoveUserOrgApiCall,
  actionUpdateJobTitleApiCall,
  actionUpdateOrganizationApiCall,
  actionUpdatePropertyVisibleApiCall,
  actionViewOrganizationApiCall,
} from "../../../../Redux/Actions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModalMain from "../../../common/Modal";
import ViewOrgSubscriptionPlan from "../../../common/ViewOrgSubscriptionPlan";

const EditOrganisation = (props) => {
  const { t } = useTranslation();
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  const loading = useSelector((state) => state.CommonReducer.loader);
  const SavePropertyListdata = useSelector(
    (state) => state.CommonReducer.SavePropertyListdata
  );
  const saveEmployeeJobTitle = useSelector(
    (state) => state.CommonReducer.saveEmployeeJobTitle
  );
  const subscriptionListdata = useSelector(
    (state) => state.CommonReducer.saveSubscriptionListForOrg
  );
  const [user, setUser] = useState({ model: { id: "" } });
  const [list, setList] = useState({ id: "" });
  const [data, setData] = useState({
    name: "",
    address: "",
    email: "",
    city: "",
    postcode: "",
    logo_image: "",
    website: "",
    phone: "",
    short_description: "",
    description: "",
    import_type: "",
    import_id: "",
    other: "",
    banner_budget: 0,
  });
  const [previewImg, setPreviewImg] = useState();
  const [activeSubscription, setActiveSubscription] = useState();
  const [focus, setFocus] = useState(false);
  const [createuser, setCreateUser] = useState(false);
  const [previewBannerImg, setPreviewBannerImg] = useState();
  const [showError, setShowError] = useState(false);
  const [jobTitle, setJobTitle] = useState();
  const [changeContactUser, setChangeContactUser] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataProviderArray = [
    { label: "Realworks", value: "TIARA" },
    { label: "Kolibri", value: "KOLIBRI" },
    { label: "Skarabee", value: "SKARABEE" },
    { label: "Other", value: "OTHER" },
    { label: "None", value: "" },
  ];
  useEffect(() => {
    if (subscriptionListdata.length > 0)
      setActiveSubscription(
        subscriptionListdata.find(
          (item) =>
            item.payment_status === "paid" &&
            item.subscription_detail.active === 1
        )
      );
  }, [subscriptionListdata]);
  useEffect(() => {
    setData({
      name: singleOrgData.name,
      address: singleOrgData.address,
      email: singleOrgData.email,
      city: singleOrgData.city,
      postcode: singleOrgData.postcode,
      logo_image: singleOrgData.logo_image,
      website: singleOrgData.website,
      phone: singleOrgData.phone,
      banner_image: singleOrgData.banner_image,
      short_description: singleOrgData.short_description,
      description: singleOrgData.description,
      import_type: singleOrgData?.agency_import?.import_type
        ? singleOrgData?.agency_import?.import_type
        : "",
      import_id: singleOrgData?.agency_import?.import_id
        ? singleOrgData?.agency_import?.import_id
        : "",
      other: singleOrgData?.agency_import?.other_type
        ? singleOrgData?.agency_import?.other_type
        : "",
      banner_budget: singleOrgData?.banner_budget
        ? singleOrgData?.banner_budget
        : 0,
    });
    if (singleOrgData?.id) {
      const data = singleOrgData.id;
      const body = { data, navigate };
      dispatch(actionGetAllSubscriptionByOrgApiCall(body));
    }
  }, [singleOrgData]);

  useEffect(() => {
    dispatch(actionGetEmployeeJobTitleApiCall({ navigate }));
  }, []);

  const onClickremoveUser = () => {
    if (user.model.user_id) {
      const data = {
        userid: user.model.user_id,
        organizationid: singleOrgData.id,
      };
      const body = { data, navigate };
      dispatch(actionRemoveUserOrgApiCall(body));
    } else {
      if (singleOrgData?.agency_members?.length > 0)
        toast.error(t("please_select_user"));
      else toast.error(t("no_user_data"));
    }
  };
  const onChangeOfInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onClickEditButton = () => {
    setShowError(true);
    let check = true;
    for (let i in data) {
      if (
        i !== "short_description" &&
        i !== "description" &&
        i != "import_type" &&
        i != "import_id" &&
        i != "other" &&
        i != "logo_image" &&
        i != "banner_image"
      ) {
        if (!data[i] || data[i] == "null" || data[i] == "undefined") {
          check = false;
        }
      }
    }
    if (check == true) {
      const formdata = new FormData();
      formdata.append("org_name", data.name);
      formdata.append("address", data.address);
      formdata.append("email", data.email);
      formdata.append("city", data.city);
      formdata.append("postcode", data.postcode);
      formdata.append("file", data.logo_image);
      formdata.append("website", data.website);
      formdata.append("phone", `${data.phone}`);
      formdata.append("banner", data.banner_image);
      formdata.append("shortDescription", data.short_description);
      formdata.append("description", data.description);
      formdata.append("import_type", data.import_type || "");
      formdata.append("import_id", data.import_id || "");
      if (data.import_type == "OTHER") {
        formdata.append("other_type", data.other);
      }
      formdata.append("banner_budget", data.banner_budget);
      const id = singleOrgData.id;
      const close = props.handleClose;
      const body = { formdata, navigate, id, close, edit: props.edit };
      dispatch(actionUpdateOrganizationApiCall(body));
    }
  };
  const onClickOfChangeRole = () => {
    if (user?.model?.user_id && jobTitle) {
      let data;
      let body;
      data = {
        agency_member_id: user?.model?.id,
        job_title: jobTitle,
      };
      body = { data, navigate, orgid: singleOrgData.id };
      dispatch(actionUpdateJobTitleApiCall(body));
    } else {
      if (singleOrgData?.agency_members?.length > 0) {
        if (!user.model.id) {
          toast.error(t("please_select_user"));
        } else if (!jobTitle) {
          toast.error(t("please_select_job_title"));
        }
      } else toast.error(t("no_user_data"));
    }
  };
  const onClickOfOrgAdmin = () => {
    if (user?.model?.agency_id && user?.model?.user_id) {
      let data;
      let body;
      data = {
        userid: user?.model?.user_id,
        organizationid: user?.model?.agency_id,
      };
      body = { data, navigate };
      dispatch(actionChangeAdminOrgApiCall(body));
    } else {
      if (singleOrgData?.agency_members?.length > 0) {
        if (user.model.id == "") {
          toast.error(t("please_select_user"));
        }
      } else toast.error(t("no_user_data"));
    }
  };
  const onClickOfChangeContact = () => {
    if (list.id && changeContactUser) {
      let data;
      let body;
      data = {
        property_id: list.id,
        updated_user_id: changeContactUser.model.user_id,
      };
      body = { data, navigate, orgid: singleOrgData.id };
      dispatch(actionChangeUserFromPropertyApiCall(body));
    } else {
      if (SavePropertyListdata?.length > 0) {
        if (list.id == "") {
          toast.error(t("please_select_list"));
        } else if (changeContactUser.length <= 0) {
          toast.error(t("please_select_user"));
        }
      } else toast.error(t("no_Listing_data"));
    }
  };
  const updatePropertyVisible = (hide, item) => {
    const data = {
      property_id: item.id,
      hide_property: hide,
    };
    const body = { data, navigate };
    dispatch(actionUpdatePropertyVisibleApiCall(body));
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setData({ ...data, ["logo_image"]: droppedFile });
    setPreviewImg(URL.createObjectURL(droppedFile));
  };
  const handleBannerDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setData({ ...data, ["banner_image"]: droppedFile });
    setPreviewBannerImg(URL.createObjectURL(droppedFile));
  };
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  function handleChange(event) {
    setChangeContactUser(JSON.parse(event.target.value));
  }
  const ViewSubscriptionList = () => {
    setCreateUser(true);
  };
  return (
    <div className={classes.EditOrganisationMain}>
      <h3>{t("edit_organisation")}</h3>
      <div className={classes.editOrgWrap}>
        <div className={classes.EditInfoSection}>
          <div className={classes.CarteOrgWarp}>
            <h6>{t("change_logo")}</h6>
            <div
              className={classes.uploadMain}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <input
                type="file"
                id="upload"
                name="logo_image"
                accept=".jpg, .jpeg, .png,.webp,.svg"
                hidden
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.files[0] });
                  setPreviewImg(URL.createObjectURL(e.target.files[0]));
                }}
              />
              <label htmlFor="upload">
                <div className={classes.PhotoUpload}>
                  {previewImg || data?.logo_image ? (
                    <img
                      className={classes.img1}
                      src={previewImg ? previewImg : data?.logo_image}
                    />
                  ) : (
                    <img src={FileUploadIcon} />
                  )}
                </div>
              </label>
              {showError && (
                <>
                  {data?.logo_image && data?.logo_image?.size > 2e6 && (
                    <p className={classes.errorText}>
                      {t("please_upload_a_file_smaller_than_two_mb")}
                    </p>
                  )}
                </>
              )}
            </div>
            <br />
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("organisation_name")}</p>
                <input
                  type="text"
                  name="name"
                  value={data?.name}
                  onChange={onChangeOfInput}
                />
                {showError && (data?.name == null || data?.name == "") && (
                  <p className={classes.errorText}>
                    {t("organisation_required")}
                  </p>
                )}
              </div>
              <div className={classes.FildBox}>
                <p>{t("city_p")}</p>
                <input
                  type="text"
                  name="city"
                  value={data?.city}
                  onChange={onChangeOfInput}
                />
                {showError && (data?.city == null || data?.city == "") && (
                  <p className={classes.errorText}>{t("city_required")}</p>
                )}
              </div>
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("address")}</p>
                <input
                  type="text"
                  name="address"
                  value={data?.address}
                  onChange={onChangeOfInput}
                />
                {showError &&
                  (data?.address == null || data?.address == "") && (
                    <p className={classes.errorText}>{t("address_required")}</p>
                  )}
              </div>
              <div className={classes.FildBox}>
                <p>{t("postal_code")}</p>
                <input
                  type="text"
                  name="postcode"
                  value={data?.postcode}
                  onChange={onChangeOfInput}
                  maxLength={6}
                />
                {showError &&
                (data?.postcode == null || data?.postcode == "") ? (
                  <p className={classes.errorText}>
                    {t("postalcode_required")}
                  </p>
                ) : (
                  data?.postcode?.length < 6 ||
                  (!/^\d{4}[a-zA-Z]{2}$/.test(data?.postcode) && (
                    <p className={classes.errorText}>
                      {t("invalid_postal_code")}
                    </p>
                  ))
                )}
              </div>
            </div>
            <div className={classes.linkInputWrap}>
              <p>{t("organisation_e_mail")}</p>
              <input
                type="email"
                value={data?.email}
                name="email"
                onChange={onChangeOfInput}
              />
              {showError && (data?.email == null || data?.email == "") && (
                <p className={classes.errorText}>{t("email_required")}</p>
              )}
            </div>
            <div className={classes.linkInputWrap}>
              <p>{t("website")}</p>
              <input
                type="text"
                value={data?.website}
                name="website"
                onChange={onChangeOfInput}
              />
              {showError && (data?.website == null || data?.website == "") && (
                <p className={classes.errorText}>{t("website_required")}</p>
              )}
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("classification")}</p>
                <input type="text" disabled />
              </div>
              <div className={classes.FildBox}>
                <p>{t("phonenumber")}</p>
                <input
                  type="text"
                  name="phone"
                  value={data?.phone}
                  onChange={onChangeOfInput}
                  maxLength="10"
                  minLength="8"
                  onKeyDown={handleKeyPressForNumber}
                />
                {showError && (data?.phone == null || data?.phone == "") && (
                  <p className={classes.errorText}>{t("phone_required")}</p>
                )}
              </div>
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("data_provider")}</p>
                <select
                  name="import_type"
                  onChange={onChangeOfInput}
                  value={data?.import_type}
                >
                  <option value="" disabled selected>
                    {`${t("data_provider")}..`}
                  </option>
                  {dataProviderArray.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
              <div className={classes.FildBox}>
                <p>{t("data_provider_id")}</p>
                <input
                  type="text"
                  name="import_id"
                  value={data?.import_id}
                  onChange={onChangeOfInput}
                  maxLength="10"
                  minLength="8"
                  onKeyDown={handleKeyPressForNumber}
                />
              </div>
            </div>
            {data?.import_type == "OTHER" && (
              <div className={classes.linkInputWrap}>
                <p>{t("other")}</p>
                <input
                  type="text"
                  name="other"
                  value={data?.other}
                  onChange={onChangeOfInput}
                />
              </div>
            )}
            <div className={classes.linkInputWrap}>
              <p>{t("subscriptions")}</p>
              <div className={classes.subscriptionDetails}>
                <span>
                  {activeSubscription?.subscription_detail?.product_detail?.name
                    ? activeSubscription?.subscription_detail?.product_detail
                        ?.name
                    : t("no_plan")}
                </span>
                <span className={classes.activeText}>
                  {activeSubscription?.subscription_detail?.product_detail
                    ?.name && "ACTIVE €"}
                </span>
                <button onClick={() => ViewSubscriptionList()}>
                  {t("view")}
                </button>
              </div>
            </div>
            <div className={classes.linkInputWrap}>
              <div className={classes.FildBox}>
                <p>{t("banner_budget")}</p>
                <input
                  type="text"
                  name="banner_budget"
                  value={data?.banner_budget}
                  onChange={onChangeOfInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.LeftSectionWrap}>
          <div className={classes.UserOrgSection}>
            <h6>{t("user")}</h6>
            <div className={classes.OrgContentWrap}>
              <table>
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("job_title")}</th>
                  <th></th>
                </tr>
                {singleOrgData?.agency_members?.length > 0 ? (
                  singleOrgData?.agency_members?.map((item) => (
                    <tr onClick={() => setUser(item)}>
                      <td
                        className={
                          user.model.id == item.model.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item.full_name ? item.full_name : "NA"}
                      </td>
                      <td
                        className={
                          user.model.id == item.model.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item?.model?.job_title ? item?.model?.job_title : "NA"}
                      </td>
                      <td
                        className={
                          user.model.id == item.model.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      ></td>
                      <td
                        className={
                          user.model.id == item.model.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item.model.user_id == singleOrgData.created_by && (
                          <BsFillStarFill />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={classes.NoData}>
                    <td className={classes.LoaderWrap} colSpan={6}>
                      {t("no_user_data")}
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className={classes.EditOrgBtnWrap}>
              <button onClick={onClickOfOrgAdmin}>
                {t("org_admin")}
                <img src={StarIcon} />
              </button>
              <button onClick={onClickOfChangeRole}>
                {t("change_role")}
                <img src={RoleChangeIcon} />
              </button>
              <button onClick={onClickremoveUser}>
                {t("remove_from_org")}
                <img src={DeleteIcon} />
              </button>
            </div>
            <div className={classes.linkInputWrap}>
              <p>{t("change_role_to")}</p>
              <select
                name="jobTitle"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              >
                <option value="" disabled selected>
                  {`${t("role")}..`}
                </option>
                {saveEmployeeJobTitle?.length > 0 &&
                  saveEmployeeJobTitle.map((option) => (
                    <option key={option.id} value={option?.value}>
                      {option?.translation}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className={classes.ListingsOrgSection}>
            <h6>{t("listings")}</h6>
            <div className={classes.OrgContentWrap}>
              <table>
                <tr>
                  <th>{t("address_capital")}</th>
                  <th>{t("contact")}</th>
                  <th></th>
                </tr>
                {SavePropertyListdata?.length > 0 ? (
                  SavePropertyListdata?.map((item) => (
                    <tr onClick={() => setList(item)}>
                      <td
                        className={
                          list.id == item.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item.address}
                      </td>
                      <td
                        className={
                          list.id == item.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item?.user_detail?.full_name
                          ? item.user_detail.full_name
                          : item.user_detail?.email}
                      </td>
                      <td
                        className={
                          list.id == item.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item.hide_property == 0 ? (
                          <FaEye
                            onClick={() => updatePropertyVisible(1, item)}
                          />
                        ) : (
                          <FaEyeSlash
                            onClick={() => updatePropertyVisible(0, item)}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={classes.NoData}>
                    <td className={classes.LoaderWrap} colSpan={6}>
                      {t("no_Listing_data")}
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className={classes.ChangeContactWrap}>
              <div className={classes.linkInputWrap}>
                <p>{t("change_contact_to")}</p>
                <select name="contact" onChange={handleChange}>
                  <option value="" disabled selected>
                    {`${t("user")}..`}
                  </option>
                  {singleOrgData?.agency_members?.length > 0 &&
                    singleOrgData?.agency_members.map((item) => (
                      <option value={JSON.stringify(item)}>
                        {item.full_name ? item.full_name : "NA"}
                      </option>
                    ))}
                </select>
              </div>
              <button onClick={onClickOfChangeContact}>
                {t("change_contact")}
                <img src={RoleChangeIcon} />
              </button>
            </div>
          </div>
        </div>
        <div className={classes.EditBanner}>
          <h6>{t("change_banner")}</h6>
          <div
            className={classes.uploadMain}
            onDrop={handleBannerDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              type="file"
              id="upload1"
              name="banner_image"
              accept=".jpg, .jpeg, .png,.webp,.svg"
              hidden
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.files[0] });
                setPreviewBannerImg(URL.createObjectURL(e.target.files[0]));
              }}
            />
            <label htmlFor="upload1">
              <div className={classes.PhotoUpload}>
                {previewBannerImg || data?.banner_image ? (
                  <img
                    className={classes.img1}
                    src={
                      previewBannerImg ? previewBannerImg : data?.banner_image
                    }
                  />
                ) : (
                  <img src={FileUploadIcon} />
                )}
              </div>
            </label>
            {showError && (
              <>
                {data?.banner_image && data?.banner_image?.size > 2e6 && (
                  <p className={classes.errorText}>
                    {t("please_upload_a_file_smaller_than_two_mb")}
                  </p>
                )}
              </>
            )}
          </div>
          <div className={classes.DescriptionWrap}>
            <p>{t("short_description")}</p>
            <textarea
              name="short_description"
              value={data.short_description}
              onChange={onChangeOfInput}
            />
          </div>
          <div className={classes.DescriptionWrap}>
            <p>{t("full_description")}</p>
            <CKEditor
              editor={ClassicEditor}
              data={data?.description ? data?.description : ""}
              onChange={(event, editor) => {
                if (focus) setData({ ...data, description: editor.getData() });
              }}
              onFocus={() => setFocus(true)}
            />
          </div>
        </div>
      </div>
      <div className={classes.EditbtnWrap}>
        <button onClick={onClickEditButton}>
          {" "}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {t("edit")}
        </button>
      </div>
      <ModalMain
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        size={"xl"}
      >
        <ViewOrgSubscriptionPlan
          handleClose={() => setCreateUser(false)}
          editPopUpClose={props.handleClose}
          orgName={singleOrgData.name}
          orgId={singleOrgData.id}
        />
      </ModalMain>
    </div>
  );
};

export default EditOrganisation;
