import React, { useEffect, useState } from "react";
import classes from "./addusertoorg.module.scss";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../../assets/icons/SearchIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddUserOrgApiCall,
  actionGetEmployeeJobTitleApiCall,
  actionUserListApiCall,
} from "../../../../Redux/Actions";
import ConfirmationModal from "../../../common/ConfirmationModal";
import { toast } from "react-toastify";

const AddUserToOrg = (props) => {
  const { t } = useTranslation();
  const userListData = useSelector((state) => state.CommonReducer.userListData);
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  const state = useSelector((state) => state.CommonReducer);
  const saveEmployeeJobTitle = useSelector(
    (state) => state.CommonReducer.saveEmployeeJobTitle
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({ id: "" });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState(1);
  const [createuser, setCreateUser] = useState();
  const [jobTitle, setJobTitle] = useState();
  useEffect(() => {
    let ans = Math.ceil(state?.totalUserCount / 10);
    setTotalPage(ans != NaN ? ans : 1);
  }, [state]);
  useEffect(() => {
    if (search == "") {
      apiCall();
    }
  }, [search]);
  useEffect(() => {
    dispatch(actionGetEmployeeJobTitleApiCall({ navigate }));
  }, []);
  const onSearchClick = (e) => {
    e.preventDefault();
    apiCall(1);
  };
  const apiCall = (temp) => {
    const data = {
      page: temp ? temp : page,
      name: search,
      per_page: 10,
    };
    const body = { data, navigate };
    dispatch(actionUserListApiCall(body));
  };

  useEffect(() => {
    apiCall();
  }, [page]);

  const AddUserOrgApi = () => {
    const data = {
      userid: user.id,
      organizationid: singleOrgData.id,
      ...(jobTitle && { job_title: jobTitle }),
    };
    const body = { data, navigate, close: props.handleClose };
    dispatch(actionAddUserOrgApiCall(body));
  };
  const onClickAddButton = () => {
    if (user.id != "") {
      setCreateUser(true);
    } else {
      toast.error(`${t("please_select_a_user")}`);
    }
  };
  return (
    <React.Fragment>
      <div className={classes.AddUserToOrgMain}>
        <h3>{t("add_user_to_org")}</h3>
        <form onSubmit={onSearchClick}>
          <div className={classes.SearchBranch}>
            <input
              type="search"
              placeholder="Name.."
              onChange={(e) => setSearch(e.target.value)}
            />
            <button onClick={onSearchClick} type="submit">
              {t("search")}
              <img src={SearchIcon} />
            </button>
          </div>
        </form>

        <div className={classes.AddUserToOrgWrap}>
          <table>
            <tr>
              <th>{t("name")}</th>
              <th>{t("e_mail")}</th>
              <th>{t("listings")}</th>
            </tr>
            {userListData.map((item) => (
              <tr onClick={() => setUser(item)}>
                <td
                  className={
                    user.id == item.id
                      ? classes.selectStyle
                      : classes.defaultStyle
                  }
                >
                  {item.full_name ? item.full_name : item.email}
                </td>
                <td
                  className={
                    user.id == item.id
                      ? classes.selectStyle
                      : classes.defaultStyle
                  }
                >
                  {item.email}
                </td>
                <td
                  className={
                    user.id == item.id
                      ? classes.selectStyle
                      : classes.defaultStyle
                  }
                >
                  {item.listing_count}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className={classes.PaginationWrapper}>
          <p onClick={() => setPage(page - 1)}>{page > 1 && "< Prev"}</p>
          <p onClick={() => setPage(page + 1)}>
            {page >= 1 && page < totalpage && "Next >"}
          </p>
        </div>
        <div className={classes.AddUseAsSection}>
          <div className={classes.AddUseAsWrap}>
            <p>{t("add_user_as")}</p>
            <select
              onChange={(e) => {
                setJobTitle(e.target.value);
              }}
            >
              <option value="">Select an option</option>
              {saveEmployeeJobTitle?.map((option) => (
                <option key={option.id} value={option.translation}>
                  {option.translation}
                </option>
              ))}
            </select>
          </div>
          <button onClick={onClickAddButton}>{t("add")}</button>
        </div>
      </div>
      <ConfirmationModal
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        size={"sm"}
        onClickYesButton={AddUserOrgApi}
        title={`${t("You_are_about_to_add")} ${user.full_name} ${t("to")} ${
          singleOrgData.name
        } `}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default AddUserToOrg;
