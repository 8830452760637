import React, { useEffect, useState } from 'react'
import MainLayount from '../common/MainLayout';
import classes from "./emailmarketingmain.module.scss";
import EmailMarketingHeader from '../common/EmailMarketingHeader';
import SingleOrgHeader from '../common/EmailMarketingHeader';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import DetailOrgPage from '../SingleOrgMain/DetailOrgPage';
import SubscriptionOrgPage from '../SingleOrgMain/SubscriptionOrgPage';
import ListingOrgPage from '../SingleOrgMain/ListingOrgPage';
import EmailCampaign from './EmailCampaign';
import EmailTags from './EmailTags/Index';
import EmailTemplate from './EmailTemplate/Index';

const EmailMarketingMain = () => {
  const [selectedTab, setSelecetedTab] = useState("Campaigns");
  const { t } = useTranslation();
  const menuItem = [
    t("Campaigns"),
    t("Tags"),
    t("Template")
  ];

  const navigate = useNavigate();
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  console.log(pathname,"pathnameeeee")
  useEffect(() => {
    const data = pathname;
  }, [selectedTab, pathname]);
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.main}>
       <EmailMarketingHeader/>
       <div className={classes.menuItemWrapper}>
            <div className={classes.menuItemContainer}>
              <div className={classes.menuItems}>
                {menuItem?.map((item) => {
                  return (
                    <div
                      className={
                        item == selectedTab
                          ? classes.selectedMenuItem
                          : classes.menuItem
                      }
                      onClick={() => setSelecetedTab(item)}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {selectedTab === "Campaigns" && <EmailCampaign/>}
          {selectedTab === "Tags" && <EmailTags />}
          {selectedTab === "Template" && <EmailTemplate />}
        </div>
      </MainLayount>
    </React.Fragment>
  )
}

export default EmailMarketingMain