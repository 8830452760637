import React, { useEffect, useState } from "react";
import deleteIcon from "../../../assets/icons/DeleteIcon.svg";
import classes from "./branchesform.module.scss";
import dropDownIcon from "../../../assets/icons/DropdownIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddBranchData,
  actionBanchNestedGetApi,
  actionEditBranch,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const BranchesForm = ({ editData, handleClose }) => {
  const nestedBranchData = useSelector(
    (state) => state?.CommonReducer?.getNestedBranchData
  );
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({
    name: "",
  });
  const { name } = formData;
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(actionBanchNestedGetApi(navigate));
  }, []);
  useEffect(() => {
    setShowOptions(false);
  }, [selectedBranch]);

  useEffect(() => {
    setFormData({
      name: editData?.name || "",
    });
    setSelectedBranch(editData?.parentBranch);
  }, [editData]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: "" };

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitEdit = () => {
    if (validateForm()) {
      const data = {
        navigate,
        params_id: editData?.id,
        name: name,
        parent_id: selectedBranch?.id,
        active: selectedBranch?.active,
      };
      const closePopup = handleClose;
      dispatch(actionEditBranch({ data, closePopup }));
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const data = {
        name: name,
        parent_id: selectedBranch?.id,
        active: selectedBranch?.active,
      };
      const closePopup = handleClose;
      dispatch(actionAddBranchData({ data, closePopup }));
    }
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  function handleLabelClick(branch) {
    setSelectedBranch(branch);
  }

  function renderBranch(branch) {
    const isSelected = selectedBranch && selectedBranch?.id === branch.id;

    return (
      <li key={branch.id}>
        <label
          className={
            isSelected
              ? classes.selectedBorder
              : branch.name == editData?.name
              ? classes.disabledBorder
              : ""
          }
          onClick={() => {
            if (branch.name !== editData?.name) {
              handleLabelClick(branch);
            }
          }}
        >
          - {branch.name}
        </label>

        {branch.subBranches.length > 0 && (
          <ul className="nested-checkbox">
            {branch.subBranches.map((subBranch) => renderBranch(subBranch))}
          </ul>
        )}
      </li>
    );
  }
  return (
    <React.Fragment>
      <div className={classes.branchesFormSection}>
        {editData ? <h3>Edit Branche</h3> : <h3>Add Branche</h3>}
        <div className={classes.inputsWrapper}>
          <div className={classes.dropdownBar}>
            <label>Type of Branches</label>
          </div>
          <div className={classes.customDropDown}>
            <div onClick={toggleOptions} className={classes.dropdownBar}>
              {selectedBranch ? (
                <label>{selectedBranch?.name}</label>
              ) : (
                <label>+ New Main Branche</label>
              )}
              <img src={dropDownIcon} alt="" />
            </div>
            {showOptions && (
              <ul>
                {nestedBranchData.map((mainBranch) => renderBranch(mainBranch))}
              </ul>
            )}
          </div>
          <div className={classes.Text}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Woninginrichting"
              onChange={handleInputChange}
              value={formData.name}
            />
            {errors.name && <p className={classes.error}>{errors.name}</p>}
          </div>
        </div>

        <div className={classes.btnwrapper}>
          <div className={classes.btnWrapperShift}>
            {editData ? (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  Cancel
                </button>
                <button className={classes.btnadd} onClick={handleSubmitEdit}>
                  Edit
                </button>
              </>
            ) : (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  Cancel
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  Add Branche
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BranchesForm;
