import React from "react";
import SupplierManagementMain from "../../components/SupplierManagementMain";

const SupplierManagementPage = () => {
  return (
    <React.Fragment>
      <SupplierManagementMain />
    </React.Fragment>
  );
};

export default SupplierManagementPage;
