import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classes from "./zipcodeform.module.scss";
import Loader from "../Loader";
import CustomSelect from "../CustomSelect";
import { actionAddZipcodeApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const ZipcodeForm = ({ editData, handleClose }) => {
  const { t } = useTranslation();
  const state = useSelector((state) => state.CommonReducer);
  const SelectItems = [
    {
      placeholder: t("municipality"),
      name: "municipality_id",
      options:
        state?.getMunicipalityTable &&
        state?.getMunicipalityTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "municipality",
    },
    {
      placeholder: t("country"),
      name: "country_id",
      options:
        state?.getCountryTable &&
        state?.getCountryTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "country",
    },
    {
      name: "province_id",
      placeholder: t("province"),
      options:
        state?.getProvinceData &&
        state?.getProvinceData?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "province",
    },
    {
      name: "city_id",
      apiName: "city",
      placeholder: t("city_p"),
      options:
        state?.getCityData &&
        state?.getCityData?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
  ];
  const SelectItemsPart1 = [
    {
      name: "neighbourhood_id",
      apiName: "neighbourhood",
      placeholder: t("neighbourhood"),
      options:
        state?.getNeighbourhoodTable &&
        state?.getNeighbourhoodTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
    {
      name: "district_id",
      apiName: "district",
      placeholder: t("district"),
      options:
        state?.getDistrictTable &&
        state?.getDistrictTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
    {
      name: "country_part_id",
      apiName: "country_part",
      placeholder: t("country_part"),
      options:
        state?.getCountryPartTable &&
        state?.getCountryPartTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    number: "",
    country_id: "",
    municipality_id: "",
    province_id: "",
    city_id: "",
    neighbourhood_id: "",
    district_id: "",
    country_part_id: "",
    code: "",
  });
  const [errors, setErrors] = useState({
    number: "",
    country_id: "",
    municipality_id: "",
    province_id: "",
    city_id: "",
    neighbourhood_id: "",
    district_id: "",
    country_part_id: "",
    code: "",
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        number: editData?.number || "",
        code: editData?.code || "",
        country_id: editData?.country_id || "",
        municipality_id: editData?.municipality_id || "",
        province_id: editData?.province_id || "",
        city_id: editData?.city_id || "",
        neighbourhood_id: editData?.neighbourhood_id || "",
        district_id: editData?.district_id || "",
        country_part_id: editData?.country_part_id || "",
      });
    }
  }, [editData]);

  // useMemo(() => {
  //   formData;
  // }, [formData]);

  const validateForm = () => {
    const requiredFields = {
      number: "number",
      code: "code",
      municipality_id: "municipality",
      country_id: "country",
      province_id: "province",
      city_id: "city_p",
      neighbourhood_id: "neighbourhood",
      district_id: "district",
      country_part_id: "country_part",
    };

    const newErrors = {};
    let valid = true;

    for (const field in requiredFields) {
      let value = formData[field];
      if (value === "") {
        newErrors[field] = t(requiredFields[field]) + " " + t("required");
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = () => {
    if (validateForm()) {
      const data = new FormData();
      for (const field in formData) {
        if (field === "number" || field === "code") {
          data.append(`${field}`, formData[field]);
        } else {
          data.append(
            `${field}`,
            formData[field] && formData[field].value
              ? formData[field].value
              : formData[field]
          );
        }
      }
      data.append(`id`, editData ? editData.id : "");
      const closePopup = handleClose;
      dispatch(
        actionAddZipcodeApiCall({
          data,
          closePopup,
          edit: editData ? editData.id : false,
          navigate,
        })
      );
    }
  };
  return (
    <React.Fragment>
      <div className={classes.branchesFormSection}>
        <h3>
          {editData ? t("edit") : t("add")} {t("zipcode")}
        </h3>
        <div className={classes.inputsWrapperContainer}>
          <div className={classes.inputsWrapper}>
            <div className={classes.Text}>
              <label htmlFor="number">{t("number")}</label>
              <input
                type="number"
                name="number"
                onChange={handleInputChange}
                onBlur={validateForm}
                value={formData.number}
              />
              {errors.number && (
                <p className={classes.error}>{errors.number}</p>
              )}
            </div>
            <div className={classes.Text}>
              <label htmlFor="code">{t("code")}</label>
              <input
                type="text"
                name="code"
                onBlur={validateForm}
                onChange={handleInputChange}
                value={formData.code}
              />
              {errors.code && <p className={classes.error}>{errors.code}</p>}
            </div>
            <div className={classes.Text}>
              {SelectItemsPart1.map((item) => {
                return (
                  <div className={`${classes.Text} ${classes.SelectText}`}>
                    <label>{item.placeholder}</label>
                    <CustomSelect
                      defaultInputValue={editData ? true : false}
                      value={
                        editData && {
                          label: editData[item.apiName]?.name,
                          value: editData[item.apiName]?.id,
                        }
                      }
                      setErrors={setErrors}
                      errors={errors}
                      item={item}
                      apiName={item.apiName}
                      isInputChange={true}
                      headerSearch={formData}
                      setHeaderSearch={setFormData}
                    />
                    {errors[item.name] && (
                      <p className={classes.error}>{errors[item.name]}</p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.inputsWrapper}>
            {SelectItems.map((item) => {
              return (
                <div className={`${classes.Text} ${classes.SelectText}`}>
                  <label>{item.placeholder}</label>
                  <CustomSelect
                    defaultInputValue={editData ? true : false}
                    item={item}
                    value={
                      editData && {
                        label: editData[item.apiName]?.name,
                        value: editData[item.apiName]?.id,
                      }
                    }
                    apiName={item.apiName}
                    isInputChange={true}
                    headerSearch={formData}
                    setHeaderSearch={setFormData}
                  />
                  {errors[item.name] && (
                    <p className={classes.error}>{errors[item.name]}</p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.btnwrapper}>
          <div className={classes.btnWrapperShift}>
            {editData ? (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? <Loader /> : `${t("edit")}`}
                </button>
              </>
            ) : (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {state?.buttonloader ? (
                    <Loader />
                  ) : (
                    `${t("add")} ${t("zipcode")}`
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ZipcodeForm;
