import React, { useState, useEffect, useRef } from "react";
import classes from "./addsubscriptionmodal.module.scss";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  actionCreateSubscriptionApiCall,
  actionGetDiscountAndExtraApiCall,
  actionEditSingleSubscriptionApiCall,
  actionCreateManualPaymentApiCall,
  actionEditSubscriptionInOrgApiCall,
} from "../../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Down from "../../../../assets/icons/downdiscountIcon.svg";
import Up from "../../../../assets/icons/leftdiscountIcon.svg";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";

const AddSubscriptionModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const discountData = useSelector(
    (state) => state.CommonReducer.saveDiscountAndExtrasData
  );
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  const loader = useSelector((state) => state.CommonReducer.loader);
  const singleSubscriptionData = useSelector(
    (state) => state.CommonReducer.saveSingleSubscriptionData
  );
  const AddSubscriptionModalClose = () => {
    props.EditOrganisationAddSubscriptionClose();
    props.handleClose();
  };
  const [description, setDescription] = useState("");
  const [removePaymentOptionsIdsData, setRemovePaymentOptionsIdsData] =
    useState([]);
  const [removeProductDiscountsIdsData, setRemoveProductDiscountsIdsData] =
    useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    subscription_name: "",
    active: false,
    auto_renew: false,
    most_popular: 0,
    subscription_start_date: "",
    subscription_end_date: "",
    description: "",
    file: "",
    order: "",
  });

  const [previewImg, setPreviewImg] = useState();

  const [paymentOptions, setPaymentOptions] = useState([
    {
      name: "",
      price: "",
      periodicity: t("monthly"),
      per: 1,
      valid_from: null,
      valid_until: null,
      most_popular: 0,
      discount_detail: [],
      filteredOptions: discountData ? discountData : [],
      order: "",
      id: 1,
    },
  ]);
  const [subscriptionErrors, setSubscriptionErrors] = useState({
    subscription_name: "",
    subscription_start_date: "",
    subscription_end_date: "",
    file: "",
    order: "",
  });
  const [errors, setErrors] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const body = { navigate, valid: false };
    dispatch(actionGetDiscountAndExtraApiCall(body));
  }, []);

  useEffect(() => {
    if (!props.edit && paymentOptions.length === 1 && discountData.length > 0) {
      setPaymentOptions([
        { ...paymentOptions[0], filteredOptions: discountData },
      ]);
    }
  }, [discountData]);
  useEffect(() => {
    if (
      props.edit &&
      singleSubscriptionData &&
      singleSubscriptionData.length > 0
    ) {
      let subScriptionDetails = {
        subscription_name: singleSubscriptionData[0].name,
        active: singleSubscriptionData[0].active,
        auto_renew: singleSubscriptionData[0].auto_renew,
        most_popular: singleSubscriptionData[0].most_popular,
        subscription_start_date: singleSubscriptionData[0].valid_from,
        subscription_end_date: singleSubscriptionData[0].valid_until,
        file: singleSubscriptionData[0].logo_image,
        id: singleSubscriptionData[0].id,
        order: singleSubscriptionData[0].order,
      };
      let paymentOption;
      if (props.orgAndPlanData?.plandata?.id) {
        paymentOption = singleSubscriptionData[0]?.payment_option
          .filter(
            (filterDataByID) =>
              filterDataByID.id == props.orgAndPlanData?.plandata?.id
          )
          .map((option) => ({
            id: option.id,
            product_id: option.product_id,
            name: option?.name,
            price: parseFloat(option.price),
            periodicity: option.periodicity,
            per: option.per,
            valid_from: option.valid_from,
            valid_until: option.valid_until,
            most_popular: option.most_popular,
            discount_detail: option.discount_detail.map((discount, index) => ({
              discounts_and_extras_id: discount.discounts_and_extras_id,
              sort_order: discount.sort_order || index,
              optional: discount.optional || 0,
              name: discount?.discounts?.name,
              id: discount.id,
            })),
            order: option.order,
            filteredOptions:
              option.discount_detail.length > 0 && discountData
                ? discountData.filter(
                    (item2) =>
                      !option.discount_detail.some(
                        (item1) => item1.discounts_and_extras_id === item2.id
                      )
                  )
                : discountData
                ? discountData
                : [],
          }));
      } else if (props?.data) {
        const { data } = props;
        paymentOption = singleSubscriptionData[0]?.payment_option
          .filter(
            (filterDataByID) =>
              filterDataByID.id ===
              props?.data?.subscription_detail?.product_payment_option_id
          )
          .map((option) => ({
            id: props?.data?.subscription_detail?.product_payment_option_id,
            product_id: option.product_id,
            name: option?.name,
            price: parseFloat(data.total_price),
            periodicity: data.periodicity,
            per: data.per,
            valid_from: data.start_date,
            valid_until: data.end_date,
            most_popular: option.most_popular,
            discount_detail: (props?.data?.subscription_discount || [])
              .filter((discount) => discount.discount_data)
              .map((discount, index) => ({
                discounts_and_extras_id: discount.discount_data.id,
                name: discount.discount_data.name,
                id: discount.discount_data.id,
              })),
            order: option.order,
            filteredOptions:
              option.discount_detail.length > 0 && discountData
                ? discountData.filter(
                    (item2) =>
                      !option.discount_detail.some(
                        (item1) => item1.discounts_and_extras_id === item2.id
                      )
                  )
                : discountData
                ? discountData
                : [],
          }));
      } else {
        paymentOption = singleSubscriptionData[0]?.payment_option.map(
          (option) => ({
            id: option.id,
            product_id: option.product_id,
            name: option?.name,
            price: parseFloat(option.price),
            periodicity: option.periodicity,
            per: option.per,
            valid_from: option.valid_from,
            valid_until: option.valid_until,
            most_popular: option.most_popular,
            discount_detail: option.discount_detail.map((discount, index) => ({
              discounts_and_extras_id: discount.discounts_and_extras_id,
              sort_order: discount.sort_order || index,
              optional: discount.optional || 0,
              name: discount?.discounts?.name,
              id: discount.id,
            })),
            order: option.order,
            filteredOptions:
              option.discount_detail.length > 0 && discountData
                ? discountData.filter(
                    (item2) =>
                      !option.discount_detail.some(
                        (item1) => item1.discounts_and_extras_id === item2.id
                      )
                  )
                : discountData
                ? discountData
                : [],
          })
        );
      }
      setPaymentOptions(paymentOption);
      setSubscriptionDetails(subScriptionDetails);
      setDescription(singleSubscriptionData[0].description);
    }
  }, [singleSubscriptionData, discountData]);

  const dateValidaterFunction = (e, func, index, subsciption) => {
    const enteredValue = e.target.value;
    if (enteredValue) {
      const dateParts = e.target.value?.split("-");
      const year = dateParts[0];
      if (year?.length > 4) {
        e.preventDefault();
      } else {
        if (subsciption) {
          func(e);
        } else {
          func(index, e);
        }
      }
    }
  };
  const close = props.close;
  const CustomSelect = ({ options, selectedOption, setSelectedOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
    const handleOptionClick = (option) => {
      setSelectedOption(option.name);
      setSearchTerm("");
      setIsOpen(false);
    };
    const handleSearchInputChange = (event) => {
      setSearchTerm(event.target.value);
    };
    const filteredOptions =
      options.filteredOptions &&
      options.filteredOptions?.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return (
      <div className={classes.customSelect}>
        <div className={classes.selectedOption} onClick={toggleDropdown}>
          {selectedOption ? (
            selectedOption
          ) : (
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Select Option..."
            />
          )}
        </div>
        {isOpen && (
          <div className={classes.options}>
            <ul>
              {loader == true ? (
                <p>{t("loading")}</p>
              ) : filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <li key={index} onClick={() => handleOptionClick(option)}>
                    {option?.name}
                  </li>
                ))
              ) : (
                <p>{t("no_data_found")}</p>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const [selectedOptionError, setSelectedOptionError] = useState(false);
  const [divIndex, setDivIndex] = useState(0);

  useEffect(() => {
    if (props?.orgAndPlanData) {
      let index = paymentOptions.findIndex(
        (day) => day.id === props.orgAndPlanData.plandata.id
      );
      if (index >= 0) {
        setDivIndex(index);
      }
    }
  }, [props?.orgAndPlanData, paymentOptions]);
  const onChangeOfInput = (e) => {
    if (e.target.name === "order") {
      setSubscriptionDetails({
        ...subscriptionDetails,
        [e.target.name]: e.target.value.split(".")[0].replace(/\D/g, ""),
      });
    }
    if (e.target.files && e.target.files[0]) {
      setSubscriptionDetails((prevCount1) => ({
        ...prevCount1,
        [e.target.name]: e.target.files[0],
      }));
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
    } else {
      setSubscriptionDetails({
        ...subscriptionDetails,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.value) {
      setSubscriptionErrors({ ...subscriptionErrors, [e.target.name]: "" });
    }
  };

  const handleAddFields = () => {
    const values = [...paymentOptions];
    values.push({
      name: "",
      price: "",
      periodicity: t("monthly"),
      per: 1,
      valid_from: null,
      valid_until: null,
      most_popular: 0,
      discount_detail: [],
      filteredOptions: discountData ? discountData : [],
      id: values.length + 1,
    });
    setErrors((prevState) => [...prevState, {}]);
    setPaymentOptions(values);
  };
  const handleremoveFields = (index, id) => {
    if (id) {
      setRemovePaymentOptionsIdsData((prevData) => [...prevData, id]);
    }
    const values = [...paymentOptions];
    values.splice(index, 1);
    setPaymentOptions(values);
    setErrors((prevState) => {
      const updatedErrors = [...prevState];
      updatedErrors.splice(index, 1);
      return updatedErrors;
    });
  };

  const handleInputChange = (index, event) => {
    const values = [...paymentOptions];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "price") {
      values[index].price = event.target.value;
    } else if (event.target.name === "periodicity") {
      values[index].periodicity = event.target.value;
    } else if (event.target.name === "per") {
      let { value } = event.target;
      value = value.replace(/\D/, ""); // Remove non-digit characters
      value = value === "" ? "" : Math.max(1, Math.min(12, parseInt(value)));
      values[index].per = value;
    } else if (event.target.name === "valid_from") {
      values[index].valid_from = event.target.value;
    } else if (event.target.name === "valid_until") {
      values[index].valid_until = event.target.value;
    } else if (event.target.name === "order") {
      values[index].order = event.target.value.split(".")[0].replace(/\D/g, "");
    } else {
      values[index].most_popular = event.target.checked == true ? 1 : 0;
    }

    setPaymentOptions(values);
    if (errors?.length > 0) {
      const newErrors = [...errors];
      delete newErrors[index][event.target.name];
      setErrors(newErrors);
    }
  };
  const validateForm = () => {
    const validationErrors = [];
    const subError = {};
    paymentOptions.forEach((item, index) => {
      const error = {};
      if (!item.name || item.name.trim().length <= 0) {
        error.name = t("name_required");
      }
      if (!item.price) {
        error.price = t("price_required");
      }
      if (!item.periodicity) {
        error.periodicity = t("periodicity_required");
      }
      if (item.order && item.order == 0) {
        error.order = t("order_validation_message");
      }
      validationErrors[index] = error;
    });
    if (
      !subscriptionDetails.subscription_name ||
      subscriptionDetails.subscription_name.trim().length <= 0
    ) {
      subError.subscription_name = t("subscription_name_required");
    }
    if (!subscriptionDetails.subscription_start_date) {
      subError.subscription_start_date = t("subscription_start_date_required");
    }
    if (!subscriptionDetails.subscription_end_date) {
      subError.subscription_end_date = t("subscription_end_date_required");
    }
    if (!subscriptionDetails.file) {
      subError.file = t("file_required");
    }
    if (subscriptionDetails?.file?.size > 2e6) {
      subError.file = t("please_upload_a_file_smaller_than_two_mb");
    }
    if (subscriptionDetails?.order && subscriptionDetails?.order < 1) {
      subError.order = t("order_validation_message");
    }
    setSubscriptionErrors(subError);
    setErrors(validationErrors);
    return { validationErrors, subError };
  };
  const AddSubscriptionHandler = () => {
    const { validationErrors, subError } = validateForm();
    let errorTemp = validationErrors.filter(
      (obj) => Object.keys(obj).length !== 0
    );
    if (errorTemp.length > 0) {
      const index = validationErrors.findIndex(
        (item) => Object.keys(item).length > 0
      );
      setDivIndex(index);
    } else {
      if (Object.values(subError).every((value) => value == "")) {
        if (props?.data) {
          const paymentIdData = paymentOptions.filter(
            (item) =>
              item.id ===
              props?.data?.subscription_detail?.product_payment_option_id
          );
          if (paymentIdData.length > 0) {
            const data = {
              id: props?.data?.id,
              price: paymentIdData[0].price,
              periodicity: paymentIdData[0].periodicity,
              per: paymentIdData[0].per,
              discount_remove_ids: removeProductDiscountsIdsData,

              discount_and_extra_id: paymentIdData[0].discount_detail.map(
                (item) => {
                  return {
                    id: item.id,
                    new: item?.price_type ? true : false,
                  };
                }
              ),
            };
            dispatch(
              actionEditSubscriptionInOrgApiCall({
                data,
                navigate,
                handleClose: AddSubscriptionModalClose,
                agency_id: singleOrgData.id,
              })
            );
          }
        } else if (props?.orgAndPlanData) {
          const paymentIdData = paymentOptions.filter(
            (item) => item.id === props?.orgAndPlanData.plandata.id
          );
          if (paymentIdData.length > 0) {
            const data = {
              product_id: props?.orgAndPlanData.plandata?.product_detail?.id,
              product_payment_option_id: props?.orgAndPlanData.plandata?.id,
              agency_id: singleOrgData.id,
              user: singleOrgData.created_by,
              created_by: localStorage.getItem("user_id"),
              price: paymentIdData[0].price,
              periocity: paymentIdData[0].periodicity,
              per: paymentIdData[0].per,
              discount_and_extra_id: paymentIdData[0].discount_detail.map(
                (item) => {
                  return {
                    id: item?.discounts_and_extras_id
                      ? item?.discounts_and_extras_id
                      : item.id,
                  };
                }
              ),
            };
            dispatch(
              actionCreateManualPaymentApiCall({
                data,
                navigate,
                handleClose: AddSubscriptionModalClose,
              })
            );
          }
        } else if (props.edit) {
          const data = {
            subscription_id: subscriptionDetails.id,
            subscription_name: subscriptionDetails.subscription_name,
            active: subscriptionDetails.active,
            auto_renew: subscriptionDetails.auto_renew,
            most_popular: subscriptionDetails.most_popular,
            subscription_start_date:
              subscriptionDetails.subscription_start_date,
            subscription_end_date: subscriptionDetails.subscription_end_date,
            description: description,
            order: subscriptionDetails.order,
            payment_option: paymentOptions.map((option) => ({
              id: option.id,
              name: option?.name,
              product_id: option.product_id,
              price: parseFloat(option.price),
              periodicity: option.periodicity,
              per: option.per,
              valid_from: option.valid_from,
              valid_until: option.valid_until,
              most_popular: option.most_popular,
              discount_detail: option.discount_detail.map(
                (discount, index) => ({
                  id: discount?.new === true ? null : discount.id,
                  discounts_and_extras_id:
                    discount.discounts_and_extras_id || discount.id,
                  sort_order: discount.sort_order || index,
                  optional: discount.optional || 0,
                })
              ),
            })),
          };
          let formData = new FormData();
          formData.append("file", subscriptionDetails.file);
          formData.append("id", subscriptionDetails.id);
          let body = {
            data,
            navigate,
            close: close,
            removePaymentOptionsIdsData,
            removeProductDiscountsIdsData,
          };
          if (previewImg) {
            body.formData = formData;
          }

          dispatch(actionEditSingleSubscriptionApiCall(body));
        } else {
          const data = {
            subscription_name: subscriptionDetails.subscription_name,
            active: subscriptionDetails.active,
            auto_renew: subscriptionDetails.auto_renew,
            most_popular: subscriptionDetails.most_popular,
            subscription_start_date:
              subscriptionDetails.subscription_start_date,
            subscription_end_date: subscriptionDetails.subscription_end_date,
            description: description,
            payment_option: paymentOptions.map((option) => ({
              name: option.name,
              price: parseFloat(option.price),
              periodicity: option.periodicity,
              per: option.per,
              valid_from: option.valid_from,
              valid_until: option.valid_until,
              most_popular: option.most_popular,
              order: option.order,
              discount_detail: option.discount_detail.map(
                (discount, index) => ({
                  discounts_and_extras_id: discount.id,
                  sort_order: discount.sort_order || index,
                  optional: discount.optional || 0,
                })
              ),
            })),
            order: subscriptionDetails.order,
          };

          let body = {
            data,
            navigate,
            handleClose: close,
            imageData: subscriptionDetails.file,
          };
          dispatch(actionCreateSubscriptionApiCall(body));
        }
      }
    }
  };
  const AddDiscountData = (index) => {
    const oldData = [...paymentOptions];
    let newData = discountData.filter((element) =>
      element.name.includes(selectedOption)
    );
    newData[0]["new"] = true;
    if (oldData[index]?.discount_detail?.length > 0 && newData?.length > 0) {
      let exist = oldData[index].discount_detail.find(
        (item, i) =>
          item.id == newData[0].id ||
          item?.discounts_and_extras_id == newData[0]?.id
      );
      if (!exist) {
        oldData[index].discount_detail.push(newData[0]);
      }
    } else {
      if (newData?.length > 0) {
        oldData[index].discount_detail.push(newData[0]);
      }
    }
    let findData = oldData[index].filteredOptions.filter(
      (item) => item.id !== newData[0].id
    );
    if (findData.length > 0) {
      oldData[index].filteredOptions = findData;
    }
    setPaymentOptions(oldData);
  };

  const RemoveDiscount = (index, discountIndex, id) => {
    let oldData = [...paymentOptions];
    if (id) {
      setRemoveProductDiscountsIdsData((prevData) => [...prevData, id]);
    }
    let remainingData = oldData[index].discount_detail.filter(
      (val, i) => i !== discountIndex
    );
    let deletedData = oldData[index].discount_detail.filter(
      (val, i) => i === discountIndex
    );
    oldData[index].discount_detail = remainingData;
    oldData[index].filteredOptions.push(deletedData[0]);
    setPaymentOptions(oldData);
  };

  const handleCheckboxClick = (event, index, discountIndex) => {
    let oldData = [...paymentOptions];
    if (event.target.checked == true) {
      oldData[index].discount_detail[discountIndex]["optional"] = 1;
    } else {
      oldData[index].discount_detail[discountIndex]["optional"] = 0;
    }
    setPaymentOptions(oldData);
  };

  const handleDragStart = (data) => (event) => {
    let fromBox = JSON.stringify({ id: data.id });
    event.dataTransfer.setData("dragContent", fromBox);
  };

  const handleDragOver = (data) => (event) => {
    event.preventDefault();
    return false;
  };

  const handleDrop = (data) => (event) => {
    event.preventDefault();

    let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
    let toBox = { id: data.id };
    let mainIndex = data.index;
    const swapBoxes = (fromBox, toBox, index) => {
      let newBoxes = [...paymentOptions[mainIndex].discount_detail];
      let fromIndex = -1;
      let toIndex = -1;

      for (let i = 0; i < newBoxes.length; i++) {
        if (newBoxes[i].id === fromBox.id) {
          fromIndex = i;
        }
        if (newBoxes[i].id === toBox.id) {
          toIndex = i;
        }
      }

      if (fromIndex !== -1 && toIndex !== -1) {
        let { fromId, ...fromRest } = newBoxes[fromIndex];
        let { toId, ...toRest } = newBoxes[toIndex];
        newBoxes[fromIndex] = { id: fromBox.id, ...toRest };
        newBoxes[toIndex] = { id: toBox.id, ...fromRest };
        let fulldata = [...paymentOptions];
        fulldata[mainIndex].discount_detail = newBoxes;
        fulldata[mainIndex].discount_detail.map((m, i) => {
          m["sort_order"] = i + 1;
          return m;
        });
        setPaymentOptions(fulldata);
      }
    };

    swapBoxes(fromBox, toBox);
    return false;
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  const minSubscriptionEndDate =
    subscriptionDetails.subscription_start_date || disablePastDate();

  const [isOpenAccordion, setIsOpenAccordion] = useState(true);

  const toggleAccordion = (index) => {
    setIsOpenAccordion((prevState) => {
      if (prevState && index === divIndex) {
        return false; // Close the accordion if it is already open
      }
      return true; // Open the accordion
    });
    setDivIndex(index);
  };
  const handleDropImage = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setSubscriptionDetails({ ...subscriptionDetails, ["file"]: droppedFile });
    setPreviewImg(URL.createObjectURL(droppedFile));
    setSubscriptionErrors({ ...subscriptionErrors, ["file"]: "" });
  };
  return (
    <div className={classes.AddSubscription}>
      <h3>
        {props?.orgAndPlanData
          ? subscriptionDetails.subscription_name
          : props.edit
          ? t("edit_subscription")
          : t("creat_new_subscription")}
      </h3>
      <div className={classes.AddSubscriptionWrap}>
        <div className={classes.LeftSide}>
          <div className={classes.InputBox}>
            <p>{t("name_of_subscription")}</p>
            <input
              type="text"
              name="subscription_name"
              onChange={onChangeOfInput}
              value={subscriptionDetails.subscription_name}
            />
            {subscriptionErrors.subscription_name && (
              <span className={classes.errorText}>
                {subscriptionErrors.subscription_name}
              </span>
            )}
          </div>
          <div className={classes.FlexWrap}>
            <div className={classes.ToggleWrap}>
              <p>{t("is_active")}</p>
              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  name="active"
                  onChange={(event) => {
                    setSubscriptionDetails({
                      ...subscriptionDetails,
                      active: event.target.checked,
                    });
                  }}
                  checked={subscriptionDetails.active ? true : false}
                />
                <span className={classes.Slider} />
              </label>
            </div>
            <div className={classes.ToggleWrap}>
              <p>{t("automatic_renewal")}</p>
              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  name="auto_renew"
                  value={subscriptionDetails.auto_renew}
                  onChange={(event) => {
                    setSubscriptionDetails({
                      ...subscriptionDetails,
                      auto_renew: event.target.checked,
                    });
                  }}
                  checked={subscriptionDetails.auto_renew ? true : false}
                />
                <span className={classes.Slider} />
              </label>
            </div>
          </div>
          <div className={classes.FlexWrap}>
            <div className={classes.InputBox}>
              <p>{t("start_at")}</p>
              <input
                type="date"
                name="subscription_start_date"
                onChange={(e) => {
                  dateValidaterFunction(e, onChangeOfInput, null, true);
                }}
                min={disablePastDate()}
                value={subscriptionDetails.subscription_start_date}
              />
              {subscriptionErrors.subscription_start_date && (
                <span className={classes.errorText}>
                  {subscriptionErrors.subscription_start_date}
                </span>
              )}
            </div>
            <div className={classes.InputBox}>
              <p>{t("end_at")}</p>
              <input
                type="date"
                name="subscription_end_date"
                onChange={(e) => {
                  dateValidaterFunction(e, onChangeOfInput, null, true);
                }}
                min={minSubscriptionEndDate}
                value={subscriptionDetails.subscription_end_date}
              />
              {subscriptionErrors.subscription_end_date && (
                <span className={classes.errorText}>
                  {subscriptionErrors.subscription_end_date}
                </span>
              )}
            </div>
          </div>
          {!props?.data && !props.orgAndPlanData && (
            <div className={classes.FlexWrap}>
              <div className={classes.ToggleWrap}>
                <p>{t("most_popular_choice")}</p>
                <label className={classes.Switch}>
                  <input
                    type="checkbox"
                    name="most_popular"
                    value={subscriptionDetails.most_popular}
                    onChange={(event) => {
                      setSubscriptionDetails({
                        ...subscriptionDetails,
                        most_popular: event.target.checked == true ? 1 : 0,
                      });
                    }}
                    checked={
                      subscriptionDetails.most_popular == 1 ? true : false
                    }
                  />
                  <span className={classes.Slider} />
                </label>
              </div>
              <div className={classes.InputBox}>
                <p>{t("order_by")}</p>
                <input
                  type="text"
                  name="order"
                  onChange={(e) => onChangeOfInput(e)}
                  value={subscriptionDetails.order}
                />
                {subscriptionErrors.order && (
                  <span className={classes.errorText}>
                    {subscriptionErrors.order}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className={classes.UploadWrap}>
            <p>{t("upload_subscription_image")}</p>
            <div
              className={classes.uploadMain}
              onDrop={handleDropImage}
              onDragOver={(e) => e.preventDefault()}
            >
              <label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  multiple
                  accept=".jpg, .jpeg, .png,.webp,.svg"
                  name="file"
                  onChange={(e) => onChangeOfInput(e)}
                />
                <div className={classes.PhotoUpload}>
                  {previewImg || subscriptionDetails?.file ? (
                    <img
                      className={classes.img1}
                      src={previewImg ? previewImg : subscriptionDetails?.file}
                    />
                  ) : (
                    <img src={FileUploadIcon} />
                  )}
                </div>

                {subscriptionErrors?.file && (
                  <span className={classes.errorText}>
                    {subscriptionErrors.file}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className={classes.TextArea}>
            <p>{t("text")}</p>
            <CKEditor
              editor={ClassicEditor}
              onChange={(event, editor) => {
                setDescription(editor.getData());
              }}
              data={description ? description : ""}
            />
          </div>
        </div>
        <div className={classes.RigthSide}>
          {" "}
          {paymentOptions.map((paymentOption, index) => {
            return (
              <div
                className={`${classes.accordion} ${
                  isOpenAccordion && index === divIndex ? classes.open : ""
                }`}
              >
                <div className={classes.AccordionHeader}>
                  <div className={classes.NameDiscountWrap}>
                    <input
                      type="text"
                      className={classes.DiscountInput}
                      name="name"
                      value={paymentOption.name}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                    {index == divIndex ? (
                      <img src={Down} onClick={() => toggleAccordion(index)} />
                    ) : (
                      <img src={Up} onClick={() => toggleAccordion(index)} />
                    )}
                  </div>

                  {index > 0 && (
                    <div
                      className={classes.RemoveOfferWrap}
                      onClick={() => handleremoveFields(index)}
                    >
                      -
                    </div>
                  )}
                </div>
                {errors[index]?.name && (
                  <span className={classes.errorText}>
                    {errors[index].name}
                  </span>
                )}
                {isOpenAccordion && (
                  <div className={classes.AccordionContent}>
                    <div className={classes.tab_content}>
                      <div className={classes.InputBox}>
                        <p>{t("priceS")}</p>
                        <input
                          type="number"
                          name="price"
                          value={paymentOption.price}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                        {errors[index]?.price && (
                          <span className={classes.errorText}>
                            {errors[index].price}
                          </span>
                        )}
                      </div>
                      <div className={classes.PriodicityWrap}>
                        <div className={classes.DropDownBox}>
                          <p>{t("priodicity")}</p>
                          <select
                            name="periodicity"
                            value={paymentOption.periodicity}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                          >
                            <option>{t("monthly")}</option>
                            <option>{t("yearly")}</option>
                          </select>
                          {errors[index]?.periodicity && (
                            <span className={classes.errorText}>
                              {errors[index].periodicity}
                            </span>
                          )}
                        </div>
                        <div className={classes.SelectTimeWrpa}>
                          <p>{t("Per")}</p>
                          <div className={classes.CountSection}>
                            <div className={classes.CountWrap}>
                              <input
                                type="number"
                                name="per"
                                value={paymentOption.per}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                              />
                              <div className={classes.IncreaseAndDecrease}>
                                <div
                                  className={classes.IncreaseDecreaseBtnWrap}
                                  onClick={() => {
                                    const temp = [...paymentOptions];
                                    temp[index].per = temp[index].per + 1;

                                    setPaymentOptions(temp);
                                  }}
                                >
                                  +
                                </div>
                                <div
                                  className={classes.IncreaseDecreaseBtnWrap}
                                  onClick={() => {
                                    const temp = [...paymentOptions];
                                    if (temp[index].per > 1) {
                                      temp[index].per = temp[index].per - 1;
                                    }
                                    setPaymentOptions(temp);
                                  }}
                                >
                                  -
                                </div>
                              </div>
                            </div>
                            <p className={classes.CountText}>
                              {paymentOption.periodicity == "MONTHLY"
                                ? t("month_s")
                                : t("year_s")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={classes.FlexWrap}>
                        <div className={classes.DateWrap}>
                          <div className={classes.InputBox}>
                            <p>{t("start_at")}</p>
                            <input
                              type="date"
                              name="valid_from"
                              value={paymentOption.valid_from}
                              min={disablePastDate()}
                              onChange={(e) => {
                                dateValidaterFunction(
                                  e,
                                  handleInputChange,
                                  index
                                );
                              }}
                            />
                          </div>
                          <div className={classes.InputBox}>
                            <p>{t("end_at")}</p>
                            <input
                              type="date"
                              name="valid_until"
                              value={paymentOption.valid_until}
                              min={paymentOption.valid_from}
                              onChange={(e) => {
                                dateValidaterFunction(
                                  e,
                                  handleInputChange,
                                  index
                                );
                              }}
                            />
                          </div>
                        </div>
                        {!props?.data && !props.orgAndPlanData && (
                          <div className={classes.OrderByWrap}>
                            <div className={classes.ToggleWrap}>
                              <p>{t("most_popular_choice")}</p>
                              <label className={classes.Switch}>
                                <input
                                  type="checkbox"
                                  name="most_popular"
                                  value={paymentOption.most_popular}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  checked={
                                    paymentOption.most_popular == 1
                                      ? true
                                      : false
                                  }
                                />
                                <span className={classes.Slider} />
                              </label>
                            </div>
                            <div className={classes.InputBox}>
                              <p>{t("order_by")}</p>
                              <input
                                type="text"
                                name="order"
                                value={paymentOption.order}
                                onChange={(event) => {
                                  handleInputChange(index, event);
                                }}
                              />
                              {errors[index]?.order && (
                                <span className={classes.errorText}>
                                  {errors[index].order}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={classes.DiscountsWrap}>
                        <p>{t("discounts_extras")}</p>
                        <p className={classes.Smalltext}>
                          {t(
                            "<!> The order of discounts and extra's will affect the final price"
                          )}
                        </p>
                        <div className={classes.DiscounttsSection}>
                          {paymentOption.discount_detail?.length > 0 &&
                            // paymentOption.discount_detail[0].name &&
                            paymentOption.discount_detail.map(
                              (val, discountIndex) => {
                                return (
                                  <div
                                    key={discountIndex}
                                    className={classes.DiscounttOptionWrap}
                                    draggable={true}
                                    onDragStart={handleDragStart({
                                      id: val?.id,
                                    })}
                                    onDragOver={handleDragOver({ id: val?.id })}
                                    onDrop={handleDrop({
                                      id: val?.id,
                                      index: index,
                                    })}
                                  >
                                    <p>{val.name}</p>
                                    <div className={classes.CheckboxWrap}>
                                      {!props?.data &&
                                        !props.orgAndPlanData && (
                                          <>
                                            <input
                                              type="checkbox"
                                              id={`${discountIndex}${index}`}
                                              className={classes.radioCustom}
                                              onChange={(event) => {
                                                if (event) {
                                                  handleCheckboxClick(
                                                    event,
                                                    index,
                                                    discountIndex
                                                  );
                                                }
                                              }}
                                            />
                                            <label
                                              className={
                                                classes.radioCustomLabel
                                              }
                                              htmlFor={`${discountIndex}${index}`}
                                            ></label>
                                          </>
                                        )}
                                      <div
                                        className={classes.removeWrap}
                                        onClick={() =>
                                          RemoveDiscount(
                                            index,
                                            discountIndex,
                                            val?.id
                                          )
                                        }
                                      >
                                        -
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          {/* </div> */}
                        </div>
                        <div className={classes.AddDiscount}>
                          <div className={classes.customdropdown}>
                            <div
                              className={`${classes.dropdown} ${
                                isOpen ? "open" : ""
                              }`}
                            >
                              <CustomSelect
                                options={paymentOption}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                              />
                            </div>
                          </div>
                          <div
                            className={classes.addWrap}
                            onClick={() => {
                              if (selectedOption) {
                                setSelectedOption("");
                                AddDiscountData(index);
                                setSelectedOptionError(false);
                              } else {
                                setSelectedOptionError(true);
                              }
                            }}
                          >
                            +
                          </div>
                        </div>
                        {selectedOptionError && (
                          <span className={classes.errorText}>
                            {t("please select option")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {paymentOptions.length < 5 &&
            !props.orgAndPlanData?.plandata?.id &&
            !props?.data && (
              <div className={classes.AddDiscountMainWrap}>
                <p>{t("add")}</p>
                <div
                  className={classes.addWrap}
                  onClick={() => handleAddFields()}
                >
                  +
                </div>
              </div>
            )}
        </div>
      </div>
      <div className={classes.btnWrap}>
        <button onClick={AddSubscriptionHandler}>{t("save")}</button>
      </div>
    </div>
  );
};

export default AddSubscriptionModal;
