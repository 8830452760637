import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import React from "react";
import { routes } from "./routes";

const Private = (props) => {
  const authorizeToken = localStorage.getItem("authToken");
  const auth = authorizeToken && authorizeToken !== "" ? true : false;
  return auth ? <props.Component /> : <Navigate to="/login" />;
};

const Public = (props) => {
  return <props.Component />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.private ? (
                <Private Component={route.component} />
              ) : (
                <Public Component={route.component} />
              )
            }
          />
        ))}
      </Route>
    </React.Fragment>
  )
);
