import React, { useRef, useState } from "react";
import classes from "./provincemain.module.scss";
import MainLayout from "../common/MainLayout";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actionDeleteProvinceDataApiCall } from "../../Redux/Actions";
import OffcanvasMain from "../common/Offcanvas";
import ProvinceHeader from "../common/ProvinceHeader";
import ProvinceForm from "../common/ProvinceForm";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";
const ProvinceMain = () => {
  const getProvinceData = useSelector(
    (state) => state?.CommonReducer?.getProvinceData
  );
  const loader = useSelector((state) => state.CommonReducer.loader);

  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const divref = useRef([]);
  const [openCanvas, setOpenCanvas] = useState(false);
  const navigate = useNavigate();
  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 303) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = (item) => {
    dispatch(actionDeleteProvinceDataApiCall({ id: item?.id, navigate }));
  };

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.CountriesMain}>
          <ProvinceHeader editData={editData} />
          <div className={classes.CountriesSection}>
            <table>
              <>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("code").toUpperCase()}</th>
                    <th>{t("country").toUpperCase()}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loader == true ? (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={6}>
                        <Loader />
                      </td>
                    </tr>
                  ) : getProvinceData?.length > 0 ? (
                    getProvinceData.map((item, index) => (
                      <tr ref={(e) => (divref.current[index] = e)} key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.code ? item?.code : "-"}</td>
                        <td>{item?.country ? item?.country?.name : "-"}</td>
                        <td className={classes.userEditor}>
                          <img
                            src={userEditorIcon}
                            onClick={() => {
                              handleScroll(index);
                              if (ind !== index) {
                                setShow(!show);
                                setInd(index);
                              } else {
                                setInd("");
                              }
                            }}
                          />
                          {show && ind === index && (
                            <div
                              ref={listRef}
                              className={classes.EditorWrap}
                              onClick={() => setShow(!show)}
                              style={
                                getProvinceData.length - 1 > index
                                  ? {}
                                  : getProvinceData.length > 4
                                  ? { top: "-100px" }
                                  : {}
                              }
                            >
                              <ul>
                                <li
                                  onClick={() => {
                                    setOpenCanvas(true);
                                    setEditData(item);
                                  }}
                                >
                                  {t("edit")} {t("province")}
                                </li>
                                <li onClick={() => handleDelete(item)}>
                                  {t("delete")} {t("province")}
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={6}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            </table>
          </div>
          <OffcanvasMain
            showcanvas={openCanvas}
            placement={"end"}
            backgroundColor="true"
            handleClose={() => {
              setOpenCanvas(false);
            }}
          >
            <ProvinceForm
              editData={editData}
              handleClose={() => {
                setOpenCanvas(false);
              }}
            />
          </OffcanvasMain>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default ProvinceMain;
