import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./editemailtemplate.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddEmailApiCall,
  actionLangListAPICall,
} from "../../../../Redux/Actions";
import APIUrl from "../../../../API/APIUrl";
import JoditEditor from "jodit-react";

const EditEmailTemplate = (props) => {
  const saveSingleUserData = useSelector(
    (state) => state.CommonReducer.saveSingleUserData
  );
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const [lang, setLang] = useState(1);
  const [focus, setFocus] = useState(false);
  const [editEmail, setEditEmail] = useState({
    title: "",
    subject: "",
    templateId: "",
    content: "",
    slug: "",
  });
  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const config = {
    buttons: [
      "bold",
      "italic",
      "ul",
      "ol",
      "source",
      "link",
      "image",
      "undo",
      "redo",
    ],

    uploader: {
      url: `${APIUrl.backendUrl}/add-email-template-logo`,
      prepareData: function (data) {
        data.delete("path");
        data.delete("source");

        var file = data.getAll("files[0]")[0];
        data.delete("files[0]");
        data.append("upload", file);
      },

      isSuccess: function (resp) {
        return !resp.error;
      },

      process: function (resp) {
        return {
          url: resp.url,
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        setEditEmail((prevEditEmail) => ({
          ...prevEditEmail,
          content: prevEditEmail.content + `<img src="${data.url}" />`,
        }));
      },
      error: function (e) {},
    },

    removeButtons: [
      "about",
      "outdent",
      "indent",
      "video",
      "print",
      "table",
      "fontsize",
      "superscript",
      "subscript",
      "file",
      "cut",
      "selectall",
      "fontfamily",
      "align",
    ],
  };

  useEffect(() => {
    if (props.edit == true && saveSingleUserData) {
      setEditEmail({
        title: saveSingleUserData.title,
        subject: saveSingleUserData.subject,
        templateId: saveSingleUserData.id,
        content: saveSingleUserData.content,
        slug: saveSingleUserData.slug,
      });
      setLang(saveSingleUserData.language_id);
    }
  }, [saveSingleUserData, props]);

  useEffect(() => {
    const body = { navigate };
    dispatch(actionLangListAPICall(body));
  }, []);
  const onClickOfButton = () => {
    const data = new FormData();
    data.append("title", editEmail?.title);
    data.append("subject", editEmail?.subject);
    props.edit && data.append("templateId", editEmail?.templateId);
    data.append("content", editEmail?.content);
    data.append("languageId", lang);
    data.append(
      "slug",
      props.edit
        ? editEmail.slug
        : editEmail?.title && editEmail?.title.trim().replace(" ", "_")
    );

    let close = props.handleClose;
    const body = { data, navigate, close };
    dispatch(actionAddEmailApiCall(body));
  };
  const onInputChange = (e) => {
    setEditEmail({ ...editEmail, [e.target.name]: e.target.value });
  };

 

  return (
    <div className={classes.EditEmailMain}>
      <h3>
        {props.edit == true
          ? t("edit_email_template")
          : t("add_email_template")}
      </h3>
      <div className={classes.EditEmailWrap}>
        <div className={classes.InputWrap}>
          <p>{t("Title")}</p>
          <input
            type="text"
            name="title"
            onChange={onInputChange}
            value={editEmail?.title}
          />
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Subject")}</p>
          <input
            type="text"
            name="subject"
            onChange={onInputChange}
            value={editEmail?.subject}
          />
        </div>
        <div className={classes.InputWrap}>
          <select
            id="status"
            name="status"
            value={lang}
            onChange={(e) => setLang(e.target.value)}
            disabled={props.edit == true ? true : false}
          >
            {langdata?.map((item, index) => {
              return (
                <option value={item.id} selected={index == 0 ? true : false}>
                  {item.language_label}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          {/* <CKEditor
            config={config}
            editor={ClassicEditor}
            data={editEmail?.content ? editEmail.content : ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              let temp = { data };
              if (focus) setEditEmail({ ...editEmail, content: temp.data });
            }}
            onFocus={() => setFocus(true)}
          /> */}

          <JoditEditor
            ref={editorRef}
            id="editor"
            value={editEmail?.content ? editEmail.content : ""}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => {
              setEditEmail((prevEditEmail) => ({
                ...prevEditEmail,
                content: newContent,
              }));
            }}
            onChange={(newContent) => {}}
          />
        </div>
        <div className={classes.btnWrap}>
          <button onClick={onClickOfButton}>
            {" "}
            {loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("Save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditEmailTemplate;
