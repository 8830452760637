import React, { useEffect, useState } from "react";
import MainLayount from "../common/MainLayout";
import classes from "./settingmanagement.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetSiteSettingsApiCall,
  actionUpdateLangLabelListAPICall,
  actionUpdateSiteSettingsApiCall,
} from "../../Redux/Actions";
import Loader from "../common/Loader";
import SystemManagementHeader from "../common/SystemManagementHeader";
import EmialIcon from "../../assets/icons/EmailIcon.svg";
import SettingManagementHeader from "../common/SettingManagementHeader";

const SettingManagementMain = () => {
  const loader = useSelector((state) => state.CommonReducer.loader);

  const saveSiteSettings = useSelector(
    (state) => state.CommonReducer?.saveSiteSettings
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMaintenance, setIsMaintenance] = useState();
  const [maintenanceTitle, setMaintenanceTitle] = useState();

  useEffect(() => {
    dispatch(actionGetSiteSettingsApiCall({ navigate }));
  }, []);

  useEffect(() => {
    if (saveSiteSettings && saveSiteSettings.length > 0) {
      let isMaintenance = saveSiteSettings.find(
        (item) => item.key === "ismaintenance"
      ).title;

      let maintenanceTitle = saveSiteSettings.find(
        (item) => item.key === "maintenance"
      ).title;
      setIsMaintenance(isMaintenance);
      setMaintenanceTitle(maintenanceTitle);
    }
  }, [saveSiteSettings]);

  const onClickOfUpdate = (id, key) => {
    let data = {
      title:
        key == "ismaintenance"
          ? isMaintenance
          : key == "maintenance" && maintenanceTitle,
    };
    let body = { data, navigate, id };
    dispatch(actionUpdateSiteSettingsApiCall(body));
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.LanguageMain}>
          <SettingManagementHeader />
          <div className={classes.SystemManagementSection}>
            <table>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={3}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {/* <tr>
                    <td style={{ maxWidth: "250px" }}>{t("isMaintenance")}</td>
                    <td className={classes.selectUser}>
                      <input
                        type="checkbox"
                        id="ismaintenance"
                        name="org"
                        className={classes.radioCustom}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setIsMaintenance("true");
                          } else {
                            setIsMaintenance("false");
                          }
                        }}
                        checked={isMaintenance == "true" ? true : false}
                      />
                      <label
                        className={classes.radioCustomLabel}
                        htmlFor="ismaintenance"
                      ></label>
                    </td>
                    <td>
                      <button>{t("update")}</button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "250px" }}>{t("maintenance")}</td>
                    <td style={{ maxWidth: "250px" }}>
                      <div className={classes.fildBox}>
                        <textarea
                          placeholder={
                            t("title").charAt(0) +
                            t("title").slice(1).toLocaleLowerCase()
                          }
                          value={maintenanceTitle}
                          onChange={(e) => {
                            setMaintenanceTitle(e.target.value);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <button>{t("update")}</button>
                    </td>
                  </tr> */}

                  {saveSiteSettings &&
                    saveSiteSettings.length > 0 &&
                    saveSiteSettings
                      .filter(
                        (val) =>
                          val.key === "ismaintenance" ||
                          val.key === "maintenance"
                      )
                      .map((item, index) => {
                        return (
                          <tr>
                            <td style={{ maxWidth: "250px" }}>
                              {t(item.value)}
                            </td>
                            {item.key === "ismaintenance" && (
                              <td className={classes.selectUser}>
                                <input
                                  type="checkbox"
                                  id={index}
                                  name="org"
                                  className={classes.radioCustom}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setIsMaintenance("true");
                                    } else {
                                      setIsMaintenance("false");
                                    }
                                  }}
                                  checked={
                                    isMaintenance == "true" ? true : false
                                  }
                                />
                                <label
                                  className={classes.radioCustomLabel}
                                  htmlFor={index}
                                ></label>
                              </td>
                            )}
                            {item.key === "maintenance" && (
                              <td className={classes.selectUser}>
                                <textarea
                                  placeholder={
                                    t("title").charAt(0) +
                                    t("title").slice(1).toLocaleLowerCase()
                                  }
                                  value={maintenanceTitle}
                                  onChange={(e) => {
                                    setMaintenanceTitle(e.target.value);
                                  }}
                                />
                              </td>
                            )}
                            <td>
                              <button
                                onClick={() =>
                                  onClickOfUpdate(item.id, item.key)
                                }
                              >
                                {t("update")}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default SettingManagementMain;
