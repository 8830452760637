import React from "react";
import classes from "./userlogginghistorymain.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../common/Loader";

const UserLoggingHistoryMain = () => {
	const { t, i18n } = useTranslation();
	const userloghistorydata = useSelector(
		(state) => state.CommonReducer.userloghistorydata
	);
	const loader = useSelector((state) => state.CommonReducer.loader);
	return (
		<div className={classes.LoginHistoryMain}>
			<h3>{t("user_logging_history")}</h3>
			<div className={classes.LoginHistoryWrap}>
				{loader == true ? (
					<tr>
						<td className={classes.LoaderWrap} colSpan={3}>
							<Loader />
						</td>
					</tr>
				) : (
					<table>
						<tr>
							<th>{t("event")}</th>
							<th>{t("system")}</th>
							<th className={classes.time}>{t("time")}</th>
						</tr>
						{userloghistorydata && userloghistorydata?.length > 0 ? (
							userloghistorydata?.map((item) => (
								<tr>
									<td>{item.event ? item.system : "NA"}</td>
									<td>{item.system ? item.system : "NA"}</td>
									<td className={classes.time}>
										{moment(item.created_at).format("DD/MM/YYYY HH:MM")}
									</td>
								</tr>
							))
						) : (
							<p>No data</p>
						)}
					</table>
				)}
			</div>
		</div>
	);
};

export default UserLoggingHistoryMain;
