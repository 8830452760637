import React from "react";
import classes from "./offcanvasmain.module.scss";
import { Offcanvas } from "react-bootstrap";
const OffcanvasMain = (props) => {
  return (
    <Offcanvas
      show={props.showcanvas}
      onHide={props.handleClose}
      placement={props.placement ? props.placement : "start"}
      style={{
        width: props.width === "lg" ? "800px" : "",
        background: props.backgroundColor ? "#f3f3f3" : "",
        height: props.placement ? "calc(100% - 57px)" : "",
      }}
    >
      <Offcanvas.Header
        closeButton={props.handleClose ? props.handleClose : ""}
      >
        <Offcanvas.Title>{props.title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{props.children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMain;
