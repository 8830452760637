import React, { useState } from "react";
import classes from "./pricetableform.module.scss";
import deleteIcon from "../../../assets/images/DeleteIcon.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddPriceTableApiCall,
  actionDeletePriceTableApiCall,
  actiongetTitleListApiCall,
  actiongetAdvertisingSupplierListApiCall,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const PriceTableForm = ({ editData, handleClose }) => {
  const supplierData = useSelector((state) => state.CommonReducer.supplierData);

  const ptTypes = useSelector((state) => state.CommonReducer.priceTableTypesData);

  const plUnits = useSelector((state) => state.CommonReducer.priceLineUnitsData);

  const initialFormData = {
    ptName: "",
    ptType: "",
    ptSupplierId: "",
    ptPriceLines: [
      {
        title_id: null,
        price: 0,
        min_price: 0,
        purchase_price: 0,
        purchase_from: "",
        unit: "CPM",
      },
    ],
    start_date: new Date(),
    end_date: new Date(),
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    dispatch(actiongetTitleListApiCall({ id: supplierData.id, navigate }));
    dispatch(actiongetAdvertisingSupplierListApiCall({ navigate }));
  }, []);

  useEffect(() => {
    console.log("USE EFFECT");
    if (editData) {
      console.log("editData", editData);
      setFormData({
        ptName: editData?.name || "",
        ptType: editData?.publication_type || "",
        ptSupplierId: editData?.supplier_id || "",
        ptPriceLines: editData?.price_lines || [
          {
            title_id: null,
            price: 0,
            min_price: 0,
            purchase_price: 0,
            purchase_from: "",
            unit: "CPM",
          },
        ],
        ptTitleID: editData?.title_id || "",
        start_date: editData?.start_date || new Date(),
        end_date: editData?.end_date || new Date(),
      });
    }
  }, [editData]);

  console.log("PriceTable Form - formData", formData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log("formData 1", formData);
  };
  const handlePLChange = (event) => {
    const { name, value } = event.target;
    let pls = formData.ptPriceLines;
    const nm = name.split("-")[0];
    const index = parseInt(name.split("-")[1]);
    pls[index][nm] = value;
    setFormData({
      ...formData,
      ptPriceLines: pls,
    });
    console.log("formData 2", formData);
  };
  const validateForm = () => {
    let valid = true;
    const errors = {
      ptName: "",
      ptType: "",
      start_date: "",
      end_date: "",
    };

    if (!formData.ptName) {
      errors.ptName = "Please put in a name";
      valid = false;
    }

    if (!formData.ptType) {
      errors.ptType = "Please select a type";
      valid = false;
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    }
    return valid;
  };
  const handleAddPriceTable = () => {
    if (validateForm()) {
      let closePopup = handleClose;

      console.log("handleAddPriceTable", formData);

      const data = {
        name: formData.ptName,
        publication_type: formData.ptType,
        supplier_id: supplierData.id,
        price_lines: formData.ptPriceLines,
        start_date: formData.start_date,
        end_date: formData.end_date,
      };
      console.log("data", data);
      console.log(editData ? editData.id : false);
      dispatch(
        actionAddPriceTableApiCall({
          data,
          edit: editData ? editData.id : false,
          closePopup,
          navigate,
          getapiCall: { data: { id: supplierData.id }, navigate },
        })
      );
    }
  };
  const handleDeleteBtn = () => {
    dispatch(
      actionDeletePriceTableApiCall({
        id: editData.id,
        closePopup: handleClose,
        navigate,
        getapiCall: { data: { id: supplierData.id }, navigate },
      })
    );
  };

  const titleListData = useSelector(
    (state) => state.CommonReducer.titleListData
  );
  console.log("titleListData", titleListData);

  const advSupplierListData = useSelector(
    (state) => state.CommonReducer.advSupplierListData
  );
  console.log("advSupplierListData", advSupplierListData);

  console.log("formData", formData);

  return (
    <React.Fragment>
      <div className={classes.priceTableFormSection}>
        {editData ? <h3>Edit Pricetable</h3> : <h3>Add Pricetable</h3>}

        <div className={classes.inputsWrapper}>
          <label>Name</label>
          <input
            type="text"
            name="ptName"
            defaultValue={formData.ptName}
            onChange={handleChange}
          />
          {validationErrors.ptName && (
            <div className={classes.error}>{validationErrors.ptName}</div>
          )}
          <label>Type</label>
          <select name="ptType" value={formData.ptType} onChange={handleChange}>
            <option value="">Select an option</option>
            {ptTypes &&
              ptTypes?.map((option, index) => (
                <option key={option[0]} value={option[1]}>
                  {option[1]}
                </option>
              ))}
          </select>
          {validationErrors.ptType && (
            <div className={classes.error}>{validationErrors.ptType}</div>
          )}
          <label>Start Date</label>
          <input
            type="date"
            name="start_date"
            onChange={handleChange}
            value={formData.start_date}
          />
          {validationErrors.start_date && (
            <div className={classes.error}>{validationErrors.start_date}</div>
          )}
          <label>End Date</label>
          <input
            type="date"
            name="end_date"
            onChange={handleChange}
            value={formData.end_date}
          />
          {validationErrors.end_date && (
            <div className={classes.error}>{validationErrors.end_date}</div>
          )}
        </div>
        <div className={classes.priceLineTitle}>price line(s)</div>
        <div className={classes.inputsWrapperLine}>
          {editData?.price_lines.length > 0 ? (
            editData?.price_lines.map((pl, index) => (
              <div>
                {/* <label>Title:&nbsp;</label>
                <select
                  name={`title_id-${index}`}
                  value={pl.title_id}
                  onChange={handlePLChange}
                >
                  <option value="">Select a title</option>
                  {titleListData?.map((option, index) => (
                    <option key={option[0]} value={option[0]}>
                      {option[1]}
                    </option>
                  ))}
                </select>

                <br /> */}
                <label>Price:&nbsp;&euro;</label>
                <input
                  type="number"
                  name={`price-${index}`}
                  defaultValue={pl.price}
                  onChange={handlePLChange}
                />
                <br />
                <label>Min. Price:&nbsp;&euro;</label>
                <input
                  type="number"
                  name={`min_price-${index}`}
                  defaultValue={pl.min_price}
                  onChange={handlePLChange}
                />
                <br />
                <label>Unit:&nbsp;</label>
                <select
                  name={`unit-${index}`}
                  value={pl.unit}
                  onChange={handlePLChange}
                >
                  <option value="">Select an option</option>
                  {plUnits &&
                    plUnits?.map((option, index) => (
                      <option key={option[0]} value={option[1]}>
                        {option[1]}
                      </option>
                    ))}
                </select>
                <br />
                <label>Purchase Price:&nbsp;&euro;</label>
                <input
                  type="number"
                  name={`purchase_price-${index}`}
                  defaultValue={pl.purchase_price}
                  onChange={handlePLChange}
                  step="0.1"
                />
                <br />
                <label>Purchase From:&nbsp;</label>
                <select
                  name={`purchase_from-${index}`}
                  value={pl.purchase_from}
                  onChange={handlePLChange}
                >
                  <option value="">Select an option</option>
                  {advSupplierListData?.map((option, index) => (
                    <option key={option[0]} value={option[1]}>
                      {option[1]}
                    </option>
                  ))}
                </select>
              </div>
            ))
          ) : (
            <div>
              {/* <label>Title:&nbsp;</label>
              <select
                name={`title_id-0`}
                defaultValue="0"
                onChange={handlePLChange}
              >
                <option value="">Select a title</option>
                {titleListData?.map((option, index) => (
                  <option key={option[0]} value={option[0]}>
                    {option[1]}
                  </option>
                ))}
              </select>

              <br /> */}
              <label>Price:&nbsp;&euro;</label>
              <input
                type="number"
                name={`price-0`}
                defaultValue="0"
                onChange={handlePLChange}
              />
              <br />
              <label>Min. Price:&nbsp;&euro;</label>
              <input
                type="number"
                name={`min_price-0`}
                defaultValue="0"
                onChange={handlePLChange}
              />
              <br />
              <label>Unit:&nbsp;</label>
              <select
                name={`unit-0`}
                defaultValue="CPM"
                onChange={handlePLChange}
              >
                <option value="">Select an option</option>
                {plUnits &&
                  plUnits?.map((option, index) => (
                    <option key={option[0]} value={option[1]}>
                      {option[1]}
                    </option>
                  ))}
              </select>
              <br />
              <label>Purchase Price:&nbsp;&euro;</label>
              <input
                type="number"
                name={`purchase_price-0`}
                defaultValue="0"
                onChange={handlePLChange}
                step="0.1"
              />
              <br />
              <label>Purchase From:&nbsp;</label>
              <select name={`purchase_from-0`} onChange={handlePLChange}>
                <option value="">Select an option</option>
                {advSupplierListData?.map((option, index) => (
                  <option key={option[0]} value={option[1]}>
                    {option[1]}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className={classes.btnwrapper}>
          {editData ? (
            <>
              <button className={classes.btncancel} onClick={handleDeleteBtn}>
                <img src={deleteIcon} alt="" /> Delete
              </button>
              <button className={classes.btnadd} onClick={handleAddPriceTable}>
                Save
              </button>
            </>
          ) : (
            <>
              <button className={classes.btncancel} onClick={handleClose}>
                Cancel
              </button>
              <button className={classes.btnadd} onClick={handleAddPriceTable}>
                Add
              </button>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PriceTableForm;
