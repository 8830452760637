if (
  window.location.href.includes("https://wm-admin.testyourapp.online") ||
  window.location.href.includes("localhost")
) {
  localStorage.setItem("Platform", "DEV");
} else if (
  window.location.href.includes("https://admin-test.woningmarkt.nl/")
) {
  localStorage.setItem("Platform", "UAT");
} else {
  localStorage.setItem("Platform", "PROD");
}
const platform = localStorage.getItem("Platform");

export default {
  backendUrl: process.env[`REACT_APP_${platform}_BACKEND_URL`],
  // backendUrl: "https://api.woningmarkt.nl/api/admin",
};
