import React, { useRef, useState } from "react";
import classes from "./bannermanagementmain.module.scss";
import MainLayount from "../common/MainLayout";
import BannerManagementHeadre from "../common/BannerManagementHeadre";
import { useTranslation } from "react-i18next";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/Loader";
import {
  actionDeleteBannerApiCall,
  actionViewBannerApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import ModalMain from "../common/Modal";
import CreateBanner from "../ModalComponents/BannerManagementModal/CrateBanner";
import moment from "moment";

const BannerManagementMain = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [creatbanner, setCreateBanner] = useState(false);
  const bannerlist = useSelector((state) => state.CommonReducer.bannerListData);
  const loader = useSelector((state) => state.CommonReducer.loader);
  const listRef = useRef(null);
  const divref = useRef([]);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 400) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const onCLickDeleteBanner = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionDeleteBannerApiCall(body));
  };
  const onClickOfEditBanner = (id) => {
    const data = id;
    const body = { data, navigate };
    dispatch(actionViewBannerApiCall(body));
    setCreateBanner(true);
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.BannerMain}>
          <BannerManagementHeadre />
          <div className={classes.BannerListingSection}>
            <table>
              <tr>
                <th>{t("position_c")}</th>
                <th>{t("start_date_c")}</th>
                <th>{t("end_date_c")}</th>
                <th>{t("is_default")}</th>
                {/* <th></th> */}
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {bannerlist && bannerlist?.length > 0 ? (
                    bannerlist.map((item, index) => (
                      <tr ref={(e) => (divref.current[index] = e)}>
                        <td>{item.position}</td>
                        <td>
                          {item?.start_date != "0000-00-00"
                            ? moment(item.start_date).format("DD/MM/YYYY HH:MM")
                            : "NA"}
                        </td>
                        <td>
                          {item?.end_date != "0000-00-00"
                            ? moment(item.end_date).format("DD/MM/YYYY HH:MM")
                            : "NA"}
                        </td>
                        <td>{item.default == 1 ? t("yes") : t("no")}</td>
                        <td className={classes.BannerEditor}>
                          <img
                            src={userEditorIcon}
                            onClick={() => {
                              if (bannerlist.length - 1 > index) {
                                handleScroll(index);
                              }

                              if (ind !== index) {
                                setShow(!show);
                                setInd(index);
                              } else {
                                setInd("");
                              }
                            }}
                          />
                          {show && ind == index && (
                            <div
                              ref={listRef}
                              style={
                                bannerlist.length - 2 > index
                                  ? {}
                                  : { top: "-100px" }
                              }
                              className={classes.EditorWrap}
                              onClick={() => setShow(!show)}
                            >
                              <ul>
                                <li
                                  onClick={() => onClickOfEditBanner(item.id)}
                                >
                                  {t("edit_banner")}
                                </li>
                                <li
                                  onClick={() => onCLickDeleteBanner(item.id)}
                                >
                                  {t("delete_banner")}
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={6}>
                        <p>{t("no_data_found")}</p>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
      <ModalMain
        showmodel={creatbanner}
        handleClose={() => setCreateBanner(false)}
        size={"md"}
      >
        <CreateBanner onClose={() => setCreateBanner(false)} edit={true} />
      </ModalMain>
    </React.Fragment>
  );
};

export default BannerManagementMain;
