import React, { useEffect, useRef, useState } from "react";
import classes from "./classificationmain.module.scss";
import MainLayout from "../common/MainLayout";
import ClassificationHeader from "../common/ClassificationHeader";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useTranslation } from "react-i18next";
import {
  actionClassificationGetTableApi,
  actionClassificationRemoveData,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import ClassificationForm from "../common/ClassificationForm";
import OffcanvasMain from "../common/Offcanvas";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
const ClassficationMain = () => {
  const dispatch = useDispatch();
  const classificationTable = useSelector(
    (state) => state?.CommonReducer?.getClassificationTable
  );
  const [openCanvas, setOpenCanvas] = useState();
  const [editData, setEditData] = useState();
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const [show, setShow] = useState(false);

  const divref = useRef([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  // calling api for classification table data
  const loader = useSelector((state) => state.CommonReducer.loader);
  useEffect(() => {
    dispatch(actionClassificationGetTableApi({ navigate }));
  }, []);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 303) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = (id) => {
    dispatch(actionClassificationRemoveData({ idL: id, navigate }));
  };
  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.ClassificationMain}>
          <ClassificationHeader editData={editData} />

          <div className={classes.ClassificationSection}>
            <table>
              <thead>
                <tr>
                  <th>{t("NAME")}</th>
                  <th>{t("MAIN CLASS")}</th>
                  <th>{t("SUB CLASS")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader == true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={3}>
                      <Loader />
                    </td>
                  </tr>
                ) : classificationTable.length > 0 ? (
                  classificationTable?.map((item, index) => (
                    <tr ref={(e) => (divref.current[index] = e)}>
                      <td>{item.name}</td>
                      <td>
                        {item?.parentClassification
                          ? item?.parentClassification.name
                          : item.name}
                      </td>
                      <td>{item?.parentClassification ? item?.name : "-"}</td>
                      <td className={classes.userEditor}>
                        <img
                          src={userEditorIcon}
                          onClick={() => {
                            handleScroll(index);
                            if (ind !== index) {
                              setShow(!show);
                              setInd(index);
                            } else {
                              setInd("");
                            }
                          }}
                        />

                        {show && ind == index && (
                          <div
                            ref={listRef}
                            className={classes.EditorWrap}
                            onClick={() => setShow(!show)}
                            style={
                              classificationTable.length - 1 > index
                                ? {}
                                : classificationTable.length > 4
                                ? { top: "-100px" }
                                : {}
                            }
                          >
                            <ul>
                              <li
                                onClick={() => {
                                  setOpenCanvas(true);
                                  setEditData(item);
                                }}
                              >
                                {t("Edit Classification")}
                              </li>
                              <li onClick={() => handleDelete(item?.id)}>
                                {t("Delete Classification")}
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={3}>
                      <h2>{t("no_data_found")}</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <OffcanvasMain
            showcanvas={openCanvas}
            placement={"end"}
            backgroundColor="true"
            handleClose={() => setOpenCanvas(false)}
          >
            <ClassificationForm
              editData={editData}
              handleClose={() => setOpenCanvas(false)}
            />
          </OffcanvasMain>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default ClassficationMain;
