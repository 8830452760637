import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

const ModalMain = (props) => {
  const modalRef = useRef();
  // useEffect(() => {
  //   if (props.showmodels && modalRef.current) {
  //    console.log(props.showmodel,"insideeffect")
  //     const modalElement = modalRef.current.querySelector('.modal');

  //     if (modalElement) {
  //       // Remove tabindex from the modal
  //       modalElement.removeAttribute('tabindex');}
      
  //   }
  // },[props.showmodel]);
  return (
    <Modal
    ref={modalRef}
    // data-bs-focus="false"
      show={props.showmodel}
      onHide={props.handleClose}
      centered
      enforceFocus={false}
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default ModalMain;
