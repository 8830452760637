import React, { useEffect, useState } from "react";
import classes from "./editlisting.module.scss";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionEditPropertyAPICall,
  actionGetAutoSuggestCityApiCall,
  actionSaveAutoSuggestCityData,
  actiongetTranslationListApiCall,
} from "../../../../Redux/Actions";
const EditListing = (props) => {
  const singleListData = useSelector(
    (state) => state.CommonReducer.saveSinglePropertyData
  );
  const state = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const [previewImg, setPreviewImg] = useState();
  const [showError, setShowError] = useState(false);
  const [data, setData] = useState({
    property_id: "",
    house_number: "",
    post_code: "",
    city: "",
    no_bedroom: "",
    livable_area: "",
    logo_image: "",
    rent_price: "",
    sell_price: "",
    rent_prefix: "",
    rent_postfix: "",
    sell_prefix: "",
    sell_postfix: "",
  });
  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState({ rentError: "", sellError: "" });
  const autoSuggestedCity = useSelector(
    (state) => state.CommonReducer.autoSuggestedCity
  );
  useEffect(() => {
    dispatch(actionSaveAutoSuggestCityData([]));
  }, []);
  useEffect(() => {
    if (autoSuggestedCity && autoSuggestedCity.length > 0) {
      setData({
        ...data,
        city: autoSuggestedCity[0]?.city?.name,
        zipcode_id: autoSuggestedCity[0]?.id,
        street: autoSuggestedCity[0]?.street,
        neighbourhood: autoSuggestedCity[0]?.neighbourhood?.name,
        district: autoSuggestedCity[0]?.district?.name,
        municipality: autoSuggestedCity[0]?.municipality?.name,
        province: autoSuggestedCity[0]?.province?.name,
        country_part: autoSuggestedCity[0]?.country_part_detail?.name,
      });
    }
  }, [autoSuggestedCity]);
  useEffect(() => {
    if (data?.house_number && data?.post_code?.length == 6) {
      dispatch(
        actionGetAutoSuggestCityApiCall({
          house_no: data.house_number,
          zipcode: data.post_code,
        })
      );
    }
  }, [data?.post_code, data?.house_number]);
  const TranslationListApiCall = (title) => {
    const data = title;
    const body = { data, navigate };
    dispatch(actiongetTranslationListApiCall(body));
  };
  useEffect(() => {
    TranslationListApiCall("rent_prefix");
    TranslationListApiCall("rent_postfix");
    TranslationListApiCall("sell_prefix");
    TranslationListApiCall("sell_postfix");
    TranslationListApiCall("status");
  }, []);

  useEffect(() => {
    if (singleListData.is_rent == 1) {
      if (
        data.rent_prefix === "FIXED_PRICE" ||
        data.rent_prefix === "ASKING_PRICE"
      ) {
        if (data.rent_postfix?.length == 0 && data.rent_price?.length == 0) {
          setError({ ...error, rentError: "price & price type required" });
        } else if (data.rent_price?.length == 0) {
          setError({ ...error, rentError: "price required" });
        } else if (data.rent_postfix?.length == 0) {
          setError({ ...error, rentError: "price type required" });
        } else if (data.rent_prefix?.length == 0 || data.rent_prefix == null) {
          setError({ ...error, rentError: "price info required" });
        } else {
          setError({ ...error, rentError: "" });
        }
      } else {
        setError({ ...error, rentError: "" });
      }
    }
  }, [
    singleListData.is_rent,
    data.rent_price,
    data.rent_prefix,
    data.rent_postfix,
  ]);
  useEffect(() => {
    if (singleListData.is_sell == 1) {
      if (
        data.sell_prefix === "FIXED_PRICE" ||
        data.sell_prefix === "ASKING_PRICE"
      ) {
        if (
          (data.sell_postfix?.length == 0 || data.sell_postfix == null) &&
          data.sell_price?.length == 0
        ) {
          setError({ ...error, sellError: "price & price type required" });
        } else if (data.sell_price?.length == 0) {
          setError({ ...error, sellError: "price required" });
        } else if (
          data.sell_postfix?.length == 0 ||
          data.sell_postfix == null
        ) {
          setError({ ...error, sellError: "price type required" });
        } else {
          setError({ ...error, sellError: "" });
        }
      } else if (data.sell_prefix?.length == 0 || data.sell_prefix == null) {
        setError({ ...error, sellError: "price info required" });
      } else {
        setError({ ...error, sellError: "" });
      }
    }
  }, [
    singleListData.is_sell,
    data.sell_price,
    data.sell_prefix,
    data.sell_postfix,
  ]);

  useEffect(() => {
    setData({
      property_id: singleListData.id,
      house_number: singleListData.housenumber,
      post_code: singleListData.postcode,
      street_name: singleListData.street,
      addition: singleListData.housenumber_addition,
      city: singleListData.city,
      neighbourhood: singleListData.neighbourhood,
      district: singleListData.district,
      municipality: singleListData.municipality,
      province: singleListData.province,
      country_part: singleListData.country_part,
      no_bedroom: singleListData.no_bedroom,
      livable_area: singleListData.livable_area,
      logo_image:
        singleListData?.image_list &&
        singleListData?.image_list[0]?.url_original_file,
      status: singleListData.status,
      rent_price: singleListData.rent_price,
      sell_price: singleListData.sell_price,
      rent_prefix: singleListData.rent_prefix,
      rent_postfix: singleListData.rent_postfix,
      sell_prefix: singleListData.sell_prefix,
      sell_postfix: singleListData.sell_postfix,
    });
  }, [singleListData]);

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value.trim() });
  };
  const handleEditButton = () => {
    setShowError(true);

    if (
      data.no_bedroom &&
      data.livable_area &&
      data.post_code &&
      data.house_number &&
      data.logo_image
    ) {
      let formdata = new FormData();
      formdata.append(
        "main_img",
        data.logo_image?.name ? data.logo_image.name : data.logo_image
      );
      formdata.append("property_id", singleListData.id);
      formdata.append("zipcode_id", data.zipcode_id);
      formdata.append("street", data.street);
      formdata.append("number", data.house_number);
      formdata.append("addition", data.addition);
      formdata.append("postcode", data.post_code);
      formdata.append("city", data.city);
      formdata.append("neighbourhood", data.neighbourhood);
      formdata.append("district", data.district);
      formdata.append("municipality", data.municipality);
      formdata.append("province", data.province);
      formdata.append("country_part", data.country_part);
      formdata.append("no_bedroom", data.no_bedroom);
      formdata.append("livable_area", data.livable_area);
      formdata.append("files", data.logo_image);
      formdata.append("rent_price", data.rent_price);
      formdata.append("sell_price", data.sell_price);
      formdata.append("rent_prefix", data.rent_prefix);
      formdata.append("rent_postfix", data.rent_postfix);
      formdata.append("sell_prefix", data.sell_prefix);
      formdata.append("sell_postfix", data.sell_postfix);
      let close = props.handleClose;
      const body = {
        formdata,
        navigate,
        close,
        edit: true,
        property_id: singleListData.id,
      };
      dispatch(actionEditPropertyAPICall(body));
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setData({ ...data, ["logo_image"]: droppedFile });
    setPreviewImg(URL.createObjectURL(droppedFile));
  };
  return (
    <div className={classes.EditListing}>
      <h3>{t("edit_listing")}</h3>
      <div className={classes.EditListingWrap}>
        <div className={classes.EditDetailsSection}>
          <div className={classes.CarteOrgWarp}>
            <h6>{t("change_main_picture")}</h6>
            <div
              className={classes.uploadMain}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <input
                type="file"
                id="upload"
                name="logo_image"
                accept=".jpg, .jpeg, .png,.webp,.svg"
                hidden
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.files[0] });
                  setPreviewImg(URL.createObjectURL(e.target.files[0]));
                }}
              />
              <label htmlFor="upload">
                <div className={classes.PhotoUpload}>
                  {previewImg || data?.logo_image ? (
                    <img
                      className={classes.img1}
                      src={previewImg ? previewImg : data?.logo_image}
                    />
                  ) : (
                    <img src={FileUploadIcon} />
                  )}
                </div>
              </label>
              {showError &&
                (data?.logo_image == null || data?.logo_image == "") && (
                  <p className={classes.errorText}>{t("file_required")}</p>
                )}
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("house_number")}</p>
                <input
                  type="number"
                  name="house_number"
                  defaultValue={data.house_number}
                  onChange={onInputChange}
                />
                {showError &&
                  (data?.house_number == null || data?.house_number == "") && (
                    <p className={classes.errorText}>
                      {t("house_number")} {t("required")}
                    </p>
                  )}
              </div>
              <div className={classes.FildBox}>
                <p>{t("postal_code")}</p>
                <input
                  type="text"
                  name="post_code"
                  defaultValue={data.post_code}
                  onChange={onInputChange}
                />
                {showError &&
                  (data?.post_code == null || data?.post_code == "") && (
                    <p className={classes.errorText}>
                      {t("postalcode_required")}
                    </p>
                  )}
              </div>
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("street_name")}</p>
                <input value={data.street_name} disabled />
              </div>
              <div className={classes.FildBox}>
                <p>{t("addition")}</p>
                <input
                  type="text"
                  name="addition"
                  defaultValue={data.addition}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("city")}</p>
                <input value={data.city} disabled />
              </div>
              <div className={classes.FildBox}>
                <p>{t("neighbourhood")}</p>
                <input value={data.neighbourhood} disabled />
              </div>
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("district")}</p>
                <input value={data.district} disabled />
              </div>
              <div className={classes.FildBox}>
                <p>{t("municipality")}</p>
                <input value={data.municipality} disabled />
              </div>
            </div>
            <div className={classes.InputWrap}>
              <div className={classes.FildBox}>
                <p>{t("province")}</p>
                <input value={data.province} disabled />
              </div>
              <div className={classes.FildBox}>
                <p>{t("country_part")}</p>
                <input value={data.country_part} disabled />
              </div>
            </div>
            <div className={classes.linkInputWrap}>
              <p>{t("House status")}</p>
              <select
                name="status"
                defaultValue={data.status}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  {`${t("status")}...`}
                </option>
                {state?.saveStatusData?.length > 0 &&
                  state?.saveStatusData.map((item) => {
                    return (
                      <option value={item.value}>{item.translation}</option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className={classes.EditListingSection}>
          <div className={classes.ListingSection}>
            {singleListData.is_rent == 1 && (
              <div className={classes.ListingWrap}>
                <div className={classes.ListingBox}>
                  <div className={classes.LsitBox}>
                    <p>{t("type_of_listing")}</p>
                    <select name="property_type" disabled>
                      <option>{t("rent")}</option>
                    </select>
                  </div>
                  <div className={classes.LsitBox}>
                    <p>{t("price_of_listing")}</p>
                    <input
                      type="text"
                      name="rent_price"
                      defaultValue={data.rent_price}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className={classes.LsitBox}>
                    <p>{t("Price Type")}</p>
                    <select
                      name="rent_prefix"
                      onChange={onInputChange}
                      defaultValue={data.rent_prefix}
                    >
                      <option value="" disabled selected>
                        {`${t("Price Type")}...`}
                      </option>
                      {state?.saveRentPostfixData &&
                        state?.saveRentPostfixData?.map((item1) => {
                          return (
                            <option
                              value={item1.value}
                              selected={
                                item1.value == data.rent_prefix ? true : false
                              }
                            >
                              {item1.translation}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className={classes.LsitBox}>
                    <p>{t("price_info")}</p>
                    <select
                      name="rent_postfix"
                      id="cars"
                      form="carform"
                      defaultValue={data.rent_postfix}
                      onChange={onInputChange}
                    >
                      <option value="" disabled selected>
                        {`${t("price_info")}...`}
                      </option>
                      {state?.saveRentPrefixData &&
                        state?.saveRentPrefixData?.map((item1) => {
                          return (
                            <option
                              value={item1.value}
                              selected={
                                item1.value == data.rent_postfix ? true : false
                              }
                            >
                              {item1.translation}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {error.rentError != "" && (
              <p className={classes.errorText}>{error.rentError}</p>
            )}
            {singleListData.is_sell == 1 && (
              <div className={classes.ListingWrap}>
                <div className={classes.ListingBox}>
                  {" "}
                  <div className={classes.LsitBox}>
                    <p>{t("type_of_listing")}</p>
                    <select name="property_type" disabled>
                      <option>{t("sell")}</option>
                    </select>
                  </div>
                  <div className={classes.LsitBox}>
                    <p>{t("price_of_listing")}</p>
                    <input
                      type="text"
                      name="sell_price"
                      defaultValue={data.sell_price}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className={classes.LsitBox}>
                    <p>{t("Price Type")}</p>
                    <select
                      name="sell_postfix"
                      defaultValue={data.sell_postfix}
                      onChange={onInputChange}
                    >
                      <option value="" disabled selected>
                        {`${t("Price Type")}...`}
                      </option>
                      {state?.saveSellPostfixData &&
                        state?.saveSellPostfixData?.map((item1) => {
                          return (
                            <option
                              value={item1.value}
                              selected={
                                item1.value == data.sell_postfix ? true : false
                              }
                            >
                              {item1.translation}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className={classes.LsitBox}>
                    <p>{t("price_info")}</p>
                    <select
                      name="sell_prefix"
                      defaultValue={data.sell_prefix}
                      onChange={onInputChange}
                    >
                      <option value="" disabled selected>
                        {`${t("price_info")}...`}
                      </option>
                      {state?.saveSellPrefixData &&
                        state?.saveSellPrefixData?.map((item1) => {
                          return (
                            <option
                              value={item1.value}
                              selected={
                                item1.value == data.sell_prefix ? true : false
                              }
                            >
                              {item1.translation}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {error.sellError != "" && (
              <p className={classes.errorText}>{error.sellError}</p>
            )}
          </div>
          {/* <div className={classes.AddOrRemoveListing}>
                    <span
                      className={classes.RemoveListing}
                      onClick={handleremoveFields}
                    >
                      -
                    </span>
                    {inputFields??.length - 1 == index && (
                      <span
                        className={classes.AddListing}
                        onClick={handleAddFields}
                      >
                        +
                      </span>
                    )}
                  </div> */}
          {/* ))} */}
          <div className={classes.ListingDetails}>
            <div className={classes.ListngInput}>
              <p>{t("Square meters of listing")}</p>
              <input
                type="text"
                name="livable_area"
                defaultValue={data.livable_area}
                onChange={onInputChange}
              />{" "}
              {showError &&
                (data?.livable_area == null || data?.livable_area == "") && (
                  <p className={classes.errorText}>
                    {t("square_meters_required")}
                  </p>
                )}
            </div>
            <div className={classes.ListngInput}>
              <p>{t("Number of bedrooms")}</p>
              <input
                type="text"
                name="no_bedroom"
                defaultValue={data.no_bedroom}
                onChange={onInputChange}
              />
              {showError &&
                (data?.no_bedroom == null || data?.no_bedroom == "") && (
                  <p className={classes.errorText}>
                    {t("no_of_bedrooms_required")}
                  </p>
                )}
            </div>
          </div>
          <div className={classes.EditbtnWrap}>
            <button onClick={handleEditButton}>
              {" "}
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <span>{t("edit")}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditListing;
