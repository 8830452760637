import React, { useEffect, useState } from "react";
import classes from "./edituser.module.scss";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import RoleChangeIcon from "../../../../assets/icons/RoleChangeIcon.svg";
import DeleteIcon from "../../../../assets/icons/DeleteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionCreateUserApiCall,
  actionremoveJoinedOrganisationApiCall,
} from "../../../../Redux/Actions";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../common/ConfirmationModal";

const EditUser = (props) => {
  let singleUserData = useSelector(
    (state) => state.CommonReducer.singleUserData
  );
  
  // const reduxState = useSelector((state) => state.CommonReducer);
  const [org, setorg] = useState({ id: "" });
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    file: "",
    id: "",
  });
  const loading = useSelector((state) => state.CommonReducer.loader);
  const [previewImg, setPreviewImg] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [showError, setShowError] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (singleUserData?.full_name || singleUserData?.first_name != null) {
      let temp = {
        ["first_name"]: singleUserData.first_name?.split(" ")[0],
        ["last_name"]: singleUserData.last_name?.split(" ")[0],
        email: singleUserData?.email,
        url_original_file: singleUserData?.url_original_file,
        id: singleUserData.id,
      };
      setData(temp);
    } else {
      let temp = {
        ["first_name"]: "",
        ["last_name"]: "",
        email: singleUserData?.email,
        url_original_file: singleUserData?.url_original_file,
        id: singleUserData.id,
      };
      setData(temp);
    }
  }, [singleUserData]);
  const onChangeOfInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const onClickremoveOrg = () => {
    const data = {
      userid: singleUserData.id,
      organization_id: org.id,
    };
    const body = { data, navigate };
    dispatch(actionremoveJoinedOrganisationApiCall(body));
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setData({ ...data, ["url_original_file"]: droppedFile });
    setPreviewImg(URL.createObjectURL(droppedFile));
  };

  const onClickEditButton = () => {
    setShowError(true);
    let check = true;
    for (let key in data) {
      if (key !== "last_name" && key !== "url_original_file") {
        if (!data[key] || data[key] === "null" || data[key] === "undefined") {
          check = false;
          break;
        }
      }
    }
    if (data?.url_original_file?.size && data?.url_original_file?.size > 2e6) {
      check = false;
    }
    if (check == true) {
      const formdata = new FormData();
      formdata.append("email", data?.email);
      formdata.append("file", data?.url_original_file);
      formdata.append("first_name", data?.first_name);
      formdata.append("last_name", data?.last_name);
      formdata.append("user_id", data.id);
      let close = props.handleClose;
      let body = { formdata, navigate, close, edit: props.edit, id: data.id };
      dispatch(actionCreateUserApiCall(body));
    }
  };


  return (
    <>
      <div className={classes.EditUserMain}>
        <h3>{t("edit_user")}</h3>
        <div className={classes.SectionWrap}>
          <div className={classes.EditInfoSection}>
            <div className={classes.CarteOrgWarp}>
              <h6>{t("change_profile_picture")}</h6>
              <div
                className={classes.uploadMain}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <input
                  type="file"
                  id="upload"
                  hidden
                  multiple
                  accept=".jpg, .jpeg, .png,.webp,.svg"
                  name="url_original_file"
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.files[0] });
                    setPreviewImg(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                <label htmlFor="upload">
                  <div className={classes.PhotoUpload}>
                    {previewImg || data?.url_original_file ? (
                      <img
                        className={classes.img1}
                        src={previewImg ? previewImg : data?.url_original_file}
                      />
                    ) : (
                      <img src={FileUploadIcon} />
                    )}
                  </div>
                  {/* {showError && (
                    <>
                      {data?.url_original_file?.size > 2e6 ? (
                        <p className={classes.errorText}>
                          {t("please_upload_a_file_smaller_than_two_mb")}
                        </p>
                      ) : (
                        (data?.url_original_file == null ||
                          data?.url_original_file == "") && (
                          <p className={classes.errorText}>
                            {t("file_required")}
                          </p>
                        )
                      )}
                    </>
                  )} */}
                </label>
              </div>
              <div className={classes.InputWrap}>
                <div className={classes.FildBox}>
                  <p>{t("first_name")}</p>
                  <input
                    type="text"
                    onChange={onChangeOfInput}
                    name="first_name"
                    defaultValue={data?.first_name}
                  />
                  {showError &&
                    (data?.first_name == null || data?.first_name == "") && (
                      <p className={classes.errorText}>
                        {t("first_name_required")}
                      </p>
                    )}
                </div>
                <div className={classes.FildBox}>
                  <p>{t("last_name")}</p>
                  <input
                    type="text"
                    onChange={onChangeOfInput}
                    name="last_name"
                    defaultValue={data?.last_name}
                  />
                  {/* {showError &&
                    (data?.last_name == null || data?.last_name == "") && (
                      <p className={classes.errorText}>
                        {t("last_name_required")}
                      </p>
                    )} */}
                </div>
              </div>
              <div className={classes.linkInputWrap}>
                <p>{t("email")}</p>
                <input
                  type="email"
                  name="email"
                  onChange={onChangeOfInput}
                  defaultValue={data?.email}
                />
                {showError && (data?.email == null || data?.email == "") && (
                  <p className={classes.errorText}>{t("email_required")}</p>
                )}
              </div>
              <div className={classes.changeRoleWrap}>
                <p>{t("change_role_to")}</p>
                <div className={classes.changeBtnWrap}>
                  <select id="role" name="Role">
                    <option value="option1">{`${t("Option1")}`}</option>
                    <option value="option2">{`${t("Option2")}`}</option>
                    <option value="option3">{`${t("Option3")}`}</option>
                  </select>
                  <button onClick={onClickEditButton}>{t("save")}</button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={classes.OrgSection}>
            <h6>{t("organisations_c")}</h6>
            <div className={classes.OrgContentWrap}>
              <table>
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("location")}</th>
                  <th>{t("role_c")}</th>
                </tr>
                {reduxState?.saveOrganizationListSingleUserData?.length > 0 ? (
                  reduxState?.saveOrganizationListSingleUserData?.map(
                    (item) => (
                      <tr onClick={() => setorg(item)}>
                        <td
                          className={
                            org.id == item.id
                              ? classes.selectedtd
                              : classes.normaltd
                          }
                        >
                          {item.name}
                        </td>
                        <td
                          className={
                            org.id == item.id
                              ? classes.selectedtd
                              : classes.normaltd
                          }
                        >
                          {item.city}
                        </td>
                        <td
                          className={
                            org.id == item.id
                              ? classes.selectedtd
                              : classes.normaltd
                          }
                        >
                          {item.role}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={3}>
                      <h3>{t("no_user_data")}</h3>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className={classes.OrgBtnWrap}>
              <button onClick={() => toast.success("will be added soon")}>
                {t("change_role")}
                <img src={RoleChangeIcon} />
              </button>
              <button
                onClick={() => {
                  if (org.name) {
                    setDeleteUser(true);
                  } else {
                    toast.warn(t("please_select_org"));
                  }
                }}
              >
                {t("remove_from_org")}
                <img src={DeleteIcon} />
              </button>
            </div>
            <div className={classes.linkInputWrap}>
              <p>{t("change_role_to")}</p>
              <input type="text" disabled />
            </div>
            <div className={classes.EditbtnWrap}>
              <button onClick={onClickEditButton}>
                {" "}
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {t("edit")}
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <ConfirmationModal
        showmodel={deleteUser}
        handleClose={() => setDeleteUser(false)}
        size={"sm"}
        onClickYesButton={onClickremoveOrg}
        title={`You are about to remove User from ${org?.name}`}
      ></ConfirmationModal>
    </>
  );
};

export default EditUser;
