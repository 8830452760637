import React, { useEffect, useState } from "react";
import LanguageManagementHeader from "../common/LanguageManagementHeader";
import MainLayount from "../common/MainLayout";
import classes from "./translationmain.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddEditTranslationApiCall,
  actionUpdateLangLabelListAPICall,
} from "../../Redux/Actions";
import Loader from "../common/Loader";
import TranslationHeader from "../common/TranslationHeader";

const TranslationMain = () => {
  const saveTranslationColumn = useSelector(
    (state) => state.CommonReducer?.saveTranslationColumn
  );
  const loader = useSelector((state) => state.CommonReducer.loader);
  const lang = useSelector((state) => state.CommonReducer.language);
  const state = useSelector((state) => state.CommonReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [translationName, setTranslationName] = useState();

  const onClickOfUpdate = (id, value, column, language, language_id) => {
    // const data = {
    //   id: id,
    //   label_value: name,
    // };
    // const body = { data, navigate };
    // dispatch(actionUpdateLangLabelListAPICall(body));

    const stringData = {
      column: column,
      value: value,
      language: language,
      language_id: language_id,
      translation: translationName,
    };
    let data = JSON.stringify(stringData);

    dispatch(
      actionAddEditTranslationApiCall({
        data,
        edit: id,
        navigate,
      })
    );
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.LanguageMain}>
          <TranslationHeader />
          <div className={classes.LanguageListingSection}>
            <table>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={3}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {saveTranslationColumn &&
                  saveTranslationColumn?.length > 0 ? (
                    saveTranslationColumn?.map((itme) => (
                      <tr>
                        <td style={{ maxWidth: "250px" }}>{itme.value}</td>
                        <td style={{ maxWidth: "250px" }}>
                          <div className={classes.fildBox}>
                            <textarea
                              placeholder={`${t("name_p")}...`}
                              value={
                                id == itme.id
                                  ? translationName
                                  : itme.translation
                              }
                              onChange={(e) => {
                                setTranslationName(e.target.value);
                                setId(itme.id);
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              onClickOfUpdate(
                                itme.id,
                                itme.value,
                                itme.column,
                                itme.language,
                                itme.language_id
                              )
                            }
                          >
                            {t("update")}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={3}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default TranslationMain;
