import React, { useState } from "react";
import classes from "./singlelistingheader.module.scss";
import { useTranslation } from "react-i18next";
import carteUserIcon from "../../../assets/icons/carteUserIcon.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import BlockIcon from "../../../assets/images/BlockIcon.svg";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionDeletePropertyAPICall,
  actionHideAllListingAPICall,
} from "../../../Redux/Actions";
import onlineIcon from "../../../assets/icons/onlineIcon.svg";

const SingleListingHeader = () => {
  const { t } = useTranslation();
  const [sidebar, setSideBar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleListData = useSelector(
    (state) => state.CommonReducer.saveSinglePropertyData
  );

  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
  };
  const onClickOfDelete = () => {
    const data = { id: [singleListData.id] };
    const body = { data, navigate, singleData: true };
    dispatch(actionDeletePropertyAPICall(body));
  };
  const OnClickOfBlock = () => {
    hideApiCall(singleListData.id, 1);
  };
  const OnClickOfUnBlock = () => {
    hideApiCall(singleListData.id, 0);
  };
  const hideApiCall = (id, value) => {
    const data = {
      property_id: [id],
      hide_property: value,
    };
    const body = { data, navigate, singleData: true };
    dispatch(actionHideAllListingAPICall(body));
  };
  return (
    <React.Fragment>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <div className={classes.containerHeader}>
        <div className={classes.CanvasWarp}>
          <button onClick={sidebarShow}>
            <GiHamburgerMenu />
          </button>
        </div>
        <div className={classes.BreadCrumbLayout}>
          <p
            className={classes.semiBoldLightText}
            onClick={() => navigate("/listing-management")}
          >
            {t("listing_management")}
          </p>
          <p className={classes.hyphen}>-</p>
          <p className={classes.semiBoldMedText}>{t("View listing")}</p>
        </div>
        <div className={classes.ActionOnUser}>
          <div className={classes.buttonLayout}>
            <button onClick={onClickOfDelete}>
              {t("delete_listing_small")}
              <img src={DelIcon} alt="" />
            </button>
          </div>
          <div className={classes.buttonLayout}>
            {singleListData.hide_property == 1 ? (
              <button onClick={OnClickOfUnBlock}>
                {t("online_listing")} <img src={onlineIcon} alt="" />
              </button>
            ) : (
              <button onClick={OnClickOfBlock}>
                {t("block_listing_small")}
                <img src={BlockIcon} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleListingHeader;
