import React, { useEffect, useState } from "react";
import classes from "./mastertablemanagementheadre.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import ModalMain from "../Modal";
import { useTranslation } from "react-i18next";
import CarteMasterTable from "../../../assets/icons/CarteMasterTable.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  actionMasterTableGetTranslationColumnApiCall,
  actionLangListAPICall,
  actionSavePerPageMasterTableCount,
  actionGetTranslationApiCall,
  actionGetTranslationColumnApiCall,
} from "../../../Redux/Actions";
import CrateTranslation from "../../ModalComponents/MasterTableManagementModal/CrateTranslation";

const MasterTableManagementHeadre = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [creatbanner, setCreateBanner] = useState(false);
  const state = useSelector((state) => state.CommonReducer);
  const [perpage, setPerPage] = useState(
    state?.perPageMasterTableCount != "" ? state?.perPageMasterTableCount : 40
  );
  const [lang, setLang] = useState(
    state?.saveTranslationLanguageFilter?.language
      ? state?.saveTranslationLanguageFilter?.language
      : "en"
  );
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const saveMasterTableColumn = useSelector(
    (state) => state.CommonReducer?.saveMasterTableColumn
  );
  const [translationColumn, setTransaltionColumn] =
    useState("applicable_options");
  const [name, setName] = useState();
  const params = useParams();

  // useEffect(() => {
  //   filterClick();
  //   dispatch(actionSavePerPageMasterTableCount(perpage));
  // }, [perpage]);

  useEffect(() => {
    filterClick();
  }, [perpage]);

  useEffect(() => {
    dispatch(actionLangListAPICall({ navigate }));
    dispatch(actionMasterTableGetTranslationColumnApiCall({ navigate }));
  }, []);

  useEffect(() => {
    if (state?.saveTranslationLanguageFilter?.language) {
      setLang(state?.saveTranslationLanguageFilter?.language);
    }
  }, [state?.saveTranslationLanguageFilter]);

  const sidebarShow = () => {
    setSideBar(true);
  };
  const createBannerShow = () => {
    setCreateBanner(true);
  };

  const handleClose = () => {
    setSideBar(false);
    setCreateBanner(false);
  };
  const filterClick = () => {
    // const data = {
    //   page: 1,
    //   search_text: name,
    //   per_page: perpage,
    //   lang: lang,
    //   column: params.column,
    // };

    // const body = { data, navigate };
    // dispatch(actionGetTranslationApiCall(body));

    const body = {
      navigate,
      search: name,
      page: 1,
      language: lang,
      column: params.column,
      per_page: perpage,
    };
    dispatch(actionGetTranslationColumnApiCall(body));
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t(`${params.column}`)}</h5>
            <form>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <input
                    type="text"
                    placeholder={`${t("search")}...`}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className={classes.fildBox}>
                  <select
                    id="status"
                    name="status"
                    value={lang}
                    onChange={(e) => setLang(e.target.value)}
                  >
                    {langdata?.map((item, index) => {
                      return (
                        <option
                          value={item.language}
                          selected={index == 0 ? true : false}
                        >
                          {item.language_label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* <div className={classes.fildBox}>
                  <select
                    id="status"
                    name="status"
                    onChange={(e) => setTransaltionColumn(e.target.value)}
                  >
                    {saveMasterTableColumn &&
                      saveMasterTableColumn?.length > 0 &&
                      saveMasterTableColumn?.map((item, index) => {
                        return (
                          <option
                            value={item.column}
                            selected={index == 0 ? true : false}
                          >
                            {item.column}
                          </option>
                        );
                      })}
                  </select>
                </div> */}
                <div className={classes.fildBox}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      filterClick();
                    }}
                  >
                    {t("filter")}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={createBannerShow}>
              {t("create") + " " + t(`${params.column}`)}
              <img src={CarteMasterTable} />
            </button>
          </div>
        </div>
        <div className={classes.BannersPerPageSection}>
          <p>{t("master_per_page")}</p>
          <div className={classes.numberOfUser}>
            <select
              id="page"
              name="page"
              onChange={(e) => setPerPage(e.target.value)}
            >
              <option value={40} selected={40 == perpage}>
                40
              </option>
              <option value={50} selected={50 == perpage}>
                50
              </option>
              <option value={60} selected={60 == perpage}>
                60
              </option>
              <option value={70} selected={70 == perpage}>
                70
              </option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ModalMain showmodel={creatbanner} handleClose={handleClose} size={"md"}>
        <CrateTranslation handleClose={handleClose} edit={false} />
      </ModalMain>
    </React.Fragment>
  );
};

export default MasterTableManagementHeadre;
