import React, { useEffect, useState } from "react";
import classes from "./cratebanner.module.scss";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import { actionAddBannerApiCall } from "../../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CreateBanner = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    start_date: "",
    end_date: "",
    is_default: 0,
    file: "",
    link: "",
    position: "",
    banner_id: "",
    link_target: "",
    imgaeData: "",
  });
  const close = props.onClose;
  const saveSingleBannerData = useSelector(
    (state) => state.CommonReducer.saveSingleBannerData
  );
  const loading = useSelector((state) => state.CommonReducer.loader);
  const [previewImg, setPreviewImg] = useState();
  const [showError, setShowError] = useState(false);
  const [imgSizeError, setImgSizeError] = useState(false);
  const positionArray = [
    { label: "Home Top (1920 × 766)", value: "Home Top" },
    { label: "Search Organisation (1920 × 613)", value: "Search Organisation" },
    { label: "Home Left (613 x 415)", value: "Home Left" },
    { label: "Home Right (613 x 415)", value: "Home Right" },
    { label: "Home Middle (1306 x 442)", value: "Home Middle" },
    { label: "Membership (613 x 415)", value: "Membership" },
    { label: "Thankyou (613 x 415)", value: "Thankyou" },
    { label: "Payment Failed (613 x 415)", value: "Payment Failed" },
    { label: "Home Ad 1 (260 x 208)", value: "Home Ad 1" },
    { label: "Home Ad 2 (260 x 208)", value: "Home Ad 2" },
    { label: "Home Ad 3 (260 x 208)", value: "Home Ad 3" },
    { label: "Home Ad 4 (260 x 208)", value: "Home Ad 4" },
    { label: "Search Ad 1 (260 x 208)", value: "Search Ad 1" },
    { label: "Search Ad 2 (260 x 208)", value: "Search Ad 2" },
    { label: "Search Ad 3 (260 x 208)", value: "Search Ad 3" },
    { label: "Search Ad 4 (260 x 208)", value: "Search Ad 4" },
    { label: "Listing View Ad 1 (260 x 208)", value: "Listing View Ad 1" },
    { label: "Listing View Ad 2 (260 x 208)", value: "Listing View Ad 2" },
    { label: "Listing View Ad 3 (260 x 208)", value: "Listing View Ad 3" },
    { label: "Listing View Ad 4 (260 x 208)", value: "Listing View Ad 4" },
  ];
  const linkTargetArray = [
    { value: "Same tab" },
    { value: "New tab" },
    { value: "New Window" },
  ];
  useEffect(() => {
    if (data.file != "" && props.edit == false) {
      const file = data.file;
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = handleImageLoad;
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }, [data.file]);
  useEffect(() => {
    if (props.edit == true) {
      setData({
        start_date: saveSingleBannerData.start_date,
        end_date: saveSingleBannerData.end_date,
        is_default: saveSingleBannerData.default,
        file: saveSingleBannerData.url_original_file,
        link: saveSingleBannerData.link,
        position: saveSingleBannerData.position,
        banner_id: saveSingleBannerData.id,
        link_target: saveSingleBannerData.link_target,
      });
    }
  }, [props, saveSingleBannerData]);
  useEffect(() => {
    if (data.imgaeData != "" && props.edit == false) {
      if (
        data.position == "Home Top" &&
        data.imgaeData.height < 766 &&
        data.imgaeData.width < 1920
      ) {
        setImgSizeError("minimum 1920 × 766 pixel image required");
      } else if (
        (data.position == "Home Left" || data.position == "Home Right") &&
        data.imgaeData.width < 613 &&
        data.imgaeData.height < 415
      ) {
        setImgSizeError("minimum 613 × 415 pixel image required");
      } else if (
        data.position == "Home Middle" &&
        data.imgaeData.width < 1306 &&
        data.imgaeData.height < 442
      ) {
        setImgSizeError("minimum 1306 × 766 pixel image required");
      } else if (
        data.position.includes("ad") &&
        data.imgaeData.width < 260 &&
        data.imgaeData.height < 208
      ) {
        setImgSizeError("minimum 260 × 208 pixel image required");
      } else {
        setImgSizeError("");
      }
    }
  }, [data.imgaeData, data.position]);
  const onChangeOfInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setData({ ...data, ["file"]: droppedFile });
    setPreviewImg(URL.createObjectURL(droppedFile));
  };

  const onClickEditButton = (e) => {
    e.preventDefault();
    setShowError(true);
    if (data.file && data.position && data.start_date && data.end_date) {
      let formdata = new FormData();
      formdata.append("start_date", data.start_date);
      formdata.append("end_date", data.end_date);
      formdata.append(
        "is_default",
        data.is_default == true ? 1 : data.is_default == false && 0
      );
      formdata.append("file", data.file);
      formdata.append("link", data.link);
      formdata.append("position", data.position);
      formdata.append("link_target", data.link_target);
      props.edit && formdata.append("banner_id", data.banner_id);
      const body = { formdata, navigate, close };
      dispatch(actionAddBannerApiCall(body));
    }
  };
  const handleImageLoad = (event) => {
    setData({
      ...data,
      imgaeData: { width: event.target.width, height: event.target.height },
    });
  };

  return (
    <div className={classes.CreateBannerMain}>
      <h3>{props.edit == true ? t("edit_banner") : t("create_new_banner")}</h3>
      <div className={classes.CreateBannerWrap}>
        <h6>{t("upload_banner_picture")}</h6>
        <form onSubmit={onClickEditButton}>
          <div
            className={classes.uploadMain}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              type="file"
              id="upload"
              accept=".jpg, .jpeg, .png,.webp,.svg"
              hidden
              name="file"
              onChange={(e) => {
                setData((prevCount1) => ({
                  ...prevCount1,
                  [e.target.name]: e.target.files[0],
                }));
                setPreviewImg(URL.createObjectURL(e.target.files[0]));
              }}
            />
            <label htmlFor="upload">
              <div className={classes.PhotoUpload}>
                {previewImg || data?.file ? (
                  <img
                    className={classes.img1}
                    src={previewImg ? previewImg : data.file}
                  />
                ) : (
                  <img src={FileUploadIcon} />
                )}
              </div>
              {showError && (data?.file == null || data?.file == "") && (
                <p className={classes.errorText}>{t("file_required")}</p>
              )}
              {imgSizeError != "" && (
                <p className={classes.errorText}>{imgSizeError}</p>
              )}
            </label>
          </div>
          <div className={classes.InputWrap}>
            <p>{t("position")}</p>
            <select
              id="status"
              name="position"
              onChange={onChangeOfInput}
              value={data.position}
            >
              <option value="" disabled selected>
                {`${t("position")}...`}
              </option>
              {positionArray.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
            {showError && (data?.position == null || data?.position == "") && (
              <p className={classes.errorText}>{t("position_required")}</p>
            )}
          </div>
          <div className={classes.FlexBox}>
            <div className={classes.InputWrap}>
              <p>{t("start_date")}</p>
              <input
                type="date"
                name="start_date"
                onChange={onChangeOfInput}
                value={data.start_date}
              />
              {showError &&
                (data?.start_date == null || data?.start_date == "") && (
                  <p className={classes.errorText}>
                    {t("start_date_required")}
                  </p>
                )}
            </div>
            <div className={classes.InputWrap}>
              <p>{t("end_date")}</p>
              <input
                type="date"
                name="end_date"
                onChange={onChangeOfInput}
                value={data.end_date}
              />
              {showError &&
                (data?.end_date == null || data?.end_date == "") && (
                  <p className={classes.errorText}>{t("end_date_required")}</p>
                )}
            </div>
          </div>
          <div className={classes.InputWrap}>
            <p>{t("redirection_link")}</p>
            <input
              type="text"
              name="link"
              onChange={onChangeOfInput}
              value={data.link}
            />
            {showError &&
              data?.link &&
              data?.link.length > 0 &&
              !new RegExp(
                "^(https?:\\/\\/)?" +
                  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                  "((\\d{1,3}\\.){3}\\d{1,3}))" +
                  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                  "(\\?[;&a-z\\d%_.~+=-]*)?" +
                  "(\\#[-a-z\\d_]*)?$",
                "i"
              ).test(data?.link) && (
                <p className={classes.errorText}>{t("invalid_website_name")}</p>
              )}
          </div>
          <div className={classes.InputWrap}>
            <p>{t("link_target")}</p>
            <select
              id="status"
              name="link_target"
              onChange={onChangeOfInput}
              value={data.link_target}
            >
              <option value="" disabled selected>
                {`${t("link_target")}...`}
              </option>
              {linkTargetArray.map((item) => {
                return <option value={item.value}>{item.value}</option>;
              })}
            </select>
          </div>
          <div className={classes.IsDefalutWrap}>
            <p>{t("Is_Default")}</p>
            <input
              type="checkbox"
              id="Default"
              name="is_default"
              onChange={(event) =>
                setData({ ...data, is_default: event.target.checked })
              }
              className={classes.radioCustom}
              checked={data.is_default == 1 ? true : false}
            />
            <label
              className={classes.radioCustomLabel}
              htmlFor="Default"
            ></label>
          </div>
          <div className={classes.btnWrap} onClick={onClickEditButton}>
            <button>
              {loading && (
                <span
                  style={{ marginRight: "5px" }}
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {t("Upload")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBanner;
