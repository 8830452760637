import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader = ({ banner }) => {
  return (
    <div>
      {banner ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
};

export default Loader;
