import React, { useEffect } from "react";
import classes from "./countrypartheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import { useState } from "react";
import {
  actionGetCountriesTableSystemApiCall,
  actionGetCountryPartTableSystemApiCall,
} from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import CountryPartForm from "../CountryPartForm";
import SideBar from "../SideBar";
import CustomSelect from "../CustomSelect";
const CountryPartHeader = ({ openCanvasB }) => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);
  const [headerSearch, setHeaderSearch] = useState({
    name: "",
    country_id: "",
  });
  const [sidebar, setSideBar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state.CommonReducer);

  const SelectItems = {
    name: "country_id",
    apiName: "country",
    placeholder: t("country"),
    options:
      state?.getCountryTable &&
      state?.getCountryTable?.map((p) => {
        return { label: p.name, value: p.id };
      }),
  };

  useEffect(() => {
    if (openCanvasB) {
      setOpenCanvas(openCanvasB);
    }
  }, [openCanvasB]);

  useEffect(() => {
    const body = { navigate, page: 1 };
    dispatch(actionGetCountriesTableSystemApiCall(body));
  }, []);

  const filterClick = () => {
    const body = {
      navigate,
      page: 1,
      search: headerSearch.name,
      country_id: headerSearch.country_id ? headerSearch.country_id?.value : "",
    };

    dispatch(actionGetCountryPartTableSystemApiCall(body));
  };

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>

          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")}{" "}
              {t("country_part")}
            </h5>

            <form onSubmit={onClickOfButton} className={classes.managementFild}>
              <div className={classes.fildBox}>
                <input
                  type="text"
                  placeholder={`${t("name_p")}...`}
                  onChange={(e) =>
                    setHeaderSearch({
                      ...headerSearch,
                      name: e.target.value,
                    })
                  }
                />
              </div>

              <div className={classes.fildBox}>
                <CustomSelect
                  isClearable={true}
                  item={SelectItems}
                  apiName={SelectItems.apiName}
                  isInputChange={true}
                  headerSearch={headerSearch}
                  setHeaderSearch={setHeaderSearch}
                />
              </div>

              <div className={classes.fildBox} onClick={onClickOfButton}>
                <button>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        width="md"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <CountryPartForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default CountryPartHeader;
