import React, { useEffect } from "react";
import classes from "./classificationheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import ClassificationForm from "../ClassificationForm";
import { useState } from "react";
import SideBar from "../SideBar";

const ClassificationHeader = () => {
  const [openCanvas, setOpenCanvas] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span>{" "}
              {t("View Classifications")}
            </h5>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => setOpenCanvas(false)}
      >
        <ClassificationForm
          editData={null}
          handleClose={() => setOpenCanvas(false)}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default ClassificationHeader;
