import React, { useState } from "react";
import classes from "./singleuserheader.module.scss";
import { useTranslation } from "react-i18next";
import carteUserIcon from "../../../assets/icons/carteUserIcon.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import BlockIcon from "../../../assets/images/BlockIcon.svg";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import {
  actionBlockUserApiCall,
  actionDeleteUserApiCall,
  actionUnblockUserApiCall,
} from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import onlineIcon from "../../../assets/icons/onlineIcon.svg";

const SingleUserHeader = () => {
  const { t, i18n } = useTranslation();
  const userData = useSelector((state) => state.CommonReducer.singleUserData);
  const [sidebar, setSideBar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
  };
  const onCLickBlockUser = () => {
    const data = {
      users_id: [userData.id],
    };
    const body = { data, navigate, status: "blocked" };
    dispatch(actionBlockUserApiCall(body));
  };
  const onCLickUnBlockUser = () => {
    const data = {
      users_id:[userData.id],
    };
    const body = { data, navigate, status:"online"};
    dispatch(actionUnblockUserApiCall(body));
  };
  const onCLickDeleteUser = () => {
    const data = {
      users_id: [userData.id],
    };
    const body = { data, navigate, singledata: true };
    dispatch(actionDeleteUserApiCall(body));
  };

  return (
    <React.Fragment>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <div className={classes.containerHeader}>
        <div className={classes.CanvasWarp}>
          <button onClick={sidebarShow}>
            <GiHamburgerMenu />
          </button>
        </div>
        <div className={classes.BreadCrumbLayout}>
          <p
            className={classes.semiBoldLightText}
            onClick={() => navigate("/user-management")}
          >
            {t("user_management")}
          </p>
          <p className={classes.hyphen}>-</p>
          <p className={classes.semiBoldMedText}>{t("View User")}</p>
        </div>
        <div className={classes.ActionOnUser}>
          <div className={classes.buttonLayout}>
            <button onClick={onCLickDeleteUser}>
              {t("delete_user")}
              <img src={DelIcon} alt="" />
            </button>
          </div>
          <div className={classes.buttonLayout}>
            {userData.status == "online" && (
              <button onClick={onCLickBlockUser}>
                {t("block_user")}
                <img src={BlockIcon} alt="" />
              </button>
            )}
            {userData.status == "blocked" && (
              <button onClick={onCLickUnBlockUser}>
                {t("online_user")}
                <img src={onlineIcon} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleUserHeader;
