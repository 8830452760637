import React, { useState } from "react";
import MainLayount from "../common/MainLayout";
import classes from "./emailmanagement.module.scss";
import EmailManagmentHeader from "../common/EmailManagmentHeader";
import { useTranslation } from "react-i18next";
import { BannerManagementData } from "../../data";
import ModalMain from "../common/Modal";
import EditEmailTemplate from "../ModalComponents/EmailManagementModal/EditEmailTemplate";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";
import { actionViewEmailApiCall } from "../../Redux/Actions";

const EmailManagementMain = () => {
  const emailList = useSelector((state) => state.CommonReducer.emailListData);
  const loader = useSelector((state) => state.CommonReducer.loader);

  const { t } = useTranslation();
  const [editemail, setEmailEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editEmailShow = (id) => {
    let data = id;
    let body = { data, navigate };
    dispatch(actionViewEmailApiCall(body));
    setEmailEdit(true);
  };

  const handleClose = () => {
    setEmailEdit(false);
  };

  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.EmailMain}>
          <EmailManagmentHeader />
          <div className={classes.EmailListingSection}>
            <table>
              <tr>
                <th>{t("title")}</th>
                <th>{t("subject")}</th>
                <th>{t("action_c")}</th>
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {emailList && emailList?.length > 0 ? (
                    emailList.map((item) => (
                      <tr>
                        <td>{item?.title}</td>
                        <td>{item?.subject}</td>
                        <td>
                          <button onClick={() => editEmailShow(item.id)}>
                            {t("edit")}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={6}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
        <ModalMain showmodel={editemail} handleClose={handleClose} size={"lg"}>
          <EditEmailTemplate edit={true} handleClose={handleClose} />
        </ModalMain>
      </MainLayount>
    </React.Fragment>
  );
};

export default EmailManagementMain;
