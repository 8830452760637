// import React, { useEffect, useRef, useState } from "react";
// import classes from "./emailtemplate.module.scss";
// import { useTranslation } from "react-i18next";
// import EditIcon from "../../../assets/images/EditLogo.svg";
// import SearchIcon from "../../../assets/images/SearchIcon.svg";
// import moment from "moment";

// import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import LogForm from "../../common/LogForm";
// import {
//   getCreatedTemplateApiCall,
// } from "../../../Redux/Actions";
// import ViewProfile from "../../common/ViewProfile";
// import CustomSelect from "../../common/CustomSelect";
// import ModalMain from "../../common/Modal";
// import CreateTag from "../CreateTag";
// import CreateTemplate from "../CreateTemplate/Index";

// const EmailTemplate = () => {
//   const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const Templates = useSelector(
//     (state) => state.CommonReducer.savedNewTemplates
//   );
//   console.log(Templates,"templates from selector")
//   const { t } = useTranslation();
//   const [templateSearch, setTemplateSearch] = useState();
//   const [filteredTemplates, setFilteredTemplates] = useState([]);
//   let pathname = useLocation().pathname;
//   pathname = pathname.substring(pathname.lastIndexOf("/") + 1);

//   const [windowSize, setWindowSize] = useState(getWindowSize());

//   useEffect(() => {
//     function handleWindowResize() {
//       setWindowSize(getWindowSize());
//     }

//     window.addEventListener("resize", handleWindowResize);

//     return () => {
//       window.removeEventListener("resize", handleWindowResize);
//     };
//   }, [window]);

//   function getWindowSize() {
//     return window.innerWidth;
//   }
//   useEffect(() => {
//     if (Templates) {
//       setFilteredTemplates(
//         Templates?.filter((template) =>
//           template?.title?.toLowerCase().includes(templateSearch?.toLowerCase())
//         )
//       );
//     }
//   }, [Templates, templateSearch]);
//   const handleEdit = (id, selectedValue) => {
//     // if (selectedValue === "edit") {
//     //   const selectedTag = Tags.find((tag) => tag.id === id);
//     //   if (selectedTag) {
//     //     // Set the tag value to state
//     //     setTagValue(selectedTag.tag_name);
//     //     setTagId(id);
//     //   }
//     //   setEdit(!edit);
//     // } else if (selectedValue === "Delete") {
//     //   console.log("delete dispatched", id);
//     //   dispatch(deleteTagRequest(id));
//     //   setTimeout(() => {
//     //     dispatch(actionGetAllTagsApiCall());
//     //   }, 2000);
//     // }
//   };

//   const handleModal = () => {
//     setOpen(!open);
//   };
//   useEffect(() => {
//     dispatch(getCreatedTemplateApiCall())
//   },[])
//   return (
//     <React.Fragment>
//       <div className={classes.detailPage}>
//         <div className={classes.container}>
//           <div className={classes.address}>
//             <div className={classes.headerwrap}>
//               <p>{t("Template")}</p>
//               <button onClick={handleModal}>Create New</button>
//             </div>
//             <div className={classes.addressContainer}>
//               <div className={classes.tableContainer}>
//                 <div className={classes.SearchBar}>
//                   <img src={SearchIcon} alt="" />
//                   <input
//                     type="search"
//                     placeholder="Search Template"
//                     value={templateSearch}
//                     onChange={(e) => setTemplateSearch(e.target.value)}
//                   />
//                 </div>
//                 <div className={classes.TableListngLayOut}>
//                   <table>
//                     <tr>
//                       <th></th>
//                       <th>{t("Template")}</th>
//                       <th>{t("Created")}</th>
//                       <th>{t("Actions")}</th>
//                       <th></th>
//                     </tr>
//                     {filteredTemplates?.length > 0 ? (
//                           filteredTemplates?.map((item) => (
                        //     <tr>
                        //       <td>
                        //         <p></p>
                        //       </td>
                        //       <td>
                        //         <span className={classes.title}>
                        //           <h6>{item?.title}</h6>
                        //         </span>
                        //       </td>
                        //       <td>
                        //         <span>{moment(item?.created_at).format('LL')}</span> <br />
                        //         <span>Created date</span>
                        //       </td>
                        //       <td>
                        //         <select
                        //           className={classes.select}
                        //           onChange={(e) =>
                        //             handleEdit(item.id, e.target.value)
                        //           }
                        //         >
                        //           <option value="options">Options</option>
                        //           <option value="edit">Edit</option>
                        //           <option>Delete</option>
                        //         </select>
                        //       </td>
                        //     </tr>
                        //   ))
                        // ) : (
//                           <tr>
//                             <td>
//                               <p></p>
//                             </td>
//                             <p>No data</p>
//                           </tr>
//                         )}
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ModalMain
//         showmodel={open}
//         handleClose={() => setOpen(false)}
//         size={"xl"}
//       >
//         <CreateTemplate onClose={() => setOpen(false)} edit={true}/>
//         {/* <CreateTag  /> */}
//       </ModalMain>
//     </React.Fragment>
//   );
// };

// export default EmailTemplate;

import React, { useEffect, useState } from "react";
import classes from "./emailtemplate.module.scss";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllTemplatesApiCall, deleteTemplateRequest, getCreatedTemplateApiCall } from "../../../Redux/Actions";
import ModalMain from "../../common/Modal";
import CreateTemplate from "../CreateTemplate/Index";
import EditTemplate from "../EditTemplate";

const EmailTemplate = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [templateValue, setTemplateValue] = useState(null)
  const [templateId, setTemplateId] = useState(null)
  const [templateContent, setTemplateContent] = useState(null)
  const [selectedValue, setSelectedValue] = useState("options");
  const Templates = useSelector(
    (state) => state.CommonReducer.savedNewTemplates
  );


  const { t } = useTranslation();
  const [templateSearch, setTemplateSearch] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState("");

  useEffect(() => {
    dispatch(getCreatedTemplateApiCall());
  }, [dispatch]);

  useEffect(() => {

    setFilteredTemplates(
      Templates && Templates.filter((template) =>
        template?.title?.toLowerCase().includes(templateSearch?.toLowerCase())
      )
    );
  }, [Templates, templateSearch]);

  const handleEdit = (id, selectedValue) => {
    // handle edit logic
    if (selectedValue === "edit") {
      const selectedTemplate = Templates.find((tag) => tag.id === id);

      if (selectedTemplate) {
        // Set the tag value to state
        setTemplateValue(selectedTemplate.title);
        setTemplateContent(selectedTemplate.content)
        setTemplateId(id);
      }
      setEdit(!edit);
    } else if (selectedValue === "Delete") {

      dispatch(deleteTemplateRequest(id));
      setTimeout(() => {
        dispatch(getCreatedTemplateApiCall());
      }, 2000);
    }
    setSelectedValue("options");
  };

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <div className={classes.detailPage}>
        <div className={classes.container}>
          <div className={classes.address}>
            <div className={classes.headerwrap}>
              <p>{t("Template")}</p>
              <button onClick={handleModal}>Create New</button>
            </div>
            <div className={classes.addressContainer}>
              <div className={classes.tableContainer}>
                <div className={classes.SearchBar}>
                  <img src={SearchIcon} alt="" />
                  <input
                    type="search"
                    placeholder="Search Template"
                    value={templateSearch}
                    onChange={(e) => setTemplateSearch(e.target.value)}
                  />
                </div>
                <div className={classes.TableListngLayOut}>
                  <table>
                    <tr>
                      <th></th>
                      <th>{t("Template")}</th>
                      <th>{t("Created")}</th>
                      <th>{t("Actions")}</th>
                      <th></th>
                    </tr>
                    {filteredTemplates && filteredTemplates?.length > 0 ? (
                      filteredTemplates?.map((item) => (
                        <tr>
                        <td>
                          <p></p>
                        </td>
                        <td>
                          <span className={classes.title}>
                            <h6>{item?.title}</h6>
                          </span>
                        </td>
                        <td>
                          <span>{moment(item?.created_at).format('LL')}</span> <br />
                          <span>Created date</span>
                        </td>
                        <td>
                          <select
                            className={classes.select}
                            onChange={(e) =>
                              handleEdit(item?.id, e.target.value)
                            }
                            value={selectedValue}
                          >
                            <option value="options" >Options</option>
                            <option value="edit">Edit</option>
                            <option >Delete</option>
                          </select>
                        </td>
                      </tr>
                    ))
                  )  : (
                      <tr>
                        <td colSpan={5}>No data</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalMain
      id= "modal-container"
      autofocus={false}
      backdrop="static"
        keyboard={false}
        showmodel={open}
        handleClose={() => setOpen(false)}
        size={"xl"}
      >
        <CreateTemplate onClose={() => setOpen(false)} edit={true} />
      </ModalMain>
      <ModalMain
        showmodel={edit}
        handleClose={() => setEdit(false)}
        size={"xl"}
      >
      <EditTemplate 
      onClose={() => setEdit(false)} 
      edit={true}
      templateValue = {templateValue}
      templateContent = {templateContent}
      templateId={templateId}
      />

      </ModalMain>
    </React.Fragment>
  );
};

export default EmailTemplate;
