import React, { useEffect, useState } from "react";
import classes from "./countriesform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionBanchNestedGetApi,
  actionCountriesAddApiCall,
} from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CountriesForm = ({ editData, handleClose }) => {
  const { t } = useTranslation();
  const buttonloader = useSelector((state) => state.CommonReducer.buttonloader);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    image: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    code: "",
    image: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (editData) {
      setFormData({
        name: editData?.name || "",
        code: editData?.code || "",
        description: editData?.description || "",
        image: editData?.slider_image || "",
      });
    }
  }, [editData]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: "", code: "", image: "" };

    if (formData.name.trim() === "") {
      newErrors.name = t("name_required");
      valid = false;
    }
    if (formData.code === "") {
      newErrors.code = t("code") + " " + t("required");
      valid = false;
    }
    if (formData.image === "") {
      newErrors.image = t("image_required");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("code", formData.code);
      data.append("description", formData.description);
      data.append(
        "file",
        formData["image 1"] ? formData["image 1"] : formData["image"]
      );
      const closePopup = handleClose;
      dispatch(
        actionCountriesAddApiCall({
          data,
          closePopup,
          edit: editData ? editData.id : false,
          navigate,
        })
      );
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/svg+xml",
      ];
      if (allowedTypes.includes(file.type)) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          toast.error("Image size must not exceed 2 MB.");
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            setFormData({
              ...formData,
              [`${event.target.name} 1`]: file,
              [event.target.name]: e.target.result,
            });
          };
          setErrors({ ...errors, [event.target.name]: "" });
          reader.readAsDataURL(file);
        }
      } else {
        toast.error("Invalid file type. Please select a valid image file.");
      }
    }
  };
  const clearImage = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };
  return (
    <React.Fragment>
      <div className={classes.branchesFormSection}>
        {editData ? (
          <h3>
            {t("edit")} {t("country")}
          </h3>
        ) : (
          <h3>
            {t("add")} {t("country")}
          </h3>
        )}
        <div className={classes.inputsWrapper}>
          <div className={classes.Text}>
            <label htmlFor="name">{t("countriename")}</label>
            <input
              type="text"
              name="name"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.name}
            />
            {errors.name && <p className={classes.error}>{errors.name}</p>}
          </div>
          <div className={classes.Text}>
            <label htmlFor="code">{t("code")}</label>
            <input
              type="text"
              name="code"
              onChange={handleInputChange}
              onBlur={validateForm}
              value={formData.code}
            />
            {errors.code && <p className={classes.error}>{errors.code}</p>}
          </div>
          <div className={classes.Text}>
            <label htmlFor="name">{t("description")}</label>
            <textarea
              name="description"
              onChange={handleInputChange}
              value={formData.description}
              onBlur={validateForm}
              rows="4"
            />
          </div>
          <div className={classes.uploadPicWrapper}>
            <div className={classes.imageprview}>
              <label htmlFor="file-upload1">{t("slider_image")}</label>
              <div className={`${classes.preview} `}>
                {formData.image && formData.image != "null" ? (
                  <div className={`${classes.innerpreview} `}>
                    <img src={formData.image} alt="Profile Preview" />
                    {formData.image && (
                      <div
                        className={classes.cross}
                        onClick={() => clearImage("image")}
                      >
                        <IoClose />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={`${classes.innerpreview}`}>
                    <p>
                      Slider <br />
                      image
                    </p>

                    {formData.image && (
                      <div
                        className={classes.cross}
                        onClick={() => clearImage("image")}
                      >
                        <IoClose />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className={classes.upload}>
              <div className={classes.uploadImg}>
                <label htmlFor="file-upload1">
                  <img src={upload} alt="Upload" />
                </label>
                <input
                  type="file"
                  id="file-upload1"
                  accept=".jpg, .jpeg, .png,.webp,.svg"
                  style={{ display: "none" }}
                  name="image"
                  onChange={handleImageChange}
                />
              </div>

              <div className={classes.labelupload}>
                <label htmlFor="file-upload1">Upload New</label>
              </div>
            </div>
          </div>
          {errors.image && <p className={classes.error}>{errors.image}</p>}
        </div>

        <div className={classes.btnwrapper}>
          <div className={classes.btnWrapperShift}>
            {editData ? (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {buttonloader ? <Loader /> : `${t("edit")}`}
                </button>
              </>
            ) : (
              <>
                <button className={classes.btncancel} onClick={handleClose}>
                  {t("cancel")}
                </button>
                <button className={classes.btnadd} onClick={handleSubmit}>
                  {buttonloader ? <Loader /> : `${t("add")} ${t("country")}`}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CountriesForm;
