import React, { useState, useEffect } from "react";
import classes from "./emailmanagmentheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setperPageEmailCount,
  actionEmailListApiCall,
  actionLangListAPICall,
} from "../../../Redux/Actions";
import CardEmailIcon from "../../../assets/icons/CardEmailIcon.svg";
import ModalMain from "../Modal";
import EditEmailTemplate from "../../ModalComponents/EmailManagementModal/EditEmailTemplate";
const EmailManagmentHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [lang, setLang] = useState("en");
  const state = useSelector((state) => state.CommonReducer);
  const [perpage, setPerPage] = useState(
    state?.perPageEmailCount ? state?.perPageEmailCount : 40
  );
  const [subject, setSubject] = useState("");
  const [editemail, setEditEmail] = useState("");
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    filterClick();
    dispatch(setperPageEmailCount(perpage));
  }, [perpage]);

  useEffect(() => {
    const body = { navigate };
    dispatch(actionLangListAPICall(body));
  }, []);

  const filterClick = () => {
    const data = {
      page: 1,
      subject: subject,
      per_page: perpage,
      lang: lang,
    };
    const body = { data, navigate };
    dispatch(actionEmailListApiCall(body));
  };
  const sidebarShow = () => {
    setSideBar(true);
  };

  const handleClose = () => {
    setSideBar(false);
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("email_management")}</h5>
            <form>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <input
                    type="text"
                    placeholder={`${t("search")}...`}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className={classes.fildBox}>
                  <select
                    id="status"
                    name="status"
                    onChange={(e) => setLang(e.target.value)}
                  >
                    {langdata?.map((item, index) => {
                      return (
                        <option
                          value={item.language}
                          selected={index == 0 ? true : false}
                        >
                          {item.language_label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={classes.fildBox}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      filterClick();
                    }}
                  >
                    {t("filter")}
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setEditEmail(true)}>
              {t("create_email_template")}
              <img src={CardEmailIcon} />
            </button>
          </div> */}
        </div>
        <div className={classes.EmainPerPageSection}>
          <p>{t("email_per_page")}</p>
          <div className={classes.numberOfUser}>
            <select
              id="cars"
              name="cars"
              onChange={(e) => setPerPage(e.target.value)}
            >
              <option value={40} selected={40 == perpage}>
                40
              </option>
              <option value={50} selected={50 == perpage}>
                50
              </option>
              <option value={60} selected={60 == perpage}>
                60
              </option>
              <option value={70} selected={70 == perpage}>
                70
              </option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ModalMain
        showmodel={editemail}
        handleClose={() => setEditEmail(false)}
        size={"md"}
      >
        <EditEmailTemplate handleClose={() => setEditEmail(false)} />
      </ModalMain>
    </React.Fragment>
  );
};

export default EmailManagmentHeader;
