import { t } from "i18next";
const Validate = (name, value, isSubmit = false, obj = {}, passwordData) => {
  let data = {};
  let errors = {};
  if (!isSubmit) {
    data = { [name]: value };
  } else {
    data = obj;
  }
  Object.keys(data).forEach((item) => {
    switch (item) {
      case "type":
        if (!data[item]?.length > 0) {
          errors.type = t("type_required");
        }
        break;
      case "name":
        if (data[item]?.length > 0) {
          if (!data[item].trim()) {
            errors.name = t("name_invalid");
          }
        } else {
          errors.name = t("name_required");
        }
        break;
      case "price":
        if (data[item]) {
          if (data[item] < 1) {
            errors.price = t("price_invalid");
          }
        } else {
          errors.price = t("price_required");
        }
        break;
      case "valid_from":
        if (!data[item]) {
          errors.valid_from = t("start_date_required");
        } else {
          if (data[item] > passwordData) {
            errors.valid_from = t("start_date_must_be_before_end_date");
          }
        }
        break;
      case "valid_until":
        if (!data[item]) {
          errors.valid_until = t("end_date_required");
        } else {
          if (data[item] < passwordData) {
            errors.valid_until = t("end_date_must_be_after_start_date");
          }
        }
        break;
    }
  });
  return errors;
};
export default Validate;
