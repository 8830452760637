import React from "react";
import OrganisationManagementMain from "../../components/OrganisationManagementMain/indedx";

const OrganisationManagementPage = () => {
 
  return (
    <React.Fragment>
      <OrganisationManagementMain />
    </React.Fragment>
  );
};

export default OrganisationManagementPage;
