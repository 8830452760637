import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./cityheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import OffcanvasMain from "../Offcanvas";
import CityForm from "../CityForm";
import { useDispatch, useSelector } from "react-redux";
import { actionGetCitySystemApiCall } from "../../../Redux/Actions";
import CustomSelect from "../CustomSelect";
import SideBar from "../SideBar";
const CityHeader = () => {
  const state = useSelector((state) => state?.CommonReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openCanvas, setOpenCanvas] = useState(false);
  const [sidebar, setSideBar] = useState(false);
  const [headerSearch, setHeaderSearch] = useState({
    name: "",
    municipality_id: "",
  });
  const navigate = useNavigate();
  const SelectItems = [
    {
      placeholder: t("municipality"),
      name: "municipality_id",
      options:
        state?.getMunicipalityTable &&
        state?.getMunicipalityTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "municipality",
    },
  ];

  const filterClick = () => {
    const body = {
      navigate,
      page: 1,
      search: headerSearch.name,
      municipality_id: headerSearch.municipality_id
        ? headerSearch.municipality_id.value
        : "",
      edit: false,
    };
    dispatch(actionGetCitySystemApiCall(body));
  };
  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };
  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")} {t("cities")}
            </h5>
            <form className={classes.managementFild} onSubmit={onClickOfButton}>
              <div className={classes.fildBox}>
                <input
                  placeholder={t("name_p")}
                  onChange={(e) => {
                    setHeaderSearch({
                      ...headerSearch,
                      name: e.target.value,
                    });
                  }}
                />
              </div>

              {SelectItems.map((item) => {
                return (
                  <div className={classes.fildBox}>
                    <CustomSelect
                      isClearable={true}
                      item={item}
                      apiName={item.apiName}
                      isInputChange={true}
                      headerSearch={headerSearch}
                      setHeaderSearch={setHeaderSearch}
                    />
                  </div>
                );
              })}
              <div className={classes.fildBox} onClick={onClickOfButton}>
                <button>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        width="md"
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <CityForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default CityHeader;
