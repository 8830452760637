import React, { useEffect, useState } from "react";
import classes from "./usermanagmentheadre.module.scss";
import { useTranslation } from "react-i18next";
import createUserIcon from "../../../assets/icons/carteUserIcon.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import ModalMain from "../Modal";
import CreateUserMain from "../../ModalComponents/UserManagementModals/CreateUser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionBlockUserApiCall,
  actionDeleteUserApiCall,
  actionUnblockUserApiCall,
  actionUserListApiCall,
  setperPageUserCount,
} from "../../../Redux/Actions";
import ConfirmationModal from "../ConfirmationModal";
import BlockIcon from "../../../assets/images/BlockIcon.svg";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import onlineIcon from "../../../assets/icons/onlineIcon.svg";
import { toast } from "react-toastify";

const UserManagmentHeadre = (props) => {
  const { t } = useTranslation();
  const state = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [createuser, setCreateUser] = useState(false);
  const [deleteuser, setdeleteUser] = useState(false);
  const [blockuser, setblockUser] = useState(false);
  const [unblockuser, setUnBlockUser] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [name, setName] = useState();
  const [perpage, setPerPage] = useState(
    state?.perPageUserCount ? state?.perPageUserCount : 40
  );

  useEffect(() => {
    filterClick();
    dispatch(setperPageUserCount(perpage));
  }, [perpage]);

  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
    setCreateUser(false);
  };

  const createuserShow = () => {
    setCreateUser(true);
  };
  const onCLickBlockUser = () => {
    const data = {
      users_id: props.selectedUser,
    };
    const body = { data, navigate };
    dispatch(actionBlockUserApiCall(body));
  };
  const filterClick = () => {
    const data = {
      page: 1,
      name: name,
      per_page: perpage,
      status: selectedOption,
    };
    const body = { data, navigate };
    dispatch(actionUserListApiCall(body));
  };

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };

  const onCLickDeleteUser = () => {
    const data = {
      users_id: props.selectedUser,
    };
    const body = { data, navigate };
    dispatch(actionDeleteUserApiCall(body));
  };
  const onCLickUnBlockUser = () => {
    const data = {
      users_id: props.selectedUser,
    };
    const body = { data, navigate };
    dispatch(actionUnblockUserApiCall(body));
  };
  return (
    <React.Fragment>
      <div className={classes.HeaderWrap} onClick={props.ClosePopup}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("user_management")}</h5>
            <form onSubmit={onClickOfButton}>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <input
                    type="text"
                    placeholder={`${t("name_p")}...`}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className={classes.fildBox}>
                  <select
                    id="status"
                    name="status"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option value="" disabled selected>
                      {`${t("status")}...`}
                    </option>
                    <option value={t("online")}>{t("online")}</option>
                    <option value={t("blocked")}>{t("blocked")}</option>
                  </select>
                </div>

                <button onClick={filterClick}>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagUserSection}>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_user")}`);
                } else {
                  setUnBlockUser(true);
                }
              }}
            >
              {t("online_user_small")}
              <img src={onlineIcon} alt="" />
            </button>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_user")}`);
                } else {
                  setblockUser(true);
                }
              }}
            >
              {t("block_user_small")}
              <img src={BlockIcon} alt="" />
            </button>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_user")}`);
                } else {
                  setdeleteUser(true);
                }
              }}
            >
              {t("delete_user_small")}
              <img src={DelIcon} alt="" />
            </button>
            <button onClick={createuserShow}>
              {t("create_user")}
              <img src={createUserIcon} />
            </button>
          </div>
        </div>
        <div className={classes.UsersPerPageSection}>
          <p>{t("users_per_page")}</p>
          <div className={classes.numberOfUser}>
            <select
              id="cars"
              name="cars"
              onChange={(e) => setPerPage(e.target.value)}
            >
              <option value={40} selected={40 == perpage}>
                40
              </option>
              <option value={50} selected={50 == perpage}>
                50
              </option>
              <option value={60} selected={60 == perpage}>
                60
              </option>
              <option value={70} selected={70 == perpage}>
                70
              </option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ModalMain showmodel={createuser} handleClose={handleClose} size={"md"}>
        <CreateUserMain handleClose={handleClose} />
      </ModalMain>
      <ConfirmationModal
        showmodel={deleteuser}
        handleClose={() => setdeleteUser(false)}
        size={"sm"}
        onClickYesButton={onCLickDeleteUser}
        title={`You are about to delete ${props.selectedUser.length} User(s)`}
      ></ConfirmationModal>
      <ConfirmationModal
        showmodel={unblockuser}
        handleClose={() => setUnBlockUser(false)}
        size={"sm"}
        onClickYesButton={onCLickUnBlockUser}
        title={`You are about to un-block ${props.selectedUser.length} User(s)`}
      ></ConfirmationModal>
      <ConfirmationModal
        showmodel={blockuser}
        handleClose={() => setblockUser(false)}
        size={"sm"}
        onClickYesButton={onCLickBlockUser}
        title={`You are about to block ${props.selectedUser.length} User(s)`}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default UserManagmentHeadre;
