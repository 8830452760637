import axios from "axios";
import { isAndroidWeb, isDesktop, isIosWeb } from "../utils/getSystem";
import APIUrl from "./APIUrl";
class API {
  instance;
  constructor() {
    this.instance = axios.create({
      baseURL: APIUrl.backendUrl,
    });
  }

  APICALL(methods, url, body, header) {
    let system = "";
    if (isAndroidWeb()) {
      system = "Android Web";
    } else if (isIosWeb()) {
      system = "iOS Web";
    } else if (isDesktop()) {
      system = "Desktop Computer";
    }
    const headerType =
      header == "header"
        ? {
            "Content-Type": "application/json",
            system: system,
            localization: localStorage.getItem("language")
              ? localStorage.getItem("language")
              : "en",
          }
        : header == "formHeader"
        ? {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
            system: system,
            localization: localStorage.getItem("language"),
            // maxBodyLength: Infinity,
          }
        : {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
            system: system,
            localization: localStorage.getItem("language"),
          };
    const config = {
      headers: headerType,
    };
    try {
      let response;
      if (methods == "post") {
        response = this.instance.post(
          `${APIUrl.backendUrl}/${url}`,
          body,
          config
        );
      } else if (methods == "get") {
        response = this.instance.get(`${APIUrl.backendUrl}/${url}`, config);
      } else if (methods == "delete") {
        response = this.instance.delete(`${APIUrl.backendUrl}/${url}`, config);
      } else if (methods == "put") {
        response = this.instance.put(
          `${APIUrl.backendUrl}/${url}`,
          body,
          config
        );
      } else if (methods == "patch") {
        response = this.instance.patch(
          `${APIUrl.backendUrl}/${url}`,
          body,
          config
        );
      }
      return response;
    } catch (error) {
      return error;
    }
  }
}
const apiInstance = new API();

export const LoginAPICall = async (body) => {
  return await apiInstance.APICALL("post", "login", body, "header");
};

//user apis
export const UserListAPICall = async (body) => {
  return await apiInstance.APICALL("post", "user-list", body, "authheader");
};

export const CreateUserAPICall = async (body) => {
  return await apiInstance.APICALL("post", "add-user", body, "formHeader");
};
export const ViewUserAPICall = async (body) => {
  return await apiInstance.APICALL("get", `view-user/${body}`, "authheader");
};
export const DeleteUserAPICall = async (body) => {
  return await apiInstance.APICALL("post", "delete-user", body, "authheader");
};
export const BlockUserAPICall = async (body) => {
  return await apiInstance.APICALL("post", "block-user", body, "authheader");
};

export const OrganizationListAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "organization-list",
    body,
    "authheader"
  );
};

export const ViewOrganizationAPICall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `view-organization/${body}`,
    "authheader"
  );
};

export const getToken = async (body) => {
  console.log(body,"skdfjksdfsd")
  return await apiInstance.APICALL(
    "get",
    `send-token/${body}`,
    "authheader"
  );
};

export const DeleteOrganizationAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "delete-organization",
    body,
    "authheader"
  );
};

export const BlockOrganizationAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "block-organization",
    body,
    "authheader"
  );
};

export const CreateOrganizationAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "create-organization",
    body,
    "formHeader"
  );
};


export const AddUserOrgAPICall = async (body) => {
  return await apiInstance.APICALL("post", "add-user-org", body, "authheader");
};

export const RemoveUserOrgAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "remove-user-org",
    body,
    "authheader"
  );
};

export const UpdateOrgAPICall = async (body, path) => {
  return await apiInstance.APICALL(
    "post",
    `update-organization/${path}`,
    body,
    "formHeader"
  );
};

export const ViewLogHistoryAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "viewLogHistory",
    body,
    "authheader"
  );
};

export const GetPropertyListAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "getPropertyList",
    body,
    "authheader"
  );
};

export const RemoveJoinedOrganisationAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "remove-joined-organisation",
    body,
    "authheader"
  );
};

export const ChangeAdminOrganisationAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "change-admin-org",
    body,
    "authheader"
  );
};

export const UnblockUserAPICall = async (body) => {
  return await apiInstance.APICALL("post", "unblock-user", body, "authheader");
};

export const UpdatePropertyVisibleAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "updatePropertyVisible",
    body,
    "authheader"
  );
};

export const GetPropertyDetailAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "getPropertyDetail",
    body,
    "authheader"
  );
};

export const EditPropertyStatusAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "editPropertyStatus",
    body,
    "authheader"
  );
};

export const GetPropertyViewsAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "getPropertyViews",
    body,
    "authheader"
  );
};

export const GetPriceHistoryAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "getPriceHistory",
    body,
    "authheader"
  );
};

export const HideAllListingAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "hideAllListing",
    body,
    "authheader"
  );
};

export const SendPasswordLinkAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "sendPasswordLink",
    body,
    "authheader"
  );
};

export const LangListAPICall = async () => {
  return await apiInstance.APICALL("get", `lang-list`, "authheader");
};

export const LangLabelListAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "lang-label-list",
    body,
    "authheader"
  );
};

export const UpdateLangLabelValueAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "update-lang-label-value",
    body,
    "authheader"
  );
};
export const ChangeAdminPropertyAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "changeAdminProperty",
    body,
    "authheader"
  );
};
export const DeletePropertyAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "deleteProperty",
    body,
    "authheader"
  );
};

export const EditPropertyAPICall = async (body) => {
  return await apiInstance.APICALL("post", "editProperty", body, "formHeader");
};

export const LogoutAPICall = async () => {
  return await apiInstance.APICALL("get", `logout`, "authheader");
};

export const OrgApproveAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "approveOrganization",
    body,
    "authheader"
  );
};

export const UnblockOrgAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "unblock-organization",
    body,
    "authheader"
  );
};

export const BannerListAPICall = async (body) => {
  return await apiInstance.APICALL("post", "banner-list", body, "authheader");
};

export const AddBannerAPICall = async (body) => {
  return await apiInstance.APICALL("post", "add-banner", body, "formHeader");
};

export const DeleteBannerAPICall = async (body) => {
  return await apiInstance.APICALL("post", "delete-banner", body, "authheader");
};

export const ViewBannerAPICall = async (body) => {
  return await apiInstance.APICALL("get", `view-banner/${body}`, "authheader");
};

export const EmailListAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `email-template-list`,
    body,
    "authheader"
  );
};

export const ViewEmailAPICall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `view-email-template/${body}`,
    "authheader"
  );
};

export const AddEmailAPICall = async (body) => {
  return await apiInstance.APICALL("post", `add-template`, body, "authheader");
};
export const AddTagAPICall = async (body) => {
  return await apiInstance.APICALL("post", `add-template`, body, "authheader");
};

export const TranslationListAPICall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${localStorage.getItem("language")}/${body}`,
    "authheader"
  );
};

export const GetEmployeeJobTitleApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${localStorage.getItem("language")}/job_title`,
    "authHeader"
  );
};

export const UpdateJobTitleAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `update-job-title`,
    body,
    "authheader"
  );
};

export const GetSiteSettingsApiCall = async () => {
  return await apiInstance.APICALL("get", "site-settings", "authheader");
};

export const UpdateSiteSettingsApiCall = async (body, id) => {
  return await apiInstance.APICALL(
    "post",
    `site-settings/${id}`,
    body,
    "authheader"
  );
};

export const ChangeUserFromPropertyAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `chnage-user-from-property`,
    body,
    "authheader"
  );
};

export const ChangeImportStatusAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `chnage-import-status`,
    body,
    "authheader"
  );
};

export const AddEmailTemplateLogoAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `add-email-template-logo`,
    body,
    "formHeader"
  );
};

export const ChangeImportProfileStatusAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `chnage-import-profile-status`,
    body,
    "authheader"
  );
};

export const RunImportAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `start-realtor-import`,
    body,
    "authheader"
  );
};

export const MasterTableGetTranslationApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    `get-translation-column`,
    "authheader"
  );
};

export const GetTranslationApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `get-translation?${
      body.search_text ? `search_text=${body.search_text}` : `search_text=${""}`
    }&lang=${body.lang}&column=${body.column}&page=${body.page}&per_page=${
      body.per_page
    }
    `,
    "authheader"
  );
};

export const AddTranslationApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `add-translation`,
    body,
    "authheader"
  );
};

export const EditPropertyDocumentsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `edit-property-documents`,
    body,
    "formHeader"
  );
};

export const RemovePropertyImagesApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `remove-property-image`,
    body,
    "authheader"
  );
};

export const DiscountListApiCall = async (body, valid, page) => {
  return await apiInstance.APICALL(
    "get",
    `get-all-discount-extras?id=${body || ""}&valid_only=${
      valid || false
    }&page=${page || 1}&per_page=${40}`,
    "authheader"
  );
};

export const DeleteDiscountApiCall = async (body) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-discount-extras?id=${body}`,
    "authheader"
  );
};
// pee_page for pagination

export const AddDiscountApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `create-discount-extra`,
    body,
    "authheader"
  );
};

//subsciption apis

export const SubscriptionListApiCall = async (body, page) => {
  return await apiInstance.APICALL(
    "get",
    body
      ? `get-all-subscriptions?id=${body}`
      : `get-all-subscriptions?page=${page || 1}&per_page=${page || 40}`,
    "authheader"
  );
};

export const DeleteSubscriptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-subscription?subscription_id=${body}`,
    "authheader"
  );
};

export const CreateSubscriptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `create-subscription`,
    body,
    "authheader"
  );
};

export const EditSubscriptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "put",
    `edit-subscription`,
    body,
    "authheader"
  );
};

export const RemoveSubscriptionPaymentOptionsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `payment-options`,
    body,
    "authheader"
  );
};

export const RemoveSubscriptionDiscountsExtrasOptionsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `product-discounts`,
    body,
    "authheader"
  );
};

export const EditActiveSubscriptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "put",
    `change-subscription-status`,
    body,
    "authheader"
  );
};

export const AddSubscriptionImageApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `add-subscription-image`,
    body,
    "formHeader"
  );
};

export const ChangeSubscriptionProductOrderApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `change-product-order`,
    body,
    "authheader"
  );
};

export const GetAllSubscriptionByOrgApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `get-all-subscription-by-organisation/${body}`,
    "authheader"
  );
};

export const GetAllPaymentOptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `get-all-payment-option?page=-1&per_page=10&id=`,
    "authheader"
  );
};
// export const GetAllTagsApiCall = async (body) => {
//   return await apiInstance.APICALL(
//     "get",
//     `view-tag`,
//     "authheader"
//   );
// };
export const GetAllClassificationsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `get-classifications?id=&type=array&page=&per_page=`,
    "authheader"
  );
};

export const CreateManualPaymentForOrgApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `create-payment-manually`,
    body,
    "authheader"
  );
};

export const StopSubscriptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "put",
    `cancle-subscription`,
    body,
    "authheader"
  );
};

export const EditSubscriptionInOrgApiCall = async (body) => {
  return await apiInstance.APICALL(
    "put",
    `edit-subscription-manually`,
    body,
    "authheader"
  );
};

export const getBranchesTableData = async (page) => {
  return await apiInstance.APICALL(
    "get",
    `get-branches?id=&type=array&page=${page}&per_page=40`,
    "authheader"
  );
};

export const getBranchesData = async () => {
  return await apiInstance.APICALL(
    "get",
    `get-branches?id=&type=array`,
    "authheader"
  );
};

export const getBranchesNestedData = async () => {
  return await apiInstance.APICALL(
    "get",
    `get-branches?id=&type=tree`,
    "authheader"
  );
};

export const addBranchesData = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `branches-and-modify`,
    body,
    "authheader"
  );
};

export const deleteBranchData = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-branches/${id}`,
    "authheader"
  );
};

export const editBranchData = async (data) => {
  return await apiInstance.APICALL(
    "post",
    `branches-and-modify/${data.params_id}`,
    data,
    "authheader"
  );
};

export const getClassificationNestedData = async () => {
  return await apiInstance.APICALL(
    "get",
    `get-classifications?id=&type=tree`,
    "authheader"
  );
};

export const addClassificationData = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `classifications-and-modify/${edit}` : `classifications-and-modify`,
    body,
    "authheader"
  );
};

export const getClassificationTableData = async (page) => {
  return await apiInstance.APICALL(
    "get",
    `/get-classifications?id=&type=array&page=${page}&per_page=40`,
    "authheader"
  );
};

export const deleteClassification = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-classifications/${id}`,
    "authheader"
  );
};
//contact apis
export const addContactApiCall = async (body) => {
 

  return await apiInstance.APICALL(
    "post",
    `add-update-user-org?organizationId=${body.orgid}&agency_member_id=${
      body?.member_id || ""
    }`,
    body.data,
    "formHeader"
  );
};
export const deleteContactApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-user-org/${id}`,
    "authheader"
  );
};

export const getCountriesTableData = async (page, search) => {
  return await apiInstance.APICALL(
    "get",
    `get-country?per_page=40&page=${page}&search=${search ? search : ""}`,
    "authheader"
  );
};

export const addCountriesData = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-country/${edit}` : `add-update-country`,
    body,
    "formHeader"
  );
};

export const deleteCountriesApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-country/${id}`,
    "formHeader"
  );
};

export const getMunicipalityTableDataApiCall = async (
  page,
  search,
  country_part_id,
  province_id
) => {
  return await apiInstance.APICALL(
    "get",
    `get-municipality?per_page=40&page=${page}&search=${
      search ? search : ""
    }&country_part_id=${country_part_id ? country_part_id : ""}&province_id=${
      province_id ? province_id : ""
    }`,
    "authheader"
  );
};

export const addMunicipalityDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-municipality/${edit}` : `add-update-municipality`,
    body,
    "formHeader"
  );
};

export const deleteMunicipalityDataApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-municipality/${id}`,
    "formHeader"
  );
};

export const addAgencyBranchesApiCall = async (data) => {
  return await apiInstance.APICALL(
    "post",
    `add-agency-branches?agency_id=${data.agency_id}&branch_id=${data.id}`,
    {},
    "formHeader"
  );
};
export const addAgencyClassificationApiCall = async (data) => {
  return await apiInstance.APICALL(
    "post",
    `add-agency-classifications?agency_id=${data.agency_id}&classification_id=${data.id}`,
    {},
    "formHeader"
  );
};
export const getDistrictTableDataApiCall = async (page, search, city_id) => {
  return await apiInstance.APICALL(
    "get",
    `get-district?per_page=40&page=${page}&search=${
      search ? search : ""
    }&city_id=${city_id ? city_id : ""}`,
    "authheader"
  );
};

export const addDistrictDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-district/${edit}` : `add-update-district`,
    body,
    "formHeader"
  );
};

export const deleteDistrictDataApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-district/${id}`,
    "formHeader"
  );
};

export const getNeighbourhoodTableDataApiCall = async (
  page,
  search,
  city_id
) => {
  return await apiInstance.APICALL(
    "get",
    `get-neighbourhood?per_page=40&page=${page}&search=${
      search ? search : ""
    }&city_id=${city_id ? city_id : ""}`,
    "authheader"
  );
};

export const addNeighbourhoodDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-neighbourhood/${edit}` : `add-update-neighbourhood`,
    body,
    "formHeader"
  );
};

export const deleteNeighbourhoodDataApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-neighbourhood/${id}`,
    "formHeader"
  );
};

export const getCountryPartTableDataApiCall = async (
  page,
  search,
  country_id
) => {
  return await apiInstance.APICALL(
    "get",
    `get-country-part?per_page=40&page=${page}&search=${
      search ? search : ""
    }&country_id=${country_id ? country_id : ""}`,
    "authheader"
  );
};

export const addCountryPartDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-country-part/${edit}` : `add-update-country-part`,
    body,
    "formHeader"
  );
};

export const deleteCountryPartDataApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-country-part/${id}`,
    "formHeader"
  );
};

export const getCityApiCall = async (page, search, id, municipality_id) => {
  return await apiInstance.APICALL(
    "get",
    `get-city?city_id${id ? id : ""}=&per_page=40&page=${page}&search=${
      search ? search : ""
    }&municipality_id=${municipality_id ? municipality_id : ""}`,
    "authheader"
  );
};

export const addCityApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-city/${edit}` : `add-update-city`,
    body,
    "formHeader"
  );
};

export const deleteCityApiCall = async (id) => {
  return await apiInstance.APICALL("delete", `delete-city/${id}`, "formHeader");
};

export const getZipcodeApiCall = async (
  page,
  search,
  edit,
  country_id,
  municipality_id,
  province_id,
  city_id,
  neighbourhood_id,
  district_id,
  contry_part_id,
  id
) => {
  return await apiInstance.APICALL(
    "get",
    `get-zipcode?zipcode_id${id ? id : ""}=&per_page=40&page=${page}&search=${
      search ? search : ""
    }&country_id=${country_id ? country_id : ""}&neighbourhood_id=${
      neighbourhood_id ? neighbourhood_id : ""
    }&district_id=${district_id ? district_id : ""}&city_id=${
      city_id ? city_id : ""
    }&municipality_id=${municipality_id ? municipality_id : ""}&province_id=${
      province_id ? province_id : ""
    }&country_part_id=${contry_part_id ? contry_part_id : ""}`,
    "authheader"
  );
};

export const addZipcodeApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-zipcode/${edit}` : `add-update-zipcode`,
    body,
    "formHeader"
  );
};

export const deleteZipcodeApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-zipcode/${id}`,
    "formHeader"
  );
};
// province module
export const getProvinceApiCall = async (page, search, id, country_id) => {
  return await apiInstance.APICALL(
    "get",
    `get-province?province_id=${id ? id : ""}&per_page=40&page=${page}&search=${
      search ? search : ""
    }&country_id=${country_id ? country_id : ""}`,
    "authheader"
  );
};
export const AudienceProvinceApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    `get-province`,
    "authheader"
  );
};
export const AudienceCityApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    `get-city?city_id=&per_page=3000000&page=1&search=`,
    "authheader"
  );
};

export const addProvinceDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-province/${edit}` : `add-update-province`,
    body,
    "formHeader"
  );
};

export const deleteProvinceDataApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-province/${id}`,
    "formHeader"
  );
};
// contact log apis for single org
export const getContactLogApiCall = async (agency_member_log_id, agency_id) => {
  return await apiInstance.APICALL(
    "get",
    `view-agency-member-log?per_page=5000&agency_member_log_id=${
      agency_member_log_id ? agency_member_log_id : ""
    }&agency_id=${agency_id ? agency_id : ""}`,
    "authheader"
  );
};

export const addContactLogDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit
      ? `add-update-agency-member-log/${edit}`
      : `add-update-agency-member-log`,
    body,
    "formHeader"
  );
};

export const deleteContactLogDataApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-agency-member-log/${id}`,
    "authheader"
  );
};

export const addAddressOrgApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit
      ? `add-update-address-org?agency_address_id=${edit}`
      : `add-update-address-org?agency_address_id=`,
    body,
    "authheader"
  );
};

export const deleteAddressOrgApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-address-org/${id}`,
    "authheader"
  );
};

export const getAutoSuggestCityApiCall = async (house_no, zipcode) => {
  return await apiInstance.APICALL(
    "get",
    `auto-suggest-city?house_no=${house_no ? house_no : ""}&zipcode=${
      zipcode ? zipcode : ""
    }`,
    "authheader"
  );
};

export const getTranslationColumnApiCall = async (
  search,
  lang,
  column,
  page,
  per_page
) => {
  return await apiInstance.APICALL(
    "get",
    `get-translation?search_text=${search ? search : ""}&lang=${lang}&column=${
      column ? column : ""
    }&page=${page}&per_page=${per_page ? per_page : 40}&id=`,
    "authheader"
  );
};

export const AddEditTranslationApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-edit-translation/${edit}` : `add-edit-translation`,
    body,
    "authheader"
  );
};

export const getInternalBannerApiCall = async (agency_id, search) => {
  return await apiInstance.APICALL(
    "get",
    `get-agency-banners?agency_id=${agency_id ? agency_id : ""}&search=${
      search ? search : ""
    }`,
    "authheader"
  );
};

export const addInternalBannerDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit ? `add-update-agency-banners/${edit}` : `add-update-agency-banners`,
    body,
    "formHeader"
  );
};

export const deleteInternalBannerDataApiCall = async (id, banner_id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-agency-banners/?agency_banner_id=${id}&agency_banner_size_id=${
      banner_id ? banner_id : ""
    }`,
    "authheader"
  );
};

export const getCityInfoApiCall = async (search) => {
  return await apiInstance.APICALL(
    "get",
    `city-info?types=city,municipality,neighbourhood,district,province,country_part,country&search=${search}`,
    "authheader"
  );
};
export const UpdateVatDataApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "patch",
    edit ? `update-vat/${edit}` : `update-vat`,
    body,
    "authheader"
  );
};
export const sendSubjectApiCall = async (body, id,imgdata) => {
  console.log(body,"Id from API")
  return await apiInstance.APICALL(
    "patch",
    `email-mkt-campaign-part-two/${id}`,
    body,
    imgdata == "formdata" ? "formHeader" :"authheader"
  );
};
// export const sendContentApiCall = async (body, id) => {
//   console.log(id,"Id from API")
//   return await apiInstance.APICALL(
//     "patch",
//     `email-mkt-campaign-part-two/${id}`,
//     body,
//     "formheader"
//   );
// };
export const sendAllCampaignApiCall = async (body, id) => {
  console.log(id,"Id from API")
  return await apiInstance.APICALL(
    "post",
    `sendCampaign/${id}`,
    body,
    "authheader"
  );
};
export const getVatDataApiCall = async (page, per_page) => {
  return await apiInstance.APICALL(
    "get",
    `get-vat?vat_id=&per_page=${per_page ? per_page : 40}&page=${
      page ? page : 1
    }`
  );
};
export const contactLinkingToOrgApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "contact-linking-to-org",
    body,
    "authheader"
  );
};
export const getBannerUrlApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "get-agency-banner-url",
    body,
    "formHeader"
  );
};

export const BannerCampaignsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "banner-campaigns",
    body,
    "authHeader"
  );
};

export const MakeBannerStopApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "make-banner-stop",
    body,
    "authHeader"
  );
};

export const DeletePropertyBannerApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `remove-banner/${body.id}`,
    {},
    "authHeader"
  );
};

export const ViewPropertyBannerApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `banner-campaign/${body.id}`,
    "authHeader"
  );
};

export const InvoicesApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "invoices",
    body,
    "authHeader"
  );
};

export const HoldInvoiceApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `hold-invoice/${body.id}`,
    body,
    "authHeader"
  );
};

export const ResendInvoiceApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `resend-invoice/${body.id}`,
    body,
    "authHeader"
  );
};

export const DeleteInvoiceApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `remove-invoice/${body.id}`,
    body,
    "authHeader"
  );
};

export const ViewInvoiceApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `invoice/${body.id}`,
    "authHeader"
  );
};


export const GetAllTagsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `view-tag`,
    "authheader"
  );
};


export const CreateAudienceAPICall = async (body, id) => {

console.log("idddddddd", id)

  return await apiInstance.APICALL(
    "post",
    id !== undefined ? `email-mkt-campaign-part-one/${id}` : "email-mkt-campaign-part-one",
    body,
    "authheader"
  );
};
export const CreateTagAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "add-tag",
    body,
    "authheader"
  );
};
export const CreateRecipientAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "recipient-count",
    body,
    "authheader"
  );
};
export const DownloadRecipientAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "export-recevierdata",
    body,
    "authheader"
  );
};
export const CreateTemplateAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `add-mkt-template`,
    body,
    "authheader"
  );
};
export const getTemplateAPICall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `email-mkt-template-list`,
    body,
    "authheader"
  );
};
export const getCampaignAPICall = async () => {
  return await apiInstance.APICALL(
    "get",
    `view-campaign`,
    "authheader"
  );
};

export const updateTagAPICall = async (body) => {

   const {id, tag} = body

   console.log("inside id api", id, tag)

  return await apiInstance.APICALL(
    "post",
    `add-tag/${id}`,
    body,
    "authheader"
  );
};


export const deleteTagAPICall = async (id) => {

 return await apiInstance.APICALL(
   "delete",
   `delete-tag/${id}`,
   "authheader"
 );
};


export const updateTemplateAPICall = async (body) => {

  const {id, tag} = body

 return await apiInstance.APICALL(
   "post",
   `add-mkt-template/${id}`,
   body,
   "authheader"
 );
};


export const deleteTemplateAPICall = async (id) => {
return await apiInstance.APICALL(
  "delete",
  `delete-email-mkt-template/${id}`,
  "authheader"
);
};
export const deleteCampaignAPICall = async (id) => {
return await apiInstance.APICALL(
  "delete",
  `delete-campaign/${id}`,
  "authheader"
);
};


export const AddCreditApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "add-credit",
    body,
    "authHeader"
  )
}

export const getSuppliersData = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `suppliers`,
    body,
    "authheader"
  );
};

export const getSupplierData = async (id) => {
  return await apiInstance.APICALL("get", `suppliers/${id}`, "authheader");
};

export const addSupplierApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `add-supplier`,
    body,
    "authheader"
  );
};

export const updateSupplierApiCall = async (body, path) => {
  console.log("updateSupplierApiCall", body, path);
  return await apiInstance.APICALL(
    "post",
    `update-supplier/${path}`,
    body,
    "authheader"
  );
};

export const deleteSupplierApiCall = async (data) => {
  return await apiInstance.APICALL("delete", `delete-supplier/${data.id}`, "authheader");
};

export const locationListApiCall = async () => {
  return await apiInstance.APICALL("get", "locations", "autheader");
};

export const addLocationApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit
      ? `add-update-location?location_id=${edit}`
      : `add-update-location?location_id=`,
    body,
    "authheader"
  );
};

export const deleteLocationApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-location/${id}`,
    "authheader"
  );
};

export const addPriceTableApiCall = async (body, edit) => {
  return await apiInstance.APICALL(
    "post",
    edit
      ? `add-update-pricetable?pt_id=${edit}`
      : `add-update-pricetable?pt_id=`,
    body,
    "authheader"
  );
};

export const deletePriceTableApiCall = async (id) => {
  return await apiInstance.APICALL(
    "delete",
    `delete-pricetable/${id}`,
    "authheader"
  );
};

export const titleListApiCall = async (id) => {
  console.log("ID", id)
  return await apiInstance.APICALL("get", `titles/${id}`, "autheader");
};

export const advSupplierListApiCall = async () => {
  return await apiInstance.APICALL("get", `advertising_suppliers`, "autheader");
};
export const supplierTypesApiCall = async () => {
  return await apiInstance.APICALL("get", `supplier_types`, "autheader");
};

export const priceTableTypesApiCall = async () => {
  return await apiInstance.APICALL("get", `price_table_types`, "autheader");
};

export const priceLineUnitsApiCall = async () => {
  return await apiInstance.APICALL("get", `price_line_units`, "autheader");
};

export default apiInstance;
