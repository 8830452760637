import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../common/MainLayout";
import classes from "./financialmanagementmain.module.scss";
import FinancialManagementHeader from "../common/FinancialManagementHeader";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import ModalMain from "../common/Modal";
import CreditInvoice from "../ModalComponents/CreditInvoiceModal";
import {
  actionViewInvoiceApiCall,
  actionDeleteInvoiceApiCall,
  actionHoldInvoiceApiCall,
  actionResendInvoiceApiCall,
  actionSaveInvoiceData,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import PriceFormatter from "../../utils/PriceFormatter";
import {
  TbSquareRoundedLetterC,
  TbSquareRoundedLetterD,
  TbSquareRoundedLetterH,
  TbSquareRoundedLetterO,
  TbSquareRoundedLetterP,
} from "react-icons/tb";
const FinancialManagementMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.CommonReducer.loader);

  const [showCredit, setShowCredit] = useState(false);
  const [showTitle, setShowTitle] = useState();
  const [showContent, setShowContent] = useState();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const invoicesData = useSelector(
    (state) => state.CommonReducer.invoicesData
  );

  console.log(invoicesData)

  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const divref = useRef([]);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 304) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const platform = localStorage.getItem("Platform");

  const onCLickViewInvoice = (nr) => {
    window.open(
      `https://woningmarkt.s3.amazonaws.com/${
        platform !== "PROD" ? "development" : "production"
      }/invoices/${nr}.pdf`
    );
  };

  const onCLickCreditInvoice = (invoice) => {
    const data = {
      invoice: invoice,
    };
    const body = { data, navigate };
    console.log("body", body);
    dispatch(actionSaveInvoiceData(body));
    setShowCredit(true);
  };

  const onClickHoldInvoice = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionHoldInvoiceApiCall(body));
  };

  const onClickResendInvoice = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionResendInvoiceApiCall(body));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Close = () => setShowCredit(false);

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.BannerMain}>
          <FinancialManagementHeader />
          <div className={classes.BannerListingSection}>
            <table>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>{t("invoice_nr_fm_c")}</th>
                  <th>{t("client_pb_c")}</th>
                  <th>{t("concerns_fm_c")}</th>
                  <th>{t("invoice_date_fm_c")}</th>
                  <th>{t("amount_fm_c")}</th>
                  <th>{t("vat_fm_c")}</th>
                  <th>{t("total_fm_c")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader === true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={6}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {invoicesData?.count > 0 &&
                      invoicesData?.invoices.map((item, index) => {
                        return (
                          <tr ref={(e) => (divref.current[index] = e)}>
                            {item.on_hold ? (
                              <td className={classes.OnHold}>
                                <TbSquareRoundedLetterH title="On Hold" />
                              </td>
                            ) : item.total_amount === item.amout_paid ? (
                              <td className={classes.OnHold}>
                                <TbSquareRoundedLetterP title="Paid" />
                              </td>
                            ) : DateTime.fromISO(item.invoice_date) <
                              DateTime.now().plus({ days: -14 }) ? (
                              <td className={classes.Due}>
                                <TbSquareRoundedLetterD title="Due" />
                              </td>
                            ) : item.kind === "credit" ? (
                              <td className={classes.Credit}>
                                <TbSquareRoundedLetterC title="Credit" />
                              </td>
                            ) : (
                              <td>
                                <TbSquareRoundedLetterO title="Open" />
                              </td>
                            )}
                            <td>{item.number}</td>
                            <td>{item.agency?.name}</td>
                            <td>{item.concerns}</td>
                            <td>
                              {DateTime.fromISO(item.invoice_date).toFormat(
                                "dd-MM-yyyy"
                              )}
                            </td>
                            <td className={classes.BannerNumber}>
                              {PriceFormatter(
                                parseFloat(item.amount),
                                2,
                                "€ ",
                                true
                              )}{" "}
                            </td>
                            <td className={classes.BannerNumber}>
                              {PriceFormatter(
                                parseFloat(item.vat_amount),
                                2,
                                "€ ",
                                true
                              )}{" "}
                            </td>
                            <td className={classes.BannerNumber}>
                              {PriceFormatter(
                                parseFloat(item.total_amount),
                                2,
                                "€ ",
                                true
                              )}{" "}
                            </td>
                            <td className={classes.BannerEditor}>
                              <img
                                src={userEditorIcon}
                                alt="Invoice Actions"
                                onClick={() => {
                                  if (invoicesData.length - 1 > index) {
                                    handleScroll(index);
                                  }
                                  if (ind !== index) {
                                    setShow(!show);
                                    setInd(index);
                                  } else {
                                    setInd("");
                                  }
                                }}
                              />
                              {show && ind === index && (
                                <div
                                  className={classes.EditorWrap}
                                  style={
                                    invoicesData.length - 2 > index
                                      ? {}
                                      : { top: "-170px" }
                                  }
                                  onClick={() => setShow(!show)}
                                  ref={listRef}
                                >
                                  <ul>
                                    <li
                                      onClick={() =>
                                        onCLickViewInvoice(item.number)
                                      }
                                    >
                                      {t("view_invoice")}
                                    </li>
                                    {item.kind !== "credit" && (
                                      <li
                                        onClick={(event) =>
                                          onCLickCreditInvoice(item)
                                        }
                                      >
                                        {t("credit_invoice")}
                                      </li>
                                    )}
                                    <li
                                      onClick={() =>
                                        onClickHoldInvoice(item.id)
                                      }
                                    >
                                      {t("hold_invoice")}
                                    </li>
                                    <li
                                      onClick={() =>
                                        onClickResendInvoice(item.id)
                                      }
                                    >
                                      {t("resend_invoice")}
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </MainLayout>
      <ModalMain showmodel={showCredit} size={"lg"} handleClose={Close}>
        <CreditInvoice edit={false} close={Close} />
      </ModalMain>{" "}
    </React.Fragment>
  );
};

export default FinancialManagementMain;
