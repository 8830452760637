import React, { useEffect, useState } from "react";
import classes from "./financialmanagementheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import ModalMain from "../Modal";
import { useTranslation } from "react-i18next";
import CreateBanner from "../../ModalComponents/BannerManagementModal/CrateBanner";
import CarteBannerIcon from "../../../assets/icons/CarteBannerIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionInvoicesApiCall,
  setperPageBannerCount,
} from "../../../Redux/Actions";
import { useMemo } from "react";

const FinancialManagementHeader = () => {
  const { t } = useTranslation();
  const state = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [creatbanner, setCreateBanner] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [perpage, setPerPage] = useState(
    state?.perPageBannerCount ? state?.perPageBannerCount : 40
  );
  const [inputType, setInputType] = useState("text");
  const [inputType1, setInputType1] = useState("text");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [name, setName] = useState();

  const filterClick = () => {
    const data = {
      page: 1,
      per_page: perpage,
      agency_name: name,
      start_date: startDate,
      end_date: endDate,
    };
    const body = { data, navigate };

    dispatch(actionInvoicesApiCall(body));
  };
  useMemo(() => {
    filterClick();
    dispatch(setperPageBannerCount(perpage));
  }, [perpage]);

  const handleFocus = () => {
    setInputType("date");
  };
  const handleFocus1 = () => {
    setInputType1("date");
  };
  const handleBlur = () => {
    setInputType("text");
  };
  const handleBlur1 = () => {
    setInputType1("text");
  };
  const sidebarShow = () => {
    setSideBar(true);
  };
  const createBannerShow = () => {
    setCreateBanner(true);
  };

  const handleClose = () => {
    setSideBar(false);
    setCreateBanner(false);
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("financial_management")}</h5>
            <form>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <input
                    type="text"
                    placeholder={`${t("name_p")}...`}
                    onChange={(e) => setName(e.target.value)}
                    className={classes.Text}
                  />
                </div>
                <div className={classes.fildBox}>
                  <input
                    type={inputType}
                    placeholder="Start Date"
                    onChange={(event) => {
                      if (event.target.type === "date") {
                        setStartDate(event.target.value);
                      }
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.fildBox}>
                  <input
                    type={inputType1}
                    placeholder="End Date"
                    onChange={(event) => {
                      if (event.target.type === "date") {
                        setEndDate(event.target.value);
                      }
                    }}
                    onFocus={handleFocus1}
                    onBlur={handleBlur1}
                  />
                </div>
                <div
                  className={classes.fildBox}
                  onClick={(e) => {
                    e.preventDefault();
                    filterClick();
                  }}
                >
                  <button>{t("filter")}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={classes.BannersPerPageSection}>
          <p>{t("invoices_per_page")}</p>
          <div className={classes.numberOfUser}>
            <select
              id="page"
              name="page"
              onChange={(e) => setPerPage(e.target.value)}
              defaultValue={perpage}
            >
              <option value={40} key={40}>
                40
              </option>
              <option value={50} key={50}>
                50
              </option>
              <option value={60} key={60}>
                60
              </option>
              <option value={70} key={70}>
                70
              </option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ModalMain showmodel={creatbanner} handleClose={handleClose} size={"md"}>
        <CreateBanner onClose={handleClose} edit={false} />
      </ModalMain>
    </React.Fragment>
  );
};

export default FinancialManagementHeader;
