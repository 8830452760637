import React, { useState } from "react";
import classes from "./locationform.module.scss";
import deleteIcon from "../../../assets/images/DeleteIcon.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddLocationApiCall,
  actionDeleteLocationApiCall,
  actiongetLocationListApiCall,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const LocationForm = ({ editData, handleClose }) => {
  const initialFormData = {
    selectedOption: "",
    selectedLocation: "",
  };
  const supplierData = useSelector(
    (state) => state.CommonReducer.supplierData
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [
    ["ZIPCODE", "Zipcode"],
    ["NEIGHBOURHOOD", "Neighbourhood"],
    ["DISTRICT", "District"],
    ["CITY", "City"],
    ["MUNICIPALITY", "Municipality"],
    ["PROVINCE", "Province"],
    ["COUNTRY_PART", "Country Part"],
    ["COUNTRY", "Country"],
  ];
  const [formData, setFormData] = useState(initialFormData);
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    console.log("USE EFFECT 1");
    dispatch(actiongetLocationListApiCall({ navigate }));
  }, []);

  const locationListData = useSelector(
    (state) => state.CommonReducer.locationListData
  );
  console.log("locationListData", locationListData);
  useEffect(() => {
    console.log("USE EFFECT");
    if (editData) {
      setFormData({
        selectedOption: editData?.location_type || "",
        selectedLocation: editData?.location_id || "",
      });
    }
  }, [editData]);

  console.log("Location form formData", formData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    let valid = true;
    const errors = {
      selectedOption: "",
      selectedLocation: "",
    };

    if (!formData.selectedOption) {
      errors.selectedOption = "Please select a location type";
      valid = false;
    }

    if (!formData.selectedLocation) {
      errors.selectedLocation = "Please select a location";
      valid = false;
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    }
    return valid;
  };
  const handleAddLocation = () => {
    if (validateForm()) {
      let closePopup = handleClose;

      const data = {
        location_type: formData.selectedOption,
        selected_location_id: formData.selectedLocation,
        supplier_id: supplierData.id,
      };
      dispatch(
        actionAddLocationApiCall({
          data,
          edit: editData ? editData.id : false,
          closePopup,
          navigate,
          getapiCall: { data: {id: supplierData.id}, navigate },
        })
      );
    }
  };
  const handleDeleteBtn = () => {
    dispatch(
      actionDeleteLocationApiCall({
        id: editData.id,
        closePopup: handleClose,
        navigate,
        getapiCall: { data: {id: supplierData.id}, navigate },
      })
    );
  };

  return (
    <React.Fragment>
      <div className={classes.locationFormSection}>
        {editData ? <h3>Edit Location</h3> : <h3>Add Location</h3>}

        <div className={classes.inputsWrapper}>
          <label>Type of Location</label>
          <select
            name="selectedOption"
            value={formData.selectedOption}
            onChange={handleChange}
          >
            <option value="">Select an option</option>
            {options?.map((option, index) => (
              <option key={option[0]} value={option[0]}>
                {option[1]}
              </option>
            ))}
          </select>
          {validationErrors.selectedOption && (
            <div className={classes.error}>
              {validationErrors.selectedOption}
            </div>
          )}
          <label>Name</label>
          <select name="selectedLocation" value={formData.selectedLocation} onChange={handleChange}>
            <option value="">Select an option</option>
            {locationListData && locationListData[formData.selectedOption]?.map((option, index) => (
              <option key={option[0]} value={option[0]}>
                {option[1]}
              </option>
            ))}
          </select>
          {validationErrors.selectedOption && (
            <div className={classes.error}>
              {validationErrors.selectedOption}
            </div>
          )}
        </div>

        <div className={classes.btnwrapper}>
          {editData ? (
            <>
              <button className={classes.btncancel} onClick={handleDeleteBtn}>
                <img src={deleteIcon} alt="" /> Delete
              </button>
              <button className={classes.btnadd} onClick={handleAddLocation}>
                Save
              </button>
            </>
          ) : (
            <>
              <button className={classes.btncancel} onClick={handleClose}>
                Cancel
              </button>
              <button className={classes.btnadd} onClick={handleAddLocation}>
                Add
              </button>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocationForm;
