import React from "react";
import SystemManagementMain from "../../components/SystemManagementMain";

const SystemManagementPage = () => {
  return (
    <React.Fragment>
      <SystemManagementMain />
    </React.Fragment>
  );
};

export default SystemManagementPage;
