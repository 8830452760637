import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classes from "./zipcodemain.module.scss";
import MainLayout from "../common/MainLayout";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import {
  actionDeleteZipcodeApiCall,
  actionGetCountriesTableSystemApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import OffcanvasMain from "../common/Offcanvas";
import ZipcodeForm from "../common/ZipcodeForm";
import ZipcodeHeader from "../common/ZipcodeHeader";
import Loader from "../common/Loader";

const ZipcodeMain = ({}) => {
  const getZipcodeData = useSelector(
    (state) => state?.CommonReducer?.getZipcodeData
  );
  const loader = useSelector((state) => state.CommonReducer.loader);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const divref = useRef([]);
  const [openCanvas, setOpenCanvas] = useState(false);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 303) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  React.useEffect(() => {
    const body1 = { navigate, page: 1 };
    dispatch(actionGetCountriesTableSystemApiCall(body1));
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = (item) => {
    dispatch(actionDeleteZipcodeApiCall({ id: item?.id, navigate }));
  };

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.ZipcodeMain}>
          <ZipcodeHeader />
          <div className={classes.ZipcodeSection}>
            <table>
              <thead>
                <tr>
                  <th>{t("number").toUpperCase()}</th>
                  <th>{t("code").toUpperCase()}</th>
                  <th>{t("municipality").toUpperCase()}</th>
                  <th>{t("country").toUpperCase()}</th>
                  <th>{t("province").toUpperCase()}</th>
                  <th>{t("city_p").toUpperCase()}</th>
                  <th>{t("neighbourhood").toUpperCase()}</th>
                  <th>{t("district").toUpperCase()}</th>
                  <th>{t("country_part").toUpperCase()}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader == true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={9}>
                      <Loader />
                    </td>
                  </tr>
                ) : getZipcodeData.length > 0 ? (
                  getZipcodeData.map((item, index) => (
                    <tr ref={(e) => (divref.current[index] = e)} key={index}>
                      <td>{item?.number ? item?.number : "NA"}</td>
                      <td>{item?.code ? item?.code : "-"}</td>
                      <td>
                        {item?.municipality ? item?.municipality?.name : "-"}
                      </td>
                      <td>{item?.country ? item?.country?.name : "-"}</td>
                      <td>{item?.province ? item?.province?.name : "-"}</td>
                      <td>{item?.city ? item?.city?.name : "-"}</td>
                      <td>
                        {item?.neighbourhood ? item?.neighbourhood.name : "-"}
                      </td>
                      <td>{item?.district ? item?.district.name : "-"}</td>
                      <td>
                        {item?.country_part ? item?.country_part.name : "-"}
                      </td>
                      <td
                        className={classes.userEditor}
                        onClick={() => {
                          handleScroll(index);
                          if (ind !== index) {
                            setShow(!show);
                            setInd(index);
                          } else {
                            setInd("");
                          }
                        }}
                      >
                        <img src={userEditorIcon} />
                        {show && ind === index && (
                          <div
                            ref={listRef}
                            className={classes.EditorWrap}
                            onClick={() => setShow(!show)}
                            style={
                              getZipcodeData.length - 1 > index
                                ? {}
                                : getZipcodeData.length > 4
                                ? { top: "-100px" }
                                : {}
                            }
                          >
                            <ul>
                              <li
                                onClick={() => {
                                  setOpenCanvas(true);
                                  setEditData(item);
                                }}
                              >
                                {t("edit")} {t("zipcode")}
                              </li>
                              <li onClick={() => handleDelete(item)}>
                                {t("delete")} {t("zipcode")}
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={9}>
                      <h2>{t("no_data_found")}</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <OffcanvasMain
            showcanvas={openCanvas}
            placement={"end"}
            backgroundColor="true"
            width="lg"
            handleClose={() => {
              setOpenCanvas(false);
            }}
          >
            <ZipcodeForm
              editData={editData}
              handleClose={() => {
                setOpenCanvas(false);
              }}
            />
          </OffcanvasMain>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default ZipcodeMain;
