import React, { useState } from "react";
import classes from "./addressform.module.scss";
import StarIcon from "../../../assets/images/starIcon.svg";
import deleteIcon from "../../../assets/images/DeleteIcon.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddOrgAddressApiCall,
  actionDeleteOrgAddressApiCall,
  actionGetAutoSuggestCityApiCall,
  actiongetTranslationListApiCall,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const AddressForm = ({ editData, handleClose }) => {
  const initialFormData = {
    selectedOption: "",
    setAsDefault: true,
    houseNumber: "",
    postCode: "",
    city: "",
    email: "",
    phoneNumber: "",
    addition: "",
    zipcode_id: "",
    street: "",
  };
  const autoSuggestedCity = useSelector(
    (state) => state.CommonReducer.autoSuggestedCity
  );
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = useSelector((state) => state.CommonReducer.saveAddressData);
  const [formData, setFormData] = useState(initialFormData);
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    dispatch(
      actiongetTranslationListApiCall({ data: "address_type", navigate })
    );
  }, []);
  useEffect(() => {
    if (autoSuggestedCity) {
      if (
        autoSuggestedCity.length > 0 &&
        editData?.city != autoSuggestedCity[0]?.city?.name &&
        editData?.zipcode_id != autoSuggestedCity[0]?.id
      ) {
        setFormData({
          ...formData,
          city: autoSuggestedCity[0]?.city?.name,
          zipcode_id: autoSuggestedCity[0]?.id,
          street: autoSuggestedCity[0]?.street,
        });
      } else if (editData) {
        setFormData({
          ...formData,
          city: editData?.city,
          street: editData?.street,
        });
      } else {
        setFormData({
          ...formData,
          city: "",
          zipcode_id: "",
          street: "",
        });
      }
    }
  }, [autoSuggestedCity]);
  useEffect(() => {
    if (formData.houseNumber && formData.postCode.length == 6) {
      dispatch(
        actionGetAutoSuggestCityApiCall({
          house_no: formData.houseNumber,
          zipcode: formData.postCode,
        })
      );
    }
  }, [formData.postCode, formData.houseNumber]);

  useEffect(() => {
    if (editData) {
      setFormData({
        setAsDefault: editData.set_as_main_address,
        zipcode_id: editData.zipcode_id,
        selectedOption: editData?.address_type || "",
        houseNumber: editData?.house_no || "",
        addition: editData?.addition || "",
        postCode: editData?.zipcode || "",
        city: editData?.city || "",
        email: editData?.email || "",
        phoneNumber: editData?.phone || "",
        street: editData?.street || "",
      });
    }
  }, [editData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    let valid = true;
    const errors = {
      selectedOption: "",
      houseNumber: "",
      postCode: "",
      city: "",
      email: "",
      phoneNumber: "",
      addition: "",
    };

    if (!formData.selectedOption) {
      errors.selectedOption = "Please select an Address Type";
      valid = false;
    }

    if (!formData.houseNumber && formData.houseNumber.trim()) {
      errors.houseNumber = "House Number is required";
      valid = false;
    }

    if (!formData.postCode && formData.postCode.trim()) {
      errors.postCode = "Post Code is required";
      valid = false;
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
      valid = false;
    }

    if (formData.email.trim() && !isValidEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
      valid = false;
    }

    if (formData.phoneNumber && formData.phoneNumber.length !== 10) {
      errors.phoneNumber = "Please enter a valid 10-digit phone number";
      valid = false;
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    }
    return valid;
  };
  const handleAddAddress = () => {
    if (validateForm()) {
      let closePopup = handleClose;

      const data = {
        set_as_main_address: formData.setAsDefault,
        organizationId: singleOrgData.id,
        zipcode_id: formData.zipcode_id,
        zipcode: formData.postCode,
        address_type: formData.selectedOption,
        house_no: formData.houseNumber,
        addition: formData.addition,
        city: formData?.city,
        email: formData.email,
        phone: formData.phoneNumber,
        street: formData?.street,
      };
      dispatch(
        actionAddOrgAddressApiCall({
          data,
          edit: editData ? editData.id : false,
          closePopup,
          navigate,
          getapiCall: { data: singleOrgData.id, navigate },
        })
      );
    }
  };
  const handleDeleteBtn = () => {
    dispatch(
      actionDeleteOrgAddressApiCall({
        id: editData.id,
        closePopup: handleClose,
        navigate,
        getapiCall: { data: singleOrgData.id, navigate },
      })
    );
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <React.Fragment>
      <div className={classes.addressFormSection}>
        {editData ? <h3>Edit Address</h3> : <h3>Add Address</h3>}
        <p>Set as the main Address</p>

        <div
          className={classes.startImageWrapper}
          onClick={() =>
            setFormData({
              ...formData,
              setAsDefault: !formData.setAsDefault,
            })
          }
        >
          {formData.setAsDefault && <img src={StarIcon} alt="star" />}
        </div>

        <div className={classes.inputsWrapper}>
          <label>Type of Address</label>
          <select
            name="selectedOption"
            value={formData.selectedOption}
            onChange={handleChange}
          >
            <option value="">Select an option</option>
            {options?.map((option, index) => (
              <option key={index} value={option.translation}>
                {option.translation}
              </option>
            ))}
          </select>
          {validationErrors.selectedOption && (
            <div className={classes.error}>
              {validationErrors.selectedOption}
            </div>
          )}
          <div className={classes.numberPart}>
            <div>
              <label htmlFor="houseNumber">House Number</label>
              <input
                type="number"
                name="houseNumber"
                value={formData.houseNumber}
                onChange={handleChange}
                onBlur={validateForm}
              />
              {validationErrors.houseNumber && (
                <p className={classes.error}>{validationErrors.houseNumber}</p>
              )}
            </div>
            <div>
              <label htmlFor="addition">Addition</label>
              <input
                type="text"
                name="addition"
                value={formData.addition}
                onChange={handleChange}
                onBlur={validateForm}
              />
            </div>
          </div>

          <label htmlFor="postCode">Post Code</label>
          <input
            type="text"
            name="postCode"
            value={formData.postCode}
            onChange={handleChange}
            onBlur={validateForm}
          />
          {validationErrors.postCode && (
            <div className={classes.error}>{validationErrors.postCode}</div>
          )}

          <label htmlFor="city">City</label>
          <input
            type="text"
            name="city"
            className={classes.cityInput}
            value={formData?.city}
            disabled
          />
          {validationErrors?.city && (
            <div className={classes.error}>{validationErrors?.city}</div>
          )}
          <label htmlFor="street">Street Name</label>
          <input
            type="text"
            name="street"
            value={formData.street}
            onChange={handleChange}
            onBlur={validateForm}
          />

          <label htmlFor="email">E-mail</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={validateForm}
          />
          {validationErrors.email && (
            <div className={classes.error}>{validationErrors.email}</div>
          )}

          <label htmlFor="phoneNumber">Phonenumber(#)</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            onBlur={validateForm}
          />
          {validationErrors.phoneNumber && (
            <div className={classes.error}>{validationErrors.phoneNumber}</div>
          )}
        </div>

        <div className={classes.btnwrapper}>
          {editData ? (
            <>
              <button className={classes.btncancel} onClick={handleDeleteBtn}>
                <img src={deleteIcon} alt="" /> Delete
              </button>
              <button className={classes.btnadd} onClick={handleAddAddress}>
                Save
              </button>
            </>
          ) : (
            <>
              <button className={classes.btncancel} onClick={handleClose}>
                Cancel
              </button>
              <button className={classes.btnadd} onClick={handleAddAddress}>
                Add
              </button>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddressForm;
