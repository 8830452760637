import React, { useState } from "react";
import classes from "./editorganisationaddsubscription.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PriceFormatter from "../../../utils/PriceFormatter";
import moment from "moment";
import ModalMain from "../Modal";
import AddSubscriptionModal from "../../ModalComponents/SubscriptionModal/AddSubscriptionModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { actionGetSubscriptionApiCall } from "../../../Redux/Actions";

const EditOrganisationAddSubscription = (props) => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState({ id: "" });
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subdata = useSelector(
    (state) => state.CommonReducer.saveAllPaymentOption
  );
  const SavePropertyListdata = useSelector(
    (state) => state.CommonReducer.SavePropertyListdata
  );
  const getLabel = (status) => {
    if (status === 1) {
      return "Active";
    } else if (status === 0) {
      return "Inactive";
    }
  };
  const EditOrganisationAddSubscriptionClose = () => {
    // props.ViewOrgSubscriptionPlanClose();
    props.handleClose();
  };
  const getStatusStyle = (paymentStatus) => {
    switch (paymentStatus) {
      case "Inactive":
        return { color: "#8F8F8F" };
      case "Active":
        return { color: "#df1681" };
      default:
        return null;
    }
  };
  const onClickOfSelect = () => {
    if (plan.id) {
      const data = plan.product_detail.id;
      const body = { data, navigate, edit: true };
      dispatch(actionGetSubscriptionApiCall(body));
      setShow(true);
    } else {
      toast.error("please select one plan");
    }
  };
  return (
    <React.Fragment>
      <div className={classes.ViewOrgSubscriptionPlanMain}>
        <h3>{t("add_subscription")}</h3>
        <div className={classes.ViewOrgSubscriptionPlanWrap}>
          <table>
            <tr>
              <th>{t("name")}</th>
              <th>
                {t("price")} / {t("periodicity")}
              </th>
              <th>{t("starrt")}</th>
              <th>{t("end")}</th>
              {/* <th>{t("price")}</th> */}
              <th>{t("status_c")}</th>
            </tr>
            {subdata.length > 0 ? (
              subdata.map((item) => (
                <tr
                  onClick={() => setPlan(item)}
                  className={
                    plan.id !== item.id ? classes.default : classes.selected
                  }
                >
                  <td>
                    {item.product_detail.name}({item.name})
                  </td>{" "}
                  <td>{`${item.price} / ${item.periodicity}`}</td>
                  <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                  {/* <td>{PriceFormatter(item.price)}</td> */}
                  <td
                    style={getStatusStyle(getLabel(item.product_detail.active))}
                  >
                    {getLabel(item.product_detail.active)}
                  </td>{" "}
                </tr>
              ))
            ) : (
              <tr className={classes.NoData}>
                <td className={classes.LoaderWrap} colSpan={6}>
                  <h2>{t("no_data_found")}</h2>
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className={classes.AddUseAsSection}>
          <button onClick={onClickOfSelect}>{t("select")}</button>
        </div>
      </div>
      <ModalMain
        showmodel={show}
        handleClose={() => setShow(false)}
        size={"xl"}
      >
        <AddSubscriptionModal
          edit={true}
          handleClose={() => setShow(false)}
          EditOrganisationAddSubscriptionClose={
            EditOrganisationAddSubscriptionClose
          }
          orgAndPlanData={{ orgid: SavePropertyListdata, plandata: plan }}
        />
      </ModalMain>
    </React.Fragment>
  );
};

export default EditOrganisationAddSubscription;
