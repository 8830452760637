import React, { useEffect } from "react";
import classes from "./municipalityheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import { useState } from "react";
import MunicipalityForm from "../MunicipalityForm";
import { useDispatch, useSelector } from "react-redux";
import { actionGetMunicipalityTableSystemApiCall } from "../../../Redux/Actions";
import CustomSelect from "../CustomSelect";
import SideBar from "../SideBar";

const MunicipalityHeader = () => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);
  const [name, setName] = useState();
  const [headerSearch, setHeaderSearch] = useState({
    name: "",
    country_part_id: "",
    province_id: "",
  });
  const [sidebar, setSideBar] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.CommonReducer);

  const SelectItems = [
    {
      name: "country_part_id",
      placeholder: t("country_part"),
      options:
        state?.getCountryPartTable &&
        state?.getCountryPartTable?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "country_part",
    },
    {
      name: "province_id",
      placeholder: t("province"),
      options:
        state?.getProvinceData &&
        state?.getProvinceData?.map((p) => {
          return { label: p.name, value: p.id };
        }),
      apiName: "province",
    },
  ];

  useEffect(() => {
    if (!name) filterClick();
  }, [name]);

  const filterClick = () => {
    const body = {
      navigate,
      page: 1,
      search: headerSearch.name,
      country_part_id: headerSearch.country_part_id
        ? headerSearch.country_part_id?.value
        : "",
      province_id: headerSearch.province_id
        ? headerSearch.province_id?.value
        : "",
    };
    dispatch(actionGetMunicipalityTableSystemApiCall(body));
  };

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };
  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")}{" "}
              {t("municipality")}
            </h5>
            <form className={classes.managementFild} onSubmit={onClickOfButton}>
              <div className={classes.fildBox}>
                <input
                  type="text"
                  placeholder={`${t("name_p")}...`}
                  onChange={(e) =>
                    setHeaderSearch({
                      ...headerSearch,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              {SelectItems.map((item) => {
                return (
                  <div className={classes.fildBox}>
                    <CustomSelect
                      isClearable={true}
                      item={item}
                      apiName={item.apiName}
                      isInputChange={true}
                      headerSearch={headerSearch}
                      setHeaderSearch={setHeaderSearch}
                    />
                  </div>
                );
              })}
              <div className={classes.fildBox} onClick={onClickOfButton}>
                <button>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <MunicipalityForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default MunicipalityHeader;
