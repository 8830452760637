import React, { useEffect, useState } from "react";
import classes from "./singlelistingmain.module.scss";
import MainLayount from "./../common/MainLayout/index";
import EditIcon from "../../assets/images/EditLogo.svg";
import SingleListingHeader from "../common/SingleListingHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionEditPropertyStatusApiCall,
  actiongetPriceHistoryApiCall,
  actionGetPropertyDetailApiCall,
  actiongetPropertyViewsApiCall,
  actionUpdatePropertyVisibleApiCall,
} from "../../Redux/Actions";
import Default from "../../assets/images/default.png";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PricingHistoryOfListing from "../ModalComponents/ListingManagementModals/PricingHistoryOfListing";
import ModalMain from "../common/Modal";
import ListingViews from "../ModalComponents/ListingManagementModals/ListingViews";
import moment from "moment";
import EditMainContact from "../ModalComponents/ListingManagementModals/EditMainContact";
import EditListing from "../ModalComponents/ListingManagementModals/EditListing";
import EditMediaFrom from "../ModalComponents/ListingManagementModals/EditMediaFrom";
import { FaEye, FaEyeSlash, FaEdit } from "react-icons/fa";
const SingleListingMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state.CommonReducer.saveSinglePropertyData
  );
  const logdata = useSelector(
    (state) => state.CommonReducer.SaveSinglePropertyViewData
  );
  const params = useParams();
  const [createuser, setCreateUser] = useState(false);
  const [singleListData, SetSingleListData] = useState(state);
  const [historyData, setHistoryData] = useState(false);
  const [editMainContact, setEditMainContact] = useState(false);
  const [editListing, setEdiListing] = useState(false);
  const [editmedia, setEditMedia] = useState(false);
  const [isFloorPlanAvailable, setIsFloorPlanAvailabale] = useState({});
  const [isBrochureAvailable, setIsBrochureAvailable] = useState({});

  useEffect(() => {
    SetSingleListData(state);
  }, [state]);

  useEffect(() => {
    const data = {
      property_id: singleListData?.id ? singleListData?.id : params?.id,
    };
    const body = { data, navigate };
    dispatch(actionGetPropertyDetailApiCall(body));
    dispatch(actiongetPropertyViewsApiCall(body));
  }, []);

  const updatePropertyVisible = (hide, item) => {
    const data = {
      property_id: item.id,
      hide_property: hide,
    };
    const body = { data, navigate, singleData: item.id };
    dispatch(actionUpdatePropertyVisibleApiCall(body));
  };
  const onClickChangeStatus = (id) => {
    const data = {
      property_id: id,
      status: "sold",
    };
    const body = { data, navigate };
    dispatch(actionEditPropertyStatusApiCall(body));
  };
  const onClickOfHistoryFullDoc = (id) => {
    const data = {
      property_id: id,
      page: 1,
    };
    const body = { data, navigate };
    dispatch(actiongetPriceHistoryApiCall(body));
    setHistoryData(true);
  };
  const ListingFullView = () => {
    const data = {
      property_id: singleListData?.id,
      per_page: logdata.count,
    };
    const body = { data, navigate };
    dispatch(actiongetPropertyViewsApiCall(body));
    setCreateUser(true);
  };
  const updatePropertyVisibleIcon = (hide, item) => {
    const data = {
      property_id: item.id,
      hide_property: hide,
    };
    const body = { data, navigate, singleData: item.id };
    dispatch(actionUpdatePropertyVisibleApiCall(body));
  };

  const editmediaShow = () => {
    setEditMedia(true);
  };

  useEffect(() => {
    setIsFloorPlanAvailabale(
      singleListData?.image_list?.find((item) => item.type == "FLOORPLAN")
    );

    setIsBrochureAvailable(
      singleListData?.image_list?.find((item) => item.type == "BROCHURE")
    );
  }, [singleListData?.image_list]);

  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.main}>
          <SingleListingHeader />
          <div className={classes.MainLayoutOrg}>
            <div className={classes.OrgDetails}>
              <div className={classes.basicOrgDetails}>
                <div className={classes.orgLogo}>
                  <div className={classes.IconWrap}>
                    {singleListData?.hide_property == 0 ? (
                      <FaEye
                        onClick={() =>
                          updatePropertyVisibleIcon(1, singleListData)
                        }
                      />
                    ) : (
                      <FaEyeSlash
                        onClick={() =>
                          updatePropertyVisibleIcon(0, singleListData)
                        }
                      />
                    )}
                    <FaEdit onClick={editmediaShow} />
                  </div>
                  <div className={classes.UplodLinks}>
                    {/* <p>
                      {singleListData?.image_list.length > 0 &&
                        singleListData?.image_list?.map((val) => {
                          if (val.type == "FLOORPLAN") {
                            return (
                              <>
                                <span onClick={editmediaShow}>
                                  {t("floorplan_uploaded")}
                                </span>
                              </>
                            );
                          } else {
                            return (
                              <>
                                {t("no_floorplan_uploaded_yet")}&nbsp;
                                <span onClick={editmediaShow}>
                                  {t("upload_now")}
                                </span>
                              </>
                            );
                          }
                        })}
                    </p> */}
                    <p>
                      {isFloorPlanAvailable &&
                      Object.keys(isFloorPlanAvailable).length > 0 ? (
                        <>
                          <span onClick={editmediaShow}>
                            {t("floorplan_uploaded")}
                          </span>
                        </>
                      ) : (
                        <>
                          {t("no_floorplan_uploaded_yet")}&nbsp;
                          <span onClick={editmediaShow}>{t("upload_now")}</span>
                        </>
                      )}
                    </p>
                    <p>
                      {isBrochureAvailable &&
                      Object.keys(isBrochureAvailable).length > 0 ? (
                        <span onClick={editmediaShow}>
                          {t("brochure_uploaded")}
                        </span>
                      ) : (
                        <>
                          {t("no_brochure_uploaded_yet")}&nbsp;
                          <span onClick={editmediaShow}>{t("upload_now")}</span>
                        </>
                      )}
                    </p>
                  </div>
                  <img
                    src={
                      singleListData?.image_list &&
                      singleListData?.image_list[0]?.url_original_file
                        ? singleListData?.image_list[0]?.url_original_file
                        : Default
                    }
                    alt="orgLogo"
                  />
                </div>
                <p className={classes.orgName}>{singleListData?.address}</p>
                <div className={classes.OtherDetails}>
                  <div className={classes.flex}>
                    <div className={classes.SomeSpacing}>
                      <p>{t("city_p")}</p>
                      <p className={classes.ItalicText}>
                        {singleListData?.city}
                      </p>
                    </div>
                    <div className={classes.SomeSpacingFixedWidth}>
                      <p>{t("postal_code")}:</p>
                      <p className={classes.ItalicText}>
                        {singleListData?.postcode
                          ? singleListData?.postcode
                          : "NA"}
                      </p>
                    </div>
                  </div>
                  {singleListData?.is_sell == 1 && (
                    <>
                      <div className={classes.flex}>
                        <div className={classes.SomeSpacing}>
                          <p>{"Type of listing:"}</p>
                          {singleListData?.is_sell == 1 && (
                            <p className={classes.ItalicText}>{t("sell")}</p>
                          )}
                        </div>
                        <div className={classes.SomeSpacingFixedWidth}>
                          <p>{t("price_of_listing:")}</p>
                          <p className={classes.ItalicText}>
                            {singleListData?.sell_price}
                          </p>
                        </div>
                      </div>
                      <div className={classes.flex}>
                        <div className={classes.SomeSpacing}>
                          <p>{t("Price Type")}</p>
                          <p className={classes.ItalicText}>
                            {singleListData?.sell_postfix
                              ? singleListData?.sell_postfix
                              : "NA"}
                          </p>
                        </div>
                        <div className={classes.SomeSpacingFixedWidth}>
                          <p>{t("price_info:")}</p>
                          <p className={classes.ItalicText}>
                            {singleListData?.sell_prefix
                              ? singleListData?.sell_prefix
                              : "NA"}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {singleListData?.is_rent == 1 && (
                    <>
                      <div className={classes.flex}>
                        <div className={classes.SomeSpacing}>
                          <p>{"Type of listing:"}</p>
                          {singleListData?.is_rent == 1 && (
                            <p className={classes.ItalicText}>{t("Rent")}</p>
                          )}
                        </div>
                        <div className={classes.SomeSpacingFixedWidth}>
                          <p>{t("price_of_listing:")}</p>
                          <p className={classes.ItalicText}>
                            {singleListData?.rent_price}
                          </p>
                        </div>
                      </div>
                      <div className={classes.flex}>
                        <div className={classes.SomeSpacing}>
                          <p>{t("Price Type")}</p>
                          <p className={classes.ItalicText}>
                            {singleListData?.rent_postfix
                              ? singleListData?.rent_postfix
                              : "NA"}
                          </p>
                        </div>
                        <div className={classes.SomeSpacingFixedWidth}>
                          <p>{t("price_info:")}</p>
                          <p className={classes.ItalicText}>
                            {singleListData?.rent_prefix
                              ? singleListData?.rent_prefix
                              : "NA"}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  <div className={classes.flex}>
                    <div className={classes.SomeSpacing}>
                      <p>{t("no_of_bedrooms")}</p>
                      <p className={classes.ItalicText}>
                        {singleListData?.no_bedroom
                          ? singleListData?.no_bedroom
                          : "NA"}
                      </p>
                    </div>
                    <div className={classes.SomeSpacingFixedWidth}>
                      <p>{t("Square meters of listing:")}</p>
                      <p className={classes.ItalicText}>
                        {singleListData?.livable_area
                          ? singleListData?.livable_area
                          : "NA"}
                      </p>
                    </div>
                  </div>
                  <div className={classes.Flex}>
                    <div>
                      <p>{t("created_at")}</p>
                      <p className={classes.ItalicText}>
                        {singleListData?.created_at
                          ? moment(singleListData?.created_at).format(
                              "DD/MM/YYYY HH:MM"
                            )
                          : "NA"}
                      </p>
                    </div>
                  </div>
                  <div
                    className={classes.EditIcon}
                    onClick={() => setEdiListing(true)}
                  >
                    <img src={EditIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "fit-content",
                width: "70%",
              }}
            >
              <div className={classes.QuickOptAndInvoiceContainer}>
                <div className={classes.QuickOptionContainer}>
                  <div className={classes.BoldMedText}>
                    <p>{t("quick_options")}</p>
                  </div>
                  <div className={classes.SimpleGreyText}>
                    <p onClick={() => onClickChangeStatus(singleListData?.id)}>
                      {t("change_status")}
                    </p>
                    <p onClick={() => toast.success("will be added soon")}>
                      {t("cut_attachment_from_organisation")}
                    </p>
                    {singleListData?.hide_property == 0 ? (
                      <p
                        onClick={() => updatePropertyVisible(1, singleListData)}
                      >
                        {t("Hide listing from public view")}
                      </p>
                    ) : (
                      <p
                        onClick={() => updatePropertyVisible(0, singleListData)}
                      >
                        {t("online_listing_from_public_view")}
                      </p>
                    )}
                  </div>
                </div>
                <div className={classes.UserLogginLayout}>
                  <div className={classes.TextAndActionBtn}>
                    <p>
                      {t("listing_views")} {">"}
                      <span> {logdata?.count}</span>
                    </p>
                    <button onClick={ListingFullView}>
                      {t("view_full_document")}
                    </button>
                  </div>
                  <div className={classes.LoginHistroyLayout}>
                    {logdata?.rows?.length > 0 ? (
                      logdata?.rows?.map((item, i) => {
                        return (
                          <>
                            {i < 3 && (
                              <div className={classes.logginContentRow}>
                                <p>
                                  {item.property_view.full_name
                                    ? item.property_view.full_name
                                    : "NA"}
                                </p>
                                <p>{item.device_type}</p>
                                <p>
                                  {item.created_at
                                    ? moment(item.created_at).format(
                                        "DD/MM/YYYY HH:MM"
                                      )
                                    : "NA"}
                                </p>
                              </div>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <div className={classes.logginContentRow}>
                        <h2>{t("no_data_found")}</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.UsersAndOrgBranches}>
                <div className={classes.UsersContainer}>
                  <div className={classes.HeaderAndSearchBar}>
                    <p>{t("main_contact")}</p>
                  </div>
                  <div className={classes.MainContactCard}>
                    <div className={classes.MainUserImg}>
                      <img
                        src={
                          singleListData?.user_detail?.url_original_file
                            ? singleListData?.user_detail.url_original_file
                            : Default
                        }
                        alt=""
                      />
                    </div>
                    <div className={classes.MainContactDetails}>
                      <p>
                        {/* {t("Mr")}{" "} */}
                        <span className={classes.BoldMedText}>
                          {singleListData?.full_name}
                        </span>
                      </p>
                      <p>{singleListData?.user_detail?.email}</p>
                      <p>
                        {singleListData?.user_detail?.phone
                          ? singleListData?.user_detail.phone
                          : "NA"}
                      </p>
                    </div>
                  </div>
                  <div className={classes.MainContactOtherListing}>
                    <p className={classes.simpleTextStyle}>
                      {t("main_contact")} {t("has")}{" "}
                      <span className={classes.BlueColorText}>
                        {singleListData?.user_detail?.PropertyCount}
                      </span>{" "}
                      {t("other listings,")}
                    </p>
                    <p className={classes.simpleTextStyle}>
                      {t("and is part of")}
                      <span className={classes.BlueColorText}>
                        {" "}
                        {singleListData?.user_detail?.AgencyCount}{" "}
                      </span>{" "}
                      {t("organisation_c")}.
                    </p>
                  </div>
                  <div
                    className={classes.editIcon}
                    onClick={() => setEditMainContact(true)}
                  >
                    <img src={EditIcon} alt="" />
                  </div>
                </div>
                <div className={classes.OrganisatonBranchesContainer}>
                  <div className={classes.HeaderOrgBranches}>
                    <p>{t("organisation")}</p>
                  </div>
                  {singleListData?.agency_detail ? (
                    <div className={classes.OrgLogoAndName}>
                      <div className={classes.OrgLogo}>
                        <img
                          src={
                            singleListData?.agency_detail?.logo_image
                              ? singleListData?.agency_detail.logo_image
                              : Default
                          }
                          alt=""
                        />
                      </div>
                      <p>{singleListData?.agency_detail?.name}</p>
                    </div>
                  ) : (
                    <div className={classes.OrgLogoAndName}>
                      <h3>{t("no_organisation_data")}</h3>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.PriceHistoryAndAddedLater}>
                <div className={classes.PriceHistory}>
                  <div className={classes.TextAndActionBtn}>
                    <p>
                      {t("price_history")}{" "}
                      <span>
                        {" "}
                        {">"} {t("since")} {singleListData?.construction_year}
                      </span>
                    </p>
                    <button
                      onClick={() =>
                        onClickOfHistoryFullDoc(singleListData?.id)
                      }
                    >
                      {t("view_full_document")}
                    </button>
                  </div>
                  <div className={classes.LoginHistroyLayout}>
                    {singleListData?.PropertyPriceHistory?.length > 0 ? (
                      singleListData?.PropertyPriceHistory?.map((item, i) => (
                        <div className={classes.logginContentRow}>
                          <p>{item.date}</p>
                          <p>{item.price}</p>
                        </div>
                      ))
                    ) : (
                      <div className={classes.logginContentRow}>
                        <h3>{t("no_data_found")}</h3>
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.WillBeAdded}></div>
              </div>
            </div>
          </div>
        </div>
      </MainLayount>
      <ModalMain
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        size={"lg"}
      >
        <ListingViews />
      </ModalMain>
      <ModalMain
        showmodel={historyData}
        handleClose={() => setHistoryData(false)}
        size={"sm"}
      >
        <PricingHistoryOfListing />
      </ModalMain>
      <ModalMain
        showmodel={editMainContact}
        handleClose={() => setEditMainContact(false)}
        size={"xl"}
      >
        <EditMainContact
          handleClose={() => setEditMainContact(false)}
          edit={true}
        />
      </ModalMain>
      <ModalMain
        showmodel={editListing}
        handleClose={() => setEdiListing(false)}
        size={"xl"}
      >
        <EditListing handleClose={() => setEdiListing(false)} />
      </ModalMain>
      <ModalMain
        showmodel={editmedia}
        handleClose={() => setEditMedia(false)}
        size={"md"}
      >
        <EditMediaFrom handleClose={() => setEditMedia(false)} />
      </ModalMain>
    </React.Fragment>
  );
};

export default SingleListingMain;
