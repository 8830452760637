import React from "react";
import EmailMarketingMain from "../../components/EmailMarketingMain/Index";

const EmailMarketingPage = () => {
  return (
    <React.Fragment>
      <EmailMarketingMain/>
    </React.Fragment>
  );
};

export default EmailMarketingPage
