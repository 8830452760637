import React from "react";
import classes from "./createmenu.module.scss";
import { useTranslation } from "react-i18next";

const CreateMenu = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.CreateMenuMain}>
      <h3>{t("create_new_Menu")}</h3>
      <div className={classes.CreateMenuWrap}>
        <div className={classes.FlexBox}>
          <div className={classes.InputWrap}>
            <p>{t("position")}</p>
            <select>
              <option></option>
              <option></option>
              <option></option>
              <option></option>
            </select>
          </div>
          <div className={classes.InputWrap}>
            <p>{t("Section")}</p>
            <select>
              <option></option>
              <option></option>
              <option></option>
              <option></option>
            </select>
          </div>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Menu Title")}</p>
          <input type="text" />
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Menu Link")}</p>
          <input type="text" />
        </div>
        <div className={classes.InputWrap}>
          <button>{t("Brouse")}</button>
        </div>
        <div className={classes.IsDefalutWrap}>
          <input
            type="checkbox"
            id="Default"
            name="org"
            className={classes.radioCustom}
          />
          <label className={classes.radioCustomLabel} htmlFor="Default"></label>
          <p>{t("is_extend")}</p>
        </div>
        <div className={classes.btnWrap}>
          <button>{t("Save")}</button>
        </div>
      </div>
    </div>
  );
};

export default CreateMenu;
