import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./createtemplate.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createRoot } from 'react-dom/client';

// import ImageLink from '../../ImageLink/ImageLink'; 
// import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import { SlCalender } from "react-icons/sl";
import Select from "react-select";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import {
  actionAddEmailApiCall,
  actionLangListAPICall,
  getCreatedTemplateApiCall,
  getTemplateApiCall,
} from "../../../Redux/Actions";
import APIUrl from "../../../API/APIUrl";
import JoditEditor from "jodit-react";
import { FaBlackTie } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { custom_Button_LinkIcon } from "../../../assets/icons/EditButtonLinkEmailEditor";
import Swal from "sweetalert2";
import { isValidUrl, rgbStringToHex } from "../../../utils/commonFunctions";
// Define a custom Blot for handling image links
class ImageLinkBlot extends Quill.import("formats/link") {
  static create(linkData) {
    const node = super.create(linkData);
    node.setAttribute("href", linkData);
    node.setAttribute("target", "_blank"); // Open the link in a new tab
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute("href");
  }

  format(name, value) {
    if (name === "imageLink" && value) {
      this.domNode.setAttribute("href", this.constructor.sanitize(value));
      this.domNode.setAttribute("target", "_blank");
    } else {
      super.format(name, value);
    }
  }
}

ImageLinkBlot.blotName = "imageLink";
ImageLinkBlot.tagName = "a";
Quill.register(ImageLinkBlot, true);

const EditableButtonLink = Quill.import('blots/block/embed');


class ButtonBlot extends EditableButtonLink {
  static create(value) {
    // during add button link first time thicode will run  and data type of value is string
    //during edit mode , when data loaded from server to react Quill editor first time then datatype of value is object 
    // there is one issue , during edit time data of image and normal link will also come into this class,
    // so here conditionally only apply the necessary attribute to image and normal links 
    let node = super.create();
    // if ( defaultValue) return node

    // console.log(value)
    // if (defaultValue === true) {
    // value = prompt("enter button name ") 
    // Ensure value is properly formatted


    const innerHTML = typeof value === 'string' ? value.trim() : value.innerHTML;
    const buttonText = typeof value === 'string' ? value.trim() : value.buttonText.trim();
    const color = typeof value === 'string' ? 'rgb(255, 255, 255)' : value.color;
    const background = typeof value === 'string' ? 'rgb(0, 0, 0)' : value.background;
    const href = typeof value === 'string' ? window.location.origin : value.href;
    console.log(value)
    // Handle case for images
    if (innerHTML.includes('<img')) {
      node.setAttribute('href', href);
      return node;
    }

    // Handle case for normal links
    if (typeof value !== 'string' && value.contenteditable === null) {
      node.setAttribute('href', href);
      return node;
    }

    // Create an a tag
    const aTag = document.createElement('a');
    aTag.setAttribute('href', href);
    aTag.setAttribute('target', '_blank');
    aTag.setAttribute('style', `
     background: ${background};
    color: ${color};
    font-size: 18px;
    
  padding: 10px 20px;
    width:100%;    
    border: none;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block
    `);
    aTag.textContent = buttonText;

    // Append the a tag to the button node
    node.appendChild(aTag);

    // Set attributes for button
    node.setAttribute('contenteditable', false);
    node.setAttribute('style', `
    background: ${background};
    color: ${color};
    font-size: 18px;
    padding: 0;
    border: none;
    display: flex;
     
    border-radius: 3px;
    text-align: center;
    margin: 5px auto;
 
    `);


    node.onclick = (event) => {
      event.preventDefault(); // Prevent redirection



      console.log(aTag.href)
      Swal.fire({
        title: 'Edit Button Properties',
        html: `
    <style>
      #swal-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .swal-form-group {
          display: flex;
          flex-direction: column;        
          column-gap:15px;        
      }
      .swal-form-group label {        
          text-wrap:nowrap;
      }
      .swal-form-group input {
        flex-grow: 1;
        margin:0;
        padding:0;
        padding :0 6px;
      }      
      .swal-form-group input[type="color"]{      
        cursor:pointer;
        padding: 5px;
      }
    </style>
    <form id="swal-form">
      <div class="swal-form-group">
        <label for="buttonText">Button Name:</label>
        <input type="text" id="buttonText" class="swal2-input" value="${aTag.textContent.trim()}">
      </div>
      <div class="swal-form-group">
        <label for="linkUrl">URL:</label>
        <input type="text" id="linkUrl"  class="swal2-input" value="${aTag.href}">
      </div>
      <div class="swal-form-group">
        <label for="textColor">Text Color:</label>
        <input type="color" id="textColor" class="swal2-input" value="${rgbStringToHex(aTag.style.color)}">
      </div>
      <div class="swal-form-group">
        <label for="bgColor">Background Color:</label>
        <input type="color" id="bgColor" class="swal2-input" value="${rgbStringToHex(aTag.style.background)}">
      </div>
    </form>
  `,
        showCancelButton: true,
        preConfirm: () => {
          const form = document.getElementById('swal-form');
          const buttonText = form.buttonText.value.trim();
          const linkUrl = form.linkUrl.value.trim();
          const textColor = form.textColor.value;
          const bgColor = form.bgColor.value;

          if (!buttonText?.trim()) {
            Swal.showValidationMessage('Please enter a Button text');
            return false;
          }

          if (!isValidUrl(linkUrl)) {
            Swal.showValidationMessage('Please enter a valid URL');
            return false;
          }

          return {
            buttonText,
            linkUrl,
            textColor,
            bgColor
          };
        },
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          const { buttonText, linkUrl, textColor, bgColor } = result.value;

          aTag.textContent = buttonText;
          aTag.href = linkUrl;
          aTag.style.color = textColor;
          aTag.style.background = bgColor;
        }
      });

    };

    return node;
  }

  static value(node) {
    const aTag = node.querySelector('a');
    return {
      href: aTag.getAttribute('href'),
      innerHTML: aTag.innerHTML,
      buttonText: aTag.textContent.trim(),
      background: aTag.style.background,
      color: aTag.style.color,
      contenteditable: node.getAttribute('contenteditable')
    };
  }
}

ButtonBlot.blotName = 'custom_Button_Link';
ButtonBlot.tagName = 'button';

Quill.register(ButtonBlot, true);


const CreateTemplate = (props) => {
  const url = `${APIUrl.backendUrl}/add-email-mkt-template-logo`
  const [values, setValues] = useState("")
  const [submitAllowed, setSubmitAllowed] = useState(true)
  const [editorData, setEditorData] = useState('');
  const quillRef = useRef(null);
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  let close = props.onClose;

  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  // Function to clear the selected image
  useEffect(() => {
    const quill = quillRef.current.getEditor();
    let clickTimer = null;

    const handleImageUpload = () => {
      console.log("Image button clicked");
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.click();

      fileInput.onchange = async () => {
        const file = fileInput.files[0];
        if (file) {
          const range = quill.getSelection(true);
          const loadingText = "Loading...";
          quill.insertText(range.index, loadingText, Quill.sources.USER);
          quill.setSelection(range.index + loadingText.length);

          // Attempt to upload the image
          const uploadedImageUrl = await uploadImage(
            file,
            range.index,
            loadingText.length
          );
          if (uploadedImageUrl) {
            console.log("Image uploaded successfully:", uploadedImageUrl);
          } else {
            console.error("Image upload failed");
          }
        } else {
          console.error("No file selected for upload");
        }
      };
    };

    const toolbar = quill.getModule("toolbar");
    toolbar.addHandler("image", handleImageUpload);

    // Add custom handlers for image click and double-click
    const handleImageClick = (event) => {
      const target = event?.target;
      if (target && target.tagName === "IMG") {
        clearTimeout(clickTimer);
        clickTimer = setTimeout(() => {
          const parentAnchor = target?.parentNode;
          if (parentAnchor && (parentAnchor.tagName === "A" || parentAnchor.tagName === "a")) {
            // If the image is already wrapped in a link, prompt to edit the link

            const currentLink = parentAnchor?.getAttribute("href");
            Swal.fire({
              title: 'Edit the link for the image',
              input: 'url',
              inputValue: currentLink,
              showCancelButton: true,
              inputValidator: (value) => {
                if (!isValidUrl(value)) {
                  return 'You need to enter a valid URL!';
                }
                // Optional: Add more validation if needed
                return null;
              }
            }).then((result) => {
              if (result.isConfirmed) {
                const newLinkUrl = result.value;
                parentAnchor?.setAttribute("href", newLinkUrl);
                console.log("Link updated:", newLinkUrl); // Log the updated link
              }
            });
          } else {
            // If the image is not wrapped in a link, prompt the user for a new link
            Swal.fire({
              title: 'Enter a link for the image',
              input: 'url',
              showCancelButton: true,
              inputValidator: (value) => {
                if (!isValidUrl(value)) {
                  return 'You need to enter a valid URL!';
                }
                // Optional: Add more validation if needed
                return null;
              }
            }).then((result) => {
              if (result.isConfirmed) {
                const linkUrl = result.value;
                if (linkUrl) {
                  // Create a new anchor element and wrap the image with it
                  const anchor = document.createElement('a');
                  anchor.setAttribute('href', linkUrl);
                  anchor.setAttribute('target', '_blank');
                  target.parentNode.insertBefore(anchor, target);
                  anchor.appendChild(target);
                  console.log("Link added:", linkUrl); // Log the added link
                }
              }
            });
          }
        }, 300); // Delay for single click
      }
    };

    const handleImageDoubleClick = (event) => {
      clearTimeout(clickTimer);
      const target = event.target;
      if (target && target.tagName === "IMG") {
        const parentAnchor = target.parentNode;
        if (parentAnchor && parentAnchor.tagName === "A") {
          // If the image is wrapped in a link, open the link in a new tab
          event.preventDefault(); // Prevent default action
          const linkUrl = parentAnchor.getAttribute("href");
          if (linkUrl) {
            window.open(linkUrl, "_blank");
          }
        }
      }
    };

    quill.root.addEventListener("click", handleImageClick);
    quill.root.addEventListener("dblclick", handleImageDoubleClick);

    return () => {
      quill.root.removeEventListener("click", handleImageClick);
      quill.root.removeEventListener("dblclick", handleImageDoubleClick);
      // toolbar?.removeHandler("image", handleImageUpload);
    };
  }, []);

  let dummyCount = 0;
  const uploadImage = async (file, index, length) => {
    setSubmitAllowed(false)
    dummyCount = dummyCount + 1;

    const formData = new FormData();
    formData.append("logo", file);

    try {
      console.log("Uploading image...");
      const response = await fetch(url,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        // setSubmitAllowed(true)
        setCount(dummyCount - 1)
        dummyCount = dummyCount - 1;
        const uploadedImageUrl = result.url; // Adjust this based on your API response structure

        // Replace the loading text with the uploaded image URL
        const quill = quillRef.current.getEditor();
        quill.deleteText(index, length, Quill.sources.USER); // Remove loading text
        quill.insertEmbed(index, "image", uploadedImageUrl, Quill.sources.USER);
        quill.setSelection(index + 1);
        logContentAndImages(); // Log content and images when a new image is added

        return uploadedImageUrl;
      } else {
        console.error("Error uploading image: ", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error uploading image: ", error);
      return null;
    }
  };

  const logContentAndImages = () => {
    const quill = quillRef.current.getEditor();
    const contents = quill.getContents();
    const deltaOps = contents.ops;
    const images = deltaOps
      .filter((op) => op.insert && op.insert.image)
      .map((op) => op.insert.image);

    console.log("Editor Content:", quill.root.innerHTML);
    console.log("Images:", images);
  };

  const handleChange = (content, delta, source, editor) => {
    setEditorData(content);
    logContentAndImages();
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "custom_Button_Link"], // Include custom_Button_Link in the toolbar
      ,
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageLink",
    "custom_Button_Link", // Add custom_Button_Link to the formats

  ];
  useEffect(() => {
    const quill = quillRef.current.getEditor();

    // Add custom button to toolbar
    const toolbar = quill.getModule('toolbar');

    // Define a new handler for the custom button
    const customButtonHandler = () => {
      const range = quill.getSelection(true);
      Swal.fire({
        title: 'Edit Button Properties',
        html: `
    <style>
      #swal-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .swal-form-group {
          display: flex;
          flex-direction: column;        
          column-gap:15px;        
      }
      .swal-form-group label {        
          text-wrap:nowrap;
      }
      .swal-form-group input {
        flex-grow: 1;
        margin:0;
        padding:0;
        padding :0 6px;
      }      
      .swal-form-group input[type="color"]{      
        cursor:pointer;
        padding: 5px;
      }
    </style>
    <form id="swal-form">
      <div class="swal-form-group">
        <label for="buttonText">Button Name:</label>
        <input type="text" id="buttonText" class="swal2-input"  >
      </div>
      <div class="swal-form-group">
        <label for="linkUrl">URL:</label>
        <input type="text" id="linkUrl" class="swal2-input"  >
      </div>
      <div class="swal-form-group">
        <label for="textColor">Text Color:</label>
        <input type="color" id="textColor" class="swal2-input" value="#FFFFFF">
      </div>
      <div class="swal-form-group">
        <label for="bgColor">Background Color:</label>
        <input type="color" id="bgColor" class="swal2-input" value="#000000">
      </div>
    </form>
  `,
        showCancelButton: true,
        preConfirm: () => {
          const form = document.getElementById('swal-form');
          const buttonText = form.buttonText.value.trim();
          const linkUrl = form.linkUrl.value.trim();
          const textColor = form.textColor.value;
          const bgColor = form.bgColor.value;
          if (!buttonText?.trim()) {
            Swal.showValidationMessage('Please enter a Button Text');
            return false;
          }

          if (!isValidUrl(linkUrl)) {
            Swal.showValidationMessage('Please enter a valid URL');
            return false;
          }

          return {
            buttonText,
            linkUrl,
            textColor,
            bgColor
          };
        },

        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          const { buttonText, linkUrl, textColor, bgColor } = result.value;

          const defaultButtonValue = {
            buttonText: buttonText,
            innerHTML: "",
            href: linkUrl,
            color: textColor,
            background: bgColor
          };

          quill.insertEmbed(range.index, 'custom_Button_Link', defaultButtonValue, Quill.sources.USER);
          quill.setSelection(range.index + 1);

        }
      });

    }; 

    // Add the new handler
    toolbar.addHandler('custom_Button_Link', customButtonHandler);

    // Add custom icon 
    const custom_Button_Link = document.querySelector('.ql-custom_Button_Link');
    if (custom_Button_Link) {
      custom_Button_Link.innerHTML = '';
      const root = createRoot(custom_Button_Link);
      root.render(custom_Button_LinkIcon);
    }
  }, []);

  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
      width: "100%"
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const handleTag = (e) => {
    setValues(e.target.value)
  }
  const apiCall = () => {
    const data = JSON.stringify({
      "title": values,
      "content": editorData
    });
    console.log(data, "datafromapicall")
    const body = { data, navigate, close };
    dispatch(getTemplateApiCall(body));
    setTimeout(() => {
      dispatch(getCreatedTemplateApiCall())
    }, 1000);
  };
  const handlePost = () => {
    if (values.length === 0 || values == "" || editorData.length === 0 || editorData == "") {
      toast.error("Please fill All fields to continue")
    } else {
      apiCall()
      setValues("")
      setEditorData("")
    }

  }
  const [email, setEmail] = useState("")
  const [textData, setTextData] = useState("")
  const [count, setCount] = useState(null)
  useEffect(() => {
    console.log(editorData, "editorrrrrr22");
  }, [editorData])
  useEffect(() => {
    if (count === 0) {
      console.log(count, "count count");
      setSubmitAllowed(true);
      setCount(null)
    }
  }, [count])

  const editorConfig = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      'mediaEmbed',
      "imageUpload",
      "blockQuote",
      "insertTable",
      "|",
      "alignment",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
    ],
    mediaEmbed: {
      previewsInData: true
    },
    image: {
      toolbar: [
        "imageStyle:alignLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignRight",
        "|",
        "imageTextAlternative",
        // "linkImage",
      ],
      // plugins:["linkImage"],
      styles: [
        "alignLeft", "alignCenter", "alignRight"
      ]
    },
  };

  return (
    <div className={classes.EditEmailMain}>
      <h3> Create Template</h3>
      <div className={classes.EditEmailWrap}>
        <div className={classes.InputWrap}>
          <p className={classes.to}>{t("Template")}</p>
          <p>{t("Create Your Desired Template here.")}</p>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Template's Title")}</p>
          <input
            type="text"
            name="Title"
            value={values}
            onChange={handleTag}
            required
          />
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Content")}</p>
          {/* <CKEditor
          editor={ClassicEditor}
          config={editorConfig}
          data={editorData}
          onReady={(editor) => {
            editorRef.current = editor;
            uploadPlugin(editor);
          }}
          uploadPlugin={uploadPlugin}
          onChange={handleEditorChange}
        /> */}
          <ReactQuill
            ref={quillRef}
            value={editorData}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          />
        </div>

        <div className={classes.btnWrap}>
          {submitAllowed ? <>
            <button type="button" onClick={handlePost}>
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {t("Save")}
            </button>
          </> : <button type="button" disabled>
            {loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("Processing..")}
          </button>}{" "}
          <button onClick={() => props.onClose()} className={classes.cancelBtn}>
            {" "}
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;
