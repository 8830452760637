import React, { useEffect } from "react";
import classes from "./footer.module.scss";
import Rigth from "../../../assets/icons/RigthBtn.svg";
import Left from "../../../assets/icons/LeftBtn.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionBannerListApiCall,
  actionClassificationGetTableApi,
  actionEmailListApiCall,
  actionGetBranchTableSystemApiCall,
  actionGetCitySystemApiCall,
  actionGetCountriesTableSystemApiCall,
  actionGetCountryPartTableSystemApiCall,
  actionGetDiscountAndExtraApiCall,
  actionGetDistrictTableSystemApiCall,
  actionGetMunicipalityTableSystemApiCall,
  actionGetNeighbourhoodTableSystemApiCall,
  actionGetPropertyListAPICall,
  actionGetProvinceApiCall,
  actionGetSubscriptionApiCall,
  actionGetTranslationApiCall,
  actionGetTranslationColumnApiCall,
  actionGetVatApiCall,
  actionGetZipcodeApiCall,
  actionLangLabelListAPICall,
  actionOrganizationListApiCall,
  actionUserListApiCall,
  actionSuppliersApiCall,
} from "../../../Redux/Actions";

const FooterMain = () => {
  const pathname = useLocation().pathname;
  const state = useSelector((state) => state.CommonReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalpage, setTotalPage] = React.useState();
  const params = useParams();

  useEffect(() => {
    if (pathname.includes("user")) {
      let ans = Math.ceil(state?.totalUserCount / state?.perPageUserCount);
      setTotalPage(ans != NaN ? ans : 1);
    } else if (pathname.includes("organisation")) {
      let ans = Math.ceil(state?.totalOrgCount / state?.perPageOrgCount);
      setTotalPage(ans != NaN ? ans : 1);
    } else if (pathname.includes("listing")) {
      let ans = Math.ceil(state?.totalListCount / state?.perPageListCount);
      setTotalPage(ans != NaN ? ans : 1);
    } else if (pathname.includes("lang")) {
      let ans = Math.ceil(state?.totalLangCount / state?.perPageLangCount);
      setTotalPage(ans != NaN ? ans : 1);
    } else if (pathname.includes("banner")) {
      let ans = Math.ceil(state?.totalBannerCount / state?.perPageBannerCount);
      setTotalPage(ans != NaN ? ans : 1);
    } else if (pathname.includes("email")) {
      let ans = Math.ceil(state?.totalEmailCount / state?.perPageEmailCount);
      setTotalPage(ans != NaN ? ans : 1);
    } else if (pathname.includes("mastertable")) {
      // let ans = Math.ceil(
      //   state?.totalMasterTableCount / state?.perPageMasterTableCount
      // );
      let ans = Math.ceil(state?.totalTranslationCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("discount")) {
      let ans = Math.ceil(state?.totalDiscountCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("subscription")) {
      let ans = Math.ceil(state?.totalSubscriptionCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("Branch")) {
      let ans = Math.ceil(state?.totalBranchCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("classification")) {
      let ans = Math.ceil(state?.totalClassficationCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("countries")) {
      let ans = Math.ceil(state?.totalCountrieCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("municipality")) {
      let ans = Math.ceil(state?.totalMunicipalityCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("district")) {
      let ans = Math.ceil(state?.totalDistrictCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("neighbourhood")) {
      let ans = Math.ceil(state?.totalNeighbourhoodCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("countrypart")) {
      let ans = Math.ceil(state?.totalCountryPartCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("city")) {
      let ans = Math.ceil(state?.totalCityCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("province")) {
      let ans = Math.ceil(state?.totalProvinceCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("zipcode")) {
      let ans = Math.ceil(state?.totalZipcodeCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("translation")) {
      let ans = Math.ceil(state?.totalTranslationCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("/mastertable")) {
      let ans = Math.ceil(state?.totalTranslationCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    } else if (pathname.includes("vat")) {
      let ans = Math.ceil(state?.totalVatDataCount / 40);
      setTotalPage(ans != NaN && ans > 0 ? ans : 1);
    }
  }, [pathname, state]);

  useEffect(() => {
    if (totalpage == 1) setCurrentPage(1);
    if (totalpage == NaN) setTotalPage(1);
  }, [totalpage]);

  useEffect(() => {
    if (pathname.includes("discount")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetDiscountAndExtraApiCall(body));
    } else if (pathname.includes("Branch")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetBranchTableSystemApiCall(body));
    } else if (pathname.includes("classification")) {
      const body = { navigate, page: currentPage };
      dispatch(actionClassificationGetTableApi(body));
    } else if (pathname.includes("countries")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetCountriesTableSystemApiCall(body));
    } else if (pathname.includes("municipality")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetMunicipalityTableSystemApiCall(body));
    } else if (pathname.includes("district")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetDistrictTableSystemApiCall(body));
    } else if (pathname.includes("neighbourhood")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetNeighbourhoodTableSystemApiCall(body));
    } else if (pathname.includes("countrypart")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetCountryPartTableSystemApiCall(body));
    } else if (pathname.includes("city")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetCitySystemApiCall(body));
    } else if (pathname.includes("province")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetProvinceApiCall(body));
    } else if (pathname.includes("zipcode")) {
      const body = { navigate, page: currentPage };
      dispatch(actionGetZipcodeApiCall(body));
    } else if (pathname.includes("translation")) {
      const body = {
        navigate,
        search: false,
        language: "en",
        page: currentPage,
      };
      dispatch(actionGetTranslationColumnApiCall(body));
    } else if (pathname.includes("/mastertable")) {
      const body = {
        navigate,
        search: false,
        language: "en",
        page: currentPage,
        column: params.column,
      };
      dispatch(actionGetTranslationColumnApiCall(body));
    } else if (pathname.includes("vat")) {
      const body = {
        navigate,
        page: currentPage,
      };
      dispatch(actionGetVatApiCall(body));
    }
  }, []);

  const apiCall = (pageValue) => {
    setCurrentPage(pageValue);

    if (pathname == "/user-management") {
      const data = {
        page: pageValue,
        per_page: state?.perPageUserCount,
        name: state?.saveUserFilterData?.name,
        status: state?.saveUserFilterData?.status,
      };
      const body = { data, navigate };
      dispatch(actionUserListApiCall(body));
    } else if (pathname == "/organisation-management") {
      const data = {
        page: pageValue,
        per_page: state?.perPageOrgCount,
        name: state?.saveOrgFilterData?.name,
        city: state?.saveOrgFilterData?.city,
      };
      const body = { data, navigate };
      dispatch(actionOrganizationListApiCall(body));
    } else if (pathname == "/listing-management") {
      const data = {
        page: pageValue,
        per_page: state?.perPageListCount,
        address: state?.saveListFilterData?.address,
        city: state?.saveListFilterData?.city,
        status: state?.saveListFilterData?.status,
        userid: state?.saveListFilterData?.userid,
        organizationid: state?.saveListFilterData?.organizationid,
      };
      const body = { data, navigate };
      dispatch(actionGetPropertyListAPICall(body));
    } else if (pathname == "/language-management") {
      const data = {
        page: pageValue,
        per_page: state?.perPageLangCount ? state?.perPageLangCount : 40,
        lang: state?.saveLangFilterData?.lang
          ? state?.saveLangFilterData?.lang
          : "en",
        type: state?.saveLangFilterData?.type
          ? state?.saveLangFilterData?.type
          : 0,
      };
      const body = { data, navigate };
      dispatch(actionLangLabelListAPICall(body));
    } else if (pathname == "/banner-management") {
      const data = {
        page: pageValue,
        per_page: state?.perPageBannerCount,
        position: state?.saveBannerFilterData?.position,
        start_date: state?.saveBannerFilterData?.start_date,
        end_date: state?.saveBannerFilterData?.end_date,
      };
      const body = { data, navigate };
      dispatch(actionBannerListApiCall(body));
    } else if (pathname == "/email-management") {
      const data = {
        page: pageValue,
        per_page: state?.perPageEmailCount,
        subject: state?.saveEmailFilterData?.subject,
        lang: state?.saveEmailFilterData?.lang
          ? state?.saveEmailFilterData?.lang
          : "en",
      };
      const body = { data, navigate };
      dispatch(actionEmailListApiCall(body));
    } else if (pathname == "/supplier-management") {
      const data = {
        page: pageValue,
        per_page: state?.perPageEmailCount,
        subject: state?.saveEmailFilterData?.subject,
        lang: state?.saveEmailFilterData?.lang
          ? state?.saveEmailFilterData?.lang
          : "en",
      };
      const body = { data, navigate };
      dispatch(actionSuppliersApiCall(body));
    } else if (pathname.includes("discount")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetDiscountAndExtraApiCall(body));
    } else if (pathname.includes("subscription")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetSubscriptionApiCall(body));
    } else if (pathname.includes("Branch")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetBranchTableSystemApiCall(body));
    } else if (pathname.includes("classification")) {
      const body = { navigate, page: pageValue };
      dispatch(actionClassificationGetTableApi(body));
    } else if (pathname.includes("countries")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetCountriesTableSystemApiCall(body));
    } else if (pathname.includes("municipality")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetMunicipalityTableSystemApiCall(body));
    } else if (pathname.includes("district")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetDistrictTableSystemApiCall(body));
    } else if (pathname.includes("neighbourhood")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetNeighbourhoodTableSystemApiCall(body));
    } else if (pathname.includes("countrypart")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetCountryPartTableSystemApiCall(body));
    } else if (pathname.includes("city")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetCitySystemApiCall(body));
    } else if (pathname.includes("zipcode")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetZipcodeApiCall(body));
    } else if (pathname.includes("province")) {
      const body = { navigate, page: pageValue };
      dispatch(actionGetProvinceApiCall(body));
    } else if (pathname.includes("translation")) {
      const body = {
        navigate,
        search: false,
        language: state?.saveTranslationLanguageFilter?.language
          ? state?.saveTranslationLanguageFilter?.language
          : state?.SaveLangListData[0].language,
        column: state?.saveTranslationColumnFilter?.value,
        page: pageValue,
      };
      dispatch(actionGetTranslationColumnApiCall(body));
    } else if (pathname.includes("vat")) {
      const body = {
        navigate,
        page: pageValue,
      };
      dispatch(actionGetVatApiCall(body));
    }
  };
  return (
    <div className={classes.FooterSection}>
      {(pathname == "/user-management" ||
        pathname == "/organisation-management" ||
        pathname == "/listing-management" ||
        pathname == "/language-management" ||
        pathname == "/banner-management" ||
        pathname.includes("/mastertable-management") ||
        pathname.includes("subscription") ||
        pathname.includes("discount") ||
        pathname.includes("Branches") ||
        pathname.includes("classification") ||
        pathname == "/user-management" ||
        pathname == "/email-management" ||
        pathname == "/countries-management" ||
        pathname == "/municipality-management" ||
        pathname == "/district-management" ||
        pathname == "/neighbourhood-management" ||
        pathname == "/countrypart-management" ||
        pathname == "/city-management" ||
        pathname == "/province-management" ||
        pathname == "/zipcode-management" ||
        pathname == "/translation-management" ||
        pathname == "/vat-management") && (
        <div className={classes.PaginationWrap}>
          <p>
            PAGE <span>{currentPage}</span> OF <span>{totalpage}</span>
          </p>
          <div className={classes.PaginationBtnWrap}>
            <img
              src={Left}
              onClick={() => {
                if (currentPage > 1) {
                  apiCall(currentPage - 1);
                }
              }}
            />
            <img
              src={Rigth}
              onClick={() => {
                if (currentPage < totalpage) {
                  apiCall(currentPage + 1);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FooterMain;
