import React, { useState } from "react";
import classes from "./login.module.scss";
import loginLogo from "../../assets/images/loginlogo.svg";
import arrow from "../../assets/images/arrow.svg";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { actionLoginApiCall } from "../../Redux/Actions";
import useForm from "../../utils/useForm";

const Login = () => {
  const { handleChange, values, errors, onFocus, onBlur, handleSubmit } =
    useForm("Login");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const { t, i18n } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");

  const showPassword = (show) => {
    show ? setPasswordType("text") : setPasswordType("password");
  };
  const handleOnClick = async (event) => {
    if (event) event.preventDefault();
    let result = await handleSubmit();
    if (!result.email && !result.password) {
      let data = { email: values.email, password: values.password };
      let body = { data, navigate, check };
      if (values.email && values.password) {
        dispatch(actionLoginApiCall(body));
      }
    }
  };
  return (
    <div className={classes.main_container}>
      <div className={classes.form_container}>
        <div className={classes.img_content}>
          <img src={loginLogo} />
        </div>
        <form onSubmit={handleOnClick}>
          <div className={classes.input_content}>
            <div className={classes.input_field}>
              <p>{t("email")}</p>
              <input
                className={classes.no_outline}
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </div>
            {errors.email && (
              <p className={`${classes.errorText}`}>{errors.email}</p>
            )}
            <div className={classes.input_field}>
              <p>{t("password")}</p>
              <input
                className={classes.no_outline}
                type={passwordType}
                name="password"
                value={values.password}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              {passwordType === "password" ? (
                <AiFillEyeInvisible
                  color="#707070"
                  className={classes.eyeIcon}
                  onClick={() => {
                    showPassword(true);
                  }}
                />
              ) : (
                <AiFillEye
                  color="#707070"
                  className={classes.eyeIcon}
                  onClick={() => {
                    showPassword(false);
                  }}
                />
              )}
            </div>
            {errors.password && (
              <p className={`${classes.errorText}`}>{errors.password}</p>
            )}
            <div className={classes.checkboxBtn}>
              <div className={classes.rememberme_checkbox}>
                <input
                  type="checkbox"
                  className={classes.remember_me_checkbox}
                  id="box"
                  onClick={() => setCheck(!check)}
                />
                <label className={classes.check} htmlFor="box"></label>
                <span className={classes.remember_me_text}>
                  {t("remember_me")}
                </span>
              </div>
              <div className={classes.loginBtn}>
                <button
                  className={classes.loginButton}
                  onClick={handleOnClick}
                  type="submit"
                >
                  <p className={classes.loginBtnText}>{t("login")}</p>
                  <img src={arrow} />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
