 

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};
export function rgbStringToHex(rgbString) {
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const rgb = rgbString.match(/\d+/g).map(Number);
  return `#${toHex(rgb[0])}${toHex(rgb[1])}${toHex(rgb[2])}`;
}
export const validateText = (value, name) => {
  return {
    isValid: value !== "",
    message: `${name.replace(/_/g, " ")} is required`,
  };
};

export function isValidUrl(url) {
  // Regex pattern for basic URL validation
  const urlPattern = /^(https?:\/\/)[\da-z\.-]+\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

  return urlPattern.test(url);
}

export const validateName = (value) => {
  const nameRegex = /^[A-Za-z\-\s]+$/;
  return { isValid: nameRegex.test(value), message: "Invalid name" };
};

export const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return { isValid: emailRegex.test(value), message: "Invalid email address" };
};
export const validatePhone = (value) => {
  return { isValid: !value.length <= 7, message: `phone is required` };
};

export const validatePassword = (value) => {
  return {
    isValid: !value.length >= 8,
    message: "Password must be at least 8 characters long",
  };
};
export const validateImageSize = (value) => {
  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/svg+xml",
  ];
  const maxSize = 2 * 1024 * 1024;
  let valid = true;
  let message = "";

  if (value.size > maxSize) {
    valid = false;
    message = "File size exceeds 2 MB. Please upload a smaller file.";
  }

  if (!allowedTypes.includes(value.type)) {
    valid = false;
    message = "Invalid file type. Please select a valid image file.";
  }

  return {
    isValid: valid,
    message: message,
  };
};

export const validateWebsite = (value) => {
  const websiteRegex =
    /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)|localhost(:\d{1,5})?(\/\S*)*$/;
  return { isValid: websiteRegex.test(value), message: "Invalid website link" };
};
