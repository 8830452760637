import React from "react";
import MasterTableManagementMain from "../../components/MasterTableManagementMain";

const MasterTableManagementPage = () => {
	return (
		<React.Fragment>
			<MasterTableManagementMain />
		</React.Fragment>
	);
};

export default MasterTableManagementPage;
