import React, { useEffect, useState } from "react";
import MainLayount from "../common/MainLayout";
import classes from "./mastertablemanagementmain.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddEditTranslationApiCall,
  actionAddTranslationApiCall,
  actionUpdateLangLabelListAPICall,
} from "../../Redux/Actions";
import Loader from "../common/Loader";
import MasterTableManagementHeadre from "../common/MasterTableManagementHeadre";

const MasterTableManagementMain = () => {
  const loader = useSelector((state) => state.CommonReducer.loader);
  const lang = useSelector((state) => state.CommonReducer.language);
  const saveMasterTableData = useSelector(
    (state) => state.CommonReducer?.saveMasterTableData?.rows
  );
  const saveTranslationColumn = useSelector(
    (state) => state.CommonReducer?.saveTranslationColumn
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [name, setName] = useState();
  const params = useParams();
  const onClickOfUpdate = (id, value, column, language, language_id) => {
    // const data = {
    //   id: id,
    //   translation: name,
    // };
    // const body = { data, navigate, params };
    // dispatch(actionAddTranslationApiCall(body));

    const stringData = {
      column: column,
      value: value,
      language: language,
      language_id: language_id,
      translation: name,
    };
    let data = JSON.stringify(stringData);

    dispatch(
      actionAddEditTranslationApiCall({
        data,
        edit: id,
        navigate,
      })
    );
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.MasterTableMain}>
          <MasterTableManagementHeadre />
          <div className={classes.MasterTableSection}>
            <table>
              <tr>
                {/* <th>{t("translation")}</th>
								{lang == "en" && <th>{`${t("translation")} ${t("value")}`}</th>}								
								<th>{t("action")}</th> */}
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={3}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {saveTranslationColumn &&
                  saveTranslationColumn?.length > 0 ? (
                    saveTranslationColumn?.map((item) => (
                      <tr>
                        <td style={{ maxWidth: "250px" }}>{item.value}</td>
                        <td style={{ maxWidth: "250px" }}>
                          <div className={classes.fildBox}>
                            <textarea
                              placeholder={`${t("name_p")}...`}
                              value={id == item.id ? name : item.translation}
                              onChange={(e) => {
                                setName(e.target.value);
                                setId(item.id);
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              onClickOfUpdate(
                                item.id,
                                item.value,
                                item.column,
                                item.language,
                                item.language_id
                              )
                            }
                          >
                            {t("update")}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={3}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default MasterTableManagementMain;
