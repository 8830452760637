import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../common/MainLayout";
import classes from "./suppliermanagementmain.module.scss";
import SupplierManagementHeader from "../common/SupplierManagementHeader";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import ModalMain from "../common/Modal";
import {
  actionViewSupplierApiCall,
  actionDeleteSupplierApiCall,
  actionGetSupplierMetaDataApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";

const SupplierManagementMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.CommonReducer.loader);

  const [showInfoScreen, setShowInfoScreen] = useState(false);
  const [showTitle, setShowTitle] = useState();
  const [showContent, setShowContent] = useState();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const suppliersData = useSelector(
    (state) => state.CommonReducer.suppliersData
  );

  console.log("suppliersData", suppliersData);

  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const divref = useRef([]);

   const handleScroll = (index) => {
     let ref = divref.current[index];
     const y = ref.getBoundingClientRect().top;
     if (y > 304) {
       divref.current[index].scrollIntoView({
         behavior: "smooth",
         block: "start",
       });
     }
   };

  const onCLickViewSupplier = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionViewSupplierApiCall(body));
  };

  const onCLickDeleteSupplier = (id) => { 
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionDeleteSupplierApiCall(body));
  };

   useEffect(() => {

    console.log("USE EFFECT BEFORE DINGETJE")
 
    dispatch(actionGetSupplierMetaDataApiCall({ navigate }));

    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
 }, []);

  const platform = localStorage.getItem("Platform");

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.BannerMain}>
          <SupplierManagementHeader />
          <div className={classes.BannerListingSection}>
            <table>
              <thead>
                <tr>
                  <th>{t("name_sup_c")}</th>
                  <th>{t("url_sup_c")}</th>
                  <th>{t("locations_sup_c")}</th>
                  <th>{t("type_sup_c")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader === true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={6}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {suppliersData?.length > 0 &&
                      suppliersData.map((item, index) => {
                        return (
                          <tr ref={(e) => (divref.current[index] = e)} key={index}>
                            <td>{item.name}</td>
                            <td>{item.url}</td>
                            <td className={classes.BannerNumber}>
                              {item.supplier_locations.map((loc) => {
                                return (
                                  <span key={loc.id}>
                                    {loc.name} ( {loc.location_type} )
                                    <br />
                                  </span>
                                );
                              })}
                            </td>
                            <td>{item.supplier_type.name}</td>

                            <td className={classes.BannerEditor}>
                              <img
                                src={userEditorIcon}
                                alt="Banner Actions"
                                onClick={() => {
                                  if (suppliersData.length - 1 > index) {
                                    handleScroll(index);
                                  }
                                  if (ind !== index) {
                                    setShow(!show);
                                    setInd(index);
                                  } else {
                                    setInd("");
                                  }
                                }}
                              />
                              {show && ind === index && (
                                <div
                                  className={classes.EditorWrap}
                                  style={
                                    suppliersData.length - 2 > index
                                      ? {}
                                      : { top: "-170px" }
                                  }
                                  onClick={() => setShow(!show)}
                                  ref={listRef}
                                >
                                  <ul>
                                    <li
                                      onClick={() =>
                                        onCLickViewSupplier(item.id)
                                      }
                                    >
                                      {t("view_supplier")}
                                    </li>
                                    <li
                                      onClick={() =>
                                        onCLickDeleteSupplier(item.id)
                                      }
                                    >
                                      {t("delete_supplier")}
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </MainLayout>
      <ModalMain
        showmodel={showInfoScreen}
        handleClose={() => setShowInfoScreen(false)}
        dialogClassName={classes.ModaleSize}
        title={showTitle}
        children={showContent}
      ></ModalMain>
    </React.Fragment>
  );
};

export default SupplierManagementMain;
