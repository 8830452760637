import React from "react";
import Login from "../pages/Login";
import UserManagementPage from "../pages/UserManagementPage";
import SingleUserDetailPage from "../pages/SingleUserDetailPage";
import OrganisationManagementPage from "../pages/OrganisationManagementPage";
import ListingManagementPage from "../pages/ListingManagementPage";
import SingleOrgDetailedPage from "../pages/SingleOrgDetailedPage";
import SingleListingMain from "../components/SingleListingMain";
import LanguageManagementPage from "../pages/LanguageManagementPage";
import BannerManagementPage from "../pages/BannerManagementPage";
import EmailManagementPage from "../pages/EmailManagementPage";
import MenuManagementPage from "../pages/MenuManagementPage";
import SystemManagementPage from "../pages/SystemManagementPage";
import MasterTableManagementPage from "../pages/MasterTableManagementPage";
import SettingManagementPage from "../pages/SettingManagementPage";
import SubscriptionsMain from "../components/SubscriptionsMain";
import DiscountAndExtraMain from "../components/DiscountAndExtraMain";
import ClassficationMain from "../components/ClassificationMain";
import BranchesMain from "../components/BranchesMain";
import CountriesMain from "../components/CountriesMain";
import MunicipalityMain from "../components/MunicipalityMain";
import DistrictMain from "../components/DistrictMain";
import NeighbourhoodMain from "../components/NeighbourhoodMain";
import CityMain from "../components/CityMain";
import ZipcodeMain from "../components/ZipcodeMain";
import CountryPartMain from "../components/CountryPartMain";
import ProvinceMain from "../components/ProvinceMain";
import TranslationMain from "../components/TranslationMain";
import VatMain from "../components/VatMain";
import PropertyBannerManagementPage from "../pages/PropertyBannersManagementPage";
import SingleCampaignPage from "../pages/SingleCampaignPage";
import FinancialManagementPage from "../pages/FinancialManagementPage";
import SingleInvoicePage from "../pages/SingleInvoicePage";
import EmailMarketingPage from "../pages/EmailMarketingPage/Index";
import SupplierManagementPage from "../pages/SupplierManagementPage";
import SupplierDetailsPage from "../pages/SupplierDetailsPage";

export const routes = [
  {
    path: "/",
    component: Login,
    private: false,
  },
  {
    path: "/login",
    component: Login,
    private: false,
  },
  {
    path: "/user-management",
    component: UserManagementPage,
    private: true,
  },
  {
    path: "/single-user/:id",
    component: SingleUserDetailPage,
    private: true,
  },
  {
    path: "/organisation-management",
    component: OrganisationManagementPage,
    private: true,
  },
  {
    path: "/listing-management",
    component: ListingManagementPage,
    private: true,
  },
  {
    path: "/single-organisation/:id",
    component: SingleOrgDetailedPage,
    private: true,
  },
  {
    path: "/single-listing/:id",
    component: SingleListingMain,
    private: true,
  },
  {
    path: "/banner-management",
    component: BannerManagementPage,
    private: true,
  },

  {
    path: "/menu-management",
    component: MenuManagementPage,
    private: true,
  },
  {
    path: "/setting-management",
    component: SettingManagementPage,
    private: true,
  },
  {
    path: "/system-management",
    component: SystemManagementPage,
    private: true,
  },
  //sub part of system-management
  {
    path: "/mastertable-management/:column",
    component: MasterTableManagementPage,
    private: true,
  },
  {
    path: "/language-management",
    component: LanguageManagementPage,
    private: true,
  },

  {
    path: "/subscription-management",
    component: SubscriptionsMain,
    private: true,
  },
  {
    path: "/discount-and-extra-management",
    component: DiscountAndExtraMain,
    private: true,
  },
  {
    path: "/email-management",
    component: EmailManagementPage,
    private: true,
  },
  {
    path: "/classification-management",
    component: ClassficationMain,
    private: true,
  },
  {
    path: "/Branches-management",
    component: BranchesMain,
    private: true,
  },
  {
    path: "/countries-management",
    component: CountriesMain,
    private: true,
  },
  {
    path: "/municipality-management",
    component: MunicipalityMain,
    private: true,
  },
  {
    path: "/district-management",
    component: DistrictMain,
    private: true,
  },
  {
    path: "/neighbourhood-management",
    component: NeighbourhoodMain,
    private: true,
  },
  {
    path: "/city-management",
    component: CityMain,
    private: true,
  },
  {
    path: "/zipcode-management",
    component: ZipcodeMain,
    private: true,
  },
  {
    path: "/countrypart-management",
    component: CountryPartMain,
    private: true,
  },
  {
    path: "/province-management",
    component: ProvinceMain,
    private: true,
  },
  {
    path: "/translation-management",
    component: TranslationMain,
    private: true,
  },
  {
    path: "/vat-management",
    component: VatMain,
    private: true,
  },
  {
    path: "/campaign-management",
    component: PropertyBannerManagementPage,
    private: true,
  },
  {
    path: "/single-campaign/:id",
    component: SingleCampaignPage,
    private: true,
  },
  {
    path: "/financial-management",
    component: FinancialManagementPage,
    private: true,
  },
  {
    path: "/email-marketing",
    component: EmailMarketingPage,
    private: true,
  },
  {
    path: "/single-invoice/:id",
    component: SingleInvoicePage,
    private: true,
  },
  {
    path: "/supplier-management",
    component: SupplierManagementPage,
    private: true,
  },
  {
    path: "/supplier-details/:id",
    component: SupplierDetailsPage,
    private: true,
  },
];
