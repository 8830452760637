import React from "react";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import classes from "./discountandextraheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import AddIcon from "../../../assets/icons/AddIcon.svg";
import ModalMain from "../Modal";
import AddSubscriptionModal from "../../ModalComponents/SubscriptionModal/AddSubscriptionModal";
import CreateDiscount from "../../ModalComponents/DiscountAndExtraModal/CreateDiscount";
import { useNavigate } from "react-router-dom";

const DiscountAndExtraHeader = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [addDiscount, setAddDiscount] = useState(false);

  const sidebarShow = () => {
    setSideBar(true);
  };
  const SubscriptionShow = () => {
    setAddDiscount(true);
  };
  const handleClose = () => {
    setSideBar(false);
    setAddDiscount(false);
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap} onClick={props.ClosePopup}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span>
              {t("discountsand_extras")}
            </h5>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={SubscriptionShow}>
              {t("add_discount")}
              <img src={AddIcon} />
            </button>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ModalMain showmodel={addDiscount} handleClose={handleClose} size={"md"}>
        <CreateDiscount edit={false} close={handleClose} />
      </ModalMain>
    </React.Fragment>
  );
};

export default DiscountAndExtraHeader;
