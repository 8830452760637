import React from "react";
import MenuManagementMain from "../../components/MenuManagementMain";

const MenuManagementPage = () => {
  return (
    <React.Fragment>
      <MenuManagementMain />
    </React.Fragment>
  );
};

export default MenuManagementPage;
