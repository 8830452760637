import React, { useState } from "react";
import classes from "./subscription.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsStopCircle, BsFillStopCircleFill } from "react-icons/bs";
import moment from "moment";
import PriceFormatter from "../../../utils/PriceFormatter";
import {
  actionGetAllPaymentOptionApiCall,
  actionGetSubscriptionApiCall,
} from "../../../Redux/Actions";
import "./";
import AddSubscriptionModal from "../../ModalComponents/SubscriptionModal/AddSubscriptionModal";
import ModalMain from "../../common/Modal";
import EditOrganisationAddSubscription from "../../common/EditOrganisationAddSubscription";
import ConfirmationModal from "../../common/ConfirmationModal";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import EditIcon from "../../../assets/images/EditLogo.svg";
import { useEffect } from "react";

const SubscriptionOrgPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userListData = useSelector(
    (state) => state.CommonReducer.saveSubscriptionListForOrg
  );
  let ActiveSubscription = userListData?.find(
    (item) =>
      item.payment_status === "paid" && item.subscription_detail.active === 1
  );
  const [subscriptionList, setSubscriptionList] = useState(userListData);
  const [createuser, setCreateUser] = useState(false);
  const [show, setShow] = useState(false);
  const [activePlan, setActivePlan] = useState();
  const [stopConfirmModal, setStopConfirmModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subscriptionSearch, setSubscriptionSearch] = useState();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (subscriptionSearch) {
      setSubscriptionList(
        userListData.filter((item) =>
          item?.subscription_detail?.product_detail?.name
            .toLowerCase()
            .includes(subscriptionSearch.toLowerCase())
        )
      );
    } else if (active) {
      setSubscriptionList(
        userListData.filter((item) => item?.id === ActiveSubscription?.id)
      );
    } else {
      setSubscriptionList(userListData);
    }
  }, [subscriptionSearch, active]);

  const ViewOrgSubscriptionPlanClose = () => {
    props.editPopUpClose();
    props.handleClose();
  };
  const getLabel = (status) => {
    if (status === "canceled" || status === "failed") {
      return "Not invoiced";
    } else if (status === "open" || status === "pending") {
      return "Open invoice";
    } else if (status === "paid") {
      return "Paid";
    } else if (status === "expired") {
      return "Overdue";
    }
  };
  const getStatusStyle = (paymentStatus) => {
    switch (paymentStatus) {
      case "Not invoiced":
        return { color: "#8F8F8F" };
      case "Open invoice":
        return { color: "#FFD651" };
      case "Paid":
        return { color: "#4DCD76" };
      case "Overdue":
        return { color: "#FF0000" };
    }
  };
  const onClickOfAddNew = () => {
    dispatch(actionGetAllPaymentOptionApiCall({ navigate }));
    setCreateUser(true);
  };
  const onClickEdit = (id, item) => {
    const data = id;
    const body = { data, navigate, edit: true };
    dispatch(actionGetSubscriptionApiCall(body));
    setActivePlan(item);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className={classes.SubscriptionOrgPage}>
      <p>Organisation Subscription</p>
      <div className={classes.tableContainer}>
        <div className={classes.TableListngLayOut}>
          <div className={classes.SearchBar}>
            <div>
              <img src={SearchIcon} alt="" />
              <input
                type="search"
                placeholder="Search in subscriptions"
                onChange={(e) => setSubscriptionSearch(e.target.value)}
              />
            </div>
            <div className={classes.activeOnly}>
              <span> Only Show Active</span>
              <label className={classes.Switch}>
                <input
                  type="checkbox"
                  checked={active}
                  onChange={() => setActive(!active)}
                />
                <span className={classes.Slider} />
              </label>
            </div>
          </div>
          <table>
            <tr>
              <th>{t("name")}</th>
              <th>{t("starrt")}</th>
              <th>{t("end")}</th>
              <th>{t("price")}</th>
              {/* <th>{t("PAID")}</th> */}
              <th>{t("RENEW")}</th>
              <th>{t("status_c")}</th>
              <th></th>
            </tr>
            {subscriptionList.length > 0 ? (
              subscriptionList.map((item) => (
                <tr>
                  <td>
                    {item?.subscription_detail?.product_detail?.name
                      ? item?.subscription_detail?.product_detail?.name
                      : "NA"}
                  </td>
                  <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                  <td>
                    {PriceFormatter(
                      Number(item.total_price)
                      // + Number(item.total_vat)
                    )}
                  </td>
                  {/* <td style={getStatusStyle(getLabel(item.payment_status))}>
                    {getLabel(item.payment_status)}
                  </td>{" "} */}
                  <td>-</td>
                  <td className={classes.activeIcon}>
                    {item?.id === ActiveSubscription?.id ? (
                      <span className={classes.pinkText}>Active</span>
                    ) : (
                      <span>Inactive</span>
                    )}
                  </td>
                  <td>
                    <div className={classes.actionContainer}>
                      {/* {(getLabel(item.payment_status) === "Paid" ||
                        getLabel(item.payment_status) === "Open invoice") &&
                        item.subscription_detail.active == 1 && ( */}
                      {item.subscription_detail.active == 1 && (
                        <div
                          onClick={() => {
                            setStopConfirmModal(true);
                            setSubscriptionId(item.subscription_detail.id);
                          }}
                          className={`${classes.stopButton}`}
                        >
                          <BsStopCircle />
                          {/* <BsFillStopCircleFill /> */}
                        </div>
                      )}
                      {/* )} */}
                      {getLabel(item?.payment_status) === "Open invoice" && (
                        <div
                          className={`${classes.editButton} ${classes.EditIcon}`}
                          onClick={() =>
                            onClickEdit(
                              item?.subscription_detail?.product_detail?.id,
                              item
                            )
                          }
                        >
                          <img
                            src={EditIcon}
                            alt=""
                            onClick={() => setCreateUser(true)}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className={classes.LoaderWrap} colSpan={6}>
                  <h2>{t("no_data_found")}</h2>
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className={classes.AddUseAsSection}>
          <button onClick={onClickOfAddNew}>{t("add")}</button>
        </div>
      </div>
      <ModalMain
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        dialogClassName={classes.ModaleSize}
        size={"xl"}
      >
        <EditOrganisationAddSubscription
          handleClose={() => setCreateUser(false)}
          ViewOrgSubscriptionPlanClose={ViewOrgSubscriptionPlanClose}
        />
      </ModalMain>
      <ModalMain showmodel={show} handleClose={handleClose} size={"xl"}>
        <AddSubscriptionModal
          edit={true}
          close={handleClose}
          data={activePlan}
          EditOrganisationAddSubscriptionClose={handleClose}
        />
      </ModalMain>
      <ConfirmationModal
        showmodel={stopConfirmModal}
        size={"sm"}
        input={true}
        subscriptionId={subscriptionId}
        orgId={props.orgId}
        handleClose={() => setStopConfirmModal(false)}
      ></ConfirmationModal>
    </div>
  );
};

export default SubscriptionOrgPage;
