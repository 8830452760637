import React, { useEffect } from "react";
import classes from "./singleorgmain.module.scss";
import MainLayount from "../common/MainLayout";
import SingleOrgHeader from "../common/SingleOrgHeader";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DetailOrgPage from "./DetailOrgPage";
import SubscriptionOrgPage from "./SubscriptionOrgPage";
import { useLocation, useNavigate } from "react-router-dom";
import { actionGetAllSubscriptionByOrgApiCall } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import ListingOrgPage from "./ListingOrgPage";
import InvoiceOrgPage from "./InvoiceOrgPage";
import BannerOrgPage from "./BannerOrgPage";

export const SingleOrgMain = () => {
  const { t } = useTranslation();
  const menuItem = [
    t("details"),
    t("invoices"),
    t("subscription"),
    t("banners"),
    t("listings_s"),
  ];
  const [selectedTab, setSelecetedTab] = useState("Details");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);

  useEffect(() => {
    const data = pathname;
    const body = { data, navigate };
    dispatch(actionGetAllSubscriptionByOrgApiCall(body));
  }, [selectedTab, pathname]);

  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.main}>
          <SingleOrgHeader />
          <div className={classes.menuItemWrapper}>
            <div className={classes.menuItemContainer}>
              <div className={classes.menuItems}>
                {menuItem?.map((item) => {
                  return (
                    <div
                      className={
                        item == selectedTab
                          ? classes.selectedMenuItem
                          : classes.menuItem
                      }
                      onClick={() => setSelecetedTab(item)}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {selectedTab === "Details" && <DetailOrgPage />}
          {selectedTab === "Subscription" && <SubscriptionOrgPage />}
          {selectedTab === "Listings" && <ListingOrgPage />}
          {selectedTab === "Invoices" && <InvoiceOrgPage />}
          {selectedTab === "Banners" && <BannerOrgPage />}
        </div>
      </MainLayount>
    </React.Fragment>
  );
};
