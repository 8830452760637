import React, { useEffect } from "react";
import classes from "./countriesheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import { useState } from "react";
import CountriesForm from "../CountriesForm";
import { actionGetCountriesTableSystemApiCall } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import SideBar from "../SideBar";

const CountriesHeader = ({ openCanvasB }) => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);
  const [name, setName] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebar, setSideBar] = useState(false);
  useEffect(() => {
    if (openCanvasB) {
      setOpenCanvas(openCanvasB);
    }
  }, [openCanvasB]);

  const filterClick = () => {
    const body = { navigate, page: 1, search: name };
    dispatch(actionGetCountriesTableSystemApiCall(body));
  };

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>

          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")}{" "}
              {t("countries")}
            </h5>
            <form onSubmit={onClickOfButton}>
              <div className={classes.fildBox}>
                <input
                  type="text"
                  placeholder={`${t("name_p")}...`}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={() => setOpenCanvas(true)}>{t("add")}</button>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        width="md"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <CountriesForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default CountriesHeader;
