import React, { useState } from "react";
import classes from "./menumanagementmain.module.scss";
import MainLayount from "../common/MainLayout";
import MenuManagementHeader from "../common/MenuManagementHeader";
import { BannerManagementData } from "../../data";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useTranslation } from "react-i18next";

const MenuManagementMain = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
//   actionMenuListApiCall
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.MenuMain}>
          <MenuManagementHeader />
          <div className={classes.MenuListingSection}>
            <table>
              <tr>
                <th>{t("position_c")}</th>
                <th>{t("SECTION")}</th>
                <th>{t("MENU TITLE")}</th>
              </tr>
              {BannerManagementData.map((item, index) => (
                <tr>
                  <td>{item.position}</td>
                  <td>{item.stardate}</td>
                  <td>{item.enddate}</td>
                  <td className={classes.MenuEditor}>
                    <img
                      src={userEditorIcon}
                      onClick={() => {
                        setShow(!show);
                        setInd(index);
                      }}
                    />
                    {show && ind == index && (
                      <div
                        className={classes.EditorWrap}
                        onClick={() => setShow(!show)}
                      >
                        <ul>
                          <li>{t("Edit menu")}</li>
                          <li>{t("Delete menu")}</li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default MenuManagementMain;
