import React, { useEffect, useState } from "react";
import classes from "./addorgtouser.module.scss";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../../assets/icons/SearchIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  actionAddUserOrgApiCall,
  actionOrganizationListApiCall,
} from "../../../../Redux/Actions";
import ConfirmationModal from "../../../common/ConfirmationModal";

const AddOrgToUser = (props) => {
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  const { t } = useTranslation();
  const orgListData = useSelector((state) => state.CommonReducer.orgListData);
  const state = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [org, setOrg] = useState({ id: "" });
  const [search, setSearch] = useState();
  const [createuser, setCreateUser] = useState();
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState(1);
  useEffect(() => {
    let ans = Math.ceil(state?.totalOrgCount / 10);
    setTotalPage(ans != NaN ? ans : 1);
  }, [state]);
  useEffect(() => {
    if (search == "") {
      apiCall();
    }
  }, [search]);
  const onSearchClick = (e) => {
    e.preventDefault();
    apiCall(1);
  };
  const apiCall = (temp) => {
    const data = {
      page: temp ? temp : page,
      name: search,
      per_page: 10,
    };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  };

  useEffect(() => {
    apiCall();
  }, [page]);

  const AddUserOrgApi = () => {
    const data = {
      userid: pathname,
      organizationid: org.id,
    };
    const body = { data, navigate };
    dispatch(actionAddUserOrgApiCall(body));
  };
  const onClickAddButton = () => {
    setCreateUser(true);
  };
  return (
    <React.Fragment>
      <div className={classes.AddUserToOrgMain}>
        <h3>{t("add_org_to_user")}</h3>
        <div className={classes.SearchBranch}>
          <input
            type="search"
            placeholder="Name.."
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={onSearchClick}>
            {t("search")}
            <img src={SearchIcon} />
          </button>
        </div>
        <div className={classes.AddUserToOrgWrap}>
          <table>
            <tr>
              <th>{t("name")}</th>
              <th>{t("e_mail")}</th>
              <th>{t("listings")}</th>
            </tr>
            {orgListData.map((item) => (
              <tr onClick={() => setOrg(item)}>
                <td
                  className={
                    org.id == item.id
                      ? classes.selectStyle
                      : classes.defaultStyle
                  }
                >
                  {item.name ? item.name : item.email}
                </td>
                <td
                  className={
                    org.id == item.id
                      ? classes.selectStyle
                      : classes.defaultStyle
                  }
                >
                  {item.email ? item.email : "NA"}
                </td>
                <td
                  className={
                    org.id == item.id
                      ? classes.selectStyle
                      : classes.defaultStyle
                  }
                >
                  {item.listing_count}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className={classes.PaginationWrapper}>
          <p onClick={() => setPage(page - 1)}>{page > 1 && "< Prev"}</p>
          <p onClick={() => setPage(page + 1)}>
            {page >= 1 && page < totalpage && "Next >"}
          </p>
        </div>
        <div className={classes.AddUseAsSection}>
          <button onClick={onClickAddButton}>{t("add")}</button>
        </div>
      </div>
      <ConfirmationModal
        showmodel={createuser}
        handleClose={() => setCreateUser(false)}
        size={"sm"}
        onClickYesButton={AddUserOrgApi}
        title={`${t("You_are_about_to_add")} ${t("in")} ${org.name} `}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default AddOrgToUser;
