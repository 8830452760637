import React, { useState } from "react";
import classes from "./editmaincontact.module.scss";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../../assets/icons/SearchIcon.svg";
import RoleChangeIcon from "../../../../assets/icons/RoleChangeIcon.svg";
import Default from "../../../../assets/images/default.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionChangeAdminPropertyAPICall,
  actionGetPropertyListAPICall,
  actionOrganizationListApiCall,
  actionUserListApiCall,
  actionViewUserApiCall,
} from "../../../../Redux/Actions";
import ConfirmationModal from "../../../common/ConfirmationModal";
import { useEffect } from "react";

const EditMainContact = (props) => {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.CommonReducer.loader);
  const singleListData = useSelector(
    (state) => state.CommonReducer.saveSinglePropertyData
  );
  const userlist = useSelector((state) => state.CommonReducer.userListData);
  const [userSearch, setUserSearch] = useState("");
  const [user, setUser] = useState({ id: "" });
  const [changeUser, setChangeUser] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userSearch.length <= 0) {
      onCLickofSearchButton();
    }
  }, [userSearch]);
  const onCLickofSearchButton = () => {
    const data = { page: 1, name: userSearch, per_page: 10 };
    const body = { data, navigate };
    dispatch(actionUserListApiCall(body));
  };
  const onCLickViewUser = (id) => {
    const data = id;
    const body = { data, navigate };
    dispatch(actionViewUserApiCall(body));
    listuserapicall(id);
    getPropertyListAPICall(id);
  };
  const listuserapicall = (id) => {
    const data = { page: 1, userid: id };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  };
  const getPropertyListAPICall = (id) => {
    const data = { page: 1, userid: id };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  };
  const ChangeMainContact = () => {
    const data = {
      userid: user.id ? user.id : singleListData.user_detail.id,
      property_id: singleListData.id,
    };
    let close = props.handleClose;
    const body = { data, navigate, edit: props.edit, close };
    dispatch(actionChangeAdminPropertyAPICall(body));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onCLickofSearchButton();
  };
  return (
    <React.Fragment>
      <div className={classes.EditMainContact}>
        <h3>{t("edit_main_contact")}</h3>
        <div className={classes.EditMainContactWrap}>
          <div className={classes.UserInfoSection}>
            <div className={classes.UserInfoWrap}>
              <div className={classes.UserPhoto}>
                <img
                  src={
                    singleListData?.user_detail.url_original_file
                      ? singleListData.user_detail.url_original_file
                      : Default
                  }
                />
              </div>
              <div className={classes.UserDetailWrap}>
                <h4>{singleListData.full_name}</h4>
                <p>{singleListData.user_detail.email}</p>
                <p>
                  {" "}
                  {singleListData.user_detail.phone
                    ? singleListData.user_detail.phone
                    : "NA"}
                </p>
                <p>
                  {t("main_contact")}
                  {t("has")}
                  <span className={classes.BlueColorText}>
                    {singleListData.user_detail.PropertyCount}
                  </span>{" "}
                  {t("other listings,")}
                  {t("and is part of")}
                  <span className={classes.BlueColorText}>
                    {" "}
                    {singleListData.user_detail.AgencyCount}{" "}
                  </span>{" "}
                  {t("organisation")}
                </p>
              </div>
              <button
                onClick={() => onCLickViewUser(singleListData.user_detail.id)}
              >
                {t("view_user")}
              </button>
            </div>
          </div>
          <div className={classes.ChangeContactSection}>
            <h6>{t("Change main contact")}</h6>
            <div className={classes.ChangeMainContactWrap}>
              <div className={classes.WithSameOrg}>
                <p>{t("Within the same Organisation:")}</p>
                <input
                  type="radio"
                  id="Energy1"
                  name="property_type"
                  className={classes.radioCustom}
                />
                <label
                  htmlFor="Energy1"
                  className={classes.radioCustomLabel}
                ></label>
              </div>
              <form onSubmit={handleSubmit}>
                <div className={classes.SearchBranch}>
                  <input
                    type="search"
                    placeholder="Name.."
                    onChange={(e) => setUserSearch(e.target.value)}
                  />
                  <button onClick={onCLickofSearchButton}>
                    {t("search")}
                    <img src={SearchIcon} />
                  </button>
                </div>
              </form>
            </div>
            <div className={classes.ChangeContactTable}>
              <table>
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("email")}</th>
                  <th>{t("organisations")}</th>
                </tr>
                {userlist.length > 0 ? (
                  userlist.map((item) => (
                    <tr onClick={() => setUser(item)}>
                      <td
                        className={
                          user.id == item.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item.full_name ? item.full_name : "NA"}
                      </td>
                      <td
                        className={
                          user.id == item.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item.email}
                      </td>
                      <td
                        className={
                          user.id == item.id
                            ? classes.selectedtd
                            : classes.normaltd
                        }
                      >
                        {item.organisation_count}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <p>{t("no_data_found")}</p>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className={classes.ChangeContactBtn}>
              <button onClick={() => setChangeUser(true)}>
                {t("change_main_contact")}
                <img src={RoleChangeIcon} />
              </button>
            </div>
            <div className={classes.EditbtnWrap}>
              <button>
                {" "}
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {t("edit")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        showmodel={changeUser}
        handleClose={() => setChangeUser(false)}
        size={"sm"}
        onClickYesButton={ChangeMainContact}
        title={`You are about to make ${user.full_name} the main contact of ${singleListData.address}`}
      />
    </React.Fragment>
  );
};

export default EditMainContact;
