import React from "react";
import UserManagementMain from "../../components/UserManagementMain";

const UserManagementPage = () => {
  return (
    <React.Fragment>
      <UserManagementMain />
    </React.Fragment>
  );
};

export default UserManagementPage;
