import React from "react";
import BannerManagementMain from "../../components/BannerManagementMain";

const BannerManagementPage = () => {
  return (
    <React.Fragment>
      <BannerManagementMain />
    </React.Fragment>
  );
};

export default BannerManagementPage;
