import React, { useEffect } from "react";
import classes from "./supplierdetailsmain.module.scss";
import MainLayount from "../common/MainLayout";
import SupplierDetailsHeader from "../common/SupplierDetailsHeader";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  actionUpdateSupplierApiCall,
  actionViewSupplierApiCall,
  actionGetSupplierMetaDataApiCall,
} from "../../Redux/Actions";
import EditIcon from "../../assets/images/EditLogo.svg";
import SearchIcon from "../../assets/images/SearchIcon.svg";
import StarIcon from "../../assets/images/starIcon.svg";
import { FaUserPlus } from "react-icons/fa";
import { RiGlobalLine } from "react-icons/ri";
import OffcanvasMain from "../common/Offcanvas";
import LocationForm from "../common/LocationForm";
import PriceTableForm from "../common/PriceTableForm";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const SupplierDetailsMain = () => {
  const { t } = useTranslation();

  const supplierData = useSelector((state) => state.CommonReducer.supplierData);
  const supplierTypes = useSelector((state) => state.CommonReducer.supplierTypesData);
  const [supplierName, setSupplierName] = useState(`${supplierData?.name}`);
  const [supplierType, setSupplierType] = useState(
    supplierData?.supplier_type.name
  );
  const [supplierUrl, setSupplierUrl] = useState(supplierData?.url);

  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [locationFormShow, setLocationFormShow] = useState({
    data: null,
    status: false,
  });
  const [ptFormShow, setPtFormShow] = useState({
    data: null,
    status: false,
  });

  const dispatch = useDispatch();
  const pathname = useLocation().pathname;

  useEffect(() => {
    console.log("supplierData", supplierData);
    if (!supplierData) {
      console.log(pathname);
      const id = pathname.substring(pathname.lastIndexOf("/") + 1);
      console.log("data", id);
      const data = { id: id };
      const body = { data, navigate };
      dispatch(actionViewSupplierApiCall(body));
      dispatch(actionGetSupplierMetaDataApiCall({ navigate }));
    }
    setSupplierName(supplierData?.name);
    setSupplierType(supplierData?.supplier_type_id);
    setSupplierUrl(supplierData?.url);
  }, [supplierData, pathname, navigate, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
  };

  const onClickOfSaveBtn = () => {
    const id = supplierData.id;
    const data = {
    supplier_id: supplierData.id,
    supplier_type_id: supplierType,
    supplier_name: supplierName,
    url: supplierUrl,
    }
    console.log("supplierUrl", supplierUrl);
    console.log("onClickOfSaveBtn", data);              

    dispatch(actionUpdateSupplierApiCall({data,
          edit: id,
          navigate,
          getapiCall: { data: {id: id}, navigate },}));
  };

    const handleChange = (event) => {
      const { name, value } = event?.target;
      if (name === "supplier_name") setSupplierName(value);
      if (name === "supplier_type") setSupplierType(value);
      if (name === "url") setSupplierUrl(value);
    };

    console.log(supplierType)


  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.main}>
          <SupplierDetailsHeader />
          <div className={classes.detailPage}>
            <div className={classes.gridWrapper}>
              <div className={classes.gridContainer}>
                <div key="supplier_name" className={classes.labelContainer}>
                  <label>Name</label>
                  <div className={classes.inputContainer}>
                    <input
                      type="text"
                      name="supplier_name"
                      defaultValue={supplierData?.name}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                  <p className={classes.errorText}>{error["supplier_name"]}</p>
                </div>
                <div className={classes.labelContainer}>
                  <label>Type</label>
                  <div className={classes.inputContainer}>
                    <select
                      name="supplier_type"
                      value={supplierType}
                      onChange={(event) => handleChange(event)}
                    >
                      <option value="">Select an option</option>
                      {supplierTypes &&
                        supplierTypes?.map(
                          (option, index) => (
                            <option key={option[0]} value={option[0]}>
                              {option[1]}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                  <p className={classes.errorText}>{error["supplier_name"]}</p>
                </div>
                <div className={classes.labelContainer}>
                  <label>Url</label>
                  <div className={classes.inputContainer}>
                    <input
                      type="text"
                      name="url"
                      defaultValue={supplierData?.url}
                      onChange={(event) => handleChange(event)}
                    />
                    <div
                      className={classes.iconContainer}
                      onClick={() => {
                        window.open(
                          `https://www.${supplierData.url}`,
                          "_blank"
                        );
                      }}
                    >
                      <RiGlobalLine />
                    </div>
                  </div>
                  <p className={classes.errorText}>{error["supplier_name"]}</p>
                </div>
              </div>
              <button onClick={onClickOfSaveBtn}>Save</button>
            </div>
            <div className={classes.container}>
              <div className={classes.address}>
                <p>{t("locations")}</p>
                <div className={classes.addressContainer}>
                  <div className={classes.tableContainer}>
                    <div className={classes.SearchBar}>
                      <img src={SearchIcon} alt="" />
                      <input
                        type="search"
                        placeholder="Search in Locations"
                        // onChange={(e) => setAddressSearch(e.target.value)}
                      />
                    </div>
                    <div className={classes.TableListngLayOut}>
                      <table>
                        <tr>
                          <th></th>
                          <th>{t("id")}</th>
                          <th>{t("location_name")}</th>
                          <th>{t("location_type")}</th>
                          <th></th>
                          <th></th>
                        </tr>
                        {supplierData?.supplier_locations?.length > 0 ? (
                          supplierData?.supplier_locations?.map((item) => (
                            <tr
                              className={
                                item?.hide_property == 1
                                  ? classes.InvisibleCard
                                  : null
                              }
                            >
                              <td></td>
                              <td>{item?.id}</td>
                              <td>{item?.name}</td>
                              <td>{item?.location_type}</td>
                              <td
                                onClick={() =>
                                  setLocationFormShow({
                                    data: item,
                                    status: true,
                                  })
                                }
                              >
                                <div className={classes.ListingIcons}>
                                  <img src={EditIcon} alt="" />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className={classes.NoData}>
                            <td className={classes.LoaderWrap} colSpan={6}>
                              <h2>{t("no_data_found")}</h2>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      setLocationFormShow({
                        data: null,
                        status: true,
                      })
                    }
                  >
                    add location
                  </button>
                </div>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.address}>
                <div className={classes.contact}>
                  <p>{t("pricetables")}</p>
                </div>
                <div className={classes.addressContainer}>
                  <div className={classes.tableContainer}>
                    <div className={classes.SearchBar}>
                      <img src={SearchIcon} alt="" />
                      <input
                        type="search"
                        placeholder="Search in Prices"
                        // onChange={(e) => setContactSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className={`${classes.TableListngLayOut} ${classes.contactTable}`}
                    >
                      <table>
                        <tr>
                          <th></th>
                          <th>{t("NAME")}</th>
                          <th>{t("TYPE")}</th>
                          <th>{t("START DATE")}</th>
                          <th>{t("END DATE")}</th>
                          <th></th>
                          <th></th>
                        </tr>
                        {supplierData?.price_tables?.length > 0 ? (
                          supplierData?.price_tables?.map((item) => (
                            <tr
                              className={
                                item?.hide_property == 1
                                  ? classes.InvisibleCard
                                  : null
                              }
                            >
                              <td
                                className={`${classes.starIcon} ${classes.iconStyle}`}
                              >
                                {item?.set_as_main_contact && (
                                  <img src={StarIcon} alt="" />
                                )}
                              </td>
                              <td className={classes.setContent}>
                                {item?.name}
                              </td>
                              <td>{item?.publication_type}</td>
                              <td>{item?.start_date}</td>
                              <td>{item?.end_date}</td>
                              <td></td>
                              <td
                                onClick={() =>
                                  setPtFormShow({
                                    data: item,
                                    status: true,
                                  })
                                }
                              >
                                <div className={classes.ListingIcons}>
                                  <img src={EditIcon} alt="" />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className={classes.NoData}>
                            <td className={classes.LoaderWrap} colSpan={10}>
                              <h2>{t("no_data_found")}</h2>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>

                  <button
                    onClick={() => setPtFormShow({ data: null, status: true })}
                  >
                    add pricetable
                  </button>
                </div>
              </div>
            </div>
          </div>

          <OffcanvasMain
            showcanvas={locationFormShow.status}
            placement={"end"}
            handleClose={() =>
              setLocationFormShow({ ...locationFormShow, status: false })
            }
            backdropClassName="abc"
            backgroundColor="true"
          >
            <LocationForm
              handleClose={() =>
                setLocationFormShow({ ...locationFormShow, status: false })
              }
              editData={locationFormShow.data}
            />
          </OffcanvasMain>
          <OffcanvasMain
            showcanvas={ptFormShow.status}
            placement={"end"}
            handleClose={() => setPtFormShow({ ...ptFormShow, status: false })}
            backdropClassName="abc"
            backgroundColor="true"
          >
            <PriceTableForm
              handleClose={() =>
                setPtFormShow({ ...ptFormShow, status: false })
              }
              editData={ptFormShow.data}
            />
          </OffcanvasMain>
        </div>
      </MainLayount>
    </React.Fragment>
  );
};

export default SupplierDetailsMain;
