import React, { useState } from "react";
import ListingManagementHeadre from "../common/ListingManagementHeadre";
import MainLayount from "../common/MainLayout";
import classes from "./listingmanagementmain.module.scss";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionDeletePropertyAPICall,
  actionGetPropertyDetailApiCall,
  actiongetPropertyViewsApiCall,
  actionSavePropertyViewData,
  actionUpdatePropertyVisibleApiCall,
  actionHideAllListingAPICall,
} from "../../Redux/Actions";
import Loader from "../common/Loader";
import ModalMain from "../common/Modal";
import EditListing from "../ModalComponents/ListingManagementModals/EditListing";
import PriceFormatter from "../../utils/PriceFormatter";
import { useRef } from "react";
import { useEffect } from "react";
import EditMainContact from "../ModalComponents/ListingManagementModals/EditMainContact";

const ListingManagementMain = () => {
  const ListingData = useSelector(
    (state) => state.CommonReducer?.SavePropertyListdata
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.CommonReducer.loader);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [ind, setInd] = useState("");
  const [editListing, setEditListing] = useState();
  const [editMainContact, setEditMainContact] = useState();
  const listRef = useRef(null);
  const divref = useRef([]);

  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 340) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updatePropertyVisible = (hide, item) => {
    const data = {
      property_id: item.id,
      hide_property: hide,
    };
    const body = { data, navigate };
    dispatch(actionUpdatePropertyVisibleApiCall(body));
  };
  const onClickViewProperty = (id, edit) => {
    const data = {
      property_id: id,
    };
    const body = { data, navigate, edit: edit };
    dispatch(actionGetPropertyDetailApiCall(body));
    dispatch(actiongetPropertyViewsApiCall(body));
  };
  const adduser = (id) => {
    let data = [...selectedUser];
    data.push(id);
    setSelectedUser(data);
  };
  const deleteUser = (id) => {
    if (selectedUser?.length > 0) {
      let data = selectedUser.filter((item) => item != id);
      setSelectedUser(data);
    }
  };
  const onClickOfDelete = (id) => {
    const data = { id: [id] };
    const body = { data, navigate };
    dispatch(actionDeletePropertyAPICall(body));
  };
  const OnClickOfBlock = (id) => {
    hideApiCall(id, 1);
  };
  const OnClickOfUnBlock = (id) => {
    hideApiCall(id, 0);
  };
  const OnClickEditList = (id) => {
    onClickViewProperty(id, true);
    setEditListing(true);
  };
  const hideApiCall = (id, value) => {
    const data = {
      property_id: [id],
      hide_property: value,
    };
    const body = { data, navigate };
    dispatch(actionHideAllListingAPICall(body));
  };
  const onClickOfChangeContact = (id) => {
    const data = { id: [id] };
    const body = { data, navigate };
    dispatch(actionGetPropertyDetailApiCall(body));
    setEditMainContact(true);
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.ListingMain}>
          <ListingManagementHeadre selectedUser={selectedUser} />
          <div className={classes.ListingSection}>
            <table>
              <tr>
                <th className={classes.selectOrg}></th>
                <th></th>
                <th>{t("address")}</th>
                <th>{t("city_p")}</th>
                <th>{t("postalcode")}</th>
                <th>{t("price")}</th>
                <th>{t("type_c")}</th>
                <th>{t("id")}</th>
                <th>{t("contact")}</th>
                <th>{t("organisation_c")}</th>
                <th>{t("status_c")}</th>
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {ListingData && ListingData?.length > 0 ? (
                    ListingData?.map((item, index) => (
                      <tr
                        className={
                          selectedUser.find((s) => s == item.id) &&
                          item.hide_property != 0
                            ? `${classes.active} ${classes.InvisibleCard}`
                            : item.hide_property != 0
                            ? `${classes.InvisibleCard}`
                            : selectedUser.find((s) => s == item.id)
                            ? `${classes.active}`
                            : null
                        }
                        ref={(e) => (divref.current[index] = e)}
                      >
                        <td className={classes.selectOrg}>
                          <input
                            type="checkbox"
                            id={`org1` + index}
                            name="org"
                            className={classes.radioCustom}
                            onChange={(e) => {
                              if (e.target.checked) {
                                adduser(item.id);
                              } else {
                                deleteUser(item.id);
                              }
                            }}
                            checked={
                              selectedUser.find((s) => s == item.id)
                                ? true
                                : false
                            }
                          />
                          <label
                            className={classes.radioCustomLabel}
                            htmlFor={`org1` + index}
                          ></label>
                        </td>
                        <td>
                          {item.hide_property == 0 ? (
                            <FaEye
                              onClick={() => updatePropertyVisible(1, item)}
                            />
                          ) : (
                            <FaEyeSlash
                              onClick={() => updatePropertyVisible(0, item)}
                            />
                          )}
                        </td>
                        <td>
                          {item.address
                            ? item.address
                            : `${item.street} ${item.housenumber}`}
                        </td>
                        <td>{item.city}</td>
                        <td>{item.postcode}</td>
                        {item?.PropertyPrice?.length > 0 ? (
                          <td>{PriceFormatter(item.PropertyPrice[0].price)}</td>
                        ) : (
                          <>
                            {item.is_rent == 0 && item.is_sell == 1 && (
                              <td>{PriceFormatter(item.sell_price)}</td>
                            )}
                            {item.is_rent == 1 && item.is_sell == 0 && (
                              <td>{PriceFormatter(item.rent_price)}</td>
                            )}
                            {item.is_rent == 1 && item.is_sell == 1 && (
                              <td>
                                {PriceFormatter(item.sell_price)}
                                {PriceFormatter(item.rent_price)}
                              </td>
                            )}
                          </>
                        )}
                        {item.is_rent == 0 && item.is_sell == 1 && (
                          <td>{t("sell")}</td>
                        )}
                        {item.is_rent == 1 && item.is_sell == 0 && (
                          <td>{t("rent")}</td>
                        )}
                        {item.is_rent == 1 && item.is_sell == 1 && (
                          <td>{t("both")}</td>
                        )}
                        {item.is_rent == 0 && item.is_sell == 0 && (
                          <td>{t("none")}</td>
                        )}
                        <td>{item.id}</td>
                        <td>
                          {item.user_detail
                            ? item.user_detail.full_name ||
                              item.user_detail.email
                            : "NA"}
                        </td>
                        <td>
                          {item.agency_detail ? item.agency_detail.name : "NA"}
                        </td>

                        <td>{item.status ? item.status : "NA"}</td>
                        <td className={classes.userEditor}>
                          <img
                            src={userEditorIcon}
                            onClick={() => {
                              if (ListingData.length - 1 > index) {
                                handleScroll(index);
                              }
                              if (ind !== index) {
                                setShow(!show);
                                setInd(index);
                              } else {
                                setInd("");
                              }
                            }}
                          />
                          {show && ind == index && (
                            <div
                              className={classes.EditorWrap}
                              onClick={() => setShow(!show)}
                              style={
                                ListingData.length - 1 > index ||
                                ListingData.length <= 3
                                  ? {}
                                  : { top: "-170px" }
                              }
                              ref={listRef}
                            >
                              <ul>
                                <li
                                  onClick={() =>
                                    onClickViewProperty(item.id, false)
                                  }
                                >
                                  {t("view_listing")}
                                </li>
                                <li onClick={() => OnClickEditList(item.id)}>
                                  {t("edit_listing")}
                                </li>
                                {item.hide_property == 1 ? (
                                  <li onClick={() => OnClickOfUnBlock(item.id)}>
                                    {t("online_listing")}
                                  </li>
                                ) : (
                                  <li onClick={() => OnClickOfBlock(item.id)}>
                                    {t("block_listing")}
                                  </li>
                                )}
                                <li onClick={() => onClickOfDelete(item.id)}>
                                  {t("delete_listing")}
                                </li>
                                <li onClick={onClickOfChangeContact}>
                                  {t("change_contact")}
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={9}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
      <ModalMain
        showmodel={editListing}
        handleClose={() => setEditListing(false)}
        size={"xl"}
      >
        <EditListing handleClose={() => setEditListing(false)} />
      </ModalMain>
      <ModalMain
        showmodel={editMainContact}
        handleClose={() => setEditMainContact(false)}
        size={"xl"}
      >
        <EditMainContact
          handleClose={() => setEditMainContact(false)}
          edit={false}
        />
      </ModalMain>
    </React.Fragment>
  );
};

export default ListingManagementMain;
