import React, { useEffect, useState } from "react";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import classes from "./organisationmanagementheader.module.scss";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import CreateOrgIcon from "../../../assets/icons/CreateOrgIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionBlockOrganizationApiCall,
  actionDeleteOrganizationApiCall,
  actionOrganizationListApiCall,
  actionUnblockOrgApiCall,
  setperPageOrgCount,
} from "../../../Redux/Actions";
import ModalMain from "../Modal";
import CreateNewOrganisationMain from "../../ModalComponents/OrganisationManagementModals/CreateNewOrganisation";
import ConfirmationModal from "../ConfirmationModal";
import BlockIcon from "../../../assets/images/BlockIcon.svg";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import { toast } from "react-toastify";
import onlineIcon from "../../../assets/icons/onlineIcon.svg";
import CustomSelect from "../CustomSelect";

const OrganisationManagementHeader = (props) => {
  const { t } = useTranslation();
  const [sidebar, setSideBar] = useState(false);
  const [createuser, setCreateUser] = useState(false);
  const [deleteuser, setdeleteUser] = useState(false);
  const [blockuser, setblockUser] = useState(false);
  const [unBlockuser, setUnblockUser] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [city, setCity] = useState();
  const state = useSelector((state) => state.CommonReducer);
  const [perpage, setPerPage] = useState(
    state?.perPageOrgCount ? state?.perPageOrgCount : 40
  );
  const selectItem = {
    type: "select",
    name: t("city_p"),
    apiName: "city",
    placeholder: t("city_p"),
    options:
      state?.getCityData &&
      state?.getCityData?.map((p) => {
        return { label: p.name, value: p.name };
      }),
    loader: true,
  };
  useEffect(() => {
    filterClick();
    dispatch(setperPageOrgCount(perpage));
  }, [perpage]);

  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
    setCreateUser(false);
  };

  const createuserShow = () => {
    setCreateUser(true);
  };
  const filterClick = () => {
    const data = {
      page: 1,
      name: name,
      city: city ? city?.City?.value : "",
      per_page: perpage,
    };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  };
  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };
  const onCLickBlockOrg = () => {
    const data = {
      id: props.selectedUser,
    };
    const body = { data, navigate };
    dispatch(actionBlockOrganizationApiCall(body));
  };

  const onCLickDeleteOrg = () => {
    const data = {
      id: props.selectedUser,
    };
    const body = { data, navigate };
    dispatch(actionDeleteOrganizationApiCall(body));
  };
  const onCLickUnBlockOrg = (id) => {
    const data = {
      id: props.selectedUser,
    };
    const body = { data, navigate };
    dispatch(actionUnblockOrgApiCall(body));
  };
  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("organisation_management")}</h5>
            <form onSubmit={onClickOfButton}>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <input
                    type="text"
                    placeholder={`${t("name_p")}...`}
                    onChange={(e) => setName(e.target.value)}
                    className={classes.Text}
                  />
                </div>

                <div className={classes.fildBox}>
                  <CustomSelect
                    item={selectItem}
                    apiName={selectItem.apiName}
                    isInputChange={true}
                    headerSearch={city}
                    setHeaderSearch={setCity}
                  />
                </div>
                <button onClick={filterClick}>{t("filter")}</button>
              </div>
            </form>
          </div>
          <div className={classes.ManagOrgSection}>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_org")}`);
                } else {
                  setUnblockUser(true);
                }
              }}
            >
              {t("online_org1")}
              <img src={onlineIcon} alt="" />
            </button>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_org")}`);
                } else {
                  setblockUser(true);
                }
              }}
            >
              {t("block_org_header")}
              <img src={BlockIcon} alt="" />
            </button>

            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_org")}`);
                } else {
                  setdeleteUser(true);
                }
              }}
            >
              {t("delete_org_header")}
              <img src={DelIcon} alt="" />
            </button>
            <button onClick={createuserShow}>
              {t("create_org")}
              <img src={CreateOrgIcon} />
            </button>
          </div>
        </div>
        <div className={classes.OrgPerPageSection}>
          <p>{t("org_per_page")}</p>
          <div className={classes.numberOfOrg}>
            <select
              id="cars"
              name="cars"
              onChange={(e) => setPerPage(e.target.value)}
            >
              <option value={40} selected={40 == perpage}>
                40
              </option>
              <option value={50} selected={50 == perpage}>
                50
              </option>
              <option value={60} selected={60 == perpage}>
                60
              </option>
              <option value={70} selected={70 == perpage}>
                70
              </option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ModalMain showmodel={createuser} handleClose={handleClose} size={"lg"}>
        <CreateNewOrganisationMain handleClose={handleClose} />
      </ModalMain>
      <ConfirmationModal
        showmodel={unBlockuser}
        handleClose={() => setUnblockUser(false)}
        size={"sm"}
        onClickYesButton={onCLickUnBlockOrg}
        title={`You are about to online ${props.selectedUser.length} Organisation(s)`}
      ></ConfirmationModal>
      <ConfirmationModal
        showmodel={deleteuser}
        handleClose={() => setdeleteUser(false)}
        size={"sm"}
        onClickYesButton={onCLickDeleteOrg}
        title={`You are about to delete ${props.selectedUser.length} Organisation(s)`}
      ></ConfirmationModal>
      <ConfirmationModal
        showmodel={blockuser}
        handleClose={() => setblockUser(false)}
        size={"sm"}
        onClickYesButton={onCLickBlockOrg}
        title={`You are about to block ${props.selectedUser.length} Organisation(s)`}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default OrganisationManagementHeader;
