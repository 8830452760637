import React, { useEffect, useState } from "react";
import classes from "./createusermain.module.scss";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionCreateUserApiCall } from "../../../../Redux/Actions";
import useForm from "../../../../utils/useForm";

const CreateUserMain = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let close = props.handleClose;
  const { handleChange, values, errors, onFocus, onBlur, handleSubmit } =
    useForm();
  const loading = useSelector((state) => state.CommonReducer.loader);
  const [previewImg, setPreviewImg] = useState();
  const onCreate = async () => {
    let result = await handleSubmit();
    apiCall(result);
  };
  const apiCall = (result) => {
    if (
      !result.first_name &&
      !result.last_name &&
      !result.email &&
      !result.password &&
      !result.file
    ) {
      const formdata = new FormData();
      formdata.append("first_name", values.first_name);
      formdata.append("last_name", values.last_name);
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      formdata.append("file", values.file);
      const body = { formdata, navigate, close };
      dispatch(actionCreateUserApiCall(body));
    }
  };
  const onChangePicture = (e) => {
    handleChange(e);
    setPreviewImg(URL.createObjectURL(e.target.files[0]));
  };
  const handleDrop = (e) => {
    e.preventDefault();
    handleChange(e);
    const droppedFile = e.dataTransfer.files[0];
    setPreviewImg(URL.createObjectURL(droppedFile));
  };
  return (
    <div className={classes.CreateUserMain}>
      <h3>{t("create_new_user")}</h3>
      <div className={classes.CarteUserWarp}>
        <h6>{t("upload_profile_picture")}</h6>
        <div
          className={classes.uploadMain}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          name="file"
        >
          <input
            type="file"
            id="upload"
            accept=".jpg, .jpeg, .png,.webp,.svg"
            hidden
            name="file"
            onChange={onChangePicture}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <label htmlFor="upload">
            {previewImg ? (
              <div className={classes.PhotoUpload}>
                <img className={classes.img1} src={previewImg} />
              </div>
            ) : (
              <div className={classes.PhotoUpload}>
                <img src={FileUploadIcon} />
              </div>
            )}
            {errors?.file && (
              <p className={`${classes.errorText}`}>{errors.file}</p>
            )}
          </label>
        </div>
        <div className={classes.FildBox}>
          <p>{t("first_name")}</p>
          <input
            type="text"
            name="first_name"
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        {errors?.first_name && (
          <p className={`${classes.errorText}`}>{errors.first_name}</p>
        )}
        <div className={classes.FildBox}>
          <p>{t("last_name")}</p>
          <input
            type="text"
            name="last_name"
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        {/* {errors?.last_name && (
          <p className={`${classes.errorText}`}>{errors.last_name}</p>
        )} */}
        <div className={classes.FildBox}>
          <p>{t("email")}</p>
          <input
            type="email"
            name="email"
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        {errors?.email && (
          <p className={`${classes.errorText}`}>{errors.email}</p>
        )}
        <div className={classes.FildBox}>
          <p>{t("password")}</p>
          <input
            type="password"
            name="password"
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        {errors?.password && (
          <p className={`${classes.errorText}`}>{errors.password}</p>
        )}
        <div className={classes.btnWrap}>
          <button onClick={onCreate}>
            {" "}
            {loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("create")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateUserMain;
