// import React, { useEffect, useRef, useState } from "react";
// import classes from "./emailtags.module.scss";
// import { useTranslation } from "react-i18next";
// import ModalMain from "../../common/Modal";
// import SearchIcon from "../../../assets/images/SearchIcon.svg";
// import moment from "moment";
// import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import {
//   actionGetAllTagsApiCall,
//   deleteTagRequest,
// } from "../../../Redux/Actions";
// import CreateTag from "../CreateTag";
// import EditTag from "../EditTag";
// import Loader from "../../common/Loader";

// const EmailTags = () => {
//   const [open, setOpen] = useState(false);
//   const [edit, setEdit] = useState(false);
//   const [tagValue, setTagValue] = useState("");
//   const [tagId, setTagId] = useState("");
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   //GETTING ALL TAGS
//   const Tags = useSelector((state) => state.CommonReducer.saveAllTags);
//   const { t } = useTranslation();
//   const [tagSearch, setTagSearch] = useState();
//   let pathname = useLocation().pathname;
//   pathname = pathname.substring(pathname.lastIndexOf("/") + 1);

//   const [windowSize, setWindowSize] = useState(getWindowSize());
//   useEffect(() => {
//     function handleWindowResize() {
//       setWindowSize(getWindowSize());
//     }

//     window.addEventListener("resize", handleWindowResize);

//     return () => {
//       window.removeEventListener("resize", handleWindowResize);
//     };
//   }, [window]);

//   function getWindowSize() {
//     return window.innerWidth;
//   }
//   const loader = useSelector((state) => state.CommonReducer.loader);
//   const handleModal = () => {
//     setOpen(!open);
//   };

//   const handleEdit = (id, selectedValue) => {
//     if (selectedValue == "edit") {
//       const selectedTag = Tags.find((tag) => tag.id === id);
//       if (selectedTag) {
//         // Set the tag value to state
//         setTagValue(selectedTag.tag_name);
//         setTagId(id);
//       }
//       setEdit(!edit);
//     } else if (selectedValue == "Delete") {
//       console.log("delete dispatched", id);
//       dispatch(deleteTagRequest(id));
//       setTimeout(() => {
//         dispatch(actionGetAllTagsApiCall());
//       }, 2000);
//     } else {
//       setEdit(edit);
//     }
//   };
//   useEffect(() => {
//     dispatch(actionGetAllTagsApiCall());
//   }, []);

//   return (
//     <React.Fragment>
//       <div className={classes.detailPage}>
//         <div className={classes.container}>
//           <div className={classes.address}>
//             <div className={classes.headerwrap}>
//               <p>{t("Tags")}</p>
//               <button onClick={handleModal}>Create New</button>
//             </div>
//             <div className={classes.addressContainer}>
//               <div className={classes.tableContainer}>
//                 <div className={classes.SearchBar}>
//                   <img src={SearchIcon} alt="" />
//                   <input
//                     type="search"
//                     placeholder="Search Tags"
//                     onChange={(e) => setTagSearch(e.target.value)}
//                   />
//                 </div>
//                 <div className={classes.TableListngLayOut}>
//                   {loader == true ? (
//                     <tr>
//                       <td className={classes.LoaderWrap} colSpan={6}>
//                         <Loader />
//                       </td>
//                     </tr>
//                   ) : (
//                     <>
//                       <table>
//                         <tr>
//                           <th></th>
//                           <th>{t("Tags")}</th>
//                           <th>{t("Created")}</th>
//                           <th>{t("Actions")}</th>
//                           <th></th>
//                         </tr>
//                         {Tags && Tags.length > 0 ? (
//                           Tags?.map((item) => (
//                             <tr>
//                               <td>
//                                 <p></p>
//                               </td>
//                               <td>
//                                 <span className={classes.title}>
//                                   <h6>{item?.tag_name}</h6>
//                                 </span>
//                               </td>
//                               <td>
//                                 <span>
//                                   {moment(item?.created_at).format("LL")}
//                                 </span>{" "}
//                                 <br />
//                                 <span>Created date</span>
//                               </td>
//                               <td>
//                                 <select
//                                   className={classes.select}
//                                   onChange={(e) =>
//                                     handleEdit(item.id, e.target.value)
//                                   }
//                                 >
//                                   <option value="options">Options</option>
//                                   <option value="edit">Edit</option>
//                                   <option>Delete</option>
//                                 </select>
//                               </td>
//                             </tr>
//                           ))
//                         ) : (
//                           <tr>
//                             <td>
//                               <p></p>
//                             </td>
//                             <p>No data</p>
//                           </tr>
//                         )}
//                       </table>
//                     </>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <ModalMain
//         showmodel={open}
//         handleClose={() => setOpen(false)}
//         size={"xl"}
//       >
//         <CreateTag onClose={() => setOpen(false)} edit={true} />
//       </ModalMain>
//       <ModalMain
//         showmodel={edit}
//         handleClose={() => setEdit(false)}
//         size={"xl"}
//       >
//         <EditTag
//           onClose={() => setEdit(false)}
//           edit={true}
//           tagValue={tagValue}
//           tagId={tagId}
//         />
//       </ModalMain>
//     </React.Fragment>
//   );
// };

// export default EmailTags;

import React, { useEffect, useState } from "react";
import classes from "./emailtags.module.scss";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/images/SearchIcon.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetAllTagsApiCall,
  deleteTagRequest,
} from "../../../Redux/Actions";
import CreateTag from "../CreateTag";
import EditTag from "../EditTag";
import Loader from "../../common/Loader";
import ModalMain from "../../common/Modal";

const EmailTags = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [tagId, setTagId] = useState("");
  const [tagSearch, setTagSearch] = useState(""); // State for search
  const dispatch = useDispatch();

  // Getting all tags
  const Tags = useSelector((state) => state.CommonReducer.saveAllTags);
  const { t } = useTranslation();
  const loader = useSelector((state) => state.CommonReducer.loader);
  
  

  useEffect(() => {
    dispatch(actionGetAllTagsApiCall());
  }, [dispatch]);

  // Filter tags based on search input
  const filteredTags = Array.isArray(Tags)
    ? Tags.filter((tag) =>
        tag.tag_name.toLowerCase().includes(tagSearch.toLowerCase())
      )
    : [];

  const handleEdit = (id, selectedValue) => {
    if (selectedValue === "edit") {
      const selectedTag = Tags.find((tag) => tag.id === id);
      if (selectedTag) {
        // Set the tag value to state
        setTagValue(selectedTag.tag_name);
        setTagId(id);
      }
      setEdit(!edit);
    } else if (selectedValue === "Delete") {
      console.log("delete dispatched", id);
      dispatch(deleteTagRequest(id));
      setTimeout(() => {
        dispatch(actionGetAllTagsApiCall());
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.detailPage}>
        <div className={classes.container}>
          <div className={classes.address}>
            <div className={classes.headerwrap}>
              <p>{t("Tags")}</p>
              <button onClick={() => setOpen(true)}>Create New</button>
            </div>
            <div className={classes.addressContainer}>
              <div className={classes.tableContainer}>
                <div className={classes.SearchBar}>
                  <img src={SearchIcon} alt="" />
                  <input
                    type="search"
                    placeholder="Search Tags"
                    value={tagSearch}
                    onChange={(e) => setTagSearch(e.target.value)}
                  />
                </div>
                <div className={classes.TableListngLayOut}>
                  {loader ? (
                    <div className={classes.LoaderWrap}>
                      <Loader />
                    </div>
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th />
                          <th>{t("Tags")}</th>
                          <th>{t("Created")}</th>
                          <th>{t("Actions")}</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTags.length > 0 ? (
                          filteredTags.map((item) => (
                            <tr key={item.id}>
                              <td />
                              <td>
                                <span className={classes.title}>
                                  <h6>{item.tag_name}</h6>
                                </span>
                              </td>
                              <td>
                                <span>{moment(item.created_at).format("LL")}</span>{" "}
                                <br />
                                <span>Created date</span>
                              </td>
                              <td>
                                <select
                                  className={classes.select}
                                  onChange={(e) =>
                                    handleEdit(item.id, e.target.value)
                                  }
                                >
                                  <option value="options">Options</option>
                                  <option value="edit">Edit</option>
                                  <option>Delete</option>
                                </select>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4}>No data</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalMain showmodel={open} handleClose={() => setOpen(false)} size="xl">
        <CreateTag onClose={() => setOpen(false)} edit={true} />
      </ModalMain>
      <ModalMain
        showmodel={edit}
        handleClose={() => setEdit(false)}
        size="xl"
      >
        <EditTag
          onClose={() => setEdit(false)}
          edit={true}
          tagValue={tagValue}
          tagId={tagId}
        />
      </ModalMain>
    </React.Fragment>
  );
};

export default EmailTags;

