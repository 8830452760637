import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./selectsubject.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddEmailApiCall,
  actionGetSubjectApiCall,
  actionLangListAPICall,
} from "../../../Redux/Actions";
import APIUrl from "../../../API/APIUrl";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { FaBlackTie } from "react-icons/fa";
import { toast } from "react-toastify";

const SelectSubject = (props) => {
  const saveSingleUserData = useSelector(
    (state) => state.CommonReducer.saveSingleUserData
  );
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const [lang, setLang] = useState(1);
  const [focus, setFocus] = useState(false);
  const [editEmail, setEditEmail] = useState({
    title: "",
    subject: "",
    templateId: "",
    content: "",
    slug: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [subjectLine, setSubjectLine] = useState("");
  const [previewText, setPreviewText] = useState("");
  const subjectData = useSelector(
    (state) => state.CommonReducer?.savedSubjects
  );
  const audienceData = useSelector(
    (state) => state.CommonReducer?.savedAudience
  );
  console.log(audienceData, "audience ID from selector");
  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const options2 = [
      { value: "0 km", label: "0 km" },
      { value: "1 km", label: "1 km" },
      { value: "2 km", label: "2 km" },
    ];
    const options3 = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];
    let close = props.onClose;
    const handleSubject = (e) => {
      setSubjectLine(e.target.value)
    }
    const handlePreview = (e) => {
      setPreviewText(e.target.value)
    }
  useEffect(() => {
    if (props.edit == true && saveSingleUserData) {
      setEditEmail({
        title: saveSingleUserData.title,
        subject: saveSingleUserData.subject,
        templateId: saveSingleUserData.id,
        content: saveSingleUserData.content,
        slug: saveSingleUserData.slug,
      });
      setLang(saveSingleUserData.language_id);
    }
  }, [saveSingleUserData, props]);

  useEffect(() => {
    const body = { navigate };
    dispatch(actionLangListAPICall(body));
  }, []);
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
      width:"100%"
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const array = [
    "0 km",
    "1 km",
    "2 km",
    "5 km",
    "10 km",
    "15 km",
    "30 km",
    "50 km",
    "100 km",
  ];
  const handleSubmit = (e)=>{
    e.preventDefault();
    if(subjectLine.length === 0 || subjectLine == "" || previewText.length === 0 || previewText == ""){
      toast.error("Please fill all the fields to continue!")
  }else{
    apiCallSubject()
  }}

  
  const apiCallSubject = () => {
    const data = JSON.stringify({
      "subject_line": subjectLine,
      "preview_text": previewText
    });
    const body = {data, navigate, close,id: props.id ? props.id : audienceData?.id}
    dispatch(actionGetSubjectApiCall(body))
  }
  useEffect(() => {
    if (props.editable == true) {
    setSubjectLine(subjectData?.subject_line)
    setPreviewText(subjectData?.preview_text)
    }
  }, [props.editable]);
  return (
    <div className={classes.EditEmailMain}>
      <h3> Select Subject </h3>
      <div className={classes.EditEmailWrap}>
        <div className={classes.InputWrap}>
          <p className={classes.to}>{t("Subject")}</p>
          <p>{t("What's the subject line for this email?")}</p>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Subject line")}</p>
          <input
          type="text"
          value={subjectLine}
          onChange={handleSubject}
          required
          />
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Preview text")}</p>
          <input
          type="text"
          value={previewText}
          onChange={handlePreview}
          required
          />
        </div>
       
        <div className={classes.btnWrap}>
          <button onClick={handleSubmit}>
            {" "}
            {loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("Save")}
          </button>
          <button onClick={() => props.onClose()} className={classes.cancelBtn}>
            {" "}
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectSubject;
