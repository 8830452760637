import { useState } from "react";
import { useTranslation } from "react-i18next";
const useForm = (title) => {
  //Form values
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: (title = "Login"
      ? localStorage.getItem("saveEmail") != null
        ? localStorage.getItem("saveEmail")
        : ""
      : ""),
    password: (title = "Login"
      ? localStorage.getItem("savePassword") != null
        ? localStorage.getItem("savePassword")
        : ""
      : ""),
    file: "",
    org_name: "",
    address: "",
    city: "",
    postalcode: "",
    website: "",
    phone: "",
    banner: "",
    logo: "",
    shortDescription: "",
    column: "",
    lang: "",
    translation: "",
    translationValue: "",
  });
  //Errors
  const [errors, setErrors] = useState({
    first_name: "",
    email: "",
    password: "",
    file: "",
    org_name: "",
    address: "",
    city: "",
    postalcode: "",
    website: "",
    phone: "",
    banner: "",
    logo: "",
    column: "",
    lang: "",
    translation: "",
    translationValue: "",
  });
  const [check, setCheck] = useState(true);
  const { t } = useTranslation();
  const validate = (name, value) => {
    //A function to validate each input values
    if (check) {
      setErrors((previousInputs) => ({
        ...previousInputs,
        [name]: "",
      }));
    } else {
      switch (name) {
        case "first_name":
          if (value.length > 0) {
            if (!new RegExp(/^[A-Za-z\-\s]+$/).test(value)) {
              setErrors({
                ...errors,
                first_name: t("invalid_first_name"),
              });
            } else {
              setErrors({
                ...errors,
                first_name: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              first_name: t("please_enter_your_first_name"),
            });
          }
          break;

        case "last_name":
          if (value.length > 0) {
            if (!new RegExp(/^[A-Za-z\-\s]+$/).test(value)) {
              setErrors({
                ...errors,
                last_name: t("invalid_last_name"),
              });
            } else {
              setErrors({
                ...errors,
                last_name: "",
              });
            }
          }
          break;

        case "email":
          if (value.length > 0) {
            if (
              !new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              ).test(value)
            ) {
              setErrors({
                ...errors,
                email: t("please_enter_valid_email_id"),
              });
            } else {
              setErrors({
                ...errors,
                email: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              email: t("please_enter_your_email_id"),
            });
          }
          break;
        case "password":
          if (value.length > 0) {
            if (value.length < 8) {
              setErrors({
                ...errors,
                password: t("minimum_eight_characters"),
              });
            } else {
              setErrors({
                ...errors,
                password: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              password: t("password_required"),
            });
          }
          break;

        case "phone":
          if (value.length > 0) {
            if (value.length < 8) {
              errors.phone = t("invalid_phone_number");
            }
          } else {
            errors.phone = t("please_enter_mobile_number");
          }
          break;
        case "shortDescription":
          if (value.length > 0) {
            errors.shortDescription = "";
          } else {
            errors.shortDescription = t("please_enter_shortDescription");
          }
          break;
        case "description":
          if (value.length > 0) {
            errors.description = "";
          } else {
            errors.description = t("please_enter_description");
          }
          break;
        case "file":
          if (value != "") {
            if (value.size > 2e6) {
              setErrors({
                ...errors,
                file: t("please_upload_a_file_smaller_than_two_mb"),
              });
            } else {
              setErrors({
                ...errors,
                file: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              file: t("please_upload_your_photo"),
            });
          }
          break;
        case "banner":
          if (value != "") {
            if (value.size > 2e6) {
              setErrors({
                ...errors,
                banner: t("please_upload_a_file_smaller_than_two_mb"),
              });
            } else {
              setErrors({
                ...errors,
                banner: "",
              });
            }
          }
          break;
        case "logo":
          if (value != "") {
            if (value.size > 2e6) {
              setErrors({
                ...errors,
                logo: t("please_upload_a_file_smaller_than_two_mb"),
              });
            } else {
              setErrors({
                ...errors,
                logo: "",
              });
            }
          }
          break;
        case "org_name":
          if (value.length > 0) {
            if (!new RegExp(/^[A-Za-z\-\s]+$/).test(value)) {
              setErrors({
                ...errors,
                org_name: t("invalid_org_name"),
              });
            } else {
              setErrors({
                ...errors,
                org_name: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              org_name: t("please_enter_organisation_name"),
            });
          }
          break;

        case "address":
          if (value.length > 0) {
            if (!value.trim()) {
              setErrors({
                ...errors,
                address: t("address_validation"),
              });
            } else {
              setErrors({
                ...errors,
                address: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              address: t("please_enter_address"),
            });
          }
          break;

        case "city":
          if (value.length > 0) {
            if (!new RegExp(/^[A-Za-z\-\s]+$/).test(value)) {
              setErrors({
                ...errors,
                city: t("invalid_city_name"),
              });
            } else {
              setErrors({
                ...errors,
                city: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              city: t("please_enter_city"),
            });
          }
          break;

        case "postalcode":
          if (value.length > 0) {
            if (value.length < 4) {
              setErrors({
                ...errors,
                postalcode: t("invalid_postal_code"),
              });
            } else {
              setErrors({
                ...errors,
                postalcode: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              postalcode: t("please_enter_postalcode"),
            });
          }
          break;

        case "website":
          if (value.length > 0) {
            if (
              !new RegExp(
                "^(https?:\\/\\/)?" +
                  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                  "((\\d{1,3}\\.){3}\\d{1,3}))" +
                  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                  "(\\?[;&a-z\\d%_.~+=-]*)?" +
                  "(\\#[-a-z\\d_]*)?$",
                "i"
              ).test(value)
            ) {
              setErrors({
                ...errors,
                website: t("invalid_website_name"),
              });
            } else {
              setErrors({
                ...errors,
                website: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              website: t("please_enter_website"),
            });
          }
          break;

        case "column":
          if (value.length > 0) {
            setErrors({
              ...errors,
              column: "",
            });
          } else {
            setErrors({
              ...errors,
              column: t("column_required"),
            });
          }
          break;

        case "lang":
          if (value.length > 0) {
            setErrors({
              ...errors,
              lang: "",
            });
          } else {
            setErrors({
              ...errors,
              lang: t("lang_required"),
            });
          }
          break;

        case "translation":
          if (value.length > 0) {
            setErrors({
              ...errors,
              translation: "",
            });
          } else {
            setErrors({
              ...errors,
              translation: t("translation_required"),
            });
          }
          break;

        case "translationValue":
          if (value.length > 0) {
            setErrors({
              ...errors,
              translationValue: "",
            });
          } else {
            setErrors({
              ...errors,
              translationValue: t("translationvalue_required"),
            });
          }
          break;

        default:
          break;
      }
    }
  };
  const handleSubmit = async () => {
    let result = {
      first_name:
        values.first_name.length > 0
          ? !new RegExp(/^[A-Za-z\-\s]+$/).test(values.first_name)
            ? t("invalid_first_name")
            : undefined
          : t("please_enter_your_first_name"),
      shortDescription:
        values.shortDescription.length > 0
          ? ""
          : t("please_enter_shortDescription"),
      last_name:
        values.last_name.length > 0 &&
        !new RegExp(/^[A-Za-z\-\s]+$/).test(values.last_name)
          ? t("invalid_last_name")
          : undefined,
      email:
        values.email.length > 0
          ? !new RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ).test(values.email)
            ? t("please_enter_valid_email_id")
            : undefined
          : t("please_enter_your_email_id"),
      password:
        values.password.length > 0
          ? values.password.length < 8
            ? t("minimum_eight_characters")
            : undefined
          : t("password_required"),
      org_name:
        values.org_name.length > 0
          ? !new RegExp(/^[A-Za-z\-\s]+$/).test(values.org_name)
            ? t("invalid_org_name")
            : undefined
          : t("please_enter_organisation_name"),
      address:
        values.address.length > 0
          ? !values.address.trim()
            ? t("address_validation")
            : undefined
          : t("please_enter_address"),
      city:
        values.city.length > 0
          ? !new RegExp(/^[A-Za-z\-\s]+$/).test(values.city)
            ? t("invalid_city_name")
            : undefined
          : t("please_enter_city"),
      postalcode:
        values.postalcode.length > 0
          ? values.postalcode.length < 4
            ? t("invalid_postal_code")
            : undefined
          : t("please_enter_postalcode"),
      website:
        values.website.length > 0
          ? !new RegExp(
              "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
              "i"
            ).test(values.website)
            ? t("invalid_website_name")
            : undefined
          : t("please_enter_website"),
      phone:
        values.phone.length > 0
          ? values.phone.length < 8
            ? t("invalid_phone_number")
            : undefined
          : t("please_enter_mobile_number"),
      file:
        values.file != ""
          ? values.file.size > 2e6
            ? t("please_upload_a_file_smaller_than_two_mb")
            : undefined
          : t("please_upload_your_photo"),
      banner:
        values.banner != "" && values.banner.size > 2e6
          ? t("please_upload_a_file_smaller_than_two_mb")
          : undefined,
      logo:
        values.logo != "" && values.logo.size > 2e6
          ? t("please_upload_a_file_smaller_than_two_mb")
          : undefined,
      column: values.column.length > 0 ? undefined : t("column_required"),
      lang: values.lang.length > 0 ? undefined : t("lang_required"),
      translation:
        values.translation.length > 0 ? undefined : t("translation_required"),
      translationValue:
        values.translationValue.length > 0
          ? undefined
          : t("translationvalue_required"),
    };
    setErrors(result);
    return result;
  };
  const handleChange = (event) => {
    event.persist();
    let { name, value, type, checked } = event.target;
    if (type == "file") {
      let { files } = event.target;
      setCheck(false);
      validate(name, checked);
      setValues({
        ...values,
        [name]: files[0],
      });
    } else if (type == undefined) {
      setCheck(false);
      validate("file", event.dataTransfer.files[0]);
      setValues({
        ...values,
        ["file"]: event.dataTransfer.files[0],
      });
    } else {
      if (check) validate(name, value);
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const onFocus = (event) => {
    let { name, value } = event.target;
    setCheck(false);
    validate(name, value);
  };

  const onBlur = (event) => {
    let { name, value } = event.target;
    setCheck(true);
    validate(name, value);
  };

  return {
    values,
    errors,
    handleChange,
    onFocus,
    onBlur,
    handleSubmit,
  };
};

export default useForm;
