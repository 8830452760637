import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import OldSingleOrgMain from "../../components/OldSingleOrgMain";
import {
  actionGetPropertyListAPICall,
  actionViewOrganizationApiCall,
} from "../../Redux/Actions";
import { SingleOrgMain } from "../../components/SingleOrgMain";

const SingleOrgDetailedPage = () => {
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getPropertyListAPICall = (id) => {
    const data = { page: 1, organizationid: id };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  };
  useEffect(() => {
    const data = pathname;
    const body = { data, navigate };
    dispatch(actionViewOrganizationApiCall(body));
    getPropertyListAPICall(pathname);
  }, []);
  return (
    <React.Fragment>
      {/* <OldSingleOrgMain /> */}
      <SingleOrgMain />
    </React.Fragment>
  );
};

export default SingleOrgDetailedPage;
