import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./creditinvoice.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionAddCreditApiCall } from "../../../Redux/Actions";
import pinkPlus from "../../../assets/icons/pinkPlus.svg"

const CreditInvoice = ({ edit, close }) => {
  const invoiceData = useSelector(
    (state) => state.CommonReducer.invoiceData.data.invoice
  );
  console.log("invoice", invoiceData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const [newIds, setnewIds] = useState([1]);
  const [existingItems, setExistingItems] = useState(invoiceData.invoice_items.map(x => "" + x.id));
  const [newItems, setNewItems] = useState([{id: 1, checked: false, article: "", description: "", price: 0.0}]);
  const [index, setIndex] = useState(1);

  const [data, setData] = useState({
    existingItems: invoiceData.invoice_items,
    existingIds: existingItems,
    newItems: newItems,
    debit_id: invoiceData.id,
  });

  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (edit && invoiceData) {
      let temp = {
        ...invoiceData[0],
      };
      delete temp.percentage;
      setData(temp);
    }
  }, [invoiceData]);

  const handleExistingChange = (e) => {
    let newArr = []
    if (e.target.checked) {
      newArr = [...existingItems, e.target.value];
    } else {
      newArr = existingItems.filter((item) => item !== e.target.value);
    }
    console.log("handleExistingChange", newArr);
    setExistingItems(newArr);
    setData({ ...data, ["existingIds"]: newArr });
  }

  const handleNewChange = (e) => {
    let newArr = newItems;
    console.log("newArr", newArr);
    console.log("e.target.id", parseInt(e.target.id.replace("n", "")));
    const i = newArr.findIndex((obj) => obj.id === parseInt(e.target.id.replace("n","")));
    console.log("i", i)
    if (i > -1) {
      newArr[i].checked = e.target.checked; 
    }
    console.log("handleNewChange", [...new Set(newArr)]);
    setNewItems([...new Set(newArr)]);
    setData({ ...data, ["newItems"]: newArr });
  };

  const AddCredit = () => {
      const body = { data, close, edit, navigate };
      console.log("data", data)
      dispatch(actionAddCreditApiCall(body));
  };

  const onChangeOfInput = (e) => {
    let { name, value, type } = e.target;
    if (type === "text" || type === "number") {
      if (name.indexOf("existing_price") > -1) {
        const id = name.split("_").at(-1);
        const index = invoiceData.invoice_items.findIndex((obj) => obj.id === parseInt(id));
        console.log("index e", index);
        invoiceData.invoice_items[index].price = parseFloat(e.target.value);
        invoiceData.invoice_items[index].amount = 1;
        invoiceData.invoice_items[index].vat_price = 0.21 * parseFloat(e.target.value);
        invoiceData.invoice_items[index].total_price = 1.21 * parseFloat(e.target.value);
        invoiceData.invoice_items[index].debit_id = invoiceData.invoice_items[index].id;
        setData({ ...data, ["existingItems"]: invoiceData.invoice_items });
      } else {
        const id = name.split("_").at(-1);
        const nm = name.split("_")[0];
        let tmpArr = newItems;
        const index = tmpArr.findIndex(
          (obj) => obj.id === parseInt(id)
        );
        console.log(tmpArr);
        console.log("index", index)
        if (index > -1) {
          tmpArr[index][nm] = e.target.value;
        } else {
          let params = { 
            id: parseInt(id),
            checked: true, 
          };
          params[nm] = e.target.value;
          tmpArr.push(params);
        }
        setNewItems(tmpArr)
        setData({ ...data, ["newItems"]: tmpArr });
      }
    }
  };
  const dateValidaterFunction = (e) => {
    const enteredValue = e.target.value;
    if (enteredValue) {
      const dateParts = e.target.value?.split("-");
      const year = dateParts[0];
      if (year?.length > 4) {
        e.preventDefault();
      } else {
        onChangeOfInput(e);
      }
    }
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const addRow = () => {
    console.log("addRow", index)
    let tmpArr = newItems;
    tmpArr.push({id: index+1, checked: true, article: "", description: "", price: 0.0});
    setNewItems(tmpArr);
    setIndex(index + 1);
  };

  return (
    <div className={classes.CreateCredit}>
      <h3>
        {edit ? t("edit") : t("create")} {t("credit_invoice")}
      </h3>
      <div className={classes.AllItemsWrap}>
        <div className={classes.ExistingItemsWrap}>
          <p>{t("which_existing_items_to_credit")}</p>
          {invoiceData.invoice_items &&
            invoiceData.invoice_items.map((item) => {
              return (
                <div className={classes.ItemWrap}>
                  <div>
                    <input
                      type="checkbox"
                      id={item.id}
                      name="existing_items[]"
                      value={item.id}
                      defaultChecked={true}
                      className={classes.radioCustom}
                      onChange={(event) => {
                        handleExistingChange(event);
                      }}
                    />
                    <label
                      className={classes.radioCustomLabel}
                      htmlFor={item.id}
                    >
                      {item.article}
                    </label>
                  </div>
                  <div>{item.description}</div>
                  <div className={classes.price}>
                    - €{" "}
                    <input
                      type="number"
                      name={`existing_price_${item.id}`}
                      onChange={onChangeOfInput}
                      defaultValue={item.amount}
                    />
                  </div>
                </div>
              );
            })}
          {error?.type && <p className={classes.errorText}>{error.type}</p>}
        </div>
        <div className={classes.NewItemsWrap} id="new_items">
          <p>{t("which_new_items_to_credit")}</p>
          <div>
            {" "}
            <p className={classes.plus} onClick={addRow}>
              <img
                src={pinkPlus}
                alt={t("add_row")}
                
                onClick={addRow}
              />{" "}
              {t("add_row")}
            </p>
          </div>
          { newItems && newItems.map(item => {
            return (
              <div className={classes.ItemWrap}>
                <div>
                  <input
                    type="checkbox"
                    id={`n${item.id}`}
                    name={`n${item.id}`}
                    value={item.id}
                    defaultChecked={item.checked}
                    className={classes.radioCustom}
                    onChange={(event) => {
                      handleNewChange(event);
                    }}
                  />
                  <label
                    className={classes.radioCustomLabel}
                    htmlFor={`n${item.id}`}
                  >
                    <input
                      type="text"
                      name={`article_${item.id}`}
                      onChange={onChangeOfInput}
                      defaultValue={item.article}
                    />
                  </label>
                </div>
                <div>
                  {" "}
                  <input
                    type="text"
                    name={`description_${item.id}`}
                    onChange={onChangeOfInput}
                    defaultValue={item.description}                    
                  />
                </div>
                <div className={classes.price}>
                 - €{" "}
                  <input
                    type="number"
                    name={`price_${item.id}`}
                    onChange={onChangeOfInput}
                    defaultValue={item.price}
                  />
                </div>
              </div>
            );
          })}
          {error?.type && <p className={classes.errorText}>{error.type}</p>}
        </div>
      </div>
      <div className={classes.btnWrap}>
        <button onClick={AddCredit}>{t("save")}</button>
      </div>
    </div>
  );
};

export default CreditInvoice;
