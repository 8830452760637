import React, { useEffect, useState } from "react";
import classes from "./classificationform.module.scss";
import dropDownIcon from "../../../assets/icons/DropdownIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  actionClassificationAddApi,
  actionClassificationNestedGetApi,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const ClassificationForm = ({ handleClose, editData }) => {
  const dispatch = useDispatch();
  const nestedClassificationData = useSelector(
    (state) => state?.CommonReducer?.getNestedClassificationData
  );

  const [showOptions, setShowOptions] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });
  const { name } = formData;

  const [errors, setErrors] = useState({
    name: "",
  });
  const [selectedclassification, setSelectedclassification] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedclassification(editData?.parentClassification);
  }, [editData]);
  useEffect(() => {
    setFormData({
      ...formData,
      name: editData?.name || "",
    });
    dispatch(actionClassificationNestedGetApi({ navigate }));
  }, []);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: "" };

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = () => {
    if (validateForm()) {
      const data = {
        name: name,
        parent_id: selectedclassification?.id,
        active: selectedclassification?.active,
      };
      const closePopup = handleClose;
      dispatch(
        actionClassificationAddApi({
          data,
          closePopup,
          edit: editData ? editData.id : false,
          navigate,
        })
      );
    }
  };

  function handleLabelClick(classification) {
    setSelectedclassification(classification);
  }
  function renderclassification(classification) {
    const isSelected =
      selectedclassification && selectedclassification.id === classification.id;

    return (
      <li
        key={classification.id}
        onClick={() => {
          if (classification.name !== editData?.name) {
            handleLabelClick(classification);
          }
        }}
      >
        <label
          className={
            isSelected
              ? classes.selectedBorder
              : classification.name == editData?.name
              ? classes.disabledBorder
              : ""
          }
        >
          - {classification?.name}
        </label>

        {classification?.subClassifications?.length > 0 && (
          <ul className="nested-checkbox" onClick={(e) => e.stopPropagation()}>
            {classification?.subClassifications?.map((subclassification) =>
              renderclassification(subclassification)
            )}
          </ul>
        )}
      </li>
    );
  }
  return (
    <React.Fragment>
      <div className={classes.classificationFormSection}>
        {editData ? <h3>Edit Classification</h3> : <h3>Add Classification</h3>}

        <div className={classes.inputsWrapper}>
          <div className={classes.dropdownBar}>
            <label>Type of Classification</label>
          </div>

          <div className={classes.customDropDown}>
            <div onClick={toggleOptions} className={classes.dropdownBar}>
              {selectedclassification ? (
                <label>{selectedclassification?.name}</label>
              ) : (
                <label>+ New Main Classification</label>
              )}

              <img src={dropDownIcon} alt="" />
            </div>

            {showOptions && (
              <ul className="dropdown-content">
                {nestedClassificationData?.map((mainclassification) =>
                  renderclassification(mainclassification)
                )}
              </ul>
            )}
          </div>
          <div className={classes.Text}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              onChange={handleInputChange}
              value={formData.name}
            />
            {errors.name && <p className={classes.error}>{errors.name}</p>}
          </div>
        </div>
        <div className={classes.btnwrapper}>
          {editData ? (
            <>
              <button onClick={handleClose} className={classes.btncancel}>
                Cancel
              </button>
              <button className={classes.btnadd} onClick={handleSubmit}>
                Edit
              </button>
            </>
          ) : (
            <>
              <button onClick={handleClose} className={classes.btncancel}>
                Cancel
              </button>
              <button className={classes.btnadd} onClick={handleSubmit}>
                Add Classification
              </button>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClassificationForm;
