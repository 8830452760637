import classes from "./viewpage.module.scss";
import deleteIcon from "../../../assets/images/DeleteIcon.svg";
import upload from "../../../assets/icons/FileUploadIcon.svg";
import cross from "../../../assets/icons/CrossIcon.svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { actionUpdateOrganizationApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ViewProfile = ({ handleClose }) => {
  const navigate = useNavigate();
  const [focus, setFocus] = useState(false);
  const [viewProfileData, setViewProfileData] = useState({
    logo_image: null,
    banner_image: null,
    created_at: "",
    description: "",
    short_description: "",
    email: "",
    language: "",
    city: "",
    phone: "",
    website: "",
  });
  const dispatch = useDispatch();
  const singleOrgData = useSelector(
    (state) => state.CommonReducer.singleOrgData
  );
  console.log("the singleOrgData in viewprofile", singleOrgData);
  useEffect(() => {
    setViewProfileData({
      ...viewProfileData,
      logo_image: singleOrgData.logo_image,
      banner_image: singleOrgData.banner_image,
      created_at: singleOrgData.created_at,
      description: singleOrgData.description,
      short_description: singleOrgData.short_description,
      email: singleOrgData?.email,
      language: singleOrgData?.language,
      city: singleOrgData?.city,
      phone: singleOrgData?.phone,
      website: singleOrgData?.website,
    });
  }, [singleOrgData]);
  // Function to handle the image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setViewProfileData({
          ...viewProfileData,
          [`${event.target.name} 1`]: file,
          [event.target.name]: e.target.result,
        });
      };

      reader.readAsDataURL(file);
    }
  };
  const clearImage = (name) => {
    setViewProfileData({
      ...viewProfileData,
      [name]: null,
    });
  };

  const onInputChnage = (e) => {
    setViewProfileData({
      ...viewProfileData,
      [e.target.name]: e.target.value,
    });
  };
  const onClickSaveButton = () => {
    const id = singleOrgData.id;
    const formdata = new FormData();
    viewProfileData[`banner_image 1`] &&
      formdata.append("banner", viewProfileData[`banner_image 1`]);
    formdata.append("description", viewProfileData.description);
    viewProfileData[`logo_image 1`] &&
      formdata.append("file", viewProfileData[`logo_image 1`]);
    formdata.append("shortDescription", viewProfileData.short_description);

    //added new payload from here
    viewProfileData[`email`] &&
      formdata.append("email", viewProfileData[`email`]);
    viewProfileData[`language`] &&
      formdata.append("language", viewProfileData[`language`]);
    viewProfileData[`city`] && formdata.append("city", viewProfileData[`city`]);
    viewProfileData[`phone`] &&
      formdata.append("phone", viewProfileData[`phone`]);
    viewProfileData[`website`] &&
      formdata.append("website", viewProfileData[`website`]);

    const body = {
      formdata,
      navigate,
      id,
      close: handleClose,
      edit: true,
    };
    dispatch(actionUpdateOrganizationApiCall(body));
  };
  return (
    <div className={classes.viewOrgSection}>
      <h3>View Org Profile</h3>
      <div className={classes.uploadPicWrapper}>
        <div className={classes.imageprview}>
          <label htmlFor="file-upload">Organisation Logo</label>
          <div className={classes.preview}>
            {viewProfileData.logo_image ? (
              <div className={classes.innerpreview}>
                <img src={viewProfileData.logo_image} alt="Profile Preview" />
                <div
                  className={classes.cross}
                  onClick={() => clearImage("logo_image")}
                >
                  <IoClose />
                </div>
              </div>
            ) : (
              <div className={classes.innerpreview}>
                <p>
                  Organisation <br /> Logo
                </p>
                <div
                  className={classes.cross}
                  onClick={() => clearImage("logo_image")}
                >
                  <IoClose />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.upload}>
          <div className={classes.uploadImg}>
            <label htmlFor="file-upload">
              <img src={upload} alt="Upload" />
            </label>
            <input
              type="file"
              accept=".jpg, .jpeg, .png,.webp,.svg"
              id="file-upload"
              name="logo_image"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>

          <div className={classes.labelupload}>
            <label htmlFor="file-upload">Upload New</label>
          </div>
        </div>
      </div>
      <div className={classes.uploadPicWrapper}>
        <div className={classes.imageprview}>
          <label htmlFor="file-upload1">Organisation Banner</label>
          <div className={`${classes.preview}  ${classes.bannerPreview}`}>
            {viewProfileData.banner_image ? (
              <div
                className={`${classes.innerpreview}   ${classes.bannerInner}`}
              >
                <img src={viewProfileData.banner_image} alt="Profile Preview" />
                <div
                  className={classes.cross}
                  onClick={() => clearImage("banner_image")}
                >
                  <IoClose />
                </div>
              </div>
            ) : (
              <div
                className={`${classes.innerpreview}  ${classes.bannerInner}`}
              >
                <p>
                  Organisation <br />
                  Banner
                </p>
                <div
                  className={classes.cross}
                  onClick={() => clearImage("banner_image")}
                >
                  <IoClose />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.upload}>
          <div className={classes.uploadImg}>
            <label htmlFor="file-upload1">
              <img src={upload} alt="Upload" />
            </label>
            <input
              type="file"
              accept=".jpg, .jpeg, .png,.webp,.svg"
              id="file-upload1"
              style={{ display: "none" }}
              name="banner_image"
              onChange={handleImageChange}
            />
          </div>

          <div className={classes.labelupload}>
            <label htmlFor="file-upload1">Upload New</label>
          </div>
        </div>
      </div>

      <div className={classes.inputsWrapper}>
        <label>
          {t("organisation")}
          {t("short_description")}
        </label>
        <textarea
          name="short_description"
          value={viewProfileData.short_description}
          onChange={onInputChnage}
        />
        <label>
          {t("organisation")}
          {t("full_description")}
        </label>

        <CKEditor
          editor={ClassicEditor}
          data={
            viewProfileData?.description ? viewProfileData?.description : ""
          }
          onChange={(event, editor) => {
            if (focus)
              setViewProfileData({
                ...viewProfileData,
                description: editor.getData(),
              });
          }}
          onFocus={() => setFocus(true)}
        />
        {/* <label>Organisation Description</label>
        <textarea
          name="description"
          value={viewProfileData.description}
          onChange={onInputChnage}
        /> */}
        <label htmlFor="street">Org created at </label>
        <input
          className={classes.createdAt}
          name="created_at"
          type="date"
          disabled
          value={
            (viewProfileData.created_at &&
              new Date(viewProfileData.created_at)
                .toISOString()
                .split("T")[0]) ||
            ""
          }
          onChange={onInputChnage}
        />
      </div>

      <div className={classes.btnwrapper}>
        <button className={classes.btncancel} onClick={handleClose}>
          {/* <img src={deleteIcon} alt="" /> Delete */}Cancel
        </button>
        <button className={classes.btnadd} onClick={onClickSaveButton}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ViewProfile;
