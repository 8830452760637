import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./addsendtime.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SlCalender } from "react-icons/sl";
import {
  actionAddEmailApiCall,
  actionGetSubjectApiCall,
  actionLangListAPICall,
} from "../../../Redux/Actions";
import APIUrl from "../../../API/APIUrl";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { FaBlackTie } from "react-icons/fa";
import { toast } from "react-toastify";

const AddSendTime = (props) => {

  let id = props.id

  const subjectData = useSelector(
    (state) => state.CommonReducer?.savedSubjects
  );
  console.log(subjectData,"addtime")
  const [lang, setLang] = useState(1);
  const [focus, setFocus] = useState(false);
  const [editEmail, setEditEmail] = useState({
    title: "",
    subject: "",
    templateId: "",
    content: "",
    slug: "",
  });
  const [selectedDate, setSelectedDate] = useState();
  const [selectedtime, setSelectedTime] = useState();

  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  
  const audienceData = useSelector(
    (state) => state.CommonReducer?.savedAudience
  );

 

  useEffect(() => {
    if (props.editable == true) {
      setSelectedDate(subjectData?.send_date)
      setSelectedTime(subjectData?.send_time)
    }
  }, [props.editable]);
  let close = props.onClose;
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    if (inputDate) {
      // Validate if the selected date is not in the past
      const currentDate = new Date().toISOString().substr(0, 10); // Current date in yyyy-mm-dd format
      if (inputDate >= currentDate) {
        setSelectedDate(inputDate);
      } else {
        toast.error('Please select a future date or today.');
      }
    }
  };
  const handleTimeChange = (e) => {
    const inputTime = e.target.value;
    if (inputTime) {
      setSelectedTime(inputTime);
    }
  };
  const HandleSave = (e) => {
    e.preventDefault(); 
    if (!selectedDate || !selectedtime) {
      toast.error('Please fill in both date and time fields.');
      return;
    }
    apiCallSubject()
  };
  const apiCallSubject = () => {
    const data = JSON.stringify({
      "send_date": selectedDate,
      "send_time": selectedtime + ':00',
    });
    const body = {data, navigate, close,id: props.id ?  id : audienceData?.id}
    dispatch(actionGetSubjectApiCall(body))
  }
  const today = new Date().toISOString().substr(0, 10);

  return (
    <div className={classes.EditEmailMain}>
      <h3> Add Send Time </h3>
      <div className={classes.EditEmailWrap}>
        <div className={classes.InputWrap}>
          <p className={classes.to}>{t("Send Time")}</p>
          <p>{t("When should we send this email?")}</p>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Delivery Date")}</p>
          <div className={classes.datepicker}>
            <input
              type="date"
              id="dateInput"
              // value={selectedDate}
              value={selectedDate}
              // onChange={(e) => setSelectedDate(e.target.value)}
              onChange={handleDateChange}
            />
            {/* <SlCalender className="calendar-icon" /> */}
          </div>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Delivery Time")}</p>
          <div className={classes.datepicker}>
            <input
              type="time"
              id="timeInput"
              value={selectedtime}
              onChange={handleTimeChange}
            />
            {/* <SlCalender className="calendar-icon" /> */}
          </div>
        </div>

        <div className={classes.btnWrap}>
          <button onClick={HandleSave}>
            {" "}
            {loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("Save")}
          </button>
          <button className={classes.cancelBtn} onClick={() => props.onClose()}>
            {" "}
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSendTime;
