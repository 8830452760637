import React from "react";
import FinancialManagementMain from "../../components/FinancialManagementMain";

const FinancialManagementPage = () => {
  return (
    <React.Fragment>
      <FinancialManagementMain />
    </React.Fragment>
  );
};

export default FinancialManagementPage;
