import React, { useEffect, useState } from "react";
import classes from "./listingmanagementheadre.module.scss";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionDeletePropertyAPICall,
  actionGetPropertyListAPICall,
  actionHideAllListingAPICall,
  actionOrganizationListApiCall,
  actionUserListApiCall,
  setperPageListCount,
} from "../../../Redux/Actions";
import Select from "react-select";
import BlockIcon from "../../../assets/images/BlockIcon.svg";
import DelIcon from "../../../assets/images/DeleteIcon.svg";
import ConfirmationModal from "../ConfirmationModal";
import { toast } from "react-toastify";
import onlineIcon from "../../../assets/icons/onlineIcon.svg";
import CustomSelect from "../CustomSelect";
const ListingManagementHeadre = (props) => {
  const { t } = useTranslation();
  const state = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [listdata, setListData] = useState();
  const [userSearch, setUserSearch] = useState("");
  const [orgSearch, setOrgSearch] = useState("");
  const [createuser, setCreateUser] = useState(false);
  const [deleteList, setDeleteList] = useState(false);
  const [blockList, setBlockList] = useState(false);
  const [onlineList, setOnlineList] = useState(false);
  const [perpage, setPerPage] = useState(
    state?.perPageListCount ? state?.perPageListCount : 40
  );
  const [userList, setUserList] = useState(state?.userListData);
  const selectItem = {
    type: "select",
    name: t("city_p"),
    apiName: "city",
    placeholder: t("city_p"),
    options:
      state?.getCityData &&
      state?.getCityData?.map((p) => {
        return { label: p.name, value: p.name };
      }),
  };
  useEffect(() => {
    setPerPage(40);
  }, []);
  useEffect(() => {
    const filteredArray = state?.userListData.filter((obj) =>
      Object.values(obj).every((value) => value !== null)
    );
    setUserList(filteredArray);
  }, [state?.userListData]);

  useEffect(() => {
    filterClick();
    dispatch(setperPageListCount(perpage));
  }, [perpage]);

  useEffect(() => {
    const data = { page: 1, name: orgSearch, per_page: 10 };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  }, [orgSearch]);

  useEffect(() => {
    const data = { page: 1, name: userSearch, per_page: 10 };
    const body = { data, navigate };
    dispatch(actionUserListApiCall(body));
  }, [userSearch]);

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };
  const filterClick = () => {
    const data = {
      page: 1,
      per_page: perpage,
      address: listdata?.address ? listdata.address : "",
      city: listdata?.City ? listdata?.City?.value : "",
      postcode: listdata?.postalcode ? listdata.postalcode : "",
      status: listdata?.status ? listdata.status : "",
      listingType: listdata?.types ? listdata.types : "",
      userid: listdata?.user ? listdata?.user.value : "",
      organizationid: listdata?.org ? listdata?.org.value : "",
    };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  };
  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
    setCreateUser(false);
  };
  const onChangeOfInput = (e) => {
    setListData({ ...listdata, [e.target.name]: e.target.value });
  };
  const createuserShow = () => {
    setCreateUser(true);
  };
  const onClickOfDelete = () => {
    const data = { id: props.selectedUser };
    const body = { data, navigate };
    dispatch(actionDeletePropertyAPICall(body));
  };
  const OnClickOfBlock = () => {
    hideApiCall(props.selectedUser, 1);
  };
  const OnClickOfUnBlock = () => {
    hideApiCall(props.selectedUser, 0);
  };
  const hideApiCall = (id, value) => {
    const data = {
      property_id: id,
      hide_property: value,
    };
    const body = { data, navigate, type: "property" };
    dispatch(actionHideAllListingAPICall(body));
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("listing_management")}</h5>
            <form onSubmit={onClickOfButton}>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <input
                    type="text"
                    placeholder={`${t("address")}...`}
                    name="address"
                    onChange={onChangeOfInput}
                  />
                </div>
                <div className={classes.fildBox}>
                  <CustomSelect
                    item={selectItem}
                    apiName={selectItem.apiName}
                    isInputChange={true}
                    headerSearch={listdata}
                    setHeaderSearch={setListData}
                  />
                </div>
                <div className={classes.fildBox}>
                  <input
                    type="number"
                    placeholder={`${t("postal_code")}...`}
                    name="postalcode"
                    onChange={onChangeOfInput}
                  />
                </div>
                <div className={classes.fildBox}>
                  <select id="types" name="types" onChange={onChangeOfInput}>
                    <option value="" disabled selected>
                      {`${t("type")}...`}
                    </option>
                    <option value={t("sell")}>{t("sell")}</option>
                    <option value={t("rent")}>{t("rent")}</option>
                    <option value={t("both")}>{t("both")}</option>
                  </select>
                </div>
                <div className={`${classes.fildBox} ${classes.SelectBorder}`}>
                  <Select
                    placeholder={<div>Contact</div>}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={state?.userListData && state.userListData[0]}
                    isClearable={false}
                    isSearchable={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    name="color"
                    options={
                      state?.userListData &&
                      state?.userListData
                        .filter((obj) =>
                          Object.values(obj).every((value) => value !== null)
                        )
                        .map((p) => {
                          return { label: p.full_name, value: p.id };
                        })
                    }
                    onInputChange={(data) => setUserSearch(data)}
                    onChange={(option) =>
                      setListData({ ...listdata, ["user"]: option })
                    }
                  />
                </div>
                <div className={`${classes.fildBox} ${classes.SelectBorder}`}>
                  <Select
                    placeholder={<div>Organisation</div>}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={state?.orgListData && state?.orgListData[0]}
                    isClearable={false}
                    isSearchable={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    name="color"
                    onInputChange={(data) => setOrgSearch(data)}
                    options={
                      state?.orgListData &&
                      state?.orgListData?.map((p) => {
                        return { label: p.name, value: p.id };
                      })
                    }
                    onChange={(option) =>
                      setListData({ ...listdata, ["org"]: option })
                    }
                  />
                </div>
                <div className={classes.fildBox}>
                  <select id="status" name="status" onChange={onChangeOfInput}>
                    <option value="" disabled selected>
                      {`${t("status")}...`}
                    </option>
                    <option value={t("sold")}>{t("sold")}</option>
                    <option value={t("new")}>{t("new")}</option>
                    <option value={t("under")}>{t("under")}</option>
                  </select>
                </div>
                <div className={classes.fildBox}>
                  <button onClick={filterClick}>{t("filter")}</button>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.ManagListingSection}>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_list")}`);
                } else {
                  setOnlineList(true);
                }
              }}
            >
              {t("online_listing")}
              <img src={onlineIcon} alt="" />
            </button>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_list")}`);
                } else {
                  setBlockList(true);
                }
              }}
            >
              {t("block_listing_header")}
              <img src={BlockIcon} alt="" />
            </button>
            <button
              onClick={() => {
                if (props.selectedUser.length == 0) {
                  toast.warn(`${t("please_select_list")}`);
                } else {
                  setDeleteList(true);
                }
              }}
            >
              {t("delete_listing_small")}
              <img src={DelIcon} alt="" />
            </button>
          </div>
        </div>
        <div className={classes.ListingPerPageSection}>
          <p>{t("listings_per_page")}</p>
          <div className={classes.numberOfListing}>
            <select
              id="cars"
              name="cars"
              onChange={(e) => setPerPage(e.target.value)}
            >
              <option value={40} selected={40 == perpage}>
                40
              </option>
              <option value={50} selected={50 == perpage}>
                50
              </option>
              <option value={60} selected={60 == perpage}>
                60
              </option>
              <option value={70} selected={70 == perpage}>
                70
              </option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ConfirmationModal
        showmodel={deleteList}
        handleClose={() => setDeleteList(false)}
        size={"sm"}
        onClickYesButton={onClickOfDelete}
        title={`You are about to delete ${props.selectedUser.length} list(s)`}
      />
      <ConfirmationModal
        showmodel={blockList}
        handleClose={() => setBlockList(false)}
        size={"sm"}
        onClickYesButton={OnClickOfBlock}
        title={`You are about to Block ${props.selectedUser.length} list(s)`}
      />
      <ConfirmationModal
        showmodel={onlineList}
        handleClose={() => setOnlineList(false)}
        size={"sm"}
        onClickYesButton={OnClickOfUnBlock}
        title={`You are about to Online ${props.selectedUser.length} list(s)`}
      />
    </React.Fragment>
  );
};

export default ListingManagementHeadre;
