import React from "react";
import classes from "./listingviews.module.scss";
import { useTranslation } from "react-i18next";
import { UserLoggingHistoryData } from "../../../../data";
import { useSelector } from "react-redux";
import moment from "moment";
const ListingViews = () => {
  const { t } = useTranslation();
  const logdata = useSelector(
    (state) => state.CommonReducer.SaveSinglePropertyViewData
  );
  return (
    <div className={classes.ListingViewsMain}>
      <h3>{t("listing_views")}</h3>
      <div className={classes.ListingViewsWrap}>
        <table>
          <tr>
            <th>{t("name")}</th>
            <th>{t("system")}</th>
            <th className={classes.time}>{t("time")}</th>
          </tr>
          {logdata?.rows?.length > 0 ? (
            logdata?.rows?.map((item, i) => {
              return (
                <tr>
                  <td>
                    {item.property_view.full_name
                      ? item.property_view.full_name
                      : "NA"}
                  </td>
                  <td>{item.device_type}</td>
                  <td className={classes.time}>
                    {item.created_at
                      ? moment(item.created_at).format("DD/MM/YYYY HH:MM")
                      : "NA"}
                  </td>
                </tr>
              );
            })
          ) : (
            <h2>{t("no_data_found")}</h2>
          )}
        </table>
      </div>
    </div>
  );
};

export default ListingViews;
