import React, { useState } from "react";
import classes from "./menumanagementheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import OffcanvasMain from "../Offcanvas";
import SideBar from "../SideBar";
import ModalMain from "../Modal";
import { useTranslation } from "react-i18next";
import CreateMenuIcon from "../../../assets/icons/CreateMenuIcon.svg";
import CreateMenu from "../../ModalComponents/MenuManagementModal/CreateMenu";

const MenuManagementHeader = () => {
  const { t } = useTranslation();
  const [sidebar, setSideBar] = useState(false);
  const [creatmenu, setCreateMenu] = useState(false);
  const sidebarShow = () => {
    setSideBar(true);
  };
  const createMenuShow = () => {
    setCreateMenu(true);
  };

  const handleClose = () => {
    setSideBar(false);
    setCreateMenu(false);
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("manu_management")}</h5>
            <form>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <select id="status" name="status">
                    <option value="" disabled selected>
                      {`${t("position")}...`}
                    </option>
                    <option value={t("online")}>{t("online")}</option>
                    <option value={t("blocked")}>{t("blocked")}</option>
                  </select>
                </div>
                <div className={classes.fildBox}>
                  <button>{t("filter")}</button>
                </div>
              </div>
            </form>
          </div>
          <div className={classes.ManagbBnnerSection}>
            <button onClick={createMenuShow}>
              {t("create_menu")}
              <img src={CreateMenuIcon} />
            </button>
          </div>
        </div>
        <div className={classes.MenuPerPageSection}>
          <p>{t("users_per_page")}</p>
          <div className={classes.numberOfUser}>
            <select id="cars" name="cars">
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={60}>60</option>
              <option value={70}>70</option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
      <ModalMain showmodel={creatmenu} handleClose={handleClose} size={"md"}>
        <CreateMenu />
      </ModalMain>
    </React.Fragment>
  );
};

export default MenuManagementHeader;
