import React, { useEffect, useState } from "react";
import classes from "./confirmationmodal.module.scss";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetPropertyListAPICall,
  actionOrganizationListApiCall,
  actionStopSubscriptionApiCall,
  actionUsedEmailFlag,
  actionViewUserApiCall,
} from "../../../Redux/Actions";

const ConfirmationModal = (props) => {
  useEffect(() => {
    dispatch(actionUsedEmailFlag(false));
  }, []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const select = useSelector((state) => state.CommonReducer);
  const [text, setText] = useState(null);

  const onClickofShowAcc = () => {
    const data = select.alreadyUsedEmailData.user_id;
    const body = { data, navigate };
    dispatch(actionViewUserApiCall(body));
    dispatch(actionUsedEmailFlag(false));
    listuserapicall(select.alreadyUsedEmailData.user_id);
    getPropertyListAPICall(select.alreadyUsedEmailData.user_id);
  };
  const listuserapicall = (id) => {
    const data = { page: 1, userid: id };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  };
  const getPropertyListAPICall = (id) => {
    const data = { page: 1, userid: id };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  };
  const onClickOfYesButton = () => {
    if (props.input) {
      const data = {
        cancel_reason: text,
        subscription_id: props.subscriptionId,
      };
      const body = { data, navigate, orgId: props.orgId };
      dispatch(actionStopSubscriptionApiCall(body));
      props.handleClose();
    } else {
      props.onClickYesButton();
      props.handleClose();
    }
  };

  return (
    <Modal
      show={props.showmodel}
      onHide={props.handleClose}
      size={props.size}
      centered
    >
      <Modal.Body>
        {props.newDesign ? (
          <div className={classes.NewDeisgnWap}>
            <h3>{props.MainTitle}</h3>
            <p>
              {props.title}
              <br />
              {t("are_you_sure")}
            </p>
            <div className={classes.ConfirmationBtnWrap}>
              <button
                className={classes.cancelButton}
                onClick={props.handleClose}
              >
                {t("Cancel")}
              </button>
              <button
                className={classes.labelButton}
                onClick={onClickOfYesButton}
              >
                {props.btnName}
              </button>
            </div>
          </div>
        ) : select?.usedEmailFlag ? (
          <div className={classes.UsedEmailWrap}>
            <h5>{t("this_e_mail_is_already_in_use")}</h5>
            <p>{t("please_select_an_option")}</p>
            <button onClick={() => dispatch(actionUsedEmailFlag(false))}>
              {t("use_different_email")}
            </button>
            <button onClick={onClickofShowAcc}>
              {t("show_account_with_this_e_mail")}
            </button>
          </div>
        ) : (
          <div className={classes.ConfirmationWap}>
            <h5>{props.title}</h5>
            <p>{t("are_you_sure")}</p>
            {props.input && (
              <textarea
                placeholder="type here.."
                onChange={(e) => setText(e.target.value)}
              />
            )}
            <div className={classes.ConfirmationBtnWrap}>
              <button onClick={onClickOfYesButton}>{t("yes_c")}</button>
              <button onClick={props.handleClose}>{t("no_c")}</button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
