import React, { useEffect } from "react";
import classes from "./vatheader.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { useFetcher, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffcanvasMain from "../Offcanvas";
import { useState } from "react";
import DistrictForm from "../DistrictForm";
import {
  actionGetDistrictTableSystemApiCall,
  actionGetTranslationColumnApiCall,
  actionLangListAPICall,
  actionMasterTableGetTranslationColumnApiCall,
  actionSaveTranslationColumnFilter,
  actionSaveTranslationLanguageFilter,
} from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../CustomSelect";
import SideBar from "../SideBar";
import TranslationForm from "../TranslationForm";
import Select from "react-select";

const VatHeader = ({ openCanvasB, editData, setopenCanvasB }) => {
  const { t } = useTranslation();
  const [openCanvas, setOpenCanvas] = useState(false);

  const [headerSearch, setHeaderSearch] = useState({
    name: "",
    language: "en",
    column_name: "",
  });
  const [selectedColumn, setSelectedColumn] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.CommonReducer);
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  // const isColumnAvailable = state?.saveTranslationColumnFilter ? state?.saveTranslationColumnFilter
  // getting data from branches form child

  const branchChild = (child) => {
    setopenCanvasB(false);
    setOpenCanvas(child);
  };
  const [sidebar, setSideBar] = useState(false);
  useEffect(() => {
    if (openCanvasB) {
      setOpenCanvas(openCanvasB);
    }
  }, [openCanvasB]);

  const filterClick = () => {
    const body = {
      navigate,
      search: headerSearch.name,
      language: headerSearch.language,
      column: headerSearch.column_name.value,
      page: 1,
    };
    dispatch(actionGetTranslationColumnApiCall(body));
  };

  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };

  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={() => setSideBar(true)}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>
              <span onClick={() => navigate("/system-management")}>
                {t("system_management")}
              </span>{" "}
              <span className={classes.Minus}>-</span> {t("view")} {t("vat")}
            </h5>
          </div>
        </div>
      </div>

      <OffcanvasMain
        showcanvas={openCanvas}
        placement={"end"}
        backgroundColor="true"
        handleClose={() => {
          setOpenCanvas(false);
        }}
      >
        <TranslationForm
          editData={null}
          handleClose={() => {
            setOpenCanvas(false);
          }}
        />
      </OffcanvasMain>
      <OffcanvasMain showcanvas={sidebar} handleClose={() => setSideBar(false)}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default VatHeader;
