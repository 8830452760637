import React, { forwardRef, useEffect, useState } from "react";
import "./customdropdown.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  actionChangeImportProfileStatusApiCall,
  actionChangeImportStatusApiCall,
} from "../../../Redux/Actions";
const CustomDropdown = forwardRef((props, ref) => {
  const {
    options,
    onSelect,
    id,
    label,
    orgid,
    importData,
    importProfileStatus,
  } = props;
  const { isOpen, setIsOpen } = props;
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    setSelectedOption(importData);
  }, [importData]);

  useEffect(() => {
    setSelectedOption(importProfileStatus);
  }, [importProfileStatus]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = (option, e) => {
    setSelectedOption(option.name);
    setIsOpen(false);
    if (label == "Import Status") {
      const data = {
        agency_id: orgid,
        status: option.value,
      };
      const body = { data, navigate, orgid: orgid };
      dispatch(actionChangeImportStatusApiCall(body));
    }
    if (label == "Profile Status") {
      const data = {
        agency_id: orgid,
        profile_status: option.value,
      };
      const body = { data, navigate, orgid: orgid };
      dispatch(actionChangeImportProfileStatusApiCall(body));
    }
  };
  return (
    <div className="select-inputField">
      <div className="custom-selectSize" id={id}>
        <div
          className={`select-header ${
            selectedOption
              ? selectedOption == "OK"
                ? "greenSelected"
                : "selected"
              : ""
          }`}
          onClick={handleToggle}
        >
          {selectedOption || "Select status"}
          <div className={`arrow ${isOpen ? "open" : ""}`}>
            <MdKeyboardArrowDown
              style={{
                transform: isOpen ? "rotate(180deg)" : "",
                transition: ".2s ease-in-out",
              }}
            />
          </div>
        </div>
        {isOpen && (
          <ul className="select-options" ref={ref}>
            {options.map((option) => (
              <li
                key={option.id}
                id={option.id}
                className={`select-option ${
                  selectedOption === option.name ? "selected" : ""
                }`}
                onClick={(e) => handleSelect(option, e)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
});

export default CustomDropdown;
