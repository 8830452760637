import React from "react";
import PropertyBannerManagementMain from "../../components/PropertyBannerManagementMain";

const PropertyBannerManagementPage = () => {
  return (
    <React.Fragment>
      <PropertyBannerManagementMain />
    </React.Fragment>
  );
};

export default PropertyBannerManagementPage;
