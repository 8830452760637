import React, { useEffect, useState } from "react";
import SingleCampaignHeader from "../common/SingleCampaignHeader";
import MainLayount from "./../common/MainLayout/index";
import classes from "./singlecampaigndetailed.module.scss";
import { useTranslation } from "react-i18next";
import Default from "../../assets/images/default.png";
import { useDispatch, useSelector } from "react-redux";
import ModalMain from "../common/Modal";
import UserLoggingHistoryMain from "../ModalComponents/UserManagementModals/UserLoggingHistory";
import { useLocation, useNavigate } from "react-router-dom";
import {
  actionViewPropertyBannerApiCall,
} from "../../Redux/Actions";
import CardLayoutIcon from "../../assets/icons/CardLayoutIcon.svg";
import ListLayoutIcon from "../../assets/icons/ListLayoutIcon.svg";
import DarkCardLayoutIcon from "../../assets/icons/DarkCardLayoutIcon.svg";
import DarkListingLayoutIcon from "../../assets/icons/DarkListingLayoutIcon.svg";
import moment from "moment";
import EditUser from "../ModalComponents/UserManagementModals/EditUser";
import AddOrgToUser from "../ModalComponents/UserManagementModals/AddOrgToUser";
import EditUsersListing from "../ModalComponents/UserManagementModals/EditUsersListing";
import PriceFormatter from "../../utils/PriceFormatter";

const SingleCampaignDetailed = () => {
  const { t } = useTranslation();
  const reduxState = useSelector((state) => state.CommonReducer);

  const campaignData = useSelector(
    (state) => state.CommonReducer.bannerCampaignData
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();


  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  console.log("pathname", pathname);

  const [listProperty, setListProperty] = useState(
    reduxState?.SavePropertyListdata
  );
  const [userToOrg, setUserToOrg] = useState();
  const [editUser, setEditUser] = useState();
  const [viewLoglist, setViewLoglist] = useState();
  const [editUserList, setEditUserList] = useState(false);
  const [icon, seticon] = useState(true);

  useEffect(() => {
    console.log("in useeffect")
    if (typeof campaignData === "undefined") {
      const data = { id: pathname };
      const body = { data, navigate };
      console.log("before dispatch");
      dispatch(actionViewPropertyBannerApiCall(body));
    }
  }, []);

  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.main}>
          <SingleCampaignHeader />
          <div className={classes.UserDetailsLayout}>
            <div className={classes.PersonalDetails}>
              <div className={classes.basicInfo}>
                <div className={classes.UserImgLayout}>
                  <img
                    src={
                      campaignData?.url_original_file
                        ? campaignData.url_original_file
                        : Default
                    }
                    alt="userImg"
                  />
                </div>
                <div className={classes.TextBold}>
                  <h1>{campaignData?.title}</h1>
                </div>
              </div>
              <div className={classes.UserInfoLayout}>
                <p className={classes.boldMedText}>{t("campaign_info")}</p>
                <div className={classes.UserInternalDetails}>
                  <div className={classes.LeftUserContent}>
                    <p>
                      {t("agency")}
                      {": "}
                      {campaignData?.agency_detail?.name}
                    </p>
                    <p>
                      {t("startdate")}
                      {": "}
                      {campaignData?.start_date
                        ? moment(campaignData?.start_date).format("DD-MM-YYYY")
                        : "NA"}
                    </p>
                  </div>
                  <div className={classes.RightUserContent}>
                    <p>
                      {t("campaign_created_at")}
                      {": "}
                      {campaignData?.created_at
                        ? moment(campaignData?.created_at).format(
                            "DD-MM-YYYY HH:MM"
                          )
                        : "NA"}
                    </p>
                    <p>
                      {t("enddate")}
                      {": "}
                      {campaignData?.end_date
                        ? moment(campaignData?.end_date).format("DD-MM-YYYY")
                        : "NA"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.OtherDetails}>
              <div className={classes.OrganisationDetailsContainer}>
                <div className={classes.OrganisationAndSearchBar}>
                  <p>
                    {t("organisation")}{" "}
                    <span>
                      {" "}
                      {">"} {campaignData?.agency_detail.length}{" "}
                    </span>
                  </p>
                </div>
                <div className={classes.OrgWrapper}>
                  {campaignData?.agency_detail ? (
                    <>
                      <div className={classes.OrganisationDetail}>
                        <div className={classes.orgnisationLogo}>
                          <img
                            src={
                              campaignData?.agency_detail?.logo_image
                                ? campaignData?.agency_detail.logo_image
                                : Default
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className={classes.OrganisationDetail}>
                        <div>
                          <p>{campaignData?.agency_detail.name}</p>
                          <p>{campaignData?.agency_detail.address}</p>
                          <p>
                            {campaignData?.agency_detail.postcode},{" "}
                            {campaignData?.agency_detail.city}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={classes.OrganisationDetail}>
                      <h2>{t("no_organisation_data")}</h2>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.ListingContainer}>
                <div className={classes.ListingHeaderAndSearchBar}>
                  <p>
                    {t("listing")}{" "}
                    <span>
                      {" "}
                      {">"} {campaignData?.banner_campaign_listing?.length}{" "}
                    </span>
                  </p>
                  <div className={classes.leftWrap}>
                    <div className={classes.ChangeLayoutBtnWarp}>
                      <button
                        onClick={() => {
                          seticon(true);
                        }}
                        className={
                          icon === false
                            ? classes.ChangeLayou
                            : classes.activeBtn
                        }
                      >
                        {icon == true ? (
                          <img src={CardLayoutIcon} />
                        ) : (
                          <img src={DarkCardLayoutIcon} />
                        )}
                      </button>
                      <button
                        onClick={() => {
                          seticon(false);
                        }}
                        className={
                          icon === true ? classes.ChangeLayou : classes.activeBtn
                        }
                      >
                        {icon === false ? (
                          <img src={ListLayoutIcon} />
                        ) : (
                          <img src={DarkListingLayoutIcon} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {campaignData?.banner_campaign_listing?.length > 0 ? (
                  <>
                    {icon ? (
                      <div className={classes.CardListingLayout}>
                        {campaignData?.banner_campaign_listing?.map(
                          (item, i) => (
                            <div
                              className={
                                item.listing_property.hide_property == 0
                                  ? classes.ListingContent
                                  : `${classes.InvisibleCard} ${classes.ListingContent}`
                              }
                            >
                              <div className={classes.LogoAndDetails}>
                                <div className={classes.ListingLogo}>
                                  <img
                                    src={
                                      item.listing_property?.image_list[0].url_medium_file
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className={classes.ListingDetail}>
                                  <p>{item.listing_property.name}</p>
                                  <p>{item.listing_property.address}</p>
                                  <p>{item.listing_property.postcode}, {item.listing_property.city}</p>
                                  {item?.listing_property.PropertyPrice
                                    ?.length > 0 ? (
                                    <p>
                                      {PriceFormatter(
                                        item.listing_property.PropertyPrice[0]
                                          .price
                                      )}
                                    </p>
                                  ) : (
                                    <>
                                      {item.listing_property.is_rent == 0 &&
                                        item.listing_property.is_sell == 1 && (
                                          <p>
                                            {PriceFormatter(
                                              item.listing_property.sell_price
                                            )}
                                          </p>
                                        )}
                                      {item.listing_property.is_rent == 1 &&
                                        item.listing_property.is_sell == 0 && (
                                          <p>
                                            {PriceFormatter(
                                              item.listing_property.rent_price
                                            )}
                                          </p>
                                        )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className={classes.TableListngLayOut}>
                        <table>
                          <tr>
                            <td>{t("address_capital")}</td>
                            <td>{t("postalcode")}</td>
                            <td>{t("CITY")}</td>
                            <td>{t("price")}</td>
                            <td>{t("TYPE OF LISTING")}</td>
                            <td>{t("VISIBILITY")}</td>
                          </tr>
                          {campaignData?.banner_campaign_listing?.map(
                            (item) => (
                              <tr
                                className={
                                  item.listing_property.hide_property === 1
                                    ? classes.InvisibleCard
                                    : null
                                }
                              >
                                <td>{item.listing_property.address}</td>
                                <td>{item.listing_property.postcode}</td>
                                <td>{item.listing_property.city}</td>
                                {item?.listing_property.PropertyPrice?.length >
                                0 ? (
                                  <td>
                                    {PriceFormatter(
                                      item.listing_property.PropertyPrice[0]
                                        .price
                                    )}
                                  </td>
                                ) : (
                                  <>
                                    {item.listing_property.is_rent == 0 &&
                                      item.listing_property.is_sell == 1 && (
                                        <td>
                                          {PriceFormatter(
                                            item.listing_property.sell_price
                                          )}
                                        </td>
                                      )}
                                    {item.listing_property.is_rent == 1 &&
                                      item.listing_property.is_sell == 0 && (
                                        <td>
                                          {PriceFormatter(
                                            item.listing_property.rent_price
                                          )}
                                        </td>
                                      )}
                                  </>
                                )}
                                {item.listing_property.is_rent == 0 &&
                                  item.listing_property.is_sell == 1 && (
                                    <td>{t("sell")}</td>
                                  )}
                                {item.listing_property.is_rent == 1 &&
                                  item.listing_property.is_sell == 0 && (
                                    <td>{t("rent")}</td>
                                  )}
                                {item.listing_property.is_rent == 1 &&
                                  item.listing_property.is_sell == 1 && (
                                    <td>{t("both")}</td>
                                  )}
                                <td></td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    )}
                  </>
                ) : (
                  <h2>{t("no_Listing_data")}</h2>
                )}
              </div>
              <div className={classes.QuickAndUserContainer}>
                <div className={classes.UserLogginLayout}>
                  <div className={classes.TextAndActionBtn}>
                    <p>{t("campaign_budgets")}</p>
                  </div>
                  <div className={classes.LoginHistroyLayout}>
                    <div className={classes.logginContentRow}>
                      <p>{t("supplier")}</p>
                      <p>{t("original_views")}</p>
                      <p>{t("remaining_views")}</p>
                      <p>{t("updated_at")}</p>
                    </div>
                    {campaignData?.campaign_budget?.length > 0 ? (
                      campaignData?.campaign_budget.map((item, i) => (
                        <div className={classes.logginContentRow}>
                          <p>{item.banner_supplier?.name}</p>
                          <p>{item.original_views}</p>
                          <p>{item.remaining_views}</p>
                          <p>
                            {moment(item.updated_at).format("DD-MM-YYYY HH:MM")}
                          </p>
                        </div>
                      ))
                    ) : (
                      <div className={classes.logginContentRow}>
                        <h2>{t("no_data_found")}</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayount>
      <ModalMain
        showmodel={viewLoglist}
        handleClose={() => setViewLoglist(false)}
        size={"lg"}
      >
        <UserLoggingHistoryMain />
      </ModalMain>
      <ModalMain
        showmodel={userToOrg}
        handleClose={() => setUserToOrg(false)}
        size={"lg"}
      >
        <AddOrgToUser />
      </ModalMain>
      <ModalMain
        showmodel={editUser}
        handleClose={() => setEditUser(false)}
        size={"xl"}
      >
        <EditUser handleClose={() => setEditUser(false)} edit={true} />
      </ModalMain>
      <ModalMain
        showmodel={editUserList}
        handleClose={() => setEditUserList(false)}
        size={"xl"}
      >
        <EditUsersListing />
      </ModalMain>
    </React.Fragment>
  );
};

export default SingleCampaignDetailed;
