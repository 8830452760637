import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SingleUserDetailed from "../../components/SingleUserDetailed/index";
import {
  actionGetPropertyListAPICall,
  actionViewUserApiCall,
} from "../../Redux/Actions";

const SingleUserDetailPage = () => {
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getPropertyApiCall = (id) => {
    const data = { page: 1, organizationid: id };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  };
  useEffect(() => {
    const data = pathname;
    const body = { data, navigate };
    dispatch(actionViewUserApiCall(body));
    getPropertyApiCall(pathname);
  }, []);

  return (
    <React.Fragment>
      <SingleUserDetailed />
    </React.Fragment>
  );
};

export default SingleUserDetailPage;
