import React, { useEffect, useRef, useState } from "react";
import classes from "./createcampaignform.module.scss";
import { useTranslation } from "react-i18next";
import { TiTick } from "react-icons/ti";
import SearchIcon from "../../assets/images/SearchIcon.svg";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalMain from "../common/Modal";
import SelectCampaignAudience from "../EmailMarketingMain/SelectCampaignAudience";
import SelectSubject from "../EmailMarketingMain/SelectSubject/Index";
import AddSendTime from "../EmailMarketingMain/AddSendTime/Index";
import AddContent from "../EmailMarketingMain/AddContent/Index";
import {
  actionGetAllCampaignApiCall,
  actionSaveCreatedAudience,
  actionSaveSubject,
  addAudienceApiCall,
} from "../../Redux/Actions";
import moment from "moment";
import { toast } from "react-toastify";

const CreateCampaign = (props) => {
  const userdata = JSON.parse(localStorage.getItem("userData"));

console.log("prr", props.editformData)
  

  const Recipients = useSelector(
    (state) => state.CommonReducer.savedRecipients
  );

  let close = props.onClose;
  let id = props?.editformData?.id

  const navigate = useNavigate();
  

  const audienceData = useSelector(
    (state) => state.CommonReducer?.savedAudience
  );

  const subjectData = useSelector(
    (state) => state.CommonReducer?.savedSubjects
  );


  // console.log(audienceData,audienceData.length, "audience from selector");
  const [isHovered, setIsHovered] = useState();
  const [modelOpen, setOpenModel] = useState({
    state: false,
    edit: false,
  });
  const [modelOpen2, setOpenModel2] = useState({
    state: false,
    edit: false,
  });
  const [modelOpen3, setOpenModel3] = useState({
    state: false,
    edit: false,
  });
  const [modelOpen4, setOpenModel4] = useState({
    state: false,
    edit: false,
  });

  const { t } = useTranslation();

  const [addressSearch, setAddressSearch] = useState();
  const ProfileRef = useRef(null);
  const ImportRef = useRef(null);
  const FinancialRef = useRef(null);
  const [error, setError] = useState({});
  const [isOpen, setIsOpen] = useState({
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
  });
  const [subject, setSubject] = useState(false);
  const [addTime, setAddTime] = useState(false);
  const [addContent, setAddContent] = useState(false);
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  const dispatch = useDispatch();

  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const contactLog = useSelector(
    (state) => state.CommonReducer?.contactLogData
  );
  const createNewtask = useSelector(
    (state) => state.CommonReducer?.createNewtask
  );
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window]);

  function getWindowSize() {
    return window.innerWidth;
  }
  const handleAudience = (e) => {
    if (e == "edit") {
      setOpenModel({ ...modelOpen, state: true, edit: true });
    } else {
      setOpenModel({ ...modelOpen, state: true, edit: false });
    }
  };
  const handleSubject = (e) => {
    // setSubject(!subject);
    if (e == "edit") {
      setOpenModel2({ ...modelOpen2, state: true, edit: true });
    } else {
      setOpenModel2({ ...modelOpen2, state: true, edit: false });
    }
  };
  const handleAddTime = (e) => {
    // setAddTime(!addTime);
    if (e == "edit") {
      setOpenModel3({ ...modelOpen3, state: true, edit: true });
    } else {
      setOpenModel3({ ...modelOpen3, state: true, edit: false });
    }
  };
  const handleAddContent = (e) => {
    if (e == "edit") {
      setOpenModel4({ ...modelOpen4, state: true, edit: true });
    } else {
      setOpenModel4({ ...modelOpen4, state: true, edit: false });
    }
    // setAddContent(!addContent);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setIsOpen({ ...isOpen, isOpen1: false });
      }
      if (ImportRef.current && !ImportRef.current.contains(event.target)) {
        setIsOpen({ ...isOpen, isOpen2: false });
      }
      if (
        FinancialRef.current &&
        !FinancialRef.current.contains(event.target)
      ) {
        setIsOpen({ ...isOpen, isOpen3: false });
      }
    };

    const body = { navigate };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // const tagstringg = audienceData?.campaign_tag_list?.map((item)=>({value: item.tag_name, label: item.tag_name,id:item.id}))
  const tagstringg = audienceData?.campaign_tag_list;

  const tagString =
    audienceData?.tags && Array.isArray(audienceData?.tags)
      ? audienceData?.tags.length > 1
        ? audienceData?.tags.join(", ")
        : audienceData?.tags[0]
      : "";


  const handleSend = () => {
    if(Recipients?.count > 0){
      apiCallAllcampaign();
    }else{
      toast.error("Please add recipients!");
    }
  };



  const apiCallAllcampaign = () => {
    const data = JSON.stringify({
      obj:Recipients?.data,
      added_by:userdata?.email
    });
    const body = {
      data,
      navigate,
      close,
      id: props?.editformData?.id ? props?.editformData?.id : audienceData?.id,
      content: subjectData?.content ? "content" : "",
    };
    console.log("content", body);
    dispatch(actionGetAllCampaignApiCall(body));
  };




  useEffect(() => {
    if (
      // props?.editformData !== undefined || props?.editformData !== "undefined" || props?.editformData !== "null"||props?.editformData !== null &&
      //   Object?.keys(props?.editformData)?.length > 0
      props?.editformData == null || props?.editformData == "null" || !createNewtask == "true"
    ) {
      
      
    }else{
      // console.log("again elseee",props?.editformData)

      if(createNewtask == "true"){
        dispatch(actionSaveCreatedAudience());

      }else{


        const data = {
          campaign_tag_list: props?.editformData?.campaign_tag_list,
          campaign_title: props?.editformData?.campaign_title,
          classification: props?.editformData?.classification,
          classification_id: props?.editformData?.classification_id,
          city_id: props?.editformData?.city_id,
          branch: props?.editformData?.branch,
          branch_id: props?.editformData?.branch_id,
          recipients: props?.editformData?.recipients,
          city: props?.editformData?.city,
          audience_tag: props?.editformData?.audience_tag,
          audience_tag_id: props?.editformData?.audience_tag_id,
          province : props?.editformData?.province,
          province_id : props?.editformData?.province_id,
          range: parseInt(props?.editformData?.range),
        };
  
        console.log("else hit", data)

        dispatch(actionSaveCreatedAudience(data));
      }
    
    }
  }, [props?.editformData]);



  
  useEffect(() => {
    if (
      // props?.editformData != undefined || props?.editformData != "undefined" || props?.editformData != "null" ||
      // props?.editformData != null &&
      //   Object?.values(props?.editformData) != undefined || Object?.values(props?.editformData) != "undefined" || Object?.values(props?.editformData) != null
      props?.editformData == null || props?.editformData == "null" 
    ) {

    
    }else{
  
      if(createNewtask == "true"){
        dispatch(actionSaveSubject());
      }else{
        const data = {
          id : props?.editformData?.id,
          subject_line: props?.editformData?.subject_line,
          preview_text: props?.editformData?.preview_text,
          send_date: props?.editformData?.send_date,
          send_time: props?.editformData?.send_time,
          template_id:props?.editformData?.template_id,
          content:props?.editformData?.content,
          slider_image:props?.editformData?.slider_image,
        };
        dispatch(actionSaveSubject(data));
      }

    }
  }, [props?.editformData]);

  console.log('audience data in createCampaign', audienceData)
  // console.log("rangeeee", props?.editformData?.range)
  console.log("propseditformdata", props.editformData)

  return (
    <React.Fragment>
      <div className={classes.detailPage}>
        <div className={classes.container}>
          <div className={classes.address}>
            {/* <div className={classes.headerwrap}>
              <p> {t("Campaigns")}</p>
              <button>Create New</button>
            </div> */}
            <div className={classes.addressContainer}>
            
              <div className={classes.tableContainer}>
                <div className={classes.TableListngLayOut}>
                  <table>
                    <tr>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <div
                          className={
                            audienceData ? classes.textwrap2 : classes.textwrap
                          }
                        >
                          <span className={classes.title}>
                            <TiTick className="tick" />
                          </span>
                          <div>
                            <span style={{ color: "#df1681" }}>To</span>
                            <br />
                            <span>
                              {audienceData ? (
                                <>
                                  <span>
                                    All {audienceData.branch}, with
                                    classification {audienceData.classification}{" "}
                                    | {audienceData.recipients} recipients
                                  </span>
                                  <br />
                                  <br />
                                  <span>
                                    <b>Branche:</b> {audienceData.branch}{" "}
                                  </span>
                                  <br />
                                  <span>
                                    <b>Classifications:</b>{" "}
                                    {audienceData.classification}{" "}
                                  </span>
                                  <br />
                                  <span style={{maxWidth:"300px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis"}}>
                                    <b>Tags:</b>{" "}
                                    {tagString ? (
                                      <span>{tagString}</span>
                                    ) : (
                                      audienceData?.campaign_tag_list
                                        ?.map((item) => item.tag_name)
                                        .join(", ")
                                    )}{" "}
                                  </span>
                                </>
                              ) : (
                                <span>Who are you sending this email to?</span>
                              )}
                            </span>
                          </div>
                        </div>
                        <br />
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: "1rem",
                          }}
                        >
                          <button
                            onClick={
                              audienceData
                                ? () => handleAudience("edit")
                                : () => handleAudience("nonedit")
                            }
                            style={{ background: "#c6d4e5", color: "black" }}
                          >
                            {audienceData ? "Edit Audience" : "Select audience"}
                          </button>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <div
                          className={
                            subjectData ? classes.textwrap2 : classes.textwrap
                          }
                        >
                          <span className={classes.title}>
                            <TiTick className="tick" />
                          </span>
                          <div>
                            <span style={{ color: "#df1681" }}>Subject</span>
                            <br />
                            <span>
                              {subjectData ? (
                                <>
                                  <span>
                                    <b>{subjectData.subject_line}</b>
                                  </span>
                                  <br />
                                  <span>
                                    Preview text: {subjectData.preview_text}{" "}
                                  </span>
                                  <br />
                                </>
                              ) : (
                                <span>
                                  What&apos;s the subject line for this email?
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <br />
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: "1rem",
                          }}
                        >
                          <button
                            onClick={
                              subjectData
                                ? () => handleSubject("edit")
                                : () => handleSubject("nonedit")
                            }
                            style={{ background: "#c6d4e5", color: "black" }}
                          >
                            {subjectData ? "Edit Subject" : "Add Subject"}
                          </button>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <div
                          className={
                            subjectData?.send_date
                              ? classes.textwrap2
                              : classes.textwrap
                          }
                        >
                          <span className={classes.title}>
                            <TiTick className="tick" />
                          </span>
                          <div>
                            <span style={{ color: "#df1681" }}>Send Time</span>
                            <br />
                            <span>
                              {subjectData?.send_date ? (
                                <>
                                  {/* <span><b>{subjectData.subject_line}</b></span><br/> */}
                                  <span>
                                    We will deliver this e-mail at:{" "}
                                    <b>
                                      {moment(subjectData?.send_date).format(
                                        "LL"
                                      )}{" "}
                                      | {subjectData.send_time}
                                    </b>{" "}
                                  </span>
                                  <br />
                                </>
                              ) : (
                                <span>When should we send this email?</span>
                              )}
                            </span>
                          </div>
                        </div>
                        <br />
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: "1rem",
                          }}
                        >
                    
                          <button
                            onClick={
                              subjectData?.send_date
                                ? () => handleAddTime("edit")
                                : () => handleAddTime("nonedit")
                            }
                            style={{ background: "#c6d4e5", color: "black" }}
                          >
                            {subjectData?.send_date
                              ? "Edit Send Time"
                              : "Add Send Time"}
                          </button>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <div
                          className={
                            subjectData?.content
                              ? classes.textwrap2
                              : classes.textwrap
                          }
                        >
                          <span className={classes.title}>
                            <TiTick className="tick" />
                          </span>
                          <div>
                            <span style={{ color: "#df1681" }}>Content</span>
                            <br />
                            <span>
                              {subjectData?.content ? (
                                <>
                                  {/* <span><b>{subjectData.subject_line}</b></span><br/> */}
                                  <span>All Set Up!</span>
                                  <br />
                                </>
                              ) : (
                                <span>Choose the template and add the content for your email</span>
                              )}
                            </span>
                          </div>
                        </div>
                        <br />
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: "1rem",
                          }}
                        >
                          
                          <button
                            onClick={
                              subjectData?.content
                               ? () => handleAddContent("edit")
                               : () => handleAddContent("nonedit")
                           }
                            style={{ background: "#c6d4e5", color: "black" }}
                          >
                            {subjectData?.content
                              ? "Edit Content"
                              : "Add Content"}
                          </button>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                          <div style={{ marginTop: "1rem" }}>
                            <p></p>
                            <p></p>
                          </div>
                        </div>
                      </td>
                    </tr>
       
                  </table>
                </div>
                {subjectData?.content ? (
                  <div className={classes.btnwrapper}>
                    <button onClick={handleSend} className={classes.sendbtn}>
                      Send
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            
            </div>
          </div>
        </div>
      </div>
 
      <ModalMain
        showmodel={modelOpen.state}
        handleClose={() => setOpenModel(false)}
        size={"xl"}
      >
        <SelectCampaignAudience
          onClose={() => setOpenModel(false)}
          edit={true}
          editable={modelOpen.edit}
          id={id}
        />
      </ModalMain>
      <ModalMain
        showmodel={modelOpen2.state}
        handleClose={() => setOpenModel2(false)}
        size={"xl"}
      >
        <SelectSubject
          onClose={() => setOpenModel2(false)}
          edit={true}
          editable={modelOpen2.edit}
          id={id}
        />
      </ModalMain>
      <ModalMain
        showmodel={modelOpen3.state}
        handleClose={() => setOpenModel3(false)}
        size={"lg"}
      >
        <AddSendTime
          onClose={() => setOpenModel3(false)}
          edit={true}
          editable={modelOpen3.edit}
          id={id}
        />
      </ModalMain>
      <ModalMain
        showmodel={modelOpen4.state}
        handleClose={() => setOpenModel4(false)}
        size={"xl"}
      >
        <AddContent 
        onClose={() => setOpenModel4(false)} 
        edit={true}
        editable={modelOpen4.edit}
        id={id}
         />
      </ModalMain>
    </React.Fragment>
  );
};

export default CreateCampaign;
