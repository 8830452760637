import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./edittag.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddEmailApiCall,
  actionGetAllTagsApiCall,
  actionLangListAPICall,
  updateTagsRequest,
} from "../../../Redux/Actions";
import APIUrl from "../../../API/APIUrl";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { FaBlackTie } from "react-icons/fa";

const EditTag = (props) => {
  const [data,setData] = useState("")
  const [tagValue, setTagValue] = useState("");
  const Tags2 = useSelector((state) => state.CommonReducer.saveAllTags);
  console.log(Tags2,"tagss22");
  const saveSingleUserData = useSelector(
    (state) => state.CommonReducer.saveSingleUserData
  );
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const [lang, setLang] = useState(1);
  const [focus, setFocus] = useState(false);
  const [editEmail, setEditEmail] = useState({
    title: "",
    subject: "",
    templateId: "",
    content: "",
    slug: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const options2 = [
      { value: "0 km", label: "0 km" },
      { value: "1 km", label: "1 km" },
      { value: "2 km", label: "2 km" },
    ];
    const options3 = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];
    
  useEffect(() => {
    if (props.edit == true && saveSingleUserData) {
      setEditEmail({
        title: saveSingleUserData.title,
        subject: saveSingleUserData.subject,
        templateId: saveSingleUserData.id,
        content: saveSingleUserData.content,
        slug: saveSingleUserData.slug,
      });
      setLang(saveSingleUserData.language_id);
    }
  }, [saveSingleUserData, props]);

  useEffect(() => {
    const body = { navigate };
    dispatch(actionLangListAPICall(body));
  }, []);

    useEffect(() => {
    // Update tagValue when props.tagValue changes
    setTagValue(props.tagValue || ""); // Initialize state with the value from props
  }, [props.tagValue]); // Watch for changes in props.tagValue
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
      width:"100%"
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const array = [
    "0 km",
    "1 km",
    "2 km",
    "5 km",
    "10 km",
    "15 km",
    "30 km",
    "50 km",
    "100 km",
  ];
  const handleOnchange = (e)=>{
    setData(e.target.value)
  }

  const handleChange = (e) => {
    // Update tagValue as user types in the input field
    setTagValue(e.target.value);
  };

  let close = props.onClose;

  console.log("close", close)

  const handleSave = () => {
    // Dispatch the action to update the data
    const id = props.tagId; // Assuming props.id contains the ID of the tag
    const newTag = tagValue;
    const data ={
      id : id,
      tag: newTag
    };
    const body = { data, navigate, close };

    dispatch(updateTagsRequest(body));
    setTimeout(() => {
      dispatch(actionGetAllTagsApiCall());
    },1000);
  };

  console.log('newTagvalue', tagValue)
  
  return (
    <div className={classes.EditEmailMain}>
      <h3> Edit Tag </h3>
      <div className={classes.EditEmailWrap}>
        <div className={classes.InputWrap}>
          <p className={classes.to}>{t("Add tag")}</p>
          <p>{t("What should we name this tag?")}</p>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Tag Name")}</p>
          <input
          type="text"
          value={tagValue}
          onChange={handleChange}
          />
        </div>
        <div className={classes.btnWrap}>
          <button onClick={handleSave}>
            {" "}
            {t("Save")}
          </button>
          <button className={classes.cancelBtn} onClick={() => props.onClose()}>
            {" "}
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTag;
