import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../common/MainLayout";
import classes from "./propertybannermanagementmain.module.scss";
import PropertyBannerManagementHeader from "../common/PropertyBannerManagementHeader";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import ModalMain from "../common/Modal";
import {
  actionViewPropertyBannerApiCall,
  actionDeletePropertyBannerApiCall,
  actionMakeBannerStopApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";

const MyBannerCampaignsMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.CommonReducer.loader);

  const [showInfoScreen, setShowInfoScreen] = useState(false);
  const [showTitle, setShowTitle] = useState();
  const [showContent, setShowContent] = useState();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const bannerCampaignsData = useSelector(
    (state) => state.CommonReducer.bannerCampaignsData
  );

  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const divref = useRef([]);

   const handleScroll = (index) => {
     let ref = divref.current[index];
     const y = ref.getBoundingClientRect().top;
     if (y > 304) {
       divref.current[index].scrollIntoView({
         behavior: "smooth",
         block: "start",
       });
     }
   };

  const onCLickViewBanner = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionViewPropertyBannerApiCall(body));
  };

  const onCLickDeleteBanner = (id) => { 
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionDeletePropertyBannerApiCall(body));
  };

  const onClickStopBanner = (id) => {
    const data = {
      id: id,
    };
    const body = { data, navigate };
    dispatch(actionMakeBannerStopApiCall(body));
  };

  useEffect(() => {
 
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
 }, []);

  const platform = localStorage.getItem("Platform");

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.BannerMain}>
          <PropertyBannerManagementHeader />
          <div className={classes.BannerListingSection}>
            <table>
              <thead>
                <tr>
                  <th>{t("client_pb_c")}</th>
                  <th>{t("title_pb_c")}</th>
                  <th>{t("start_date_c")}</th>
                  <th>{t("end_date_c")}</th>
                  <th>{t("views_pb_c")}</th>
                  <th>{t("clicks_pb_c")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader === true ? (
                  <tr>
                    <td className={classes.LoaderWrap} colSpan={6}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {bannerCampaignsData?.length > 0 &&
                      bannerCampaignsData.map((item, index) => {
                        return (
                          <tr ref={(e) => (divref.current[index] = e)}>
                            <td>{item.agency_detail?.name}</td>
                            <td>{item.title}</td>
                            <td>
                              {DateTime.fromISO(item.start_date).toFormat(
                                "dd-MM-yyyy"
                              )}
                            </td>
                            <td>
                              {DateTime.fromISO(item.end_date).toFormat(
                                "dd-MM-yyyy"
                              )}
                            </td>
                            <td className={classes.BannerNumber}>
                              {item.campaign_banners.map((cp) => {
                                return (
                                  <>
                                    {
                                      cp.property_banner.banner_template
                                        .banner_format.banner_type.name
                                    }{" "}
                                    {
                                      cp.property_banner.banner_template
                                        .banner_format.name
                                    }
                                    {": "}
                                    <strong>
                                      {
                                        cp.property_banner.view_count
                                      }
                                    </strong>
                                    <br />
                                  </>
                                );
                              })}
                            </td>
                            <td className={classes.BannerNumber}>
                              {item.campaign_banners.map((cp) => {
                                return (
                                  <>
                                    {
                                      cp.property_banner.banner_template
                                        .banner_format.banner_type.name
                                    }{" "}
                                    {
                                      cp.property_banner.banner_template
                                        .banner_format.name
                                    }
                                    {": "}
                                    <strong>
                                      {
                                        cp.property_banner.click_count
                                      }
                                    </strong>
                                    <br />
                                  </>
                                );
                              })}
                            </td>
                            <td className={classes.BannerEditor}>
                              <img
                                src={userEditorIcon}
                                alt="Banner Actions"
                                onClick={() => {
                                  if (bannerCampaignsData.length - 1 > index) {
                                    handleScroll(index);
                                  }
                                  if (ind !== index) {
                                    setShow(!show);
                                    setInd(index);
                                  } else {
                                    setInd("");
                                  }
                                }}
                              />
                              {show && ind === index && (
                                <div
                                  className={classes.EditorWrap}
                                  style={
                                    bannerCampaignsData.length - 2 > index
                                      ? {}
                                      : { top: "-170px" }
                                  }
                                  onClick={() => setShow(!show)}
                                  ref={listRef}
                                >
                                  <ul>
                                    <li
                                      onClick={() => onCLickViewBanner(item.id)}
                                    >
                                      {t("view_campaign")}
                                    </li>
                                    <li
                                      onClick={() =>
                                        onCLickDeleteBanner(item.id)
                                      }
                                    >
                                      {t("delete_campaign")}
                                    </li>
                                    <li
                                      onClick={() => onClickStopBanner(item.id)}
                                    >
                                      {t("stop_campaign")}
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </MainLayout>
      <ModalMain
        showmodel={showInfoScreen}
        handleClose={() => setShowInfoScreen(false)}
        dialogClassName={classes.ModaleSize}
        title={showTitle}
        children={showContent}
      ></ModalMain>
    </React.Fragment>
  );
};

export default MyBannerCampaignsMain;
