import React, { useEffect, useState } from "react";
import classes from "./cratetranslation.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../../../utils/useForm";
import {
  actionAddEditTranslationApiCall,
  actionAddTranslationApiCall,
} from "../../../../Redux/Actions";
import { useNavigate, useParams } from "react-router-dom";

const CrateTranslation = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  let close = props.handleClose;
  // const { handleChange, values, errors, onFocus, onBlur, handleSubmit } =
  //   useForm();
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );

  const state = useSelector((state) => state.CommonReducer);

  const [formData, setFormData] = useState({
    column: "",
    value: "",
    translation: "",
    lang: "",
  });
  const [errors, setErrors] = useState({
    lang: "",
    value: "",
    translation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { lang: "", value: "", translation: "" };

    if (formData.lang === "") {
      newErrors.lang = t("language") + " " + t("required");
      valid = false;
    }
    if (formData.value === "") {
      newErrors.value = t("value") + " " + t("required");
      valid = false;
    }
    if (formData.translation === "") {
      newErrors.translation = t("translation") + " " + t("required");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let languageId = state?.SaveLangListData?.find(
        (lang) => lang.language === formData.lang
      )?.id;
      let stringData = {
        column: params.column,
        language: formData.lang,
        translation: formData.translation,
        value: formData.value,
        language_id: languageId,
      };
      // const body = { data, navigate, close, params };
      // dispatch(actionAddTranslationApiCall(body));

      let data = JSON.stringify(stringData);
      dispatch(
        actionAddEditTranslationApiCall({
          data,
          close,
          edit: false,
          navigate,
        })
      );
    }
  };
  // const apiCall = (result) => {
  //   if (!result.lang && !result.translation && !result.translationValue) {
  //     let stringData = {
  //       column: params.column,
  //       lang: formData.lang,
  //       translation: formData.translation,
  //       value: formData.value,
  //     };
  //     // const body = { data, navigate, close, params };
  //     // dispatch(actionAddTranslationApiCall(body));

  //     let data = JSON.stringify(stringData);

  //     dispatch(
  //       actionAddEditTranslationApiCall({
  //         data,
  //         close,
  //         edit: false,
  //         navigate,
  //       })
  //     );
  //   }
  // };
  return (
    <div className={classes.CreateBannerMain}>
      <h3> {t("create") + " " + t(`${params.column}`)}</h3>
      <div className={classes.CreateBannerWrap}>
        <form>
          {/* <div className={classes.InputWrap}>
            <p>{t("Column")}</p>
            <select
              name="column"
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            >
              <option value="" disabled selected>
                {`${t("select")}`}
              </option>
              {saveMasterTableColumn &&
                saveMasterTableColumn?.length > 0 &&
                saveMasterTableColumn?.map((item, index) => {
                  return <option value={item.column}>{item.column}</option>;
                })}
            </select>
            {errors?.column && (
              <p className={`${classes.errorText}`}>{errors.column}</p>
            )}
          </div> */}
          <div className={classes.InputWrap}>
            <p>{t("language_selection")}</p>
            <select
              name="lang"
              onChange={handleChange}
              // onFocus={onFocus}
              // onBlur={onBlur}
            >
              <option value="" disabled selected>
                {`${t("select")}`}
              </option>
              {langdata?.map((item, index) => {
                return (
                  <option value={item.language}>{item.language_label}</option>
                );
              })}
            </select>
            {errors?.lang && (
              <p className={`${classes.errorText}`}>{errors.lang}</p>
            )}
          </div>
          <div className={classes.InputWrap}>
            <p>{t("translation")}</p>
            <input
              type="text"
              name="translation"
              onChange={handleChange}
              // onFocus={onFocus}
              // onBlur={onBlur}
            />
            {errors?.translation && (
              <p className={`${classes.errorText}`}>{errors.translation}</p>
            )}
          </div>
          <div className={classes.InputWrap}>
            <p>{t("translation_value")}</p>
            <input
              type="text"
              name="value"
              onChange={handleChange}
              // onFocus={onFocus}
              // onBlur={onBlur}
            />
            {errors?.value && (
              <p className={`${classes.errorText}`}>{errors.value}</p>
            )}
          </div>
          <div className={classes.btnWrap}>
            <button onClick={saveHandler}>{t("save")}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CrateTranslation;
