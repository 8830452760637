import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./selectcampaignaudience.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdFileDownload } from "react-icons/md"
import {
  actionAddEmailApiCall,
  actionGetAllTagsApiCall,
  actionLangListAPICall,
  actionGetBranchTableSystemApiCall,
  actionSaveBranchSelectAudienceApiCall,
  actionGetBranchSelectAudienceApiCall,
  actionGetBranchTableAudienceApiCall,
  saveBranchApiCall,
  getBranchApiCall,
  actionGetAllClassificationsApiCall,
  actionGetAllProvinceApiCall,
  actionGetAllCityApiCall,
  addRecipientApiCall,
  addAudienceApiCall,
  downloadRecipientApiCall,
} from "../../../Redux/Actions";
import APIUrl from "../../../API/APIUrl";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { FaBlackTie } from "react-icons/fa";
import { toast } from "react-toastify";
const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.error("Failed to parse JSON:", str);
    return null; // or return an appropriate fallback value such as []
  }
};

const SelectCampaignAudience = (props) => {


  const audienceData = useSelector(
    (state) => state.CommonReducer?.savedAudience
  );
  console.log(audienceData, "audience from selector select audience");  

   
  const Tags = useSelector((state) => state.CommonReducer.saveAllTags);
  const Recipients = useSelector(
    (state) => state.CommonReducer.savedRecipients
  );
  // console.log(Recipients,"recipientsssss")
  const Branches = useSelector((state) => state.CommonReducer.saveBranchData);
  const Classification = useSelector(
    (state) => state.CommonReducer.saveAllClassifications
  );
  const Province = useSelector((state) => state.CommonReducer.saveAllProvince);



  const City = useSelector((state) => state.CommonReducer.saveAllCity);
  // console.log(props, "city from selector");
  // console.log(Province, "province from selector");
  // console.log(Classification, "classification");
  // console.log(Branches, "I am brancheeee from selector");
  const [dynamicData, setDynamicData] = useState(0);
  const [selectedOption, setSelectedOption] = useState(); //tags
  const [addSelectedOption, setAddSelectedOption] = useState(); //tags
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedClassification, setSelectedClassification] = useState();
  const [addTitle, setAddTitle] = useState(null);
  // console.log(selectedClassification, "selectedClassification");

  useEffect(() => {
    dispatch(actionGetAllTagsApiCall());
    dispatch(getBranchApiCall());
    dispatch(actionGetAllClassificationsApiCall());
    dispatch(actionGetAllProvinceApiCall());
    dispatch(actionGetAllCityApiCall());
  }, []);

  let close = props.onClose;


  const saveSingleUserData = useSelector(
    (state) => state.CommonReducer.saveSingleUserData
  );
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const [lang, setLang] = useState(1);
  const [focus, setFocus] = useState(false);
  const [editEmail, setEditEmail] = useState({
    title: "",
    subject: "",
    templateId: "",
    content: "",
    slug: "",
  });

  const loading = useSelector((state) => state.CommonReducer.loader);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const branchOptions = Branches?.map((branch) => ({
    value: branch?.name,
    label: branch?.name,
    id: branch?.id,
  }));
  const tagOptions = Tags?.map((tag) => ({
    value: tag?.tag_name,
    label: tag?.tag_name,
    id: tag?.id,
  }));
  // console.log(tagOptions, "optionsss");
  const addTagOptions = Tags?.map((tag) => ({
    value: tag?.tag_name,
    label: tag?.tag_name,
    id: tag?.id,
  }));
  const ClassificationOptions = Classification?.map((tag) => ({
    value: tag?.name,
    label: tag?.name,
    id: tag?.id,
  }));
  console.log(ClassificationOptions, "ClassificationOptions");
  const ProvinceOptions = Province?.map((tag) => ({
    value: tag?.name,
    label: tag?.name,
    id:tag?.id
  }));
  const CityOptions = City?.map((tag) => ({
    value: tag?.name,
    label: tag?.name,
    id: tag?.id,
  }));
  const options2 = [
    { value: 0, label: "+0 km" },
    { value: 1, label: "+1 km" },
    { value: 2, label: "+2 km" },
    { value: 5, label: "+5 km" },
    { value: 10, label: "+10 km" },
    { value: 15, label: "+15 km" },
    { value: 30, label: "+30 km" },
    { value: 50, label: "+50 km" },
    { value: 100, label: "+100 km" },
  ];
  const options3 = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  
  useEffect(() => {
    // console.log(selectedClassification, "classification2");
    // console.log(audienceData, "branchID");
    // console.log(audienceData?.city_id, "cityID");
    // console.log(audienceData?.audience_tag, "audienceData?.audience_tag");
    if (props.editable == true) {
      // const tagsArray = (audienceData?.tags || []).map((tag) => ({
      //   value: tag,
      //   label: tag,
      //   id:tag
      // }));
      console.log("audienceDataaaaaaa", audienceData)
      console.log("selectedProvince editable", selectedProvince)
      console.log("audienceData?.campaign_tag_list",audienceData?.campaign_tag_list)
      const array2 = audienceData?.campaign_tag_list?.map((item) => ({
        value: item?.tag_name,
        label: item?.tag_name,
        id: item?.id ?item?.id :item?.model?.tag_id ,
      }));
      setSelectedTags(array2);
      console.log(array2, "array222");
      // console.log(tagsArray,"audie")
      console.log(audienceData?.range, "range from editt");
      setAddTitle(audienceData?.campaign_title);
      console.log(audienceData?.city, "cittytyt");
      // setSelectedClassification({...selectedClassification,value:audienceData?.classification_id,label:audienceData?.classification_id})
      setSelectedClassification({
        ...selectedClassification,
        value: audienceData?.classification,
        label: audienceData?.classification,
        id: audienceData?.classification_id,
      });
      console.log(audienceData?.province,"proooo")
      setSelectedProvince({
        ...selectedProvince,
        value: audienceData?.province,
        label: audienceData?.province,
        id: audienceData?.province_id,
      });
      setSelectedBranch({
        ...selectedBranch,
        value: audienceData?.branch,
        label: audienceData?.branch,
        id: audienceData?.branch_id,
      });
      setAddSelectedOption({
        ...addSelectedOption,
        value: audienceData?.audience_tag,
        label: audienceData?.audience_tag,
        id: audienceData?.audience_id,
      });
      setSelectedCity({
        ...selectedCity,
        value: audienceData?.city,
        label: audienceData?.city,
        id: audienceData?.city_id,
      });
      // setDynamicData(audienceData?.recipients)
      parseInt(setSelectedOption({
        ...selectedOption,
        value: audienceData?.range,
        label: audienceData?.range,
      }));
      console.log(selectedCity, "selectedcitytyt");
      // setAddSelectedOption({...addSelectedOption,value:audienceData?.audience_tag,label:audienceData?.audience_tag,id:audienceData?.audience_id})
      // setSelectedOption({...selectedOption,value:parseInt(audienceData?.range),label:audienceData?.range})
      // setSelectedOption(audienceData?.range);
      console.log(selectedOption, "range");
      console.log(selectedOption && selectedOption?.value, "tags from editt");
      // setSelectedClassification(audienceData?.classification)
      // setSelectedProvince(audienceData?.province)
    }
  }, [props.editable]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #e8e8e8",
      padding: "3px 5px",
      width: "100%",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  const array = [
    "0 km",
    "1 km",
    "2 km",
    "5 km",
    "10 km",
    "15 km",
    "30 km",
    "50 km",
    "100 km",
  ];
  // console.log(selectedClassification, "classsss");
  const handleSave = () => {
    if (
      addTitle == "" 
      // selectedClassification == "" ||
      // selectedBranch == "" ||
      // selectedOption == "" ||
      // selectedCity == "" ||
      // selectedProvince == "" ||
      // selectedTags == ""
    ) {
      toast.error("Please fill the required field to continue");
    } else {
      handlePost();
    }
    // console.log(selectedTags.map((item)=>item.value),"tagssss")
  };
  // console.log(selectedTags, "tagssss outside");
  // console.log(selectedOption?.value,"cityID")
  // const handleApi = async () => {
  //   const data = JSON.stringify({
  //     tags: selectedTags?.map((item) => item.id),
  //     city_id:selectedCity?.id,
  //     distance:parseInt(selectedOption?.value),
  //     province:selectedProvince?.value,
  //     province_id:selectedProvince?.id,
  //     city:selectedCity?.value
  //   });
  //   const body = { data };
  //   dispatch(addRecipientApiCall(body));
  // };
  const handleApi = async () => {
    let data = {};

    // Check if selectedCity has a value
    if (selectedCity) {
      data = {
        ...data,
        city_id: selectedCity.id,
        city: selectedCity.value,
      };
    }

    // Check if other selected states have values and add them to data
    if (selectedTags && selectedTags.length > 0) {
      data = {
        ...data,
        tags: selectedTags.map((item) => item.id),
      };
    }

    if (selectedOption && selectedOption.value) {
      data = {
        ...data,
        distance: parseInt(selectedOption.value),
      };
    }

    if (selectedClassification && selectedClassification.id) {
      data = {
        ...data,
        classification_id: selectedClassification.id,
      };
    }

    if (selectedProvince && selectedProvince.id) {
      data = {
        ...data,
        province: selectedProvince.value,
        province_id: selectedProvince.id,
      };
    }

    if (selectedBranch && selectedBranch.id) {
      data = {
        ...data,
        branch_id: selectedBranch.id,
      };
    }

    // If data is not empty, send the API request
    if (Object.keys(data).length > 0) {
      const body = { data: JSON.stringify(data) };
      dispatch(addRecipientApiCall(body));
    } else {
      // Handle case when no data is available
      console.log("No data available to send");
    }
  };
  const handleApiDownload = async () => {
    let data = {};

    // Check if selectedCity has a value
    if (selectedCity) {
      data = {
        ...data,
        city_id: selectedCity.id,
        city: selectedCity.value,
      };
    }

    // Check if other selected states have values and add them to data
    if (selectedTags && selectedTags.length > 0) {
      data = {
        ...data,
        tags: selectedTags.map((item) => item.id),
      };
    }

    if (selectedOption && selectedOption.value) {
      data = {
        ...data,
        distance: parseInt(selectedOption.value),
      };
    }

    if (selectedClassification && selectedClassification.id) {
      data = {
        ...data,
        classification_id: selectedClassification.id,
      };
    }

    if (selectedProvince && selectedProvince.id) {
      data = {
        ...data,
        province: selectedProvince.value,
        province_id: selectedProvince.id,
      };
    }

    if (selectedBranch && selectedBranch.id) {
      data = {
        ...data,
        branch_id: selectedBranch.id,
      };
    }

    // If data is not empty, send the API request
    if (Object.keys(data).length > 0) {
      const body = { data: JSON.stringify(data) };
      console.log(body,"body for downloaddd")
      dispatch(downloadRecipientApiCall(body));
    } else {
      // Handle case when no data is available
      console.log("No data available to send");
    }
  };
  // console.log(selectedClassification.id,"nranchiee")
  const handlePost = async () => {
    const data = {
     ...(selectedTags?.length > 0 && { tag_id: selectedTags.map((item) => item.id) }),
     ...(addTitle && { campaign_title: addTitle }),
     ...(selectedClassification?.value && { classification: selectedClassification.value }),
     ...(selectedClassification?.id && { classification_id: selectedClassification.id }),
     ...(selectedCity?.id && { city_id: selectedCity.id }),
     ...(selectedCity?.value && { city: selectedCity.value }),
     ...(selectedBranch?.value && { branch: selectedBranch.value }),
     ...(selectedBranch?.id && { branch_id: selectedBranch.id }),
     ...(selectedProvince?.value && { province: selectedProvince.value }),
     ...(selectedProvince?.id && { province_id: selectedProvince.id }),
     ...(dynamicData && { recipients: dynamicData }),
     ...(addSelectedOption?.value && { audience_tag: addSelectedOption.value }),
     ...(addSelectedOption?.id && { audience_tag_id: addSelectedOption.id }),
     ...(selectedOption?.value && { range: selectedOption.value }),
    };
  
    const body = { data, navigate, close, id: props.id };
    dispatch(addAudienceApiCall(body));
  };
  // const handlePost = async () => {
  //   console.log(selectedTags, "selectedTagssssss")
  //   const data = JSON.stringify({
  //     tag_id: selectedTags?.map((item) => item?.id).length >0 ? selectedTags?.map((item) => item?.id) : null,
  //     campaign_title: addTitle,
  //     classification: selectedClassification?.value,
  //     classification_id: selectedClassification?.id,
  //     city_id:selectedCity &&  selectedCity?.id ? selectedCity?.id : null ,
  //     branch: selectedBranch?.value,
  //     branch_id: selectedBranch?.id,
  //     province: selectedProvince?.value,
  //     province_id: selectedProvince?.id,
  //     recipients: dynamicData,
  //     city: selectedCity?.value,
  //     audience_tag: addSelectedOption?.value,
  //     audience_tag_id: addSelectedOption?.id,
  //     range: selectedOption?.value,
  //   });
  //   console.log(data?.branch, "dataddf");
  //   const body = { data, navigate, close,id:props.id };
  //   dispatch(addAudienceApiCall(body));
  // };
  // const handlePost = async () => {
  //   console.log(selectedTags, "selectedTagssssss");
  
  //   // Check if any relevant data exists
  //   if (selectedTags || addTitle || selectedClassification || selectedCity || dynamicData) {
  //     const data = JSON.stringify({
  //       tag_id: selectedTags?.map((item) => item?.id),
  //       campaign_title: addTitle,
  //       classification: selectedClassification?.value,
  //       classification_id: selectedClassification?.id,
  //       city_id: selectedCity?.id,
  //       branch: selectedBranch?.value,
  //       branch_id: selectedBranch?.id,
  //       province: selectedProvince?.value,
  //       province_id: selectedProvince?.id,
  //       recipients: dynamicData,
  //       city: selectedCity?.value,
  //       audience_tag: addSelectedOption?.value,
  //       audience_tag_id: addSelectedOption?.id,
  //       range: selectedOption?.value,
  //     });
  
  //     console.log(data?.branch, "dataddf");
  //     const body = { data, navigate, close, id: props.id };
  //     dispatch(addAudienceApiCall(body));
  //   } else {
  //     console.log("No data to send.");
  //     // Handle the case where there's no data (e.g., show an error message)
  //   }
  // };
  
  // useEffect(() => {
  //   console.log("selectedTags",selectedTags,"selectedBranch",selectedBranch,"selectedClassification",selectedClassification,"selectedCity",selectedCity,"selectedOption",selectedOption);
  //   if (selectedTags?.length >= 0 ) {
  //     handleApi();
  //   }
  // }, [selectedTags]);
  useEffect(() => {
    // const isReadyToCallAPI = selectedTags?.length > 0  &&
    // ((selectedCity && selectedOption) || selectedProvince);

    // if (isReadyToCallAPI) {
    //   handleApi();
    // }
    handleApi();
  }, [selectedTags,selectedProvince,selectedCity,selectedOption, selectedBranch, selectedClassification]);

  

  const handleSelectChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
    console.log(selectedTags,"selected tagsss")
  };

  useEffect(() => {
    if (Recipients && Recipients.data !== undefined) {
      setDynamicData(Recipients.count);
    }
  }, [Recipients]);
  const handleTitle = (e) => {
    setAddTitle(e.target.value);
    console.log(addTitle, "title");
  };
  const handleDownload = ()=>{
    handleApiDownload()
    // alert("hello world")
  }
  // handleChnageid
  const handleChnageid = (e) => {
    console.log("handleChnageid", handleChnageid);
  };

  console.log("selectedProvince", selectedProvince)
  console.log('audience data in campaign audience', audienceData)

  return (
    <div className={classes.EditEmailMain}>
      <h3> Select Audience </h3>
      {/* {Recipients && Recipients.length > 0 && <p>{recipients} Recipients</p>} */}
      <div className={classes.EditEmailWrap}>
        <div className={classes.InputWrap}>
          <div className={classes.Recipientscount}>
            <p style={{ color: "#df1681" }}>No. of Recipients</p>
            <span>{dynamicData}</span>
          </div>
          <p className={classes.to}>{t("To")}</p>
          <p>{t("Who are you sending this email to?")}</p>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Title of the Campaign")}</p>
          <input
            style={{
              width: "100%",
              border: "1px solid #e8e8e8",
              borderRadius: "10px",
              padding: "5px 10px",
            }}
            value={addTitle}
            onChange={handleTitle}
            required
            type="text"
          />
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Select tags")}</p>
          <Select
            isMulti
            styles={customStyles}
            defaultValue={selectedTags}
            value={selectedTags}
            onChange={handleSelectChange}
            options={tagOptions}
          />
        </div>
        <div className={classes.InputWrap2}>
          <div style={{ width: "100%" }}>
            <p>{t("Select Branche(s)")}</p>
            <Select
              // isMulti
              styles={customStyles2}
              type="disabled"
              defaultValue={selectedBranch}
              value={selectedBranch}
              isClearable={true}
              onChange={setSelectedBranch}
              options={branchOptions}
            />
          </div>
          <div style={{ width: "100%" }}>
            <p>{t("Select Classification(s)")}</p>
            <Select
              // isMulti
              styles={customStyles2}
              defaultValue={selectedClassification}
              value={selectedClassification}
              isClearable={true}
              // Value={selectedClassification}
              onChange={setSelectedClassification}
              options={ClassificationOptions}
            />
          </div>
        </div>
        <div className={classes.InputWrap2}>
          <div style={{ width: "100%" }}>
            <p>{t("Province")}</p>
            <Select
              // isMulti
              styles={customStyles2}
              defaultValue={selectedProvince}
              value={selectedProvince}
              isClearable={true}
              // value={{label:selectedProvince,value:selectedProvince} }
              onChange={setSelectedProvince}
              options={ProvinceOptions}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingBottom: "12px",
            }}
          >
            <p>Or</p>
          </div>
          <div style={{ width: "100%" }}>
            <p>{t("City")}</p>
            <Select
              // isMulti
              styles={customStyles2}
              defaultValue={selectedCity}
              value={selectedCity}
              isClearable={true}
              onChange={setSelectedCity}
              options={CityOptions}
            />
          </div>
          <div style={{ width: "100%" }}>
            {/* //need to add condition when save buttons hits that add options cannot be same */}
            <p>{t("Range")}</p>
            <Select
              styles={customStyles2}
              defaultValue={selectedOption}
              value={selectedOption}
              isClearable={true}
              onChange={setSelectedOption}
              options={options2}
            />
          </div>
        </div>
        <div className={classes.InputWrap}>
          <p className={classes.to}>{t("Add tag to this audience?")}</p>
          <p>
            {t("Do you want to add an tag to this audience after sending it?")}
          </p>
        </div>
        <div className={classes.InputWrap}>
          <p>{t("Add tags")}</p>
          <Select
            // isMulti
            isClearable={true}
            styles={customStyles}
            defaultValue={addSelectedOption}
            value={addSelectedOption}
            onChange={setAddSelectedOption}
            options={addTagOptions}
          />                                                                                                                                                                                 
        </div>
        <div  className={classes.btnWrap}>
          <button onClick={handleSave}>
            {" "}
            {loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("Save")}
          </button>
          {Recipients.count > 0 ? (
            <>
              <button className={classes.downloadBtn} onClick={handleDownload}>
                {" "}
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <MdFileDownload style={{ marginRight: "3px" }} />
                {t("Download")}
              </button>
            </>
          ) : (
            <></>
          )}
          <button onClick={() => props.onClose()} className={classes.cancelBtn}>
            {" "}
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectCampaignAudience;
