import React from "react";
import classes from "./pricinghistoryoflisting.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PricingHistoryOfListing = () => {
  const { t } = useTranslation();
  const historyData = useSelector(
    (state) => state.CommonReducer.savePriceHistoryData
  );
  return (
    <div className={classes.PricingHistoryMain}>
      <h3>{t("pricing_history_of_listing")}</h3>
      <div className={classes.PricingHistoryWrap}>
        <table>
          <tr>
            <th>{t("date")}</th>
            <th className={classes.price}>{t("price")}</th>
          </tr>
          {historyData ? (
            historyData.map((item) => (
              <tr>
                <td>{item.date}</td>
                <td className={classes.price}>{item.price}</td>
              </tr>
            ))
          ) : (
            <tr>
              <h2>{t("no_data_found")}</h2>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
};

export default PricingHistoryOfListing;
