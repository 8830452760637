export const isAndroidWeb = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.includes("android") &&
    !userAgent.includes("mobile") &&
    userAgent.includes("chrome")
  );
};

export const isIosWeb = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    (userAgent.includes("iphone") || userAgent.includes("ipad")) &&
    !userAgent.includes("mobile") &&
    userAgent.includes("safari")
  );
};

export const isDesktop = () => {
  return !isAndroidWeb() && !isIosWeb();
};
export const disablePastDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export const ConvertIntoFloatValue = (floatValue) => {
  // return Math.round(parseFloat(floatValue) * 100) / 100;
  return parseFloat(floatValue).toFixed(2);
};
