import React from "react";
import classes from "./editmediafrom.module.scss";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../../assets/icons/FileUploadIcon.svg";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayIcon from "../../../../assets/icons/VideoPlayIcon.svg";
import PdfIcon from "../../../../assets/icons/PdfIcon.svg";
import { actionEditPropertyDocumentsApiCall } from "../../../../Redux/Actions";

const EditMediaFrom = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const singlePropertyData = useSelector(
    (state) => state.CommonReducer.saveSinglePropertyData
  );
  const loading = useSelector((state) => state.CommonReducer.loader);
  const [uploadMedia, SetUploadMedia] = useState([]);
  const [uploadMediaFile, SetUploadMediaFile] = useState([]);
  const [uploadFloorPlan, setUploadFloorPlan] = useState([]);
  const [uploadFloorPlanFile, SetUploadFloorPlanFile] = useState([]);
  const [uploadbrochure, SetUploadBrochure] = useState([]);
  const [uploadbrochureFile, SetUploadBrochureFile] = useState([]);
  const [previousFile, setPreviousFile] = useState(null);
  const [previousFloor, setPreviousFloor] = useState(null);
  const [previousBrochure, setPreviousBrochure] = useState(null);
  const [removeImageIdsData, setRemoveImageIdsData] = useState([]);
  const handleClose = props.handleClose;

  useEffect(() => {
    if (params.id) {
      if (singlePropertyData?.image_list?.length > 0) {
        SetUploadMedia(
          singlePropertyData?.image_list
            ?.filter(
              (val) =>
                val.type == "VIDEO" ||
                (val.type == "PHOTO" && val.sub_type != "MAIN_PHOTO")
            )
            ?.map((imageVal, index) => {
              return {
                previewUrls: imageVal.url_thumb_file,
                original_file_name: imageVal.original_file_name,
                id: imageVal.id,
                type: imageVal.type,
                sub_type: imageVal.sub_type,
              };
            })
        );

        setUploadFloorPlan(
          singlePropertyData?.image_list
            ?.filter((val) => val.type == "FLOORPLAN")
            ?.map((imageVal, index) => {
              return {
                previewUrls: imageVal.url_thumb_file,
                original_file_name: imageVal.original_file_name,
                id: imageVal.id,
                type: imageVal.type,
                sub_type: imageVal.sub_type,
              };
            })
        );

        SetUploadBrochure(
          singlePropertyData?.image_list
            ?.filter((val) => val.type == "BROCHURE")
            ?.map((imageVal, index) => {
              return {
                previewUrls: imageVal.url_thumb_file,
                original_file_name: imageVal.original_file_name,
                id: imageVal.id,
                type: imageVal.type,
                sub_type: imageVal.sub_type,
              };
            })
        );
      }
    }
  }, [params]);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const previewUrls = [];
    const validFiles = [];
    const selectedNames = uploadMediaFile.map((image) => image.name);
    let duplicateFound = false;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 2 * 1024 * 1024 && file.type.includes("image")) {
        toast.error("image can be of a max of 2 MB ");
        continue;
      }
      if (selectedNames.includes(file.name)) {
        if (!duplicateFound) {
          console.error("Image already selected");
          duplicateFound = true;
        }
        continue;
      }
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push({
          previewUrls: reader.result,
          name: file.name,
          type: file.type,
        });
        SetUploadMedia([...uploadMedia, ...previewUrls]);
      };
      if (file.type.includes("video")) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsDataURL(file);
      }

      validFiles.push(file);
    }
    if (validFiles.length > 0) {
      setPreviousFile(validFiles[validFiles.length - 1]);
    }
    SetUploadMediaFile([...uploadMediaFile, ...validFiles]);
  };

  const handleFloorPlan = (event) => {
    const files = event.target.files;
    const previewUrls = [];
    const validFiles = [];
    const selectedNames = uploadFloorPlanFile.map((image) => image.name);
    let duplicateFound = false;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 2 * 1024 * 1024 && file.type.includes("image")) {
        toast.error("image can be of a max of 2 MB ");
        continue;
      }
      if (selectedNames.includes(file.name)) {
        if (!duplicateFound) {
          console.error("Image already selected");
          duplicateFound = true;
        }
        continue;
      }
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push({
          previewUrls: reader.result,
          name: file.name,
          type: file.type,
        });
        setUploadFloorPlan([...uploadFloorPlan, ...previewUrls]);
      };
      if (file.type.includes("video")) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsDataURL(file);
      }

      validFiles.push(file);
    }
    if (validFiles.length > 0) {
      setPreviousFloor(validFiles[validFiles.length - 1]);
    }
    SetUploadFloorPlanFile([...uploadFloorPlanFile, ...validFiles]);
  };

  const handleBrochureUpload = (event) => {
    const files = event.target.files;
    const previewUrls = [];
    const validFiles = [];
    const selectedNames = uploadbrochureFile.map((image) => image.name);
    let duplicateFound = false;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 2 * 1024 * 1024 && file.type.includes("image")) {
        toast.error("image can be of a max of 2 MB ");
        continue;
      }
      if (selectedNames.includes(file.name)) {
        if (!duplicateFound) {
          console.error("Image already selected");
          duplicateFound = true;
        }
        continue;
      }
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push({
          previewUrls: reader.result,
          name: file.name,
          type: file.type,
        });
        SetUploadBrochure([...uploadbrochure, ...previewUrls]);
      };
      if (file.type.includes("video")) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsDataURL(file);
      }

      validFiles.push(file);
    }
    if (validFiles.length > 0) {
      setPreviousBrochure(validFiles[validFiles.length - 1]);
    }
    SetUploadBrochureFile([...uploadbrochureFile, ...validFiles]);
  };

  const EditListingHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();

    let imageData = {
      property_id: params.id,
      image_id: removeImageIdsData,
    };

    for (let i of uploadMediaFile) {
      formData.append("files", i);
    }
    for (let i of uploadFloorPlanFile) {
      formData.append("floorplan", i);
    }
    for (let i of uploadbrochureFile) {
      formData.append("brochure", i);
    }
    if (params.id) {
      formData.append("property_id", params.id);
    }
    let body = {
      formData,
      imageData,
      navigate,
      handleClose,
      id: params.id,
    };
    dispatch(actionEditPropertyDocumentsApiCall(body));
  };

  const removeImageHandler = (id, imageUrl, title) => {
    if (title === "upload") {
      if (id) {
        SetUploadMedia((prevData) => prevData.filter((item) => item.id !== id));
        setRemoveImageIdsData((prevData) => [...prevData, id]);
      } else {
        let newData = uploadMedia.filter((item) => item.name !== imageUrl);
        SetUploadMedia(newData);
        const filteredBinaryFiles = uploadMediaFile?.filter(
          (file) => !file.name.includes(imageUrl)
        );
        SetUploadMedia(filteredBinaryFiles);
      }
    }
    if (title === "floorplan") {
      if (id) {
        setUploadFloorPlan((prevData) =>
          prevData.filter((item) => item.id !== id)
        );
        setRemoveImageIdsData((prevData) => [...prevData, id]);
      } else {
        let newData = uploadFloorPlan.filter((item) => item.name !== imageUrl);
        setUploadFloorPlan(newData);
        const filteredBinaryFiles = uploadFloorPlanFile?.filter(
          (file) => !file.name.includes(imageUrl)
        );
        SetUploadFloorPlanFile(filteredBinaryFiles);
      }
    }
    if (title === "brochure") {
      if (id) {
        SetUploadBrochure((prevData) =>
          prevData.filter((item) => item.id !== id)
        );
        setRemoveImageIdsData((prevData) => [...prevData, id]);
      } else {
        let newData = uploadbrochure.filter((item) => item.name !== imageUrl);
        SetUploadBrochure(newData);
        const filteredBinaryFiles = uploadbrochureFile?.filter(
          (file) => !file.name.includes(imageUrl)
        );
        SetUploadBrochureFile(filteredBinaryFiles);
      }
    }
  };
  return (
    <div className={classes.EditMediaFrom}>
      <h3>{t("edit_edia_from")}</h3>
      <div className={classes.UploadFileSection}>
        <div className={classes.UploadWrap}>
          <p>{t("upload_media_for_listing")}</p>
          <div className={classes.MainWrap}>
            <input
              type="file"
              id="upload"
              name="images"
              hidden
              multiple
              accept=".jpg,.jpeg,.png,video/*"
              onChange={handleFileSelect}
            />
            <label htmlFor="upload">
              <div
                className={
                  uploadMedia?.length > 0
                    ? classes.DisplayAfterImageUpload
                    : classes.PhotoUpload
                }
              >
                <div className={classes.AllImgWrap}>
                  {uploadMedia?.length > 0 ? (
                    <>
                      {uploadMedia.map((item, index) => {
                        return (
                          <div className={classes.UploadImg}>
                            {item?.type?.toLowerCase().includes("video") ? (
                              <div className={classes.VideoWrap}>
                                <video
                                  src={item.previewUrls}
                                  className={
                                    item.previewUrls
                                      ? classes.ImgWrap
                                      : classes.LogoWrap
                                  }
                                  // controls
                                />
                                <span className={classes.videoPlayButton}>
                                  <img src={VideoPlayIcon} />
                                </span>
                                <span>
                                  <AiOutlineClose
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeImageHandler(
                                        item.id,
                                        item.name,
                                        "upload"
                                      );
                                    }}
                                  />
                                </span>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={item.previewUrls}
                                  className={
                                    item.previewUrls
                                      ? classes.ImgWarp
                                      : classes.LogoWrap
                                  }
                                  key={index}
                                />
                                <span>
                                  <AiOutlineClose
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeImageHandler(
                                        item.id,
                                        item.name,
                                        "upload"
                                      );
                                    }}
                                  />
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      })}

                      <img
                        src={FileUploadIcon}
                        className={classes.AfterUploadImageIcon}
                      />
                    </>
                  ) : (
                    <img src={FileUploadIcon} />
                  )}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className={classes.UploadContent}>
          <div className={classes.MainUplad}>
            <div className={classes.UploadWrap}>
              <p>{t("upload_floorplans")}</p>
              <div className={classes.MainWrap}>
                <input
                  type="file"
                  id="upload_floorplans"
                  name="upload_floorplans"
                  hidden
                  multiple
                  accept=".jpg,.jpeg,.png"
                  onChange={handleFloorPlan}
                />
                <label htmlFor="upload_floorplans">
                  <div
                    className={
                      uploadFloorPlan?.length > 0
                        ? classes.DisplayAfterImageUpload
                        : classes.PhotoUpload
                    }
                  >
                    <div className={classes.AllImgWrap}>
                      {uploadFloorPlan?.length > 0 ? (
                        <>
                          {uploadFloorPlan.map((item, index) => {
                            return (
                              <>
                                <div className={classes.UploadImg}>
                                  <img
                                    src={item.previewUrls}
                                    className={
                                      item.previewUrls
                                        ? classes.ImgWarp
                                        : classes.LogoWrap
                                    }
                                    key={index}
                                  />
                                  <span>
                                    <AiOutlineClose
                                      onClick={(e) => {
                                        e.preventDefault();
                                        removeImageHandler(
                                          item.id,
                                          item.name,
                                          "floorplan"
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              </>
                            );
                          })}
                          <img
                            src={FileUploadIcon}
                            className={classes.AfterUploadImageIcon}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={FileUploadIcon}
                            className={classes.AfterUploadImageIcon}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className={classes.MainUplad}>
            <div className={classes.UploadWrap}>
              <p>{t("upload_brochures")}</p>
              <div className={classes.MainWrap}>
                <input
                  type="file"
                  id="upload_brochures"
                  name="upload_brochures"
                  hidden
                  multiple
                  accept=".pdf"
                  onChange={handleBrochureUpload}
                />
                <label htmlFor="upload_brochures">
                  <div
                    className={
                      uploadbrochure?.length > 0
                        ? classes.DisplayAfterImageUpload
                        : classes.PhotoUpload
                    }
                  >
                    <div className={classes.AllImgWrap}>
                      {uploadbrochure?.length > 0 ? (
                        <>
                          {uploadbrochure?.map((item, index) => {
                            return (
                              <div className={classes.UploadImg}>
                                <img src={PdfIcon} />
                                <span>
                                  <AiOutlineClose
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeImageHandler(
                                        item.id,
                                        item.name,
                                        "brochure"
                                      );
                                    }}
                                  />
                                </span>
                              </div>
                            );
                          })}
                          <img
                            src={FileUploadIcon}
                            className={classes.AfterUploadImageIcon}
                          />
                        </>
                      ) : (
                        <img src={FileUploadIcon} />
                      )}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.btnWrap}>
        <button onClick={EditListingHandler}>
          {" "}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {t("edit")}
        </button>
      </div>
    </div>
  );
};

export default EditMediaFrom;
