import React from "react";
import classes from "./customselect.module.scss";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionGetCitySystemApiCall,
  actionGetCountriesTableSystemApiCall,
  actionGetCountryPartTableSystemApiCall,
  actionGetDistrictTableSystemApiCall,
  actionGetMunicipalityTableSystemApiCall,
  actionGetNeighbourhoodTableSystemApiCall,
  actionGetProvinceApiCall,
} from "../../../Redux/Actions";
import { debounce } from "../../../utils/commonFunctions";
const CustomSelect = ({
  item,
  apiName,
  isInputChange,
  headerSearch,
  setHeaderSearch,
  defaultInputValue,
  value,
  setErrors,
  errors,
  isClearable,
  setValue,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //delay in api call
  const onChange = (data) => {
    if (isInputChange) {
      let body = { navigate, page: 1, search: data, edit: item.loader };
      makeApiCall(body);
    }
  };
  const debounceOnChange = React.useCallback(debounce(onChange, 400), []);

  useEffect(() => {
    let body = { navigate, page: 1 };
    makeApiCall(body);
  }, []);

  const makeApiCall = async (body) => {
    let apiCalls = [];

    const dispatchApiCall = (apiAction) => dispatch(apiAction);

    switch (apiName) {
      case "municipality":
        apiCalls.push(
          dispatchApiCall(actionGetMunicipalityTableSystemApiCall(body))
        );
        break;
      case "country":
        apiCalls.push(
          dispatchApiCall(actionGetCountriesTableSystemApiCall(body))
        );
        break;
      case "province":
        apiCalls.push(dispatchApiCall(actionGetProvinceApiCall(body)));
        break;
      case "city":
        apiCalls.push(dispatchApiCall(actionGetCitySystemApiCall(body)));
        break;
      case "neighbourhood":
        apiCalls.push(
          dispatchApiCall(actionGetNeighbourhoodTableSystemApiCall(body))
        );
        break;
      case "district":
        apiCalls.push(
          dispatchApiCall(actionGetDistrictTableSystemApiCall(body))
        );
        break;
      case "country_part":
        apiCalls.push(
          dispatchApiCall(actionGetCountryPartTableSystemApiCall(body))
        );
        break;
    }

    try {
      await Promise.all(apiCalls);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className={classes.fildBox}>
      <Select
        defaultValue={defaultInputValue && value}
        value={setValue && value}
        placeholder={<div>{item.placeholder}</div>}
        isClearable={isClearable && true}
        isSearchable={true}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        options={item.options}
        onInputChange={(data) => debounceOnChange(data)}
        onChange={(option) => {
          setHeaderSearch({
            ...headerSearch,
            [item.name]: option,
          });
          if (errors) {
            setErrors({
              ...errors,
              [item.name]: "",
            });
          }
        }}
      />
    </div>
  );
};

export default CustomSelect;
