import React, { useEffect, useState } from "react";
import SingleUserHeader from "../common/SingleUserHeader";
import MainLayount from "./../common/MainLayout/index";
import classes from "./singleuserdetailed.module.scss";
import EditIcon from "../../assets/images/EditLogo.svg";
import SearchIcon from "../../assets/images/SearchIcon.svg";
import { useTranslation } from "react-i18next";
import Default from "../../assets/images/default.png";
import { useDispatch, useSelector } from "react-redux";
import ModalMain from "../common/Modal";
import UserLoggingHistoryMain from "../ModalComponents/UserManagementModals/UserLoggingHistory";
import { useNavigate } from "react-router-dom";
import {
  actionGetPropertyDetailApiCall,
  actionGetPropertyListAPICall,
  actionHideAllListingAPICall,
  actionOrganizationListApiCall,
  actionRemoveUserOrgApiCall,
  actionSendPasswordLinkAPICall,
  actionUpdatePropertyVisibleApiCall,
  actionViewLogHistoryAPICall,
  actiongetPropertyViewsApiCall,
} from "../../Redux/Actions";
import CardLayoutIcon from "../../assets/icons/CardLayoutIcon.svg";
import ListLayoutIcon from "../../assets/icons/ListLayoutIcon.svg";
import tick from "../../assets/icons/tick.png";
import DarkCardLayoutIcon from "../../assets/icons/DarkCardLayoutIcon.svg";
import DarkListingLayoutIcon from "../../assets/icons/DarkListingLayoutIcon.svg";
import moment from "moment";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import EditUser from "../ModalComponents/UserManagementModals/EditUser";
import AddOrgToUser from "../ModalComponents/UserManagementModals/AddOrgToUser";
import EditUsersListing from "../ModalComponents/UserManagementModals/EditUsersListing";
import PriceFormatter from "../../utils/PriceFormatter";
import { toast } from "react-toastify";

const SingleUserDetailed = () => {
  const { t } = useTranslation();
  const reduxState = useSelector((state) => state.CommonReducer);
  const [userData, setUserData] = useState(reduxState?.singleUserData);
  const [listProperty, setListProperty] = useState(
    reduxState?.SavePropertyListdata
  );
  const [orgSearch, setorgSearch] = useState();
  const [userToOrg, setUserToOrg] = useState();
  const [editUser, setEditUser] = useState();
  const [listSearch, setListSearch] = useState();
  const [viewLoglist, setViewLoglist] = useState();
  const [editUserList, setEditUserList] = useState(false);
  const [icon, seticon] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setUserData(reduxState.singleUserData);
  }, [reduxState.singleUserData]);

  useEffect(() => {
    setListProperty(reduxState.SavePropertyListdata);
  }, [reduxState.SavePropertyListdata]);
  useEffect(() => {
    const data = { page: 1, userid: userData.id, address: listSearch };
    const body = { data, navigate };
    dispatch(actionGetPropertyListAPICall(body));
  }, [listSearch]);

  useEffect(() => {
    const data = { page: 1, userid: userData.id, name: orgSearch };
    const body = { data, navigate };
    dispatch(actionOrganizationListApiCall(body));
  }, [orgSearch]);
  const onClickSendPasswordLink = () => {
    const data = { userid: userData.id };
    const body = { data, navigate, toast: true };
    dispatch(actionSendPasswordLinkAPICall(body));
  };
  const FullHistoryLog = () => {
    const data = {
      page: 1,
      userid: userData.id,
    };
    const body = { data, navigate };
    dispatch(actionViewLogHistoryAPICall(body));
    setViewLoglist(true);
  };
  const updatePropertyVisible = (hide, item) => {
    const data = {
      property_id: item.id,
      hide_property: hide,
    };
    const body = { data, navigate, userid: userData.id };
    dispatch(actionUpdatePropertyVisibleApiCall(body));
  };
  const OnCLickOfHideAll = () => {
    if (listProperty?.length > 0) {
      const data = {
        userid: userData.id,
        hide_property: 1,
      };
      const body = { data, navigate, type: "user" };
      dispatch(actionHideAllListingAPICall(body));
    } else {
      toast.warn(t("no_property_available"));
    }
  };
  const editUserListApiCall = (id) => {
    const data = {
      property_id: id,
    };
    const body = { data, navigate, edit: true };
    dispatch(actionGetPropertyDetailApiCall(body));
    dispatch(actiongetPropertyViewsApiCall(body));
    setEditUserList(true);
  };
  const removeFromOrg = (id) => {
    const data = {
      userid: userData.id,
      organizationid: id,
    };
    const body = { data, navigate };
    dispatch(actionRemoveUserOrgApiCall(body));
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.main}>
          <SingleUserHeader />
          <div className={classes.UserDetailsLayout}>
            <div className={classes.PersonalDetails}>
              <div className={classes.basicInfo}>
                <div className={classes.UserImgLayout}>
                  <img
                    src={
                      userData?.url_original_file
                        ? userData.url_original_file
                        : Default
                    }
                    alt="userImg"
                  />
                </div>
                <div className={classes.TextBoldLarge}>
                  <h1>{userData.full_name}</h1>
                </div>
                <div className={classes.NormalTextSmall}>
                  <p>{userData.email}</p>
                </div>
                <div
                  className={classes.EditIconLayout}
                  onClick={() => setEditUser(true)}
                >
                  <img src={EditIcon} alt="editIcon" />
                </div>
              </div>
              <div className={classes.UserInfoLayout}>
                <p className={classes.boldMedText}>{t("user_info")}</p>
                <div className={classes.UserInternalDetails}>
                  <div className={classes.LeftUserContent}>
                    <p>
                      {t("last_online")}
                      {userData.last_online
                        ? moment(userData.last_online).format(
                            "DD/MM/YYYY HH:MM"
                          )
                        : "NA"}
                    </p>
                    <p>
                      {t("set_language")}
                      {userData.user_language
                        ? userData.user_language.language_label
                        : "NA"}
                    </p>
                  </div>
                  <div className={classes.RightUserContent}>
                    <p>
                      {t("total_hours_online")}{" "}
                      {userData.hours ? userData.hours : "NA"}
                    </p>
                    <p>
                      {t("account_created_at")}{" "}
                      {userData.created_at
                        ? moment(userData.created_at).format("DD/MM/YYYY HH:MM")
                        : "NA"}
                    </p>
                    <p>
                      {t("membership_status")}{" "}
                      {userData.m_status ? userData.m_status : "NA"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.OtherDetails}>
              <div className={classes.QuickAndUserContainer}>
                <div className={classes.QuickOptionLayout}>
                  <div className={classes.ItalitBoldText}>
                    <p>{t("quick_options")}</p>
                  </div>
                  <div className={classes.NormalTextSmall}>
                    <p onClick={() => setUserToOrg(true)}>
                      {t("add_to_organisation")}
                    </p>
                    <p onClick={OnCLickOfHideAll}>
                      {t("hide_all_listings_from_user")}
                    </p>
                    {reduxState.linkSend == userData.id ? (
                      <p className={classes.TextBlue}>
                        {t("link_was_sent")}
                        <img src={tick} />
                      </p>
                    ) : (
                      <p
                        className={classes.TextBlue}
                        onClick={onClickSendPasswordLink}
                      >
                        {t("send_reset_password_link")}
                      </p>
                    )}
                  </div>
                </div>
                <div className={classes.UserLogginLayout}>
                  <div className={classes.TextAndActionBtn}>
                    <p>{t("User Logging History")}</p>
                    <button onClick={FullHistoryLog}>
                      {t("view_full_document")}
                    </button>
                  </div>
                  <div className={classes.LoginHistroyLayout}>
                    {userData?.user_logs?.length > 0 ? (
                      userData?.user_logs.map((item, i) => (
                        <div className={classes.logginContentRow}>
                          <p>{item.event}</p>
                          <p>{item.system}</p>
                          <p>
                            {moment(item.created_at).format("DD/MM/YYYY HH:MM")}
                          </p>
                        </div>
                      ))
                    ) : (
                      <div className={classes.logginContentRow}>
                        <h2>{t("no_data_found")}</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.OrganisationDetailsContainer}>
                <div className={classes.OrganisationAndSearchBar}>
                  <p>
                    {t("organisation")}{" "}
                    <span>
                      {" "}
                      {">"}{" "}
                      {reduxState?.saveOrganizationListSingleUserData?.length}{" "}
                    </span>
                  </p>
                  <div className={classes.SearchBar}>
                    <img src={SearchIcon} alt="" />
                    <input
                      type="text"
                      placeholder="Search in Organisation"
                      onChange={(e) => setorgSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.OrgWrapper}>
                  {reduxState?.saveOrganizationListSingleUserData.length > 0 ? (
                    reduxState?.saveOrganizationListSingleUserData?.map(
                      (item) => (
                        <div className={classes.OrganisationDetail}>
                          <div className={classes.orgnisationLogo}>
                            <img
                              src={item?.logo_image ? item.logo_image : Default}
                              alt=""
                            />
                          </div>
                          <p>
                            {t("role_s")} {item.name}
                          </p>
                          <p
                            className={classes.removeOrg}
                            onClick={() => removeFromOrg(item.id)}
                          >
                            {t("remove_from_orga")}
                          </p>
                        </div>
                      )
                    )
                  ) : (
                    <div className={classes.OrganisationDetail}>
                      <h2>{t("no_organisation_data")}</h2>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.ListingContainer}>
                <div className={classes.ListingHeaderAndSearchBar}>
                  <p>
                    {t("listing")}{" "}
                    <span>
                      {" "}
                      {">"} {listProperty?.length}{" "}
                    </span>
                  </p>
                  <div className={classes.leftWrap}>
                    <div className={classes.ChangeLayoutBtnWarp}>
                      <button
                        onClick={() => {
                          seticon(true);
                        }}
                        className={
                          icon == false
                            ? classes.ChangeLayou
                            : classes.activeBtn
                        }
                      >
                        {icon == true ? (
                          <img src={CardLayoutIcon} />
                        ) : (
                          <img src={DarkCardLayoutIcon} />
                        )}
                      </button>
                      <button
                        onClick={() => {
                          seticon(false);
                        }}
                        className={
                          icon == true ? classes.ChangeLayou : classes.activeBtn
                        }
                      >
                        {icon == false ? (
                          <img src={ListLayoutIcon} />
                        ) : (
                          <img src={DarkListingLayoutIcon} />
                        )}
                      </button>
                    </div>
                    <div className={classes.SearchBar}>
                      <img src={SearchIcon} alt="" />
                      <input
                        type="search"
                        placeholder="Search in Listing"
                        onChange={(e) => setListSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {listProperty?.length > 0 ? (
                  <>
                    {icon ? (
                      <div className={classes.CardListingLayout}>
                        {listProperty?.map((item, i) => (
                          <div
                            className={
                              item.hide_property == 0
                                ? classes.ListingContent
                                : `${classes.InvisibleCard} ${classes.ListingContent}`
                            }
                          >
                            <div className={classes.LogoAndDetails}>
                              <div className={classes.ListingLogo}>
                                <img
                                  src={
                                    item?.property_img?.url_thumb_file
                                      ? item.property_img.url_thumb_file
                                      : Default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className={classes.ListingDetail}>
                                <p>{item.name}</p>
                                <p>{item.address}</p>
                                {item?.PropertyPrice?.length > 0 ? (
                                  <p>
                                    {PriceFormatter(
                                      item.PropertyPrice[0].price
                                    )}
                                  </p>
                                ) : (
                                  <>
                                    {item.is_rent == 0 && item.is_sell == 1 && (
                                      <p>{PriceFormatter(item.sell_price)}</p>
                                    )}
                                    {item.is_rent == 1 && item.is_sell == 0 && (
                                      <p>{PriceFormatter(item.rent_price)}</p>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className={classes.actionOnListing}>
                              <img
                                src={EditIcon}
                                onClick={() => editUserListApiCall(item.id)}
                              />
                              {item.hide_property == 0 ? (
                                <FaEye
                                  onClick={() => updatePropertyVisible(1, item)}
                                />
                              ) : (
                                <FaEyeSlash
                                  onClick={() => updatePropertyVisible(0, item)}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className={classes.TableListngLayOut}>
                        <table>
                          <tr>
                            <td>{t("address_capital")}</td>
                            <td>{t("postalcode")}</td>
                            <td>{t("CITY")}</td>
                            <td>{t("price")}</td>
                            <td>{t("TYPE OF LISTING")}</td>
                            <td>{t("VISIBILITY")}</td>
                          </tr>
                          {listProperty?.map((item) => (
                            <tr
                              className={
                                item.hide_property == 1
                                  ? classes.InvisibleCard
                                  : null
                              }
                            >
                              <td>{item.address}</td>
                              <td>{item.postcode}</td>
                              <td>{item.city}</td>
                              {item?.PropertyPrice?.length > 0 ? (
                                <td>
                                  {PriceFormatter(item.PropertyPrice[0].price)}
                                </td>
                              ) : (
                                <>
                                  {item.is_rent == 0 && item.is_sell == 1 && (
                                    <td>{PriceFormatter(item.sell_price)}</td>
                                  )}
                                  {item.is_rent == 1 && item.is_sell == 0 && (
                                    <td>{PriceFormatter(item.rent_price)}</td>
                                  )}
                                </>
                              )}
                              {item.is_rent == 0 && item.is_sell == 1 && (
                                <td>{t("sell")}</td>
                              )}
                              {item.is_rent == 1 && item.is_sell == 0 && (
                                <td>{t("rent")}</td>
                              )}
                              {item.is_rent == 1 && item.is_sell == 1 && (
                                <td>{t("both")}</td>
                              )}
                              <td>
                                <div className={classes.ListingIcons}>
                                  {item.hide_property == 0 ? (
                                    <FaEye
                                      onClick={() =>
                                        updatePropertyVisible(1, item)
                                      }
                                    />
                                  ) : (
                                    <FaEyeSlash
                                      onClick={() =>
                                        updatePropertyVisible(0, item)
                                      }
                                    />
                                  )}
                                  <img
                                    src={EditIcon}
                                    onClick={() => editUserListApiCall(item.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    )}
                  </>
                ) : (
                  <h2>{t("no_Listing_data")}</h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayount>
      <ModalMain
        showmodel={viewLoglist}
        handleClose={() => setViewLoglist(false)}
        size={"lg"}
      >
        <UserLoggingHistoryMain />
      </ModalMain>
      <ModalMain
        showmodel={userToOrg}
        handleClose={() => setUserToOrg(false)}
        size={"lg"}
      >
        <AddOrgToUser />
      </ModalMain>
      <ModalMain
        showmodel={editUser}
        handleClose={() => setEditUser(false)}
        size={"md"}
      >
        <EditUser handleClose={() => setEditUser(false)} edit={true} />
      </ModalMain>
      <ModalMain
        showmodel={editUserList}
        handleClose={() => setEditUserList(false)}
        size={"xl"}
      >
        <EditUsersListing />
      </ModalMain>
    </React.Fragment>
  );
};

export default SingleUserDetailed;
