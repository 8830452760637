import React, { useRef, useState } from "react";
import classes from "./subscriptionsmain.module.scss";
import { useTranslation } from "react-i18next";
import SubscriptionsHeader from "../common/SubscriptionsHeader";
import MainLayount from "../common/MainLayout";
import userEditorIcon from "../../assets/icons/userEditorIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionGetSubscriptionApiCall,
  actionDeleteSubscriptionApiCall,
  actionEditActiveSubscriptionApiCall,
  actionChangeSubscriptionProductOrderApiCall,
} from "../../Redux/Actions";
import Loader from "../common/Loader";
import moment from "moment";
import ModalMain from "../common/Modal";
import AddSubscriptionModal from "../ModalComponents/SubscriptionModal/AddSubscriptionModal";

const SubscriptionsMain = () => {
  const subdata = useSelector(
    (state) => state.CommonReducer.saveSubscriptionData
  );
  const loader = useSelector((state) => state.CommonReducer.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState("");
  const listRef = useRef(null);
  const [addSubscription, setSubscription] = useState(false);
  const [orderValue, setOrderValue] = useState({
    id: "",
    value: "",
  });
  const divref = useRef([]);
  const ChangeOrder = (id) => {
    if (orderValue.value > 0) {
      const data = {
        id: id,
        order: Number(orderValue.value),
      };
      dispatch(actionChangeSubscriptionProductOrderApiCall({ data, navigate }));
    }
  };
  const handleScroll = (index) => {
    let ref = divref.current[index];
    const y = ref.getBoundingClientRect().top;
    if (y > 303) {
      divref.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  React.useEffect(() => {
    dispatch(actionGetSubscriptionApiCall({ navigate }));
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Close = () => setSubscription(false);

  const onClickEditSubscription = (id) => {
    const data = id;
    const body = { data, navigate, edit: true };
    dispatch(actionGetSubscriptionApiCall(body));
    setSubscription(true);
  };
  const onClickDeleteSubscription = (id) => {
    const data = id;
    const body = { data, navigate };
    dispatch(actionDeleteSubscriptionApiCall(body));
  };
  const onChangeOfActive = (id, active) => {
    const data = {
      active: !active,
      subscription_id: id,
      subscription_name: "testdata1",
    };
    const body = { data, navigate };
    dispatch(actionEditActiveSubscriptionApiCall(body));
  };
  return (
    <React.Fragment>
      <MainLayount>
        <div className={classes.SubscriptionsMain}>
          <SubscriptionsHeader />
          <div className={classes.SubscriptionSection}>
            <table>
              <tr>
                <th>{t("name")}</th>
                <th>
                  {t("price_pre_month")} / {t("periodicity")}
                </th>
                <th>{t("starrt")}</th>
                <th>{t("end")}</th>
                <th>{t("active")}</th>
                <th>{t("order_by")}</th>
                <th></th>
              </tr>
              {loader == true ? (
                <tr>
                  <td className={classes.LoaderWrap} colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {subdata.length <= 0 ? (
                    <tr>
                      <td className={classes.LoaderWrap} colSpan={6}>
                        <h2>{t("no_data_found")}</h2>
                      </td>
                    </tr>
                  ) : (
                    subdata.map((item, index) => {
                      return (
                        <tr ref={(e) => (divref.current[index] = e)}>
                          <td>{item.name}</td>
                          <td className={classes.PaymentOption}>
                            {item.payment_option.map((i) => {
                              return <p>{`${i.price} / ${i.periodicity}`}</p>;
                            })}
                          </td>
                          <td>
                            {" "}
                            {item.valid_from
                              ? moment(item.valid_from).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                          <td>
                            {" "}
                            {item.valid_until
                              ? moment(item.valid_until).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                          <td>
                            <label className={classes.Switch}>
                              <input
                                type="checkbox"
                                checked={item.active ? true : false}
                                onChange={() =>
                                  onChangeOfActive(item.id, item.active)
                                }
                              />
                              <span className={classes.Slider} />
                            </label>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="order"
                              defaultValue={
                                orderValue.id === item.id && orderValue.value
                                  ? orderValue.value
                                  : item.order
                              }
                              onChange={(e) => {
                                const inputValue = e.target.value
                                  .split(".")[0]
                                  .replace(/\D/g, "");
                                setOrderValue({
                                  id: item.id,
                                  value: inputValue,
                                });
                              }}
                              onBlur={() => ChangeOrder(item.id)}
                            />
                          </td>
                          <td className={classes.userEditor}>
                            <img
                              src={userEditorIcon}
                              onClick={() => {
                                // if (subdata.length - 1 > index) {
                                handleScroll(index);
                                // }
                                if (ind !== index) {
                                  setShow(!show);
                                  setInd(index);
                                } else {
                                  setInd("");
                                }
                              }}
                            />
                            {show && ind == index && (
                              <div
                                ref={listRef}
                                className={classes.EditorWrap}
                                onClick={() => setShow(!show)}
                                style={
                                  subdata.length - 1 > index
                                    ? {}
                                    : subdata.length > 4
                                    ? { top: "-100px" }
                                    : {}
                                }
                              >
                                <ul>
                                  <li
                                    onClick={() =>
                                      onClickEditSubscription(item.id)
                                    }
                                  >
                                    {t("edit_subscription")}
                                  </li>
                                  <li
                                    onClick={() =>
                                      onClickDeleteSubscription(item.id)
                                    }
                                  >
                                    {t("delete_subscription_c")}
                                  </li>
                                </ul>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </MainLayount>
      <ModalMain showmodel={addSubscription} handleClose={Close} size={"lg"}>
        <AddSubscriptionModal edit={true} close={Close} />
      </ModalMain>
    </React.Fragment>
  );
};

export default SubscriptionsMain;
