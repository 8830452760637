import React from "react";
import ListingManagementMain from "../../components/ListingManagementMain";

const ListingManagementPage = () => {
  return (
    <React.Fragment>
      <ListingManagementMain />
    </React.Fragment>
  );
};

export default ListingManagementPage;
