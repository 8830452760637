import { takeEvery, put } from "redux-saga/effects";
import {
  GetAllTagsApiCall,
  AddBannerAPICall,
  AddEmailAPICall,
  AddTranslationApiCall,
  AddUserOrgAPICall,
  BannerListAPICall,
  BlockOrganizationAPICall,
  RunImportAPICall,
  BlockUserAPICall,
  ChangeAdminOrganisationAPICall,
  ChangeAdminPropertyAPICall,
  ChangeImportProfileStatusAPICall,
  ChangeImportStatusAPICall,
  ChangeUserFromPropertyAPICall,
  CreateOrganizationAPICall,
  CreateUserAPICall,
  DeleteBannerAPICall,
  DeleteOrganizationAPICall,
  DeletePropertyAPICall,
  DeleteUserAPICall,
  EditPropertyAPICall,
  EditPropertyDocumentsApiCall,
  EditPropertyStatusAPICall,
  EmailListAPICall,
  GetEmployeeJobTitleApiCall,
  GetPriceHistoryAPICall,
  GetPropertyDetailAPICall,
  GetPropertyListAPICall,
  GetPropertyViewsAPICall,
  GetSiteSettingsApiCall,
  GetTranslationApiCall,
  HideAllListingAPICall,
  LangLabelListAPICall,
  LangListAPICall,
  LoginAPICall,
  LogoutAPICall,
  MasterTableGetTranslationApiCall,
  OrgApproveAPICall,
  OrganizationListAPICall,
  RemoveJoinedOrganisationAPICall,
  RemovePropertyImagesApiCall,
  RemoveUserOrgAPICall,
  SendPasswordLinkAPICall,
  TranslationListAPICall,
  UnblockOrgAPICall,
  UnblockUserAPICall,
  UpdateJobTitleAPICall,
  UpdateLangLabelValueAPICall,
  UpdateOrgAPICall,
  UpdatePropertyVisibleAPICall,
  UpdateSiteSettingsApiCall,
  UserListAPICall,
  ViewBannerAPICall,
  ViewEmailAPICall,
  ViewLogHistoryAPICall,
  ViewOrganizationAPICall,
  ViewUserAPICall,
  DiscountListApiCall,
  DeleteDiscountApiCall,
  AddDiscountApiCall,
  CreateSubscriptionApiCall,
  SubscriptionListApiCall,
  DeleteSubscriptionApiCall,
  EditSubscriptionApiCall,
  RemoveSubscriptionPaymentOptionsApiCall,
  RemoveSubscriptionDiscountsExtrasOptionsApiCall,
  EditActiveSubscriptionApiCall,
  AddSubscriptionImageApiCall,
  ChangeSubscriptionProductOrderApiCall,
  CreateManualPaymentForOrgApiCall,
  GetAllSubscriptionByOrgApiCall,
  GetAllPaymentOptionApiCall,
  StopSubscriptionApiCall,
  EditSubscriptionInOrgApiCall,
  getBranchesTableData,
  addBranchesData,
  getBranchesNestedData,
  deleteBranchData,
  getClassificationNestedData,
  editBranchData,
  addClassificationData,
  getClassificationTableData,
  deleteClassification,
  addContactApiCall,
  deleteContactApiCall,
  getCountriesTableData,
  addCountriesData,
  deleteCountriesApiCall,
  getMunicipalityTableDataApiCall,
  addMunicipalityDataApiCall,
  deleteMunicipalityDataApiCall,
  addAgencyBranchesApiCall,
  addAgencyClassificationApiCall,
  getDistrictTableDataApiCall,
  addDistrictDataApiCall,
  deleteDistrictDataApiCall,
  getNeighbourhoodTableDataApiCall,
  addNeighbourhoodDataApiCall,
  deleteNeighbourhoodDataApiCall,
  getCountryPartTableDataApiCall,
  addCountryPartDataApiCall,
  deleteCountryPartDataApiCall,
  getCityApiCall,
  addCityApiCall,
  deleteCityApiCall,
  getProvinceApiCall,
  getZipcodeApiCall,
  addZipcodeApiCall,
  deleteZipcodeApiCall,
  addProvinceDataApiCall,
  deleteProvinceDataApiCall,
  getContactLogApiCall,
  addContactLogDataApiCall,
  deleteContactLogDataApiCall,
  getAutoSuggestCityApiCall,
  addAddressOrgApiCall,
  addLocationApiCall,
  addPriceTableApiCall,
  getTranslationColumnApiCall,
  AddEditTranslationApiCall,
  deleteAddressOrgApiCall,
  deleteLocationApiCall,
  deletePriceTableApiCall,
  addInternalBannerDataApiCall,
  deleteInternalBannerDataApiCall,
  getInternalBannerApiCall,
  getCityInfoApiCall,
  getVatDataApiCall,
  UpdateVatDataApiCall,
  contactLinkingToOrgApiCall,
  getBannerUrlApiCall,
  BannerCampaignsApiCall,
  MakeBannerStopApiCall,
  ViewPropertyBannerApiCall,
  DeletePropertyBannerApiCall,
  InvoicesApiCall,
  HoldInvoiceApiCall,
  ResendInvoiceApiCall,
  ViewInvoiceApiCall,
  DeleteInvoiceApiCall,
  CreateTagAPICall,
  GetAllClassificationsApiCall,
  AudienceProvinceApiCall,
  AudienceCityApiCall,
  updateTagAPICall,
  deleteTagAPICall,
  CreateTemplateAPICall,
  getTemplateAPICall,
  CreateRecipientAPICall,
  CreateAudienceAPICall,
  getCampaignAPICall,
  sendSubjectApiCall,
  sendAllCampaignApiCall,
  updateTemplateAPICall,
  deleteTemplateAPICall,
  deleteCampaignAPICall,
  AddCreditApiCall,
  DownloadRecipientAPICall,
  getToken,
  getSupplierData,
  getSuppliersData,
  addSupplierApiCall,
  updateSupplierApiCall,
  deleteSupplierApiCall,
  locationListApiCall,
  titleListApiCall,
  advSupplierListApiCall,
  supplierTypesApiCall,
  priceLineUnitsApiCall,
  priceTableTypesApiCall,
} from "../../API/API";
import { toast } from "react-toastify";
import * as actions from "../Actions";
import { select } from "redux-saga/effects";
import { t } from "i18next";
const logout = (navigate) => {
  localStorage.clear();
  navigate("/login");
};

const catchHandler = (err, navigate) => {
  if (err?.response?.status == 401) {
    logout(navigate);
    toast.error(
      err?.response?.data?.msg
        ? err?.response?.data?.msg
        : err?.response?.message
    );
  } else {
    toast.error(
      err?.response?.data?.msg
        ? err?.response?.data?.msg
          ? err?.response?.data?.msg
          : err?.response?.msg
        : err?.response?.message
    );
  }
};
function* processLoginApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let check = data.payload.check;
  let lang = "en";
  try {
    const response = yield LoginAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveLoginUserData(response.data.data));
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("language", lang);
      if (check == true) {
        localStorage.setItem("saveEmail", data.payload.data.email);
        localStorage.setItem("savePassword", data.payload.data.password);
      } else if (check == false) {
        localStorage.removeItem("saveEmail");
        localStorage.removeItem("savePassword");
      }
      toast.success(response.data.msg);
      navigate("/user-management");
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processUserListApiCall(data) {
  const count = yield select((state) => state.CommonReducer);
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  try {
    const response = yield UserListAPICall(body);
    if (response.status === 200) {
      yield put(
        actions.actionSaveUserListData(
          response.data.data.rows ? response.data.data.rows : response.data.data
        )
      );
      yield put(actions.actionSaveUserFilterData(body));
      yield put(actions.settotalUserCount(response.data.data.count));
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processBlockUserApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  const filterData = yield select(
    (state) => state.CommonReducer.saveUserFilterData
  );
  let status = data.payload.status;
  try {
    const response = yield BlockUserAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      if (body.users_id.length == 1) {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionUserListApiCall(body));
      }
      if (status) {
        yield put(actions.actionUpdateSingleUserData(status));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processViewOrganizationApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let edit = data.payload.edit;
  try {
    const response = yield ViewOrganizationAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveSingleOrganizationdata(response.data.data));
      yield put(actions.settotalOrgCount(response.data.data.count));
      if (!edit) {
        navigate(`/single-organisation/${body}`);
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processDeleteOrganizationApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let singledata = data.payload.singledata;
  const filterData = yield select(
    (state) => state.CommonReducer.saveOrgFilterData
  );
  try {
    const response = yield DeleteOrganizationAPICall(body);
    if (response.status === 200) {
      const data = filterData;
      const body = { data, navigate };
      yield put(actions.actionOrganizationListApiCall(body));
      if (singledata == true) {
        navigate("/organisation-management");
      }
      toast.success(response.data.data);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processBlockOrganizationApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  const filterData = yield select(
    (state) => state.CommonReducer.saveOrgFilterData
  );
  let singledata = data.payload.singledata;
  try {
    const response = yield BlockOrganizationAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      if (singledata) {
        const data = body.id;
        const body = { data, navigate };
        yield put(actions.actionViewOrganizationApiCall(body));
      } else {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionOrganizationListApiCall(body));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processUpdateOrganizationApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.formdata;
  let id = data.payload.id;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  let edit = data.payload.edit;
  const filterData = yield select(
    (state) => state.CommonReducer.saveOrgFilterData
  );
  try {
    const response = yield UpdateOrgAPICall(body, id);
    if (response.status === 200) {
      toast.success(response.data.msg);
      if (edit) {
        const data = id;
        const body = { data, navigate };
        yield put(actions.actionViewOrganizationApiCall(body));
      } else {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionOrganizationListApiCall(body));
      }
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}
function* processCreateOrganizationApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  const filterData = yield select(
    (state) => state.CommonReducer.saveOrgFilterData
  );
  try {
    const response = yield CreateOrganizationAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      const data = filterData;
      const body = { data, navigate };
      yield put(actions.actionOrganizationListApiCall(body));
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}

function* processCreateUserApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.formdata;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  let edit = data.payload.edit;
  let id = data.payload.id;
  const filterData = yield select(
    (state) => state.CommonReducer.saveUserFilterData
  );
  try {
    const response = yield CreateUserAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      if (edit) {
        let data = id;
        let body = { data, navigate };
        yield put(actions.actionViewUserApiCall(body));
        data = { page: 1, userid: id };
        body = { data, navigate };
        yield put(actions.actionOrganizationListApiCall(body));
        yield put(actions.actionGetPropertyListAPICall(body));
        yield put(actions.actionSetLoader(false));
      } else {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionUserListApiCall(body));
        yield put(actions.actionSetLoader(false));
      }
      close();
    }
  } catch (err) {
    if (err?.response?.status == 401) {
      logout(navigate);
    } else if (err?.response?.status == 409) {
      yield put(actions.actionUsedEmailFlag(true));
      yield put(actions.actionAlrearyUserdEmailData(err?.response?.data));
    } else {
    }
    yield put(actions.actionSetLoader(false));
  }
}
function* processDeleteUserApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  const filterData = yield select(
    (state) => state.CommonReducer.saveUserFilterData
  );
  let singledata = data.payload.singledata;
  try {
    const response = yield DeleteUserAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      if (body.users_id.length == 1) {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionUserListApiCall(body));
      }
      if (singledata == true) {
        navigate("/user-management");
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processAddUserOrgApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  let orgid = body.organizationid;
  try {
    const response = yield AddUserOrgAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      const data = orgid;
      const body = { data, navigate };
      yield put(actions.actionViewOrganizationApiCall(body));
      close();
    }
  } catch (err) {
    if (err?.response?.status == 401) {
      logout(navigate);
    } else {
      toast.error(err?.response?.data[0].msg);
    }
  }
}
function* processViewLogHistoryAPICall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield ViewLogHistoryAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(actions.actionSaveLogHistoryData(response.data.data.rows));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    if (err?.response?.status == 401) {
      logout(navigate);
    } else {
      toast.error(err?.response?.data[0].msg);
    }
  }
}
function* processRemoveUserOrgApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let orgid = body.organizationid;
  try {
    const response = yield RemoveUserOrgAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      let data = orgid;
      const body = { data, navigate };
      yield put(actions.actionViewOrganizationApiCall(body));
    }
  } catch (err) {
    if (err?.response?.status == 401) {
      logout(navigate);
    } else {
      toast.error(err?.response?.data[0].msg);
    }
  }
}

function* processOrganizationListApiCall(data) {
  yield put(actions.actionSetLoader(true));
  yield put(actions.actionOrgLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  try {
    const response = yield OrganizationListAPICall(body);
    if (response.status === 200) {
      if (body.userid) {
        yield put(actions.actionSaveOrgFilterData(body));

        yield put(
          actions.actionSaveOrganizationListSingleUserData(
            response.data.data.rows
              ? response.data.data.rows
              : response.data.data
          )
        );
      } else {
        yield put(actions.actionSaveOrgFilterData(body));

        yield put(
          actions.actionSaveOrganizationListData(
            response.data.data.rows
              ? response.data.data.rows
              : response.data.data
          )
        );
        yield put(actions.settotalOrgCount(response.data.data.count));
      }
      yield put(actions.actionSetLoader(false));
      yield put(actions.actionOrgLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}
function* processGetPropertyListAPICall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  try {
    const response = yield GetPropertyListAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveListFilterData(body));
      yield put(
        actions.actionSavePropertyListdata(
          response.data.data.rows ? response.data.data.rows : response.data.data
        )
      );
      yield put(
        actions.settotalListCount(
          response.data.data.count ? response.data.data.count : 1
        )
      );
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}
function* processremoveJoinedOrganisationAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield RemoveJoinedOrganisationAPICall(body);
    if (response.status === 200) {
      const data = { page: 1, userid: body.userid };
      body = { data, navigate };
      yield put(actions.actionGetPropertyListAPICall(body));
      toast.success(response.data.msg);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processChangeAdminOrgApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield ChangeAdminOrganisationAPICall(body);
    const responseee = yield ViewOrganizationAPICall(
      data.payload.data.organizationid
    );
    yield put(actions.actionSaveSingleOrganizationdata(responseee.data.data));
    toast.success(response.data.msg);
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processUnblockUserApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  const filterData = yield select(
    (state) => state.CommonReducer.saveUserFilterData
  );
  let status = data.payload.status;
  try {
    const response = yield UnblockUserAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      if (body.users_id.length == 1) {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionUserListApiCall(body));
      }
      if (status) {
        yield put(actions.actionUpdateSingleUserData(status));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processUpdatePropertyVisibleApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let temp = data.payload.userid;
  let org = data.payload.userid;
  let singledata = data.payload.singleData;
  try {
    const response = yield UpdatePropertyVisibleAPICall(body);
    if (response.status === 200) {
      toast.success(t("property_visiblity_updated"));
      if (temp) {
        const data = { page: 1, userid: temp };
        const body = { data, navigate };
        yield put(actions.actionGetPropertyListAPICall(body));
      } else if (singledata) {
        const data = { property_id: singledata };
        const body = { data, navigate };
        yield put(actions.actionGetPropertyDetailApiCall(body));
      } else {
        const data = { page: 1 };
        const body = { data, navigate };
        yield put(actions.actionGetPropertyListAPICall(body));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processGetPropertyDetailApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let edit = data.payload.edit;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  try {
    const response = yield GetPropertyDetailAPICall(body);
    if (response.status === 200) {
      if (!edit) {
        navigate(`/single-listing/${body.property_id}`);
        yield put(actions.actionSaveSinglePropertyData(response.data.data));
      } else {
        yield put(actions.actionSaveSinglePropertyData(response.data.data));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processEditPropertyStatusApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield EditPropertyStatusAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processGetProprtyViewsApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield GetPropertyViewsAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSavePropertyViewData(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processGetPriceHistoryCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield GetPriceHistoryAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSavePriceHistoryData(response.data.data.rows));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processHideAllListingAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let type = data.payload.type;
  let userid = body.userid;
  let orgid = body.organizationid;
  let property_id = body.property_id;
  let singleData = data.payload.singleData;
  const filterData = yield select(
    (state) => state.CommonReducer.saveListFilterData
  );
  try {
    const response = yield HideAllListingAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      if (singleData) {
        const data = {
          property_id: property_id,
        };
        const body = { data, navigate };
        yield put(actions.actionGetPropertyDetailApiCall(body));
        yield put(actions.actiongetPropertyViewsApiCall(body));
      } else {
        let data;
        if (type == "user") {
          data = { page: 1, userid: userid };
        } else if (type == "org") {
          data = { page: 1, organizationid: orgid };
        } else if (type == "property") {
          data = { ...filterData, property_id: property_id };
        }
        const body = { data, navigate };
        yield put(actions.actionGetPropertyListAPICall(body));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processSendPasswordLinkAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let toast1 = data.payload.toast;
  try {
    const response = yield SendPasswordLinkAPICall(body);
    if (response.status === 200) {
      if (toast1 == true) {
        yield put(actions.actionLinkSend(body.userid));
        toast.success(response.data.msg);
      } else {
        toast.success(t("password_link_hase_been_send"));
        yield put(actions.actionLinkSend(body.userid));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processLangListAPICall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield LangListAPICall();
    if (response.status === 200) {
      yield put(actions.actionSaveLangListData(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processLangLabelListAPICall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield LangLabelListAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSetLanguage(body.lang));
      yield put(actions.actionSaveLangFilterData(body));
      yield put(
        actions.actionSaveLangLabelListData(
          response.data.data.langLabelList.rows
            ? response.data.data.langLabelList.rows
            : response.data.data
        )
      );
      yield put(
        actions.settotalLangCount(
          response.data.data.langLabelList.count
            ? response.data.data.langLabelList.count
            : 1
        )
      );
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}

function* processUpdateLangLabelListAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield UpdateLangLabelValueAPICall(body);
    if (response.status === 200) {
      toast.success(t("updated_successfully"));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processChangeAdminPropertyAPICall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let singleid = body.property_id;
  let edit = data.payload.edit;
  let close = data.payload.close;
  try {
    const response = yield ChangeAdminPropertyAPICall(body);
    if (response.status === 200) {
      if (edit) {
        const data = { property_id: singleid };
        const body = { data, navigate };
        yield put(actions.actionGetPropertyDetailApiCall(body));
      }
      toast.success(response.data.msg);
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}
function* processDeletePropertyAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let singledata = data.payload.singledata;
  const filterData = yield select(
    (state) => state.CommonReducer.saveListFilterData
  );
  try {
    const response = yield DeletePropertyAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      if (singledata) {
        navigate("/listing-management");
      } else {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionGetPropertyListAPICall(body));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processEditPropertyAPICall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.formdata;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  let edit = data.payload.edit;
  let property_id = data.payload.property_id;
  const filterData = yield select(
    (state) => state.CommonReducer.saveListFilterData
  );
  try {
    const response = yield EditPropertyAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data.msg);
      if (edit) {
        const data = { property_id: property_id };
        const body = { data, navigate };
        yield put(actions.actionGetPropertyDetailApiCall(body));
      } else {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionGetPropertyListAPICall(body));
      }
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processLogoutAPICall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield LogoutAPICall();
    if (response.status === 200) {
      toast.success(response.data.msg);
      logout(navigate);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processApproveOrganizationAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield OrgApproveAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processUnBlockOrganizationAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  const filterData = yield select(
    (state) => state.CommonReducer.saveOrgFilterData
  );
  let singledata = data.payload.singledata;
  try {
    const response = yield UnblockOrgAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      if (singledata === true) {
        const data = body.id;
        const body = { data, navigate };
        yield put(actions.actionViewOrganizationApiCall(body));
      } else {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionOrganizationListApiCall(body));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processBannerListApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  for (let i in body) {
    if (!body[i] || body[i] === "null" || body[i] === "undefined") {
      delete body[i];
    }
  }
  try {
    yield put(actions.actionSaveSupplierFilterData(body));
    const response = yield BannerListAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveBannerData(response.data.data.rows));
      yield put(actions.actionSaveBannerFilterData(body));
      yield put(actions.settotalBannerCount(response.data.data.count));
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processSuppliersApiCall(data) {
  console.log("processSuppliersApiCall");
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  console.log("BODY", body);
  yield put(actions.actionSaveSupplierFilterData(body));
  const filterData = yield select(
    (state) => state.CommonReducer.saveSupplierFilterData
  );

  try {
    const response = yield getSuppliersData(filterData);
    console.log(response.data.data.count, response.data.data.rows);
    if (response.status === 200) {
      yield put(actions.actionSaveSuppliersData(response.data.data.rows));
      yield put(actions.settotalBannerCount(response.data.data.count));
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processAddSupplierApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let closePopup = data.payload.close;
  console.log(closePopup);
  const filterData = yield select(
    (state) => state.CommonReducer.saveSupplierFilterData
  );
  console.log("filterData", filterData);

  try {
    console.log("processAddSupplierApiCall", body);
    const response = yield addSupplierApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      const res = yield getSuppliersData(filterData);
      console.log(res.data.data.rows);
      if (response.status === 200) {
        yield put(actions.actionSaveSuppliersData(res.data.data.rows));
        yield put(actions.settotalBannerCount(res.data.data.count));
        yield put(actions.actionSetLoader(false));
      }
      console.log("BEFORE CLOSE POPUP");
      closePopup();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}

function* processUpdateSupplierApiCall(data) {
  yield put(actions.actionSetLoader(true));
  console.log("processUpdateSupplierApiCall", data.payload);
  let body = data.payload.data;
  let id = data.payload.id;
  let navigate = data.payload.navigate;
  let edit = data.payload.edit;
  const filterData = yield select(
    (state) => state.CommonReducer.saveOrgFilterData
  );
  try {
    const response = yield updateSupplierApiCall(body, edit);
    console.log(response.status);
    if (response.status === 200) {
      toast.success(response.data.msg);
      if (edit) {
        const data = id;
        const body = { data, navigate };
        yield put(actions.actionViewSupplierApiCall(body));
      } else {
        const data = filterData;
        const body = { data, navigate };
        yield put(actions.actionSuppliersApiCall(body));
      }
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}

function* processCreateBannerApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.formdata;
  let navigate = data.payload.navigate;
  const filterdata = yield select(
    (state) => state.CommonReducer.saveBannerFilterData
  );
  try {
    const response = yield AddBannerAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actions.actionSetLoader(false));
      data.payload.close();
      const body = { filterdata, navigate };
      yield put(actions.actionBannerListApiCall(body));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processDeleteBannerAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  const filterdata = yield select(
    (state) => state.CommonReducer.saveBannerFilterData
  );
  try {
    const response = yield DeleteBannerAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      const body = { filterdata, navigate };
      yield put(actions.actionBannerListApiCall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processDeleteSupplierApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  const filterdata = yield select(
    (state) => state.CommonReducer.saveSupplierFilterData
  );
  console.log("filterdata", filterdata);
  try {
    const response = yield deleteSupplierApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      const body = { filterdata, navigate };
      const res = yield getSuppliersData(filterdata);
      console.log(res.data.data.rows);
      if (response.status === 200) {
        yield put(actions.actionSaveSuppliersData(res.data.data.rows));
        yield put(actions.settotalBannerCount(res.data.data.count));
        yield put(actions.actionSetLoader(false));
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processViewBannerApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let edit = data.payload.edit;
  try {
    const response = yield ViewBannerAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveSingleBannerData(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processEmailListApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  try {
    const response = yield EmailListAPICall(body);
    if (response.status === 200) {
      yield put(
        actions.actionSaveEmailData(
          response.data.data.rows ? response.data.data.rows : response.data.data
        )
      );
      yield put(actions.actionSaveEmailFilterData(body));
      yield put(
        actions.settotalEmailCount(
          response.data.data.count ? response.data.data.count : 1
        )
      );
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processViewEmailApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;

  try {
    const response = yield ViewEmailAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveSingleEmailData(response.data.data));
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processAddEmailApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  try {
    const response = yield AddEmailAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processGetTranslationListApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield TranslationListAPICall(body);
    if (response.status === 200) {
      if (body == "rent_prefix") {
        yield put(actions.actionSaveRentPrefixData(response.data.data));
      } else if (body == "rent_postfix") {
        yield put(actions.actionSaveRentPostfixData(response.data.data));
      } else if (body == "sell_prefix") {
        yield put(actions.actionSaveSellPrefixData(response.data.data));
      } else if (body == "sell_postfix") {
        yield put(actions.actionSaveSellPostfixData(response.data.data));
      } else if (body == "status") {
        yield put(actions.actionSaveStatusData(response.data.data));
      } else if (body == "address_type") {
        yield put(actions.actionSaveAddressesData(response.data.data));
      }
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processGetLocationListApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield locationListApiCall();
    if (response.status === 200) {
      yield put(actions.actionSaveLocationListData(response.data.data));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processGetSupplierMetaDataApiCall(data) {
  console.log("processGetSupplierMetaDataApiCall");
  let navigate = data.payload.navigate;
  try {
    let response = yield supplierTypesApiCall();
    if (response.status === 200) {
      yield put(actions.actionSaveSupplierTypesData(response.data.data));
    }
    response = yield priceTableTypesApiCall();
    if (response.status === 200) {
      console.log("price table types:", response.data.data);
      yield put(actions.actionSavePriceTableTypesData(response.data.data));
    }
    response = yield priceLineUnitsApiCall();
    if (response.status === 200) {
            console.log("price line units:", response.data.data);

      yield put(actions.actionSavePriceLineUnitsData(response.data.data));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processGetTitleListApiCall(data) {
  let navigate = data.payload.navigate;
  let id = data.payload.id;
  try {
    const response = yield titleListApiCall(id);
    if (response.status === 200) {
      yield put(actions.actionSaveTitleListData(response.data.data));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processGetAdvSupplierListApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield advSupplierListApiCall();
    if (response.status === 200) {
      yield put(
        actions.actionSaveAdvertisingSupplierListData(response.data.data)
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processGetEmployeeJobTitleApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield GetEmployeeJobTitleApiCall();
    if (response.status === 200) {
      yield put(actions.actionSaveEmployeeJobTitle(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processUpdateJobTitleApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;
  let orgid = data.payload.orgid;
  try {
    const response = yield UpdateJobTitleAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      let data = orgid;
      const body = { data, navigate };
      yield put(actions.actionViewOrganizationApiCall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processChangeUserFromPropertyApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;
  let orgid = data.payload.orgid;
  try {
    const response = yield ChangeUserFromPropertyAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      const data = { page: 1, organizationid: orgid };
      const body = { data, navigate };
      yield put(actions.actionGetPropertyListAPICall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processChangeImportStatusApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;
  let orgid = data.payload.orgid;
  try {
    const response = yield ChangeImportStatusAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      let data = orgid;
      const body = { data, navigate };
      yield put(actions.actionViewOrganizationApiCall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processGetSiteSettingsApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield GetSiteSettingsApiCall();
    if (response.status === 200) {
      yield put(actions.actionSaveGetSiteSettings(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processUpdateSiteSettingsApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield UpdateSiteSettingsApiCall(
      data.payload.data,
      data.payload.id
    );
    if (response.status === 200) {
      toast.success(response.data.msg);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processChangeImportProfileStatusApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;
  let orgid = data.payload.orgid;
  try {
    const response = yield ChangeImportProfileStatusAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      let data = orgid;
      const body = { data, navigate };
      yield put(actions.actionViewOrganizationApiCall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processMasterTableGetTranslationApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield MasterTableGetTranslationApiCall();
    if (response.status === 200) {
      yield put(
        actions.actionSaveMasterTableGetTranslationColumn(response.data.data)
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processGetTranslationApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let navigate = data.payload.navigate;
  try {
    const response = yield GetTranslationApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveGetTranslation(response.data.data.translation)
      );
      yield put(
        actions.actionSaveTotalMasterTableCount(
          response.data.data.translation.count
        )
      );
      yield put(actions.actionSaveMasterTableFilterData(data.payload.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processAddTranslationApiCall(data) {
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  const perPageMasterTableCount = yield select(
    (state) => state.CommonReducer.perPageMasterTableCount
  );
  const saveMasterTableFilterData = yield select(
    (state) => state.CommonReducer.saveMasterTableFilterData
  );
  try {
    const response = yield AddTranslationApiCall(data.payload.data);
    if (response.status === 200) {
      toast.success(response.data.msg);
      close();
      const GetAllData = {
        page: 1,
        per_page: perPageMasterTableCount ? perPageMasterTableCount : 40,
        lang: saveMasterTableFilterData?.lang
          ? saveMasterTableFilterData?.lang
          : "en",
        column: data.payload.params.column,
      };
      const responseData = yield GetTranslationApiCall(GetAllData);
      if (responseData.status === 200) {
        yield put(
          actions.actionSaveGetTranslation(responseData.data.data.translation)
        );
        yield put(
          actions.actionSaveTotalMasterTableCount(
            responseData.data.data.translation.count
          )
        );
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processEditPropertyDocumentsApiCall(data) {
  let navigate = data.payload.navigate;
  let close = data.payload.handleClose;
  yield put(actions.actionSetLoader(true));
  try {
    if (
      data.payload.imageData.property_id &&
      data.payload.imageData.image_id.length > 0
    ) {
      const Imageresponse = yield RemovePropertyImagesApiCall(
        data.payload.imageData
      );
      if (Imageresponse.status === 200) {
      }
    }
    const response = yield EditPropertyDocumentsApiCall(data.payload.formData);
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      toast.success(response.data.msg);
      close();
      const idData = {
        property_id: data.payload.id,
      };
      const propertyResponse = yield GetPropertyDetailAPICall(idData);
      yield put(
        actions.actionSaveSinglePropertyData(propertyResponse.data.data)
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processRunImportApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;
  let orgid = data.payload.data.id;
  try {
    const response = yield RunImportAPICall(body);
    if (response.status === 200) {
      toast.success(response.data.data.msg);
      let data = orgid;
      const body = { data, navigate };
      yield put(actions.actionViewOrganizationApiCall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processGetDiscountAndExtrasApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let edit = data.payload.edit;
  try {
    const response = yield DiscountListApiCall(
      body,
      data?.payload?.valid,
      data?.payload?.page
    );
    if (response.status === 200) {
      if (edit) {
        yield put(
          actions.actionSaveSingleDiscountData(
            response.data.data.rows
              ? response.data.data.rows
              : response.data.data
          )
        );
      } else {
        yield put(
          actions.actionSaveDiscountAndExtras(
            response.data.data.rows
              ? response.data.data.rows
              : response.data.data
          )
        );
        yield put(actions.actionSaveDiscountFilterData(body));
        yield put(actions.settotalDiscountcount(response.data.data.count));
      }
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processDeleteDiscountAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield DeleteDiscountApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      let dataPayload = data.payload.data;
      const body = { dataPayload, navigate };
      yield put(actions.actionGetDiscountAndExtraApiCall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processAddDiscountApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  try {
    const response = yield AddDiscountApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      let dataPayload = data.payload.data;
      const body = { dataPayload, navigate };
      yield put(actions.actionGetDiscountAndExtraApiCall(body));
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}
function* processAddCreditApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  try {
    console.log("processAddCreditApiCall", body);
    const response = yield AddCreditApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      console.log(response.data);
      yield put(actions.actionSaveInvoiceListing(response.data.data));
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));

    catchHandler(err, navigate);
  }
}

function* processGetSubcriptionApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let edit = data.payload.edit;
  let loader = data.payload.loader;
  if (loader != false) {
    yield put(actions.actionSetLoader(true));
  }
  try {
    const response = yield SubscriptionListApiCall(body, data?.payload?.page);
    if (response.status === 200) {
      if (edit) {
        yield put(
          actions.actionSaveSingleSubscriptionData(
            response.data.data.rows
              ? response.data.data.rows
              : response.data.data
          )
        );
      } else {
        yield put(
          actions.actionSaveSubscription(
            response.data.data.rows
              ? response.data.data.rows
              : response.data.data
          )
        );
        yield put(actions.actionSaveSubscriptionFilterData(body));
        yield put(actions.settotalSubscriptioncount(response.data.data.count));
      }
      if (!loader) {
        yield put(actions.actionSetLoader(false));
      }
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processDeleteSubsciptionAPICall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield DeleteSubscriptionApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.data);
      let dataPayload = data.payload.data;
      const body = { dataPayload, navigate };
      yield put(actions.actionGetSubscriptionApiCall(body));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processCreateSubscriptionApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.handleClose;
  try {
    const response = yield CreateSubscriptionApiCall(body);
    if (response.status === 200) {
      let formData = new FormData();
      formData.append("file", data.payload.imageData);
      formData.append("id", response.data.subscription_id);
      const subscriptionImage = yield AddSubscriptionImageApiCall(formData);
      if (subscriptionImage.status === 200) {
      } else {
      }

      toast.success(response.data.msg);
      close();
      let subScriptionData = yield put(
        actions.actionGetSubscriptionApiCall({ navigate })
      );
      if (subScriptionData.status === 200) {
        yield put(actions.actionSaveSubscription(subScriptionData.data.data));
        yield put(actions.actionSetLoader(false));
      }
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processEditSubscriptionApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;

  try {
    if (data.payload.removePaymentOptionsIdsData.length > 0) {
      let idData = {
        paymentOptionIds: data.payload.removePaymentOptionsIdsData,
      };
      yield RemoveSubscriptionPaymentOptionsApiCall(idData);
    }
    if (data.payload.removeProductDiscountsIdsData.length > 0) {
      let idData = {
        productDiscountIds: data.payload.removeProductDiscountsIdsData,
      };
      yield RemoveSubscriptionDiscountsExtrasOptionsApiCall(idData);
    }
    const response = yield EditSubscriptionApiCall(body);
    if (response.status === 200) {
      if (data.payload.formData) {
        yield AddSubscriptionImageApiCall(data.payload.formData);
      }
      toast.success(response.data.msg);
      close();
      let subScriptionData = yield put(
        actions.actionGetSubscriptionApiCall({ navigate })
      );
      if (subScriptionData.status === 200) {
        yield put(actions.actionSaveSubscription(subScriptionData.data.data));
        yield put(actions.actionSetLoader(false));
      }
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processEditActiveSubscriptionApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield EditActiveSubscriptionApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetSubscriptionApiCall({ navigate, loader: false })
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processChangeSubscriptionProductOrderApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield ChangeSubscriptionProductOrderApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processGetAllSubscriptionByOrgApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield GetAllSubscriptionByOrgApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actions.actionSaveAllSubscriptionListByOrg(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processGetAllPaymnetOptionApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield GetAllPaymentOptionApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actions.actionSaveAllPaymentOptionApiCall(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processGetAllTagsApiCall(data) {
  let body = data?.payload?.data;
  let navigate = data?.payload?.navigate;
  try {
    const response = yield GetAllTagsApiCall(body);
    if (response?.status === 200) {
      // console.log(response?.data?.data?.rows,"responseee from get")
      toast.success(response?.data?.msg);
      yield put(actions.actionSaveAllTagsApiCall(response?.data?.data?.rows));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processGetAllProvinceApiCall() {
  try {
    const response = yield AudienceProvinceApiCall();
    if (response?.status === 200) {
      // toast.success(response?.data?.msg);
      yield put(
        actions.actionSaveAllProvinceApiCall(response?.data?.data?.rows)
      );
    }
  } catch (err) {
    catchHandler(err);
  }
}
function* processGetAllCityApiCall() {
  try {
    const response = yield AudienceCityApiCall();
    if (response?.status === 200) {
      // toast.success(response?.data?.msg);
      yield put(actions.actionSaveAllCityApiCall(response?.data?.data?.rows));
    }
  } catch (err) {
    catchHandler(err);
  }
}
function* processGetAllClassificationsApiCall() {
  try {
    const response = yield GetAllClassificationsApiCall();
    if (response?.status === 200) {
      // toast.success(response?.data?.msg);
      yield put(
        actions.actionSaveAllClassificationsApiCall(response?.data?.data?.rows)
      );
    }
  } catch (err) {
    catchHandler(err);
  }
}

function* processCreateManualPaymentForOrgApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let handleClose = data.payload.handleClose;
  try {
    const response = yield CreateManualPaymentForOrgApiCall(body);
    if (response.status === 200) {
      handleClose();
      const paymentHistory = yield GetAllSubscriptionByOrgApiCall(
        data.payload.data.agency_id
      );
      if (paymentHistory.status === 200) {
        yield put(
          actions.actionSaveAllSubscriptionListByOrg(paymentHistory.data.data)
        );
      }
      toast.success(response.data.msg);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processStopSubscriptionApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield StopSubscriptionApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      const getOrgData = yield GetAllSubscriptionByOrgApiCall(
        data.payload.orgId
      );
      if (getOrgData.status === 200) {
        yield put(
          actions.actionSaveAllSubscriptionListByOrg(getOrgData.data.data)
        );
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processEditSubscriptionInOrgApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let handleClose = data.payload.handleClose;
  let id = data.payload.agency_id;

  try {
    const response = yield EditSubscriptionInOrgApiCall(body);
    if (response.status === 200) {
      const paymentHistory = yield GetAllSubscriptionByOrgApiCall(id);
      if (paymentHistory.status === 200) {
        yield put(
          actions.actionSaveAllSubscriptionListByOrg(paymentHistory.data.data)
        );
      }
      toast.success(response.data.msg);
      handleClose();
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementBranchData(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield getBranchesTableData(data.payload.page);
    if (response.status === 200) {
      yield put(
        actions.actionSaveBranchTableSystemApiCall(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(actions.actionSetTotalBranchCount(response.data.data.count));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* BranchData() {
  try {
    const response = yield getBranchesTableData();
    if (response?.status === 200) {
      yield put(actions.saveBranchApiCallData(response?.data?.data?.rows));
    }
  } catch (err) {
    catchHandler(err);
  }
}

function* systemManagementAddBranchData(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  let navigate = data.payload.navigate;
  try {
    const response = yield addBranchesData(body);
    if (response.status === 200) {
      toast.success(response.data.data.msg);
      closeModal();
      yield put(actions.actionForCloseCanvas(response?.status));
      yield put(
        actions.actionGetBranchTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementGetNestedBranchData(body) {
  let navigate = body.payload.navigate;
  try {
    const response = yield getBranchesNestedData();

    if (response.status === 200) {
      yield put(
        actions.actionBranchNestedSaveData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* systemManagementDeleteBranchData(data) {
  const id = data?.payload?.id;
  let navigate = data.payload.navigate;
  try {
    const response = yield deleteBranchData(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetBranchTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* systemManagementEditBranchData(data) {
  const body = data?.payload.data;
  const closeModal = data.payload.closePopup;
  let navigate = data.payload.navigate;
  try {
    const response = yield editBranchData(body);

    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(
        actions.actionGetBranchTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* systemManagementGetNestedClassificationData(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield getClassificationNestedData();

    if (response.status === 200) {
      yield put(
        actions.actionClassificationNestedStoreAPi(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementClassificationAddData(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  try {
    const response = yield addClassificationData(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.data.msg);
      closeModal();

      yield put(actions.actionClassificationGetTableApi({ navigate, page: 1 }));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementClassificationTableData(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield getClassificationTableData(data.payload.page);
    if (response.status === 200) {
      toast.success(response.data.data.msg);

      yield put(
        actions.actionClassificationStoreTableApi(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(
        actions.actionSetTotalClassificationCount(response.data.data.count)
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteClassificationData(data) {
  const id = data?.payload?.id;
  let navigate = data.payload.navigate;
  try {
    const response = yield deleteClassification(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actions.actionClassificationGetTableApi({ navigate, page: 1 }));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* addEditContactApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield addContactApiCall(data.payload);
    if (response.status === 200) {
      yield put(actions.actionViewOrganizationApiCall(data.payload.orgApicall));
      toast.success(response.data.msg);
      data.payload.CloseModal();
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* actionDeleteContactApiCall(data) {
  let navigate = data.payload.navigate;
  const id = data?.payload.id;
  const closeModal = data.payload.CloseModal;

  try {
    const response = yield deleteContactApiCall(id);
    if (response.status === 200) {
      yield put(actions.actionViewOrganizationApiCall(data.payload.orgApicall));
      toast.success(response.data.msg);
      closeModal();
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementCountriesData(data) {
  let navigate = data.payload.navigate;
  yield put(actions.actionSetLoader(true));
  try {
    const response = yield getCountriesTableData(
      data.payload.page,
      data.payload.search
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveCountriesTableData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(actions.actionSetTotalCountriesCount(response.data.data.count));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementCountriesAddData(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;
  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield addCountriesData(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));
      yield put(
        actions.actionGetCountriesTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementRemoveCountriesDataApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;

  try {
    const response = yield deleteCountriesApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetCountriesTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementGetMunicipalityDataApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;

  try {
    const response = yield getMunicipalityTableDataApiCall(
      data.payload.page,
      data.payload.search,
      data.payload.country_part_id,
      data.payload.province_id
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveMunicipalityTableData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(
        actions.actionSetTotalMunicipalityCount(response.data.data.count)
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementAddMunicipalityDataApiCall(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;
  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield addMunicipalityDataApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));

      yield put(
        actions.actionGetMunicipalityTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteMunicipalityDataApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;

  try {
    const response = yield deleteMunicipalityDataApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetMunicipalityTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processAddAgencyBranchesApiCall(data) {
  const navigate = data.payload.navigate;

  try {
    const response = yield addAgencyBranchesApiCall(data.payload);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionViewOrganizationApiCall(data.payload.viewPayload)
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* systemManagementGetDistrictDataApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;

  try {
    const response = yield getDistrictTableDataApiCall(
      data.payload.page,
      data.payload.search,
      data.payload.city_id
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveDistrictTableData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(actions.actionSetTotalDistrictCount(response.data.data.count));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processAddAgencyClassificationApiCall(data) {
  const navigate = data.payload.navigate;

  try {
    const response = yield addAgencyClassificationApiCall(data.payload);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionViewOrganizationApiCall(data.payload.viewPayload)
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* systemManagementAddDistrictDataApiCall(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;
  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield addDistrictDataApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));

      yield put(
        actions.actionGetDistrictTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteDistrictDataApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;

  try {
    const response = yield deleteDistrictDataApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetDistrictTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementGetNeighbourhoodDataApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield getNeighbourhoodTableDataApiCall(
      data.payload.page,
      data.payload.search,
      data.payload.city_id
    );

    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));

      yield put(
        actions.actionSaveNeighbourhoodTableData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(
        actions.actionSetTotalNeighbourhoodCount(response.data.data.count)
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementAddNeighbourhoodDataApiCall(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;
  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield addNeighbourhoodDataApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));
      yield put(
        actions.actionGetNeighbourhoodTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteNeighbourhoodDataApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;

  try {
    const response = yield deleteNeighbourhoodDataApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetNeighbourhoodTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementGetCountryPartDataApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield getCountryPartTableDataApiCall(
      data.payload.page,
      data.payload.search,
      data.payload.country_id
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveCountryPartTableData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(
        actions.actionSetTotalCountryPartCount(response.data.data.count)
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementAddCountryPartDataApiCall(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;
  yield put(actions.actionSetButtonLoader(true));

  try {
    const response = yield addCountryPartDataApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));

      yield put(
        actions.actionGetCountryPartTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteCountryPartDataApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;

  try {
    const response = yield deleteCountryPartDataApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetCountryPartTableSystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementGetCityApiCall(data) {
  if (data.payload.loader) {
    yield put(actions.actionSetLoader(true));
  }
  const navigate = data.payload.navigate;

  try {
    let { page, search, edit, municipality_id, loader } = data.payload;

    const response = yield getCityApiCall(page, search, edit, municipality_id);
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveCityData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(actions.actionSetTotalCityCount(response.data.data.count));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementAddCityApiCall(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;

  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield addCityApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));
      yield put(
        actions.actionGetCitySystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteCityApiCall(data) {
  const id = data?.payload?.id;
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;

  try {
    const response = yield deleteCityApiCall(id);
    yield put(actions.actionSetLoader(false));
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetCitySystemApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementGetProvinceApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield getProvinceApiCall(
      data.payload.page,
      data.payload.search,
      data.payload.id,
      data.payload.country_id
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveProvinceData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(actions.actionSetTotalProvinceCount(response.data.data.count));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementZipcodeApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    let {
      page,
      search,
      edit,
      country_id,
      municipality_id,
      province_id,
      city_id,
      neighbourhood_id,
      district_id,
      contry_part_id,
      code,
      id,
    } = data.payload;
    const response = yield getZipcodeApiCall(
      page,
      search,
      edit,
      country_id,
      municipality_id,
      province_id,
      city_id,
      neighbourhood_id,
      district_id,
      contry_part_id,
      code,
      id
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveZipcodeData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
      yield put(actions.actionSetTotalZipcodeCount(response.data.data.count));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementAddZipcodeApiCall(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;

  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield addZipcodeApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));
      yield put(
        actions.actionGetZipcodeApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteZipcodeApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;

  try {
    const response = yield deleteZipcodeApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetZipcodeApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementAddProvinceDataApiCall(data) {
  const body = data.payload.data;
  const closeModal = data.payload.closePopup;
  const navigate = data.payload.navigate;
  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield addProvinceDataApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionSetButtonLoader(false));

      yield put(
        actions.actionGetProvinceApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteProvinceDataApiCall(data) {
  const id = data?.payload?.id;
  let navigate = data.payload.navigate;
  try {
    const response = yield deleteProvinceDataApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(
        actions.actionGetProvinceApiCall({
          navigate,
          page: 1,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* systemManagementContactLogApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    let { agency_member_log_id, agency_id } = data.payload;
    const response = yield getContactLogApiCall(
      agency_member_log_id,
      agency_id
    );
    if (response.status === 200) {
      yield put(
        actions.actionSaveContactLogData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementAddContactLogApiCall(data) {
  const body = data.payload.data;
  const navigate = data.payload.navigate;
  const getapiCall = data.payload.getapiCall;
  const closeModal = data.payload.closePopup;
  try {
    const response = yield addContactLogDataApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionGetContactLogApiCall(getapiCall));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteContactLogApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;
  const closeModal = data.payload.closePopup;
  const getapiCall = data.payload.getapiCall;

  try {
    const response = yield deleteContactLogDataApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();

      yield put(actions.actionGetContactLogApiCall(getapiCall));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementGetAutoSuggestCityApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    let { house_no, zipcode } = data.payload;
    const response = yield getAutoSuggestCityApiCall(house_no, zipcode);
    if (response.status === 200) {
      yield put(
        actions.actionSaveAutoSuggestCityData(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementAddAddressOrgApiCall(data) {
  const body = data.payload.data;
  const navigate = data.payload.navigate;
  const getapiCall = data.payload.getapiCall;
  const closeModal = data.payload.closePopup;
  try {
    const response = yield addAddressOrgApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionViewOrganizationApiCall(getapiCall));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteAddressOrgApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;
  const closeModal = data.payload.closePopup;
  const getapiCall = data.payload.getapiCall;

  try {
    const response = yield deleteAddressOrgApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionViewOrganizationApiCall(getapiCall));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* systemManagementGetTranslationColumnApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    let columnData;
    if (!data.payload.column) {
      columnData = yield MasterTableGetTranslationApiCall();
    }

    const response = yield getTranslationColumnApiCall(
      data.payload.search,
      data.payload.language,
      data.payload.column
        ? data.payload.column
        : columnData.data.data[0].column,
      data.payload.page,
      data.payload.per_page ? data.payload.per_page : false
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveTranslationColumn(response.data.data.translation.rows)
      );
      yield put(
        actions.actionSetTotalTranslationColumnCount(
          response.data.data.translation.count
        )
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementAddEditTranslationApiCall(data) {
  const body = data.payload.data;
  let closeModal;
  if (data.payload.closePopup) {
    closeModal = data.payload.closePopup;
  }
  const navigate = data.payload.navigate;
  yield put(actions.actionSetButtonLoader(true));
  try {
    const response = yield AddEditTranslationApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      if (closeModal) {
        closeModal();
      }
      if (data.payload.close) {
        data.payload.close();
      }
      yield put(actions.actionSetButtonLoader(false));
      const getTranslationData = {
        navigate,
        search: false,
        language: JSON.parse(body).language ? JSON.parse(body).language : "en",
        column: JSON.parse(body).column,
        page: 1,
      };
      yield put(
        actions.actionSaveTranslationColumnFilter({
          label: JSON.parse(body).column,
          value: JSON.parse(body).column,
        })
      );
      yield put(
        actions.actionSaveTranslationLanguageFilter({
          language: JSON.parse(body).language,
          language_id: JSON.parse(body).language_id,
        })
      );
      yield put(actions.actionGetTranslationColumnApiCall(getTranslationData));
    }
  } catch (err) {
    yield put(actions.actionSetButtonLoader(false));
    catchHandler(err, navigate);
  }
}
function* systemManagementAddInternalBannerApiCall(data) {
  const body = data.payload.data;
  const navigate = data.payload.navigate;
  const closeModal = data.payload.closePopup;
  try {
    const response = yield addInternalBannerDataApiCall(
      body,
      data.payload.edit
    );
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(
        actions.actionGetInternalBannerApiCall({
          navigate,
          agency_id: data.payload.agency_id,
        })
      );
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteInternalBannerApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;
  const closeModal = data.payload.closePopup;

  try {
    const response = yield deleteInternalBannerDataApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(
        actions.actionGetInternalBannerApiCall({
          navigate,
          agency_id: data.payload.agency_id,
        })
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* systemManagementGetInternalBannerApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield getInternalBannerApiCall(
      data.payload.agency_id,
      data.payload.search
    );
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveInternalBannerApiCall(
          response.data.data.rows
            ? response.data.data.rows
            : response.data.data
            ? response.data.data
            : []
        )
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementCityInfoApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield getCityInfoApiCall(data.payload.search);
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(
        actions.actionSaveCityInfo(
          response.data ? response.data.slice(0, 20) : []
        )
      );
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementGetVatApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield getVatDataApiCall(data.payload.page);
    if (response.status === 200) {
      yield put(actions.actionSetLoader(false));
      yield put(actions.actionSaveVatData(response.data.data.rows));
      yield put(actions.actionSetTotalVatDataCount(response.data.data.count));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementUpdateVatDataApiCall(data) {
  const body = data.payload.data;

  const navigate = data.payload.navigate;
  yield put(actions.actionSetLoader(true));
  try {
    const response = yield UpdateVatDataApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);

      yield put(actions.actionSetLoader(false));
      const vatData = {
        navigate,
        page: 1,
      };

      yield put(actions.actionGetVatApiCall(vatData));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processcontactLinkingToOrgApiCall(data) {
  const body = data.payload.data;
  const navigate = data.payload.navigate;
  try {
    const response = yield contactLinkingToOrgApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actions.actionViewOrganizationApiCall(data.payload.apiCall));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
function* processgetBannerUrlApiCall(data) {
  yield put(actions.actionSetLoader(true));
  const body = data.payload.data;
  const navigate = data.payload.navigate;
  try {
    const response = yield getBannerUrlApiCall(body);
    if (response.status === 200) {
      toast.success("Image Uploaded Sucessfully");
      yield put(actions.actionSetLoader(false));
      yield put(actions.actionSaveBannerUrlData(response.data.data));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processBannerCampaignsApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;

  try {
    const response = yield BannerCampaignsApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveBannerCampaignsListing(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processViewPropertyBannerApiCall(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;
  try {
    const response = yield ViewPropertyBannerApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveBannerCampaignData(response.data.data));
      navigate(`/single-campaign/${body.id}`);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processViewSupplierApiCall(data) {
  console.log("PROCESS VIEW SUPPLIER");
  console.log(data);
  let navigate = data.payload.navigate;
  const body = data.payload.data.id;
  try {
    const response = yield getSupplierData(body);
    console.log(response.status);
    if (response.status === 200) {
      console.log(response.data);
      yield put(actions.actionSaveSupplierData(response.data.data));
      navigate(`/supplier-details/${body}`);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processDeletePropertyBannerApiCall(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;
  try {
    const response = yield DeletePropertyBannerApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveBannerCampaignsListing(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processMakeBannerStopApiCall(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;
  try {
    const response = yield MakeBannerStopApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveBannerCampaignsListing(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processInvoicesApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;

  try {
    const response = yield InvoicesApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveInvoiceListing(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processViewInvoiceApiCall(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;
  try {
    const response = yield ViewInvoiceApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveInvoiceData(response.data.data));
      navigate(`/single-invoice/${body.id}`);
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processViewUserApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let edit = data.payload.edit;
  try {
    const response = yield ViewUserAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveSingleUserdata(response.data.data));
      if (!edit) {
        navigate(`/single-user/${body}`);
      }
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}
window.newWindow = false;
function* processGetTokenApiCall(data) {
  const body = data.payload.data;
  try {
    const response = yield getToken(body);
    if (response?.status === 200) {
      console.log(response, "reponse for token");
      const token = response?.data?.token;
      const email = response?.data?.data?.email;
      const userId = response?.data?.data?.id;
      const name = response?.data?.data?.full_name;
      yield put(actions.actionSaveToken(token));
      yield put(actions.actionSetLoader(false));
      if (window.location.href.includes("localhost")) {
        //DEV or Local
        const url = new URL("http://localhost:3000");
        url.searchParams.append("token", token);
        url.searchParams.append("email", email);
        url.searchParams.append("userId", userId);
        url.searchParams.append("name", name);
        console.log(window.newWindow, "WIndowssss");
        if (window.newWindow) {
          window.newWindow.close();
        }
        window.newWindow = window.open(
          url.toString(),
          "_blank",
          "width=600,height=400"
        );
        // window.open(url.toString(), "_blank", "width=600,height=400");
      } else if (
        window.location.href.includes("https://wm-admin.testyourapp.online")
      ) {
        const url = new URL(" https://woningmarket.testyourapp.online");
        url.searchParams.append("token", token);
        url.searchParams.append("email", email);
        url.searchParams.append("userId", userId);
        url.searchParams.append("name", name);
        console.log(window.newWindow, "WIndowssss");
        if (window.newWindow) {
          window.newWindow.close();
        }
        window.newWindow = window.open(
          url.toString(),
          "_blank",
          "width=600,height=400"
        );
      } else if (
        window.location.href.includes("https://admin-test.woningmarkt.nl")
      ) {
        //UAT
        const url = new URL("https://wm-test.woningmarkt.nl");
        url.searchParams.append("token", token);
        url.searchParams.append("email", email);
        url.searchParams.append("userId", userId);
        url.searchParams.append("name", name);
        if (window.newWindow) {
          window.newWindow.close();
        }
        window.newWindow = window.open(
          url.toString(),
          "_blank",
          "width=600,height=400"
        );
      } else if (
        window.location.href.includes("https://admin.woningmarkt.nl")
      ) {
        //PROD
        const url = new URL("https://woningmarkt.nl");
        url.searchParams.append("token", token);
        url.searchParams.append("email", email);
        url.searchParams.append("userId", userId);
        url.searchParams.append("name", name);
        if (window.newWindow) {
          window.newWindow.close();
        }
        window.newWindow = window.open(
          url.toString(),
          "_blank",
          "width=600,height=400"
        );
      }
      // window.location.href=`https://woningmarkt.nl/?token=${response?.data?.token}`;
      // window.open(`http://localhost:3001/?token=${response?.data?.token}`, '_blank');
    }
  } catch (err) {
    catchHandler(err);
    yield put(actions.actionSetLoader(false));
  }
}

function* processDeleteInvoiceApiCall(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;

  try {
    const response = yield DeleteInvoiceApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveInvoiceListing(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processHoldInvoiceApiCall(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;
  try {
    const response = yield HoldInvoiceApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveInvoiceListing(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processResendInvoiceApiCall(data) {
  let navigate = data.payload.navigate;
  const body = data.payload.data;
  try {
    const response = yield ResendInvoiceApiCall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveInvoiceListing(response.data.data));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* processaddtemplateApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;

  try {
    const response = yield CreateTemplateAPICall(body);
    if (response.status === 200) {
      toast.success(response?.data?.msg);

      close();
      yield put(actions.actionSaveCreatedTemplates(response?.data?.msg));
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processgettemplateApiCall() {
  yield put(actions.actionSetLoader(true));
  try {
    const response = yield getTemplateAPICall();
    if (response.status === 200) {
      // toast.success(response?.data?.msg);
      // close();
      yield put(
        actions.actionSaveNewCreatedTemplates(response?.data?.data?.rows)
      );
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err);
  }
}
function* processgetCampaignApiCall() {
  yield put(actions.actionSetLoader(true));

  try {
    const response = yield getCampaignAPICall();
    if (response.status === 200) {
      // toast.success(response?.data?.msg);
      // close();
      yield put(
        actions.actionSaveNewCreatedCampaign(response?.data?.data?.rows)
      );
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err);
  }
}
function* processaddtagApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  try {
    const response = yield CreateTagAPICall(body);
    if (response.status === 200) {
      toast.success(response?.data?.data?.msg);
      yield put(actions.actionSaveCreatedTags(response?.data?.data));
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processaddAudienceApiCall(data) {
  yield put(actions.actionSetLoader(true));
  console.log("data inside audience", data);
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  let id = data.payload.id;
  try {
    const response = yield CreateAudienceAPICall(body, id);
    if (response.status === 201) {
      toast.success(response?.data?.msg);
      yield put(actions.actionSaveCreatedAudience(response?.data?.data));
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processaddRecipientApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  try {
    const response = yield CreateRecipientAPICall(body);
    if (response.status === 200) {
      yield put(actions.actionSaveCreatedRecipients(response?.data));
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processDownloadRecipientApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield DownloadRecipientAPICall(body);
    if (response.status === 200) {
      window.open(response?.data?.url, "blank");
      console.log(response, "resfromdownload");
      yield put(actions.actionSaveDowloadedRecipients(response));
      yield put(actions.actionSetLoader(false));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processEditTagApiCall(data) {
  yield put(actions.actionSetLoader(true));

  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;

  try {
    const response = yield updateTagAPICall(body);
    if (response.status === 200) {
      toast.success(response?.data?.data?.msg);
      // yield put(actions.actionSaveEditedTag(response?.data?.data)); // Assuming you have an action to save the edited tag
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processPostSubjectApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let id = data.payload.id;
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  let imgdata = data.payload.imgdata;
  console.log(id, "id from saga");
  try {
    const response = yield sendSubjectApiCall(body, id, imgdata);
    if (response.status === 200) {
      console.log(response?.data?.data, "response from subject saga");
      toast.success(response?.data?.msg);
      yield put(actions.actionSaveSubject(response?.data?.data)); // Assuming you have an action to save the edited tag
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}
function* processPostAllCampaignApiCall(data) {
  yield put(actions.actionSetLoader(true));
  let id = data.payload.id;
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;
  let content = data.payload.content;
  console.log(data, "content from saga");
  try {
    const response = yield sendAllCampaignApiCall(body, id);
    if (response.status === 200) {
      console.log(response?.data?.data, "response from subject saga");
      toast.success(response?.data?.msg);
      yield put(actions.actionSaveAllCampaign(response?.data?.data)); // Assuming you have an action to save the edited tag
      if (content == "content") {
        yield put(actions.actionEmptyContent());
        yield put(actions.actionEmptyAudienceData());
      }
      yield put(actions.actionEmptyAudienceData());
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processDeleteTagApiCall(data) {
  console.log("processDeleteTagApiCall", data);
  let body = data.payload;
  console.log("body inside delete", body);

  try {
    const response = yield deleteTagAPICall(body);
    if (response?.status === 200) {
      console.log("response", response);
      toast.success(response?.data?.msg);
      //   if (body.users_id.length == 1) {
      //     const data = filterData;
      //     const body = { data };
      //     yield put(actions.deleteTagRequest(body));
    }
  } catch (err) {
    catchHandler(err);
  }
}

function* processEditTemplateApiCall(data) {
  yield put(actions.actionSetLoader(true));

  console.log("data inside processEditTemplateApiCall", data.payload.data);
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let close = data.payload.close;

  try {
    const response = yield updateTemplateAPICall(body);
    if (response.status === 200) {
      toast.success(response?.data?.data?.msg);
      // yield put(actions.actionSaveEditedTag(response?.data?.data)); // Assuming you have an action to save the edited tag
      yield put(actions.actionSetLoader(false));
      close();
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* processDeleteTemplateApiCall(data) {
  console.log("processDeleteTemplateApiCall", data);
  let body = data.payload;
  console.log("body inside delete", body);
  try {
    const response = yield deleteTemplateAPICall(body);
    if (response?.status === 200) {
      console.log("response", response);
      toast.success(response?.data?.msg);
      //   if (body.users_id.length == 1) {
      //     const data = filterData;
      //     const body = { data };
      //     yield put(actions.deleteTagRequest(body));
    }
  } catch (err) {
    catchHandler(err);
  }
}
function* processDeleteCampaignApiCall(data) {
  console.log("processDeleteCmpaignApiCall", data);
  let body = data.payload;
  console.log("body inside delete", body);
  try {
    const response = yield deleteCampaignAPICall(body);
    if (response?.status === 200) {
      console.log("response", response);
      toast.success(response?.data?.msg);
      //   if (body.users_id.length == 1) {
      //     const data = filterData;
      //     const body = { data };
      //     yield put(actions.deleteTagRequest(body));
    }
  } catch (err) {
    catchHandler(err);
  }
}

function* systemManagementAddLocationApiCall(data) {
  const body = data.payload.data;
  const navigate = data.payload.navigate;
  const getapiCall = data.payload.getapiCall;
  const closeModal = data.payload.closePopup;
  try {
    const response = yield addLocationApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionViewSupplierApiCall(getapiCall));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementDeleteLocationApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;
  const closeModal = data.payload.closePopup;
  const getapiCall = data.payload.getapiCall;

  try {
    const response = yield deleteLocationApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionViewSupplierApiCall(getapiCall));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* systemManagementAddPriceTableApiCall(data) {
  const body = data.payload.data;
  const navigate = data.payload.navigate;
  const getapiCall = data.payload.getapiCall;
  const closeModal = data.payload.closePopup;
  try {
    const response = yield addPriceTableApiCall(body, data.payload.edit);
    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionViewSupplierApiCall(getapiCall));
    }
  } catch (err) {
    catchHandler(err, navigate);
  }
}

function* systemManagementDeletePriceTableApiCall(data) {
  const id = data?.payload?.id;
  const navigate = data.payload.navigate;
  const closeModal = data.payload.closePopup;
  const getapiCall = data.payload.getapiCall;

  try {
    const response = yield deletePriceTableApiCall(id);

    if (response.status === 200) {
      toast.success(response.data.msg);
      closeModal();
      yield put(actions.actionViewSupplierApiCall(getapiCall));
    }
  } catch (err) {
    yield put(actions.actionSetLoader(false));
    catchHandler(err, navigate);
  }
}

function* CommonSaga() {
  yield takeEvery(actions.LOGIN_API_CALL, processLoginApiCall);
  yield takeEvery(actions.USER_LIST_API_CALL, processUserListApiCall);
  yield takeEvery(actions.CREATE_USER_API_CALL, processCreateUserApiCall);
  yield takeEvery(actions.DELETE_USER_API_CALL, processDeleteUserApiCall);
  yield takeEvery(actions.BLOCK_USER_API_CALL, processBlockUserApiCall);
  yield takeEvery(actions.VIEW_USER_API_CALL, processViewUserApiCall);
  yield takeEvery(actions.ADD_USER_ORG_API_CALL, processAddUserOrgApiCall);
  yield takeEvery(actions.UNBLOCK_USER_API_CALL, processUnblockUserApiCall);
  yield takeEvery(
    actions.ORGANIZATION_LIST_API_CALL,
    processOrganizationListApiCall
  );
  yield takeEvery(
    actions.VIEW_ORGANIZATION_API_CALL,
    processViewOrganizationApiCall
  );

  yield takeEvery(
    actions.DELETE_ORGANIZATION_API_CALL,
    processDeleteOrganizationApiCall
  );

  yield takeEvery(
    actions.BLOCK_ORGANIZATION_API_CALL,
    processBlockOrganizationApiCall
  );
  yield takeEvery(
    actions.UPDATE_ORGANIZATION_API_CALL,
    processUpdateOrganizationApiCall
  );
  yield takeEvery(
    actions.CREATE_ORGANIZATION_API_CALL,
    processCreateOrganizationApiCall
  );
  yield takeEvery(actions.ADD_TAG_API_CALL, processaddtagApiCall);
  yield takeEvery(actions.ADD_AUDIENCE_API_CALL, processaddAudienceApiCall);
  yield takeEvery(actions.ADD_RECIPIENT_API_CALL, processaddRecipientApiCall);
  yield takeEvery(
    actions.DOWNLOAD_ALL_RECIPIENT_API_CALL,
    processDownloadRecipientApiCall
  );
  yield takeEvery(actions.GET_TEMPLATE_API_CALL, processaddtemplateApiCall);
  yield takeEvery(
    actions.GET_CREATED_TEMPLATE_API_CALL,
    processgettemplateApiCall
  );
  yield takeEvery(actions.GET_CAMPAIGN_API_CALL, processgetCampaignApiCall);

  yield takeEvery(actions.RUN_IMPORT_API_CALL, processRunImportApiCall);

  yield takeEvery(
    actions.REMOVE_USER_ORG_API_CALL,
    processRemoveUserOrgApiCall
  );
  yield takeEvery(
    actions.VIEW_LOG_HISTORY_API_CALL,
    processViewLogHistoryAPICall
  );
  yield takeEvery(
    actions.GET_PROPERTY_LIST_API_CALL,
    processGetPropertyListAPICall
  );
  yield takeEvery(
    actions.REMOVE_JOINED_ORG_API_CALL,
    processremoveJoinedOrganisationAPICall
  );
  yield takeEvery(
    actions.CHANGE_ADMIN_ORG_API_CALL,
    processChangeAdminOrgApiCall
  );
  yield takeEvery(
    actions.UPDATE_PROPERTY_VISIBLE_API_CALL,
    processUpdatePropertyVisibleApiCall
  );
  yield takeEvery(
    actions.GET_PROPERTY_DETAIL_API_CALL,
    processGetPropertyDetailApiCall
  );
  yield takeEvery(
    actions.EDIT_PROPERTY_STATUS_API_CALL,
    processEditPropertyStatusApiCall
  );
  yield takeEvery(
    actions.GET_PROPERTY_VIEWS_API_CALL,
    processGetProprtyViewsApiCall
  );
  yield takeEvery(
    actions.GET_PRICE_HISTORY_API_CALL,
    processGetPriceHistoryCall
  );
  yield takeEvery(
    actions.HIDE_ALL_LISTING_OF_ORG_API_CALL,
    processHideAllListingAPICall
  );
  yield takeEvery(
    actions.SEND_PASSWORD_LINK_API_CALL,
    processSendPasswordLinkAPICall
  );
  yield takeEvery(actions.LANG_LIST_API_CALL, processLangListAPICall);
  yield takeEvery(
    actions.LANG_LABEL_LIST_API_CALL,
    processLangLabelListAPICall
  );
  yield takeEvery(
    actions.UPDATE_LANG_LABEL_API_CALL,
    processUpdateLangLabelListAPICall
  );
  yield takeEvery(
    actions.CHANGE_ADMIN_PROPERTY_API_CALL,
    processChangeAdminPropertyAPICall
  );
  yield takeEvery(
    actions.DELETE_PROPERTY_API_CALL,
    processDeletePropertyAPICall
  );
  yield takeEvery(actions.EDIT_PROPERTY_API_CALL, processEditPropertyAPICall);
  yield takeEvery(actions.LOGOUT_API_CALL, processLogoutAPICall);
  yield takeEvery(
    actions.APPROVE_ORGANIZATION_API_CALL,
    processApproveOrganizationAPICall
  );
  yield takeEvery(
    actions.UNBLOCK_ORGANIZATION_API_CALL,
    processUnBlockOrganizationAPICall
  );
  yield takeEvery(actions.BANNER_LIST_API_CALL, processBannerListApiCall);
  yield takeEvery(actions.SUPPLIERS_API_CALL, processSuppliersApiCall);
  yield takeEvery(
    actions.ACTION_DELETE_SUPPLIER_API_CALL,
    processDeleteSupplierApiCall
  );

  yield takeEvery(actions.ADD_SUPPLIER_API_CALL, processAddSupplierApiCall);

  yield takeEvery(
    actions.UPDATE_SUPPLIER_API_CALL,
    processUpdateSupplierApiCall
  );

  yield takeEvery(actions.ADD_BANNER_API_CALL, processCreateBannerApiCall);
  yield takeEvery(actions.DELETE_BANNER_API_CALL, processDeleteBannerAPICall);
  yield takeEvery(actions.VIEW_BANNER_API_CALL, processViewBannerApiCall);
  yield takeEvery(actions.EMAIL_LIST_API_CALL, processEmailListApiCall);
  yield takeEvery(actions.VIEW_EMAIL_API_CALL, processViewEmailApiCall);
  yield takeEvery(actions.ADD_EMAIL_API_CALL, processAddEmailApiCall);
  yield takeEvery(
    actions.GET_TRANSLATION_LIST_API_CALL,
    processGetTranslationListApiCall
  );
  yield takeEvery(
    actions.GET_LOCATION_LIST_API_CALL,
    processGetLocationListApiCall
  );

  yield takeEvery(
    actions.GET_SUPPLIER_META_DATA_API_CALL,
    processGetSupplierMetaDataApiCall
  );

  yield takeEvery(actions.GET_TITLE_LIST_API_CALL, processGetTitleListApiCall);
  yield takeEvery(
    actions.GET_ADVERTISING_SUPPLIER_LIST_API_CALL,
    processGetAdvSupplierListApiCall
  );

  yield takeEvery(
    actions.ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL,
    processGetEmployeeJobTitleApiCall
  );
  yield takeEvery(
    actions.UPDATE_JOB_TITLE_API_CALL,
    processUpdateJobTitleApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_SITE_SETTINGS_API_CALL,
    processGetSiteSettingsApiCall
  );
  yield takeEvery(
    actions.ACTION_UPDATE_SITE_SETTINGS_API_CALL,
    processUpdateSiteSettingsApiCall
  );
  yield takeEvery(
    actions.CHANGE_USER_FROM_PROPERTY_API_CALL,
    processChangeUserFromPropertyApiCall
  );
  yield takeEvery(
    actions.CHANGE_IMPORT_STATUS_API_CALL,
    processChangeImportStatusApiCall
  );
  yield takeEvery(
    actions.CHANGE_IMPORT_PROFILE_STATUS_API_CALL,
    processChangeImportProfileStatusApiCall
  );
  yield takeEvery(
    actions.ACTION_MASTER_TABLE_GET_TRANSLATION_COLUMN_API_CALL,
    processMasterTableGetTranslationApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_TRANSLATION_API_CALL,
    processGetTranslationApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_TRANSLATION_API_CALL,
    processAddTranslationApiCall
  );
  yield takeEvery(
    actions.ACTION_EDIT_PROPERTY_DOCUMENTS_API_CALL,
    processEditPropertyDocumentsApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_DISCOUNT_AND_EXTRAS_API_CALL,
    processGetDiscountAndExtrasApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_DISCOUNT_AND_EXTRAS_API_CALL,
    processDeleteDiscountAPICall
  );
  yield takeEvery(
    actions.ACTION_ADD_DISCOUNT_AND_EXTRAS_API_CALL,
    processAddDiscountApiCall
  );
  yield takeEvery(actions.ACTION_ADD_CREDIT_API_CALL, processAddCreditApiCall);
  yield takeEvery(
    actions.ACTION_GET_SUBSCIPTION_API_CALL,
    processGetSubcriptionApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_SUBSCIPTION_API_CALL,
    processDeleteSubsciptionAPICall
  );
  yield takeEvery(
    actions.ACTION_CREATE_SUBSCRIPTION_API_CALL,
    processCreateSubscriptionApiCall
  );
  yield takeEvery(
    actions.ACTION_EDIT_SINGLE_SUBSCIPTION_API_CALL,
    processEditSubscriptionApiCall
  );
  yield takeEvery(
    actions.ACTION_EDIT_ACTIVE_SUBSCIPTION_API_CALL,
    processEditActiveSubscriptionApiCall
  );
  yield takeEvery(
    actions.ACTION_CHANGE_SUBSCIPTION_ORDER_API_CALL,
    processChangeSubscriptionProductOrderApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_ALL_SUBSCIPTION_BY_ORG_API_CALL,
    processGetAllSubscriptionByOrgApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_ALL_PAYMENT_OPTION_API_CALL,
    processGetAllPaymnetOptionApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_ALL_TAGS_API_CALL,
    processGetAllTagsApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_ALL_CLASSIFICATIONS_API_CALL,
    processGetAllClassificationsApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_ALL_PROVINCE_OPTION,
    processGetAllProvinceApiCall
  );
  yield takeEvery(actions.ACTION_GET_ALL_CITY_OPTION, processGetAllCityApiCall);
  yield takeEvery(
    actions.ACTION_CREATE_MANUAL_PAYMENT_API_CALL,
    processCreateManualPaymentForOrgApiCall
  );
  yield takeEvery(
    actions.ACTION_STOP_SUBSCRIPTION_API_CALL,
    processStopSubscriptionApiCall
  );
  yield takeEvery(
    actions.ACTION_EDIT_SUBSCRIPTION_IN_ORG_API_CALL,
    processEditSubscriptionInOrgApiCall
  );

  yield takeEvery(
    actions.ACTION_GET_BRANCH_TABLE_DATA_API_CALL,
    systemManagementBranchData
  );
  yield takeEvery(actions.ACTION_GET_BRANCH, BranchData);
  yield takeEvery(
    actions.ACTION_ADD_BRANCH_DATA,
    systemManagementAddBranchData
  );
  yield takeEvery(
    actions.ACTION_GET_BRANCH_NESTED_DATA,
    systemManagementGetNestedBranchData
  );
  yield takeEvery(
    actions.ACTION_REMOVE_BRANCH_DATA,
    systemManagementDeleteBranchData
  );
  yield takeEvery(
    actions.ACTION_EDIT_BRANCH_DATA,
    systemManagementEditBranchData
  );
  yield takeEvery(
    actions.ACTION_NESTED_CLASSIFICATION_DATA,
    systemManagementGetNestedClassificationData
  );
  yield takeEvery(
    actions.ACTION_ADD_CLASSIFICATION_DATA,
    systemManagementClassificationAddData
  );
  yield takeEvery(
    actions.ACTION_GET_TABLE_CLASSIFICATION_DATA,
    systemManagementClassificationTableData
  );

  yield takeEvery(
    actions.ACTION_REMOVE_CLASSIFICATION_DATA,
    systemManagementDeleteClassificationData
  );
  yield takeEvery(actions.ACTION_ADD_CONTACT_API_CALL, addEditContactApiCall);
  yield takeEvery(
    actions.ACTION_DELETE_CONTACT_API_CALL,
    actionDeleteContactApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_COUNTRIES_TABLE_DATA_API_CALL,
    systemManagementCountriesData
  );
  yield takeEvery(
    actions.ACTION_ADD_COUNTRIES_ADD_API_CALL,
    systemManagementCountriesAddData
  );

  yield takeEvery(
    actions.ACTION_REMOVE_COUNTRIES_DATA_API_CALL,
    systemManagementRemoveCountriesDataApiCall
  );

  yield takeEvery(
    actions.ACTION_GET_MUNICIPALITY_TABLE_DATA_API_CALL,
    systemManagementGetMunicipalityDataApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_MUNICIPALITY_DATA_API_CALL,
    systemManagementAddMunicipalityDataApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_MUNICIPALITY_DATA_API_CALL,
    systemManagementDeleteMunicipalityDataApiCall
  );

  yield takeEvery(
    actions.ACTION_ADD_AGENCY_BRANCH_DATA_API_CALL,
    processAddAgencyBranchesApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_AGENCY_CLASSIFICATION_DATA_API_CALL,
    processAddAgencyClassificationApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_DISTRICT_TABLE_DATA_API_CALL,
    systemManagementGetDistrictDataApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_DISTRICT_DATA_API_CALL,
    systemManagementAddDistrictDataApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_DISTRICT_DATA_API_CALL,
    systemManagementDeleteDistrictDataApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_NEIGHBOURHOOD_TABLE_DATA_API_CALL,
    systemManagementGetNeighbourhoodDataApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_NEIGHBOURHOOD_DATA_API_CALL,
    systemManagementAddNeighbourhoodDataApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_NEIGHBOURHOOD_DATA_API_CALL,
    systemManagementDeleteNeighbourhoodDataApiCall
  );

  yield takeEvery(
    actions.ACTION_GET_COUNTRYPART_TABLE_DATA_API_CALL,
    systemManagementGetCountryPartDataApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_COUNTRYPART_DATA_API_CALL,
    systemManagementAddCountryPartDataApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_COUNTRYPART_DATA_API_CALL,
    systemManagementDeleteCountryPartDataApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_CITY_API_CALL,
    systemManagementGetCityApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_CITY_API_CALL,
    systemManagementAddCityApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_CITY_API_CALL,
    systemManagementDeleteCityApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_PROVINCE_API_CALL,
    systemManagementGetProvinceApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_ZIPCODE_API_CALL,
    systemManagementZipcodeApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_ZIPCODE_API_CALL,
    systemManagementAddZipcodeApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_ZIPCODE_API_CALL,
    systemManagementDeleteZipcodeApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_PROVINCE_DATA_API_CALL,
    systemManagementAddProvinceDataApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_PROVINCE_DATA_API_CALL,
    systemManagementDeleteProvinceDataApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_CONTACT_LOG_API_CALL,
    systemManagementContactLogApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_CONTACT_LOG_API_CALL,
    systemManagementAddContactLogApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_CONTACT_LOG_API_CALL,
    systemManagementDeleteContactLogApiCall
  );
  yield takeEvery(
    actions.ACTION_AUTO_SUGGEST_CITY_API_CALL,
    systemManagementGetAutoSuggestCityApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_ADDRESS_ORG_API_CALL,
    systemManagementAddAddressOrgApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_ADDRESS_ORG_API_CALL,
    systemManagementDeleteAddressOrgApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_LOCATION_API_CALL,
    systemManagementAddLocationApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_LOCATION_API_CALL,
    systemManagementDeleteLocationApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_PRICE_TABLE_API_CALL,
    systemManagementAddPriceTableApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_PRICE_TABLE_API_CALL,
    systemManagementDeletePriceTableApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_TRANSLATION_COLUMN_API_CALL,
    systemManagementGetTranslationColumnApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_EDIT_TRANSLATION_API_CALL,
    systemManagementAddEditTranslationApiCall
  );
  yield takeEvery(
    actions.ACTION_ADD_INTERNAL_BANNER_API_CALL,
    systemManagementAddInternalBannerApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_INTERNAL_BANNER_API_CALL,
    systemManagementDeleteInternalBannerApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_INTERNAL_BANNER_API_CALL,
    systemManagementGetInternalBannerApiCall
  );
  yield takeEvery(
    actions.ACTION_CITY_INFO_API_CALL,
    systemManagementCityInfoApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_VAT_API_CALL,
    systemManagementGetVatApiCall
  );
  yield takeEvery(
    actions.ACTION_UPDATE_VAT_DATA,
    systemManagementUpdateVatDataApiCall
  );
  yield takeEvery(
    actions.CONTACT_LINKING_TO_ORG_API_CALL,
    processcontactLinkingToOrgApiCall
  );
  yield takeEvery(
    actions.ACTION_POST_SUBJECT_API_CALL,
    processPostSubjectApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_ALL_CAMPAIGN_API_CALL,
    processPostAllCampaignApiCall
  );
  yield takeEvery(
    actions.ACTION_GET_BANNER_URL_API_CALL,
    processgetBannerUrlApiCall
  );
  yield takeEvery(
    actions.BANNER_CAMPAIGNS_API_CALL,
    processBannerCampaignsApiCall
  );
  yield takeEvery(
    actions.ACTION_VIEW_PROPERTY_BANNER_API_CALL,
    processViewPropertyBannerApiCall
  );
  yield takeEvery(
    actions.ACTION_VIEW_SUPPLIER_API_CALL,
    processViewSupplierApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_PROPERTY_BANNER_API_CALL,
    processDeletePropertyBannerApiCall
  );
  yield takeEvery(
    actions.MAKE_BANNER_STOP_API_CALL,
    processMakeBannerStopApiCall
  );

  yield takeEvery(actions.INVOICES_API_CALL, processInvoicesApiCall);
  yield takeEvery(
    actions.ACTION_VIEW_INVOICE_API_CALL,
    processViewInvoiceApiCall
  );
  yield takeEvery(actions.ACTION_VIEW_TOKEN_API_CALL, processGetTokenApiCall);
  yield takeEvery(
    actions.ACTION_DELETE_INVOICE_API_CALL,
    processDeleteInvoiceApiCall
  );
  yield takeEvery(actions.HOLD_INVOICE_API_CALL, processHoldInvoiceApiCall);
  yield takeEvery(actions.RESEND_INVOICE_API_CALL, processResendInvoiceApiCall);
  yield takeEvery(actions.ACTION_UPDATE_TAGS_SUCCESS, processEditTagApiCall);
  yield takeEvery(actions.ACTION_DELETE_TAG_SUCCESS, processDeleteTagApiCall);

  //TEMPLATE
  yield takeEvery(
    actions.ACTION_UPDATE_TEMPLATE_SUCCESS,
    processEditTemplateApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_TEMPLATE_SUCCESS,
    processDeleteTemplateApiCall
  );
  yield takeEvery(
    actions.ACTION_DELETE_CAMPAIGN_SUCCESS,
    processDeleteCampaignApiCall
  );
}
export default CommonSaga;
