import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import classes from "./languagemanagementheader.module.scss";
import { useTranslation } from "react-i18next";
import SideBar from "../SideBar";
import OffcanvasMain from "../Offcanvas";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionLangLabelListAPICall,
  actionLangListAPICall,
  actionUpdateLangLabelListAPICall,
  setperPageLangCount,
} from "../../../Redux/Actions";

const LanguageManagementHeader = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const langdata = useSelector(
    (state) => state.CommonReducer?.SaveLangListData
  );
  const [name, setName] = useState();
  const [sidebar, setSideBar] = useState(false);
  const state = useSelector((state) => state.CommonReducer);
  const [perpage, setPerPage] = useState(
    state?.perPageLangCount != "" ? state?.perPageLangCount : 40
  );
  const [lang, setLang] = useState("en");
  const [type, setType] = useState(0);
  const typeArray = [
    {
      value: 0,
      label: "Server",
    },
    {
      value: 1,
      label: "App",
    },
    {
      value: 2,
      label: "Web",
    },
  ];

  useEffect(() => {
    const body = { navigate };
    dispatch(actionLangListAPICall(body));
  }, []);
  useEffect(() => {
    filterClick();
    dispatch(setperPageLangCount(perpage));
  }, [perpage]);

  const sidebarShow = () => {
    setSideBar(true);
  };
  const handleClose = () => {
    setSideBar(false);
  };
  const filterClick = () => {
    const data = {
      page: 1,
      label_value: name,
      per_page: perpage,
      lang: lang,
      type: type,
    };
    const body = { data, navigate };
    dispatch(actionLangLabelListAPICall(body));
  };
  const onClickOfButton = (e) => {
    e.preventDefault();
    filterClick();
  };
  return (
    <React.Fragment>
      <div className={classes.HeaderWrap}>
        <div className={classes.ManagmentSection}>
          <div className={classes.CanvasWarp}>
            <button onClick={sidebarShow}>
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={classes.FilterSection}>
            <h5>{t("language_management")}</h5>
            <form onSubmit={onClickOfButton}>
              <div className={classes.managementFild}>
                <div className={classes.fildBox}>
                  <input
                    type="text"
                    placeholder={`${t("search")}...`}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className={classes.fildBox}>
                  <select
                    id="status"
                    name="status"
                    onChange={(e) => setLang(e.target.value)}
                  >
                    {langdata?.map((item, index) => {
                      return (
                        <option
                          value={item.language}
                          selected={index == 0 ? true : false}
                        >
                          {item.language_label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={classes.fildBox}>
                  <select
                    id="status"
                    name="status"
                    onChange={(e) => setType(e.target.value)}
                  >
                    {typeArray?.map((item, index) => {
                      return (
                        <option
                          value={item.value}
                          selected={index == 0 ? true : false}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={classes.fildBox}>
                  <button onClick={() => filterClick()}>{t("filter")}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={classes.UsersPerPageSection}>
          <p>{t("lang_per_page")}</p>
          <div className={classes.numberOfUser}>
            <select
              id="cars"
              name="cars"
              onChange={(e) => setPerPage(e.target.value)}
            >
              <option value={40} selected={40 == perpage}>
                40
              </option>
              <option value={50} selected={50 == perpage}>
                50
              </option>
              <option value={60} selected={60 == perpage}>
                60
              </option>
              <option value={70} selected={70 == perpage}>
                70
              </option>
            </select>
          </div>
        </div>
      </div>
      <OffcanvasMain showcanvas={sidebar} handleClose={handleClose}>
        <SideBar />
      </OffcanvasMain>
    </React.Fragment>
  );
};

export default LanguageManagementHeader;
